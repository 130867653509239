import { OhsFilterList } from 'dashboard/topFilter/OhsFilterModels';
import { OhsSafetyPlanViewPresets } from 'dashboard/topFilter/OhsSafetyPlanFilterModels';
import { SafetyPlanListsRecordState } from 'safety-plan/OhsSafetyPlanModels';
import { OhsUser } from 'user/OhsUser';

export const getSafetyPlanListViewPreset = (tierNum: number): OhsSafetyPlanViewPresets => {
  switch (tierNum) {
    case 1:
      return OhsSafetyPlanViewPresets.View1SafetyPlanList;
    case 2:
      return OhsSafetyPlanViewPresets.View1SafetyPlanList;
    case 3:
      return OhsSafetyPlanViewPresets.View2SafetyPlanList;
    case 4:
      return OhsSafetyPlanViewPresets.View3SafetyPlanList;
    default:
      return OhsSafetyPlanViewPresets.View2SafetyPlanList;
  }
};

export const getSafetyPlanSchedListViewPreset = (
  tierNum: number
): OhsSafetyPlanViewPresets | undefined => {
  switch (tierNum) {
    case 3:
      return OhsSafetyPlanViewPresets.View1SafetyPlanSchedList;
    case 4:
      return OhsSafetyPlanViewPresets.View2SafetyPlanSchedList;
    default:
      return undefined;
  }
};

export const updateSafetyPlanTabSortFilters = (
  user: OhsUser,
  filter: OhsFilterList,
  tabKey: string | null
) => {
  const defaultFilter = new OhsFilterList(user, tabKey);
  const updateSortFilter = {
    ...defaultFilter.safetyPlanModule,
  };
  return { ...filter, safetyPlanModule: updateSortFilter };
};

export const getSafetyPlanFilter = (
  filter: OhsFilterList,
  currentViewPreset: OhsSafetyPlanViewPresets
) => {
  const safetyPlanFilter = {
    [currentViewPreset]: filter.safetyPlanModule[currentViewPreset],
    sort: filter.safetyPlanModule.sort,
  };
  return safetyPlanFilter;
};

export const archiveUnArchiveSafetyPlanList = (
  safetyPlan: SafetyPlanListsRecordState,
  filter: OhsFilterList
) => {
  const updateArchiveStatus = {
    ...filter,
    safetyPlanModule: {
      ...filter.safetyPlanModule,
      [safetyPlan.currentViewPreset]: {
        ...filter.safetyPlanModule[safetyPlan.currentViewPreset],
        archived: !filter.safetyPlanModule[safetyPlan.currentViewPreset].archived,
      },
    },
  };
  return updateArchiveStatus;
};
