import { createSelector, createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResponseType } from 'global-services/api/OhsApiModels';

import OhsXsiRecord from '../OhsXsiModels';
import { getXsiList } from '../OhsXsiServices';

export interface XsiListsRecordState {
  isLoading: boolean;
  xsiList: OhsXsiRecord[] | null;
}

const initialState: XsiListsRecordState = {
  isLoading: false,
  xsiList: null,
};

export const fetchXsiListsAsync = createAsyncThunk<
  ApiResponseType<{
    items: OhsXsiRecord[];
  }> | null,
  string,
  { state: RootState }
>('xsi/fetchXsiLists', async (orgId: string, thunkAPI) => {
  try {
    const response = await getXsiList(orgId);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const xsiSlice = createSlice({
  name: 'xsi',
  initialState,
  reducers: {
    setXsiIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchXsiListsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchXsiListsAsync.fulfilled, (state, action) => {
        if (action.payload?.success) {
          state.xsiList = action.payload.result?.items ?? [];
          state.isLoading = false;
        }
      })
      .addCase(fetchXsiListsAsync.rejected, (state) => {
        state.xsiList = null;
        state.isLoading = false;
      });
  },
});

// Memoized Selectors
const xsiState = (state: RootState) => state.xsi;

export const getOhsXsiLists = createSelector([xsiState], (xsi) => xsi);

export const { setXsiIsLoading } = xsiSlice.actions;
export const xsiModuleReducer = xsiSlice.reducer;
