import React from 'react';

import { Label, SemanticCOLORS } from 'semantic-ui-react';

interface Props {
  value?: any;
  color?: string;
}

function OhsCircularLabel(props: Props) {
  let applyColor;
  if (props.color === 'nocolor') {
    applyColor = 'blue';
  }
  if (typeof props.value === 'number') {
    if (props.value === 0) {
      applyColor = 'grey';
    } else {
      applyColor = 'blue';
    }
  }
  if (props.color && props.color !== 'nocolor') {
    applyColor = props.color;
  }
  return (
    <Label circular color={applyColor as SemanticCOLORS | undefined}>
      {props.value}
    </Label>
  );
}
OhsCircularLabel.defaultProps = {
  value: 0,
  color: 'nocolor',
};
export default OhsCircularLabel;
