import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';

import { RiskType, SDSType } from './OhsChemicalRecord';

export interface PartialChronologyType {
  name: string;
  purpose: string | null;
  sds: SDSType;
  risk: RiskType;
}
export default class OhsChemicalTaskRecord {
  attachments: OhsAttachment[];

  complete: boolean | null;

  completionNotes: string;

  createdBy: OhsModuleCreateBy;

  dateCreated: string;

  dateDue: string;

  dateCompleted: string;

  dateSignedoff: string;

  dateToReview?: string | null;

  purpose: string | null;

  sds: SDSType;

  signedoffBy: {
    name: string;
    email: string;
  };

  description: string;

  for?: { _id: string; type: ModuleType.Chemical };

  type: ModuleType.Task;

  tzDateCreated: string;

  tzDateSignedoff: string;

  _id: string;

  name: string;

  title: string;

  changeNotes?: string;

  chemicalNoLongerUse: boolean;

  sdsUpdateAvailable: string | null;

  partialChronology: PartialChronologyType[];

  risk: RiskType;

  constructor() {
    this.attachments = [];
    this.complete = null;
    this.completionNotes = '';
    this.createdBy = new OhsModuleCreateBy();
    this.dateCreated = '';
    this.dateDue = '';
    this.dateCompleted = '';
    this.dateSignedoff = '';
    this.dateToReview = '';
    this.purpose = '';
    this.signedoffBy = {
      name: '',
      email: '',
    };
    this.description = '';
    this.for = undefined;
    this.type = ModuleType.Task;
    this.tzDateCreated = '';
    this.tzDateSignedoff = '';
    this._id = '';
    this.title = '';
    this.name = '';
    this.chemicalNoLongerUse = false;
    this.sdsUpdateAvailable = '';
    this.sds = {
      dateIssued: '',
      attachments: [],
    };
    this.partialChronology = [];
    this.risk = {
      assessmentCompleted: null,
      classificationGHS: [],
      dangerousGood: null,
      hazardousSubstance: null,
      attachments: [],
    };
  }
}
