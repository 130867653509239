import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import moment from 'moment';

import { RootState } from 'app/store';
import { ApiResListsType, OhsApiRequestName } from 'global-services/api/OhsApiModels';
import { getOhsLocalStorage } from 'global-services/OhsDataParse';
import globalModuleSearch from 'search/OhsSearchServices';
import {
  GlobalSearchState,
  OhsGlobalSearchFilter,
  OhsGlobalSearchPayload,
} from 'search/OhsSearchModels';
import { TierType } from 'global-services/constants/OhsObject';
import handleRegisterAsyncData, {
  handleRegisterCountAsyncData,
} from 'global-components/register/OhsModuleRegisterUtils';

import {
  AllocatedListType,
  OhsInspectionFilterPayload,
  OhsAvailableInspectionFilterPayload,
  OhsInspectionViewPresets,
  OhsInspectionRootState,
} from './OhsInspectionModels';
import OhsInspectionRecord from './models/OhsInspectionRecord';
import {
  getAvailableInspectionList,
  getInspectionAllocatedList,
  getInspectionCopyList,
  getInspectionList,
  getInspectionListWithAllocations,
  getInspectionScheduleList,
} from './OhsInspectionServices';

export interface InspectionListRecordState {
  isLoading: boolean;
  inspectionList: ApiResListsType<any[]> | null;
  inspectionScheduleList: ApiResListsType<any[]> | null;
  allocatedList: AllocatedListType[] | null;
  inspectionCopyList: {
    isLoading: boolean;
    copyList: OhsInspectionRecord[];
  };
  availableInspectionList: ApiResListsType<any[]> | null;
  currentPage: number;
  currentViewPreset: OhsInspectionViewPresets;
  availableInspectionSearch: GlobalSearchState;
}

export const defaultInspectionAvailableSearch: OhsGlobalSearchFilter = {
  page: 1,
  count: false,
  modules: [],
  skipOrgRecords: false,
  workplaces: [],
  task: {
    complete: false,
    includeMasterRecords: false,
  },
  skipAllocatedRecords: false,
  archived: false,
};

const initialInspectionSearchPayload: OhsGlobalSearchPayload = {
  searchKey: '',
  substringSearch: true,
  highlight: false,
  filter: defaultInspectionAvailableSearch,
};

const initSearchResults = {
  items: [],
  pagination: {
    page: 1,
    totalPages: 1,
    next: '',
  },
};

const initialState: InspectionListRecordState = {
  isLoading: false,
  inspectionList: null,
  inspectionScheduleList: null,
  allocatedList: null,
  inspectionCopyList: {
    isLoading: false,
    copyList: [],
  },
  availableInspectionList: null,
  availableInspectionSearch: {
    isLoading: false,
    searchInfo: initialInspectionSearchPayload,
    searchResults: initSearchResults,
    currentPage: 1,
  },
  currentPage: 1,
  currentViewPreset: OhsInspectionViewPresets.view2InspectionScheduledList,
};

export const fetchInspectionAllocatedListAsync = createAsyncThunk<
  AllocatedListType[],
  { _ids: string[] },
  { state: RootState }
>('inspection/fetchInspectionAllocatedList', async (inspectionIDs, thunkAPI): Promise<any> => {
  try {
    const response = await getInspectionAllocatedList(inspectionIDs._ids);
    const resVal = response;
    return resVal;
  } catch (err: any) {
    const error: AxiosError<any> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const inspectionSearch = async (
  searchDetails: any,
  apiRequestName?: OhsApiRequestName
): Promise<ApiResListsType<OhsInspectionRecord[]> | null> => {
  const globalSearchRes: any = await globalModuleSearch(searchDetails, apiRequestName);
  return globalSearchRes.result;
};

const inspectionListRPC = async (
  inspectionState: OhsInspectionRootState,
  inspectionFilters: OhsInspectionFilterPayload,
  scheduled: boolean,
  count: boolean,
  orgAdminTierId?: string
): Promise<ApiResListsType<any[]> | null> => {
  const { user, globalSearch } = inspectionState;
  const userTier = user.user?.tierNum ?? 0;
  const hasGlobalSearch = inspectionState.globalSearch.searchInfo.searchKey !== '';

  const getSearchFilter = () => {
    const { filter } = globalSearch.searchInfo;
    if (scheduled) {
      return {
        ...globalSearch.searchInfo,
        filter: {
          skipOrgRecords: filter.skipOrgRecords,
          workplaces: filter.workplaces,
          page: filter.page,
          count,
          archived: filter.archived,
        },
      };
    }
    if (!scheduled && user?.user?.tier?.type === TierType.T3) {
      return { ...globalSearch.searchInfo, filter: { ...filter, skipAllocatedRecords: true } };
    }
    return { ...globalSearch.searchInfo, filter: { ...filter, count } };
  };

  const withViewPermission = user.user?.configs.inspection?.PERMISSIONS.view;

  if (userTier) {
    if (userTier === 1 && orgAdminTierId) {
      const response = getInspectionList(inspectionFilters, orgAdminTierId);
      return response;
    }

    if (withViewPermission) {
      if (scheduled) {
        if (userTier === 3 || userTier === 4) {
          return hasGlobalSearch
            ? inspectionSearch(getSearchFilter(), OhsApiRequestName.InspectionScheduleSearch)
            : getInspectionScheduleList(inspectionFilters);
        }
      } else {
        if (userTier === 2) {
          return hasGlobalSearch
            ? inspectionSearch(getSearchFilter())
            : getInspectionList(inspectionFilters);
        }
        if (userTier === 3) {
          return hasGlobalSearch
            ? inspectionSearch(getSearchFilter())
            : getInspectionListWithAllocations(inspectionFilters);
        }
        if (userTier === 4) {
          return hasGlobalSearch
            ? inspectionSearch(getSearchFilter())
            : getInspectionList(inspectionFilters);
        }
      }
    }
  }
  return null;
};

export const fetchInspectionListAsync = createAsyncThunk<
  ApiResListsType<OhsInspectionRecord[]> | null,
  string | undefined
>('inspection/fetchInspectionList', async (orgAdminTierId: string | undefined, thunkAPI) => {
  const {
    inspection,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsInspectionRootState;

  const inspectionState = thunkAPI.getState() as OhsInspectionRootState;
  const data = inspection.inspectionLists;
  const { currentPage, currentViewPreset } = inspection;
  const viewPresetFilter = filterInfo.inspectionModule[currentViewPreset];
  const setInspectionFilters: OhsInspectionFilterPayload = {
    ...viewPresetFilter,
    next: currentPage > 10 ? data?.pagination.next ?? '' : undefined,
    // remove page if it is greater than 10
    ...(currentPage <= 10 && { page: currentPage }),
    sort: { ...JSON.parse(String(filterInfo.inspectionModule[currentViewPreset].sort)) },
    count: false,
  };

  try {
    const response = inspectionListRPC(
      inspectionState,
      setInspectionFilters,
      false,
      false,
      orgAdminTierId
    );
    return await response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchInspectionListCountAsync = createAsyncThunk<
  ApiResListsType<OhsInspectionRecord[]> | null,
  string | undefined
>('inspection/fetchInspectionCountList', async (orgAdminTierId: string | undefined, thunkAPI) => {
  const {
    inspection,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsInspectionRootState;
  const { currentViewPreset } = inspection;
  const viewPresetFilter = filterInfo.inspectionModule[currentViewPreset];
  const inspectionState = thunkAPI.getState() as OhsInspectionRootState;
  const inspectionFiltersWithCount: OhsInspectionFilterPayload = {
    ...viewPresetFilter,
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  try {
    const response = await inspectionListRPC(
      inspectionState,
      inspectionFiltersWithCount,
      false,
      true,
      orgAdminTierId
    );
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchInspectionScheduleListAsync = createAsyncThunk<
  ApiResListsType<OhsInspectionRecord[]> | null,
  undefined
>('inspection/fetchInspectionScheduleList', async (_, thunkAPI) => {
  const {
    inspection,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsInspectionRootState;
  const inspectionState = thunkAPI.getState() as OhsInspectionRootState;

  const data = inspection.inspectionLists;
  const { currentPage, currentViewPreset } = inspection;
  const viewPresetFilter = filterInfo.inspectionModule[currentViewPreset];

  const setInspectionFilters: OhsInspectionFilterPayload = {
    ...viewPresetFilter,
    dateDue: filterInfo.inspectionModule.dateDue
      ? {
          start: moment(new Date(filterInfo.inspectionModule.dateDue.start)).format('YYYY-MM-DD'),
          end: moment(new Date(filterInfo.inspectionModule.dateDue.end)).format('YYYY-MM-DD'),
        }
      : undefined,
    next: currentPage > 10 ? data?.pagination?.next ?? '' : '',
    page: currentPage > 10 ? 0 : currentPage,
    sort: { ...JSON.parse(String(filterInfo.inspectionModule[currentViewPreset].sort)) },
    count: false,
  };

  try {
    const response = await inspectionListRPC(inspectionState, setInspectionFilters, true, false);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchInspectionScheduleListCountAsync = createAsyncThunk<
  ApiResListsType<OhsInspectionRecord[]> | null,
  undefined
>('inspection/fetchInspectionScheduleCountList', async (_, thunkAPI) => {
  const {
    inspection,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsInspectionRootState;

  const inspectionState = thunkAPI.getState() as OhsInspectionRootState;
  const { currentViewPreset } = inspection;
  const viewPresetFilter = filterInfo.inspectionModule[currentViewPreset];

  const filtersWithCount: OhsInspectionFilterPayload = {
    ...viewPresetFilter,
    dateDue: filterInfo.inspectionModule.dateDue
      ? {
          start: moment(new Date(filterInfo.inspectionModule.dateDue.start)).format('YYYY-MM-DD'),
          end: moment(new Date(filterInfo.inspectionModule.dateDue.end)).format('YYYY-MM-DD'),
        }
      : undefined,
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  try {
    const response = await inspectionListRPC(inspectionState, filtersWithCount, true, true);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchInspectionCopyListAsync = createAsyncThunk<
  OhsInspectionRecord[],
  undefined,
  { state: RootState }
>('inspection/fetchInspectionCopyList', async (inspectionIDs, thunkAPI): Promise<any> => {
  try {
    const response = await getInspectionCopyList();
    const resVal = response;
    return resVal;
  } catch (err: any) {
    const error: AxiosError<any> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchAvailableInspectionListCountAsync = createAsyncThunk<
  ApiResListsType<OhsInspectionRecord[]> | null,
  string | undefined
>('inspection/fetchAvailableInspectionListCountAsync', async (_, thunkAPI: any) => {
  const { inspection } = thunkAPI.getState() as OhsInspectionRootState;
  const { currentPage } = inspection;

  const inspectionAvailableSearch = inspection.availableInspectionSearch;

  const setInspectionFilters: OhsAvailableInspectionFilterPayload = {
    page: currentPage > 10 ? 0 : currentPage,

    sort: { key: '_id', order: 1 },
    count: true,
  };

  const searchInfo = {
    searchKey: inspectionAvailableSearch.searchInfo.searchKey,
    substringSearch: inspectionAvailableSearch.searchInfo.substringSearch ?? false,
    highlight: inspectionAvailableSearch.searchInfo.highlight ?? false,
    filter: { count: true, page: inspectionAvailableSearch?.searchInfo?.filter?.page },
  };

  try {
    const response =
      inspectionAvailableSearch?.searchInfo?.searchKey !== ''
        ? await inspectionSearch(searchInfo, OhsApiRequestName.InspectionAvailableSearch)
        : await getAvailableInspectionList(setInspectionFilters);

    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchAvailableInspectionListAsync = createAsyncThunk<
  ApiResListsType<OhsInspectionRecord[]> | null,
  undefined
>('inspection/fetchAvailableInspectionListAsync', async (_, thunkAPI: any) => {
  const { inspection } = thunkAPI.getState() as OhsInspectionRootState;
  const { currentPage } = inspection;
  const inspectionAvailableSearch: any = inspection.availableInspectionSearch;

  const setInspectionFilters: OhsAvailableInspectionFilterPayload = {
    page: currentPage > 10 ? 0 : currentPage,

    sort: { ...JSON.parse(String('{ "key": "title", "order": 1 }')) },
    count: false,
  };

  const searchInfo = {
    searchKey: inspectionAvailableSearch.searchInfo.searchKey,
    substringSearch: inspectionAvailableSearch.searchInfo.substringSearch ?? false,
    highlight: inspectionAvailableSearch.searchInfo.highlight ?? false,
    filter: { count: false, page: inspectionAvailableSearch?.searchInfo?.filter?.page },
  };

  try {
    const response =
      inspectionAvailableSearch?.searchInfo?.searchKey !== ''
        ? await inspectionSearch(searchInfo, OhsApiRequestName.InspectionAvailableSearch)
        : await getAvailableInspectionList(setInspectionFilters);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const inspectionSlice = createSlice({
  name: 'inspection',
  initialState,
  reducers: {
    setCurrentRegisterPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setInspectionIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setViewPreset: (state, action: PayloadAction<OhsInspectionViewPresets>) => {
      state.currentViewPreset = action.payload;
    },
    setAvailableInspectionSearch: (state, action: PayloadAction<OhsGlobalSearchPayload>) => {
      state.availableInspectionSearch.searchInfo = action.payload;
    },
    clearAvailableInspectionSearch: (state) => {
      state.availableInspectionSearch.searchInfo =
        initialState.availableInspectionSearch.searchInfo;
    },
    setAvailableInspectionSearchCurrentPage: (state, action: PayloadAction<number>) => {
      state.availableInspectionSearch.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInspectionListAsync.pending, (state) => {
        state.isLoading = true;
        state.inspectionList = {
          items: [],
          pagination: state.inspectionList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchInspectionListAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const inspectionResult = action.payload;
          const currentTotalPage: number = getOhsLocalStorage('inspectionListTotalPages');

          state.inspectionList = {
            items: [...inspectionResult.items],
            pagination: { ...inspectionResult.pagination, totalPages: Number(currentTotalPage) },
          };
          state.isLoading = false;
        }
      })
      .addCase(fetchInspectionListAsync.rejected, (state) => {
        state.inspectionList = null;
      })

      .addCase(fetchInspectionScheduleListAsync.pending, (state) => {
        state.isLoading = true;
        state.inspectionScheduleList = {
          items: [],
          pagination: state.inspectionScheduleList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchInspectionScheduleListAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const inspectionScheduleResult = action.payload;
          const inspectionScheduleList = handleRegisterAsyncData(
            inspectionScheduleResult,
            'inspectionScheduleListTotalPages'
          );
          state.isLoading = false;
          state.inspectionScheduleList = inspectionScheduleList;
        }
      })
      .addCase(fetchInspectionScheduleListAsync.rejected, (state) => {
        state.inspectionScheduleList = null;
      })
      .addCase(fetchInspectionScheduleListCountAsync.fulfilled, (state, action) => {
        if (state.inspectionScheduleList && action.payload) {
          const countInspectionScheduleList = handleRegisterCountAsyncData(
            action.payload,
            state.inspectionScheduleList,
            'inspectionScheduleListTotalPages'
          );
          state.inspectionScheduleList = countInspectionScheduleList;
          state.isLoading = false;
        }
      })
      .addCase(fetchInspectionAllocatedListAsync.pending, (state) => {
        state.allocatedList = [];
      })
      .addCase(fetchInspectionAllocatedListAsync.fulfilled, (state, action) => {
        state.allocatedList = action.payload ?? [];
        state.isLoading = false;
      })
      .addCase(fetchInspectionCopyListAsync.pending, (state) => {
        state.inspectionCopyList.copyList = [];
        state.inspectionCopyList.isLoading = true;
      })
      .addCase(fetchInspectionCopyListAsync.fulfilled, (state, action) => {
        state.inspectionCopyList.copyList = action.payload ?? [];
        state.inspectionCopyList.isLoading = false;
      })
      .addCase(fetchInspectionListCountAsync.fulfilled, (state, action) => {
        if (state.inspectionList && action.payload) {
          const countInspectionList = handleRegisterCountAsyncData(
            action.payload,
            state.inspectionList,
            'inspectionListTotalPages'
          );
          state.inspectionList = countInspectionList;
        }
      })
      .addCase(fetchAvailableInspectionListCountAsync.fulfilled, (state, action) => {
        if (state.availableInspectionList && action.payload) {
          const countAvailableInspectionList = handleRegisterCountAsyncData(
            action.payload,
            state.availableInspectionList,
            'availableInspectionListTotalPages'
          );
          state.availableInspectionList = countAvailableInspectionList;
          state.isLoading = false;
        }
      })
      .addCase(fetchAvailableInspectionListAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const availableInspectionList = handleRegisterAsyncData(
            action.payload,
            'availableInspectionListTotalPages'
          );
          state.isLoading = false;
          state.availableInspectionList = availableInspectionList;
        }
      })
      .addCase(fetchAvailableInspectionListAsync.rejected, (state) => {
        state.availableInspectionList = null;
      });
  },
});
// Memoized Selectors
const inspectionState = (state: RootState) => state.inspection;
export const getOhsInspectionList = createSelector([inspectionState], (inspection) => inspection);
export const getAvailableInspectionSearch = createSelector(
  [inspectionState],
  (inspection) => inspection.availableInspectionSearch
);

export const {
  setCurrentRegisterPage,
  setInspectionIsLoading,
  setViewPreset,
  setAvailableInspectionSearch,
  clearAvailableInspectionSearch,
  setAvailableInspectionSearchCurrentPage,
} = inspectionSlice.actions;
export const inspectionModuleReducer = inspectionSlice.reducer;
