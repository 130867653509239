import { createAsyncThunk } from '@reduxjs/toolkit';

import getNoticeBoardLists, {
  getNoticeBoardFormList,
  getNoticeBoardListsWithAllocations,
} from 'notice-board/OhsNoticeBoardService';
import {
  OhsNoticeBoardListsFilterPayload,
  OhsNoticeBoardRootState,
} from 'notice-board/model/OhsNoticeBoardModel';
import { ApiResListsType, OhsApiRequestName } from 'global-services/api/OhsApiModels';
import { OhsUserState } from 'user/userSlice';
import globalSearchKey from 'search/OhsSearchServices';
import { OhsGlobalSearchPayload } from 'search/OhsSearchModels';
import OhsNoticeBoardRecord from 'notice-board/model/OhsNoticeBoardRecord';
import OhsFetchAll from 'global-services/OhsFetchAll';
import { updateSearchInfo } from 'search/OhsSearchUtils';

const noticeBoardSearch = async (
  task: OhsGlobalSearchPayload,
  count: boolean,
  page: number
): Promise<ApiResListsType<OhsNoticeBoardRecord[]> | null> => {
  const searchInfo = { ...task, filter: { ...task.filter, count, page } };
  const globalSearchRes: any = await globalSearchKey(searchInfo);
  return globalSearchRes.result;
};

const noticeBoardListsRPC = async (
  user: OhsUserState,
  filters: OhsNoticeBoardListsFilterPayload,
  searchPayload: OhsGlobalSearchPayload,
  page: number
): Promise<any> => {
  let searchRequestApi = OhsApiRequestName.List;
  if (filters.viewPreset === 'view_1') {
    searchRequestApi = OhsApiRequestName.List;
  }
  const searchInfo = updateSearchInfo(searchPayload, searchRequestApi, user);
  const hasGlobalSearch = searchInfo?.searchKey !== '';
  const userTier = user.user?.tierNum ?? 0;
  if (userTier && userTier === 3 && user.user?.configs.noticeboard?.PERMISSIONS.view) {
    const response = hasGlobalSearch
      ? await noticeBoardSearch(searchInfo, false, page)
      : await getNoticeBoardListsWithAllocations(filters);
    return response;
  }
  if (
    userTier &&
    (userTier === 2 || userTier === 4) &&
    user.user?.configs.noticeboard?.PERMISSIONS.view
  ) {
    const response = hasGlobalSearch
      ? await noticeBoardSearch(searchInfo, false, page)
      : await getNoticeBoardLists(filters);
    return response;
  }

  return null;
};

export const fetchNoticeBoardListsAsync = createAsyncThunk<
  ApiResListsType<any[]> | null,
  undefined
>('noticeboard/fetchNoticeBoardList', async (_, thunkAPI) => {
  const {
    user,
    noticeBoard,
    globalfilter: { filterInfo },
    globalSearch,
  } = thunkAPI.getState() as OhsNoticeBoardRootState;
  const { currentViewPreset, currentPage } = noticeBoard;
  const viewPresetFilter = filterInfo.noticeboardModule[currentViewPreset];
  const setNoticeBoardFilters: OhsNoticeBoardListsFilterPayload = {
    ...viewPresetFilter,
    page: currentPage > 10 ? 0 : currentPage,
    sort: { ...JSON.parse(String(filterInfo.noticeboardModule.sort)) },
    count: false,
  };

  try {
    const response = await noticeBoardListsRPC(
      user,
      setNoticeBoardFilters,
      globalSearch.searchInfo,
      currentPage
    );
    return response as ApiResListsType<any[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchNoticeBoardFullListsAsync = createAsyncThunk<
  ApiResListsType<any[]> | null,
  undefined
>('noticeboard/fetchNoticeBoardFullList', async (__, thunkAPI) => {
  const {
    noticeBoard,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsNoticeBoardRootState;
  const { currentViewPreset } = noticeBoard;
  const viewPresetFilter: any = filterInfo.noticeboardModule[currentViewPreset];

  try {
    const noticeBoardList: any = OhsFetchAll(getNoticeBoardLists, {
      ...viewPresetFilter,
      sort: JSON.parse(String(filterInfo.noticeboardModule.sort)),
    });
    return noticeBoardList as ApiResListsType<any[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

const noticeBoardFormListRPC = async (
  user: OhsUserState,
  filters: OhsNoticeBoardListsFilterPayload,
  searchInfo: OhsGlobalSearchPayload,
  page: number
): Promise<any> => {
  const hasGlobalSearch = searchInfo?.searchKey !== '';
  const userTier = user.user?.tierNum ?? 0;
  if (
    userTier &&
    (userTier === 2 || userTier === 4 || userTier === 3) &&
    user.user?.configs.noticeboard?.PERMISSIONS.view
  ) {
    const response = hasGlobalSearch
      ? await noticeBoardSearch(searchInfo, false, page)
      : await getNoticeBoardFormList(filters);
    return response;
  }

  return null;
};

export const fetchNoticeBoardFormListAsync = createAsyncThunk<
  ApiResListsType<any[]> | null,
  undefined
>('noticeboard/fetchNoticeBoardFormList', async (_, thunkAPI) => {
  const {
    user,
    noticeBoard,
    globalfilter: { filterInfo },
    globalSearch,
  } = thunkAPI.getState() as OhsNoticeBoardRootState;
  const { currentViewPreset, currentPage } = noticeBoard;
  const viewPresetFilter = filterInfo.noticeboardModule[currentViewPreset];
  const setNoticeBoardFilters: OhsNoticeBoardListsFilterPayload = {
    ...viewPresetFilter,
    viewPreset: 'view_1',
    page: currentPage > 10 ? 0 : currentPage,
    sort: { ...JSON.parse(String(filterInfo.noticeboardModule.sort)) },
    count: false,
  };

  try {
    const response = await noticeBoardFormListRPC(
      user,
      setNoticeBoardFilters,
      globalSearch.searchInfo,
      currentPage
    );
    return response as ApiResListsType<any[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchNoticeBoardFormListCountAsync = createAsyncThunk<ApiResListsType<
  OhsNoticeBoardRecord[]
> | null>('noticeboard/fetchNoticeBoardFormListWithCount', async (_, thunkAPI) => {
  const {
    noticeBoard,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsNoticeBoardRootState;

  const { currentViewPreset } = noticeBoard;
  const viewPresetFilter = filterInfo.noticeboardModule[currentViewPreset];

  const noticeboardFiltersWithCount: OhsNoticeBoardListsFilterPayload = {
    ...viewPresetFilter,
    viewPreset: 'view_1',
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  try {
    const response = await getNoticeBoardFormList(noticeboardFiltersWithCount);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchNoticeBoardListsCountAsync = createAsyncThunk<ApiResListsType<
  OhsNoticeBoardRecord[]
> | null>('noticeboard/fetchNoticeBoardListWithCount', async (_, thunkAPI) => {
  const {
    user,
    noticeBoard,
    globalSearch: { searchInfo },
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsNoticeBoardRootState;
  const hasGlobalSearch = !!searchInfo.searchKey;
  const { currentViewPreset } = noticeBoard;
  const viewPresetFilter = filterInfo.noticeboardModule[currentViewPreset];

  const noticeboardFiltersWithCount: OhsNoticeBoardListsFilterPayload = {
    ...viewPresetFilter,
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };
  const searchPayload = updateSearchInfo(searchInfo, OhsApiRequestName.List, user);

  try {
    const response = hasGlobalSearch
      ? await noticeBoardSearch(searchPayload, true, 1)
      : await getNoticeBoardLists(noticeboardFiltersWithCount);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
