import React, { useEffect } from 'react';

import _ from 'lodash';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import '../OhsFormField.css';
import { dateDiffBetween, isValidDate } from 'global-services/utils/OhsDateServices';

import OhsDatePicker, { useStyles as datePickerUseStyle } from './date-picker/OhsDatePicker';
import { OhsFormFieldBaseProps, OhsFormLayoutType } from '../services/OhsFormModels';
import OhsFormFieldLayout from '../components/OhsFormFieldLayout';
import { returnVariant } from '../components/OhsFieldLayout';

export interface Props extends OhsFormFieldBaseProps {
  maxDate?: { name: string; date: string };
  minDate?: { name: string; date: string };
  isClearable?: boolean;
}

function InputEle(props: Props) {
  const { classes } = datePickerUseStyle();
  const disabled = props.disabled || false;
  const isClearable = props.isClearable !== undefined ? props.isClearable : true;
  const date = props.ohsFormSettings.useFormMethods.watch(props.id) ?? null;

  const handleClear = () => {
    props.ohsFormSettings.useFormMethods.setValue(
      props.id,
      props.defaultValue !== undefined ? props.defaultValue !== undefined : null
    );
  };

  const validation = React.useCallback(
    () =>
      props.ohsFormSettings.useFormMethods.register(props.id, {
        required: props.required === true,
        validate: {
          validDate: (value: any) => {
            if (value && value.trim() && !isValidDate(value)) {
              return 'Date Format Invalid: yyyy-mm-dd.';
            }

            return true;
          },
          maxDate: () => {
            if (date && props.maxDate) {
              try {
                if (dateDiffBetween(date, props.maxDate.date) < 0) {
                  return `The date must not be after ${props.maxDate.name.toLowerCase()}.`;
                }
              } catch (error) {
                console.error(error);
              }
            }

            return true;
          },
          minDate: () => {
            if (date && props.minDate) {
              try {
                if (dateDiffBetween(props.minDate.date, date) < 0) {
                  return `The date must not be before ${props.minDate.name.toLowerCase()}.`;
                }
              } catch (error) {
                console.error(error);
              }
            }

            return true;
          },
          notEmpty: (value: any) => {
            if (props.required === true) {
              if (value == null || value.trim() === '') {
                return false;
              }
            }

            return true;
          },
        },
      }),
    [
      date,
      props.maxDate,
      props.id,
      props.ohsFormSettings.useFormMethods,
      props.required,
      props.minDate,
    ]
  );

  useEffect(() => {
    if (!_.isNil(date) && _.isEmpty(date)) {
      handleClear();
    }
  }, [date]);

  return (
    <div
      style={{
        marginTop:
          props.ohsFormSettings.layout === OhsFormLayoutType.AngularModalForm ? '8px' : undefined,
      }}
    >
      <OhsDatePicker
        maxDate={props.maxDate?.date}
        minDate={props.minDate?.date}
        disabled={disabled}
        onChange={(value: string) => {
          if (isValidDate(value) === true) {
            props.ohsFormSettings.useFormMethods.setValue(props.id, value);
            props.ohsFormSettings.useFormMethods.trigger(props.id);
          }
        }}
        date={date}
        renderInput={(params: any) => (
          <div className={classes.container}>
            <TextField
              fullWidth
              variant={returnVariant(props.ohsFormSettings.layout)}
              size="small"
              {...params}
              {...validation()}
              inputProps={{ 'data-testid': props.id }}
              placeholder="yyyy-mm-dd"
            />
            {!disabled && isClearable && date && (
              <div className={classes.closeContainer}>
                <CloseIcon className={classes.close} onClick={handleClear} />
              </div>
            )}
          </div>
        )}
      />
    </div>
  );
}

const defaultProps = {
  maxDate: undefined,
  minDate: undefined,
  isClearable: true,
};

InputEle.defaultProps = defaultProps;

export default function OhsDateField(props: Props) {
  return <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />;
}

OhsDateField.defaultProps = defaultProps;
