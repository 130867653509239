import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';
import { ApiResListsType, OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import { cusvalDefCreate, cusvalDefEdit } from 'admin2/OhsAdmin2Services';

import { OhsVisitorFilterPayload } from '../OhsVisitorModels';
import OhsVisitorFormRecord from './models/OhsVisitorFormRecord';

export const addVisitorForm = async (payload: any) => {
  if (payload.cusvals && payload.cusvals.length) {
    const cusDef = (await cusvalDefCreate({ cusvals: payload.cusvals })) as any;
    if (cusDef) payload.cusvals = { _id: cusDef._id, type: cusDef.type };
  } else payload.cusvals = null;
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    OhsApiRequestName.FormCreate,
    payload
  );
};

export const editVisitorForm = async (payload: any) => {
  if (payload.cusvals && payload.cusvals.length) {
    if (payload.cusvalsId) {
      const cusDef = (await cusvalDefEdit({
        _id: payload.cusvalsId,
        cusvals: payload.cusvals,
      })) as any;
      if (cusDef) payload.cusvals = { _id: payload.cusvalsId, type: cusDef.type };
    } else {
      const cusDef = (await cusvalDefCreate({ cusvals: payload.cusvals })) as any;
      if (cusDef) payload.cusvals = { _id: cusDef._id, type: cusDef.type };
    }
  } else payload.cusvals = null;
  return OhsApiRequest(RequestType.Module, ModuleType.Visitor, OhsApiRequestName.FormEdit, payload);
};

export const getVisitorForm = async (_id: string): Promise<OhsVisitorFormRecord> => {
  const res: OhsVisitorFormRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    OhsApiRequestName.FormFetch,
    { _id }
  );
  return res || new OhsVisitorFormRecord();
};

export const getVisitorFormLists = (
  filterInfo: OhsVisitorFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Visitor, OhsApiRequestName.FormList, {
    filter: filterInfo,
  });
};

export const archiveVisitorForm = (formId: string, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    archived ? OhsApiRequestName.FormArchive : OhsApiRequestName.FormUnArchive,
    {
      _id: formId,
    }
  );
};
