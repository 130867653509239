import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import { OhsCusval } from 'global-components/form/cusvals/OhsCusvalModel';
import OhsCusvalDefinition from 'global-components/form/cusvals/OhsCusvalDefinition';

import OhsCorporateRiskRecord from './OhsCorporateRiskRecord';

export default class OhsCorporateRiskTaskRecord {
  _id: string;

  referenceId: string | null;

  attachments: OhsAttachment[];

  previousAttachments?: OhsAttachment[];

  complete: boolean;

  cusvals?: OhsCusval[];

  cusvalsDefinition?: OhsCusvalDefinition;

  subcategoryCusvals?: OhsCusval[];

  subcategoryCusvalsDefinition?: OhsCusvalDefinition;

  dateCompleted: string;

  dateExpiry: string;

  dateIssued: string;

  dateDue: string;

  dateSignedoff: string;

  description: string;

  reviewNotes: string;

  signatures?: OhsAttachment[];

  signedoffBy: OhsModuleCreateBy;

  tier?: OhsTier | null;

  title: string;

  type: string;

  tzDateSignedoff: string;

  save?: boolean;

  for?: any;

  partialChronology?: OhsCorporateRiskRecord[];

  constructor(tier?: OhsTier) {
    this.referenceId = null;
    this.attachments = new Array<OhsAttachment>();
    this.complete = true;
    this.dateCompleted = '';
    this.dateExpiry = '';
    this.dateIssued = '';
    this.dateDue = '';
    this.dateSignedoff = '';
    this.description = '';
    this.type = '';
    this._id = '';
    this.reviewNotes = '';
    this.signatures = new Array<OhsAttachment>();
    this.signedoffBy = new OhsModuleCreateBy();
    this.type = '';
    this.tier = tier;
    this.title = '';
    this.tzDateSignedoff = '';
  }
}
