import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'lodash';

import { ApiResListsType, OhsApiRequestName } from 'global-services/api/OhsApiModels';
import { OhsVisitorRootState, OhsVisitorFilterPayload } from 'visitor/OhsVisitorModels';
import { OhsUser } from 'user/OhsUser';
import { OhsGlobalSearchPayload } from 'search/OhsSearchModels';
import globalModuleSearch from 'search/OhsSearchServices';

import { getVisitorSiteLists, getVisitorSiteActivityLists } from '../site/OhsVisitorSiteServices';
import { getVisitorFormLists } from '../form/OhsVisitorFormServices';
import { getVisitorPinLists } from '../pin/OhsVisitorPinServices';
import OhsVisitorSiteRecord from '../site/models/OhsVisitorSiteRecord';
import OhsVisitorFormRecord from '../form/models/OhsVisitorFormRecord';
import OhsVisitorPinRecord from '../pin/OhsVisitorPinRecord';

// SITE - LIST
const visitorSiteSearch = async (
  searchDetails: OhsGlobalSearchPayload,
  apiRequestName: OhsApiRequestName
): Promise<ApiResListsType<OhsVisitorSiteRecord[]> | null> => {
  const globalSearchRes: any = await globalModuleSearch(searchDetails, apiRequestName);
  return globalSearchRes.result;
};

export const fetchVisitorSiteListCountAsync = createAsyncThunk<
  ApiResListsType<OhsVisitorSiteRecord[]> | null,
  undefined
>('visitor/site/fetchVisitorSiteCountList', async (_, thunkAPI) => {
  const {
    visitor,
    globalfilter: { filterInfo },
    globalSearch,
  } = thunkAPI.getState() as OhsVisitorRootState;
  const hasGlobalSearch = globalSearch?.searchInfo?.searchKey !== '';

  const { currentViewPreset } = visitor;

  const viewPresetFilter = filterInfo.visitorModule[currentViewPreset];

  const visitorFiltersWithCount: OhsVisitorFilterPayload = {
    ...viewPresetFilter,
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  try {
    const response = hasGlobalSearch
      ? visitorSiteSearch(globalSearch?.searchInfo, OhsApiRequestName.List)
      : getVisitorSiteLists(visitorFiltersWithCount);
    return await response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchVisitorSiteListsAsync = createAsyncThunk<
  ApiResListsType<OhsVisitorSiteRecord[]> | null,
  undefined
>('visitor/site/fetchVisitorSiteList', async (_, thunkAPI) => {
  const {
    visitor,
    globalfilter: { filterInfo },
    globalSearch,
  } = thunkAPI.getState() as OhsVisitorRootState;
  const hasGlobalSearch = globalSearch?.searchInfo?.searchKey !== '';

  const data = visitor.visitorSiteList;
  const { currentPage, currentViewPreset } = visitor;

  const viewPresetFilter = filterInfo.visitorModule[currentViewPreset];

  const visitorSort = get(filterInfo, `visitorModule.${currentViewPreset}.sort`) || '';
  const visitorFilters: OhsVisitorFilterPayload = {
    ...viewPresetFilter,
    next: currentPage > 10 ? data?.pagination?.next ?? '' : '',
    page: visitor.currentPage > 10 ? 0 : visitor.currentPage,
    sort: { ...JSON.parse(String(visitorSort)) },
    count: false,
  };

  try {
    const response = hasGlobalSearch
      ? visitorSiteSearch(globalSearch?.searchInfo, OhsApiRequestName.List)
      : getVisitorSiteLists(visitorFilters);
    return await response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

// SITE - ACTIVITY LIST
export const fetchVisitorSiteActivityListCountAsync = createAsyncThunk<
  ApiResListsType<OhsVisitorSiteRecord[]> | null,
  string
>(
  'visitor/site/fetchVisitorSiteActivityListCountAsync',
  async (visitorSiteId: string, thunkAPI) => {
    const {
      visitor,
      globalfilter: { filterInfo },
      globalSearch,
    } = thunkAPI.getState() as OhsVisitorRootState;
    const hasGlobalSearch = globalSearch?.searchInfo?.searchKey !== '';

    const { currentViewPreset } = visitor;

    const viewPresetFilter = filterInfo.visitorModule[currentViewPreset];

    const visitorFiltersWithCount: OhsVisitorFilterPayload = {
      ...viewPresetFilter,
      count: true,
      page: 1,
      sort: { order: 1, key: '_id' },
    };
    const searchDetails = {
      ...globalSearch?.searchInfo,
      filter: {
        ...globalSearch?.searchInfo.filter,
        leave: !globalSearch?.searchInfo?.filter?.leave,
        siteId: visitorSiteId,
      },
    };

    try {
      const response = hasGlobalSearch
        ? visitorSiteSearch(searchDetails, OhsApiRequestName.VisitList)
        : getVisitorSiteActivityLists(visitorSiteId, visitorFiltersWithCount);
      return await response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchVisitorSiteActivityListsAsync = createAsyncThunk<
  ApiResListsType<OhsVisitorSiteRecord[]> | null,
  string
>('visitor/site/fetchVisitorSiteActivityListsAsync', async (visitorSiteId: string, thunkAPI) => {
  const {
    visitor,
    globalfilter: { filterInfo },
    globalSearch,
  } = thunkAPI.getState() as OhsVisitorRootState;
  const hasGlobalSearch = globalSearch?.searchInfo?.searchKey !== '';

  const data = visitor.visitorSiteList;
  const { currentPage, currentViewPreset } = visitor;

  const viewPresetFilter = filterInfo.visitorModule[currentViewPreset];

  const visitorSort = get(filterInfo, `visitorModule.${currentViewPreset}.sort`) || '';
  const setVisitorFilters: OhsVisitorFilterPayload = {
    ...viewPresetFilter,
    next: currentPage > 10 ? data?.pagination?.next ?? '' : '',
    page: visitor.currentPage > 10 ? 0 : visitor.currentPage,
    sort: { ...JSON.parse(String(visitorSort)) },
    count: false,
  };
  const searchDetails = {
    ...globalSearch?.searchInfo,
    filter: {
      ...globalSearch?.searchInfo.filter,
      leave: !globalSearch?.searchInfo?.filter?.leave,
      siteId: visitorSiteId,
    },
  };

  try {
    const response = hasGlobalSearch
      ? visitorSiteSearch(searchDetails, OhsApiRequestName.VisitList)
      : getVisitorSiteActivityLists(visitorSiteId, setVisitorFilters);
    return await response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

// FORM
export const fetchVisitorFormListCountAsync = createAsyncThunk<
  ApiResListsType<OhsVisitorFormRecord[]> | null,
  undefined
>('visitor/form/fetchVisitorFormCountList', async (_, thunkAPI) => {
  const {
    visitor,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsVisitorRootState;

  const { currentViewPreset } = visitor;

  const viewPresetFilter = filterInfo.visitorModule[currentViewPreset];

  const visitorFiltersWithCount: OhsVisitorFilterPayload = {
    ...viewPresetFilter,
    count: true,
    page: 1,
    sort: { order: -1, key: 'dateCreated' },
  };

  try {
    const response = await getVisitorFormLists(visitorFiltersWithCount);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchVisitorFormListsAsync = createAsyncThunk<
  ApiResListsType<OhsVisitorFormRecord[]> | null,
  undefined
>('visitor/form/fetchVisitorFormList', async (_, thunkAPI) => {
  const {
    visitor,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsVisitorRootState;
  const data = visitor.visitorFormList;
  const { currentPage, currentViewPreset } = visitor;

  const viewPresetFilter = filterInfo.visitorModule[currentViewPreset];

  const visitorSort = get(filterInfo, `visitorModule.${currentViewPreset}.sort`) || '';
  const setVisitorFilters: OhsVisitorFilterPayload = {
    ...viewPresetFilter,
    next: currentPage > 10 ? data?.pagination?.next ?? '' : '',
    page: visitor.currentPage > 10 ? 0 : visitor.currentPage,
    sort: { ...JSON.parse(String(visitorSort)) },
    count: false,
  };

  try {
    const response = await getVisitorFormLists(setVisitorFilters);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

// PIN
export const fetchVisitorPinListsAsync = createAsyncThunk<OhsVisitorPinRecord[] | null, OhsUser>(
  'visitor/pin/fetchVisitorPinList',
  async (user: OhsUser, thunkAPI) => {
    try {
      const response = await getVisitorPinLists(user);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
