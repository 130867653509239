import * as tus from 'tus-js-client';
import Cookies from 'js-cookie';
import shortid from 'shortid';
import utf8 from 'utf8';

import {
  OhsLinkAccessAttachmentsUploadHeader,
  OhsSessionToken,
  OhsUploadAttachmentDetailsHeader,
} from 'global-services/constants/OhsStorageNames';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import AttachmentUploadUrl, {
  AttachmentLinkAccessUploadUrl,
} from 'global-services/constants/url/OhsAttachmentUrl';

import { OhsAttachment } from './OhsAttachmentModels';

export function fileNameTrim(input: string | undefined) {
  if (!input) return input;
  let trimedLongFileName = '';
  const inputArray = input.split('.');
  const fileType = inputArray[inputArray.length - 1];
  if (input.length > 50) {
    trimedLongFileName = `${input.substr(0, 30)}(...).${fileType}`;
  } else {
    trimedLongFileName = input;
  }
  return trimedLongFileName;
}

export function fileSizeParse(size: number) {
  if (size === undefined || size === null) return 'unknown';
  const i = Math.floor(Math.log(size) / Math.log(1024));
  return `${(size / 1024 ** i).toFixed(2) ?? 0 * 1} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
}

export function openOfficeFile(url: string | undefined | null) {
  if (url) {
    let openUrl = 'https://view.officeapps.live.com/op/view.aspx?src=';
    openUrl += encodeURIComponent(url);
    window.open(openUrl, '_blank');
  }
}

export function openOnGoogleDoc(url: string | undefined | null) {
  if (url) {
    let openUrl = 'https://docs.google.com/gview?url=';
    openUrl += encodeURIComponent(url);
    window.open(openUrl, '_blank');
  }
}

export const openFileOnBrowser = async (attachment: OhsAttachment): Promise<void> => {
  const res: { items: { _ids: any[] } } | null = await OhsApiRequest(
    RequestType.Attachments,
    ModuleType.Attachment,
    OhsApiRequestName.List,
    { _ids: [attachment._id] }
  );

  const url = (res?.items?._ids || []).filter((a) => a)?.[0].url;
  const filePath = (res?.items?._ids || []).filter((a) => a)?.[0]?.details?.fileName?.toLowerCase();

  if (
    filePath.indexOf('.xlsx') >= 0 ||
    filePath.indexOf('.xlsb') >= 0 ||
    filePath.indexOf('.xls') >= 0 ||
    filePath.indexOf('.xlsm') >= 0
  ) {
    openOfficeFile(url);
  } else if (
    filePath.indexOf('.pptx') >= 0 ||
    filePath.indexOf('.ppsx') >= 0 ||
    filePath.indexOf('.ppt') >= 0 ||
    filePath.indexOf('.pps') >= 0 ||
    filePath.indexOf('.potx') >= 0 ||
    filePath.indexOf('.ppsm') >= 0
  ) {
    openOfficeFile(url);
  } else if (
    filePath.indexOf('.doc') >= 0 ||
    filePath.indexOf('.docx') >= 0 ||
    filePath.indexOf('.dotx') >= 0
  ) {
    openOfficeFile(url);
  } else if (filePath.indexOf('.pdf') >= 0) {
    openOnGoogleDoc(url);
  } else {
    const a: HTMLAnchorElement = document.createElement('a');
    a.href = url;
    a.download = url.substr(url.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const formatBase64String = (string: string) => {
  let decodedString: any;

  const padBase64String = (str: any) => {
    return str.padEnd(str.length + ((4 - (str.length % 4)) % 4), '=');
  };
  try {
    // Pad the Base64 string
    const paddedBase64String = padBase64String(string);

    // Decode the Base64 string
    decodedString = atob(paddedBase64String);
    // convert to utf8
    decodedString = utf8.decode(decodedString);
  } catch (error) {
    console.error('Error decoding or parsing Base64 string:', error);
  }
  return decodedString;
};

export const uploadAttachment = (
  file: any,
  setFiles: (state: any) => void,
  uploadToken?: string
) => {
  const id = shortid.generate();
  const endpoint = uploadToken ? AttachmentLinkAccessUploadUrl : AttachmentUploadUrl;
  const token = Cookies.get(OhsSessionToken) || '';
  let headers;
  if (uploadToken) {
    headers = { [OhsLinkAccessAttachmentsUploadHeader]: uploadToken };
  } else {
    headers = { [OhsSessionToken]: token };
  }

  const upload = new tus.Upload(file, {
    endpoint,
    retryDelays: [0, 3000, 5000],
    metadata: {
      fileName: file.name,
    },
    headers,
    onError(error: any) {
      console.error(`Failed because: ${error}`);
    },
    onProgress(bytesUploaded: number, bytesTotal: number) {
      setFiles((state: OhsAttachment[]) => {
        return state.map((item: OhsAttachment) => {
          if (item.id === id) {
            const progress = Math.trunc((bytesUploaded / bytesTotal) * 100);
            return { ...item, progress };
          }

          return item;
        });
      });
    },
    onSuccess() {
      setFiles((state: OhsAttachment[]) =>
        state.map((item: OhsAttachment) => {
          if (item.id === id) {
            return { ...item, ...{ state: 'completed', file } };
          }

          return item;
        })
      );
    },
    onAfterResponse(req: tus.HttpRequest, res: tus.HttpResponse) {
      const xSafetychampionTusResponse = res.getHeader(OhsUploadAttachmentDetailsHeader);
      if (xSafetychampionTusResponse) {
        setFiles((state: OhsAttachment[]) =>
          state.map((item: OhsAttachment) => {
            if (item.id === id) {
              return { ...item, details: formatBase64String(xSafetychampionTusResponse) };
            }

            return item;
          })
        );
      }
    },
  });

  upload.start();
  let newList: any[] = [];

  setFiles((state: OhsAttachment[]) => {
    const list = [
      ...state,
      { id, name: file.name, upload, progress: 0, state: 'inprogress', details: '' },
    ];
    newList = list;
    return list;
  });
  return newList;
};

export const getAttachmentList = async (payload: string[]): Promise<OhsAttachment[]> => {
  const res: { items: { _ids: any[] } } | null = await OhsApiRequest(
    RequestType.Attachments,
    ModuleType.Attachment,
    OhsApiRequestName.List,
    { _ids: payload }
  );
  let attachmentList: any[] = [];
  if (res) {
    attachmentList = (res.items?._ids || [])
      .filter((a) => a) // check if any attachment null or undefined
      .map((attachment) => ({
        ...attachment?.details,
        _id: attachment._id,
        url: attachment.url,
        type: attachment.type,
      }));
  }
  return attachmentList;
};

export const downloadAttachment = async (attachment: OhsAttachment): Promise<void> => {
  const res: any[] | null = await getAttachmentList([attachment._id || '']);

  const url = res[0]?.url ?? '';
  const a: HTMLAnchorElement = document.createElement('a');
  a.href = url;
  a.download = url.substr(url.lastIndexOf('/') + 1);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
