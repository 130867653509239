import React from 'react';

import { Icon, Modal } from 'semantic-ui-react';
import ImageGallery from 'react-image-gallery';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import './OhsImageViewer.css';

export interface OhsImageViewerItem {
  original: string;
  thumbnail: string;
}
interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  imageList: OhsImageViewerItem[];
  startIndex?: number;
}

const useStyles = makeStyles()(() => ({
  modal: {
    background: 'transparent !important',
  },
  closeIcon: {
    color: 'white',
    fontSize: '30px !important',
    cursor: 'pointer',
    width: 'auto !important',
    height: 'auto !important',
    margin: '10px 15px !important',
    '&:hover': {
      fontSize: '31px !important',
    },
  },
  actionWrap: { position: 'absolute', right: '10px', zIndex: '9999' },
}));

function OhsImageViewerModal(props: Props) {
  const { classes } = useStyles();
  return (
    <Modal
      basic
      open={props.open}
      className={classes.modal}
      onClose={() => props.setOpen(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <>
        <Box className={classes.actionWrap}>
          <Icon name="close" className={classes.closeIcon} onClick={() => props.setOpen(false)} />
        </Box>
        <ImageGallery
          items={props.imageList}
          startIndex={props.startIndex ?? 0}
          showThumbnails={props.imageList.length > 1}
        />
      </>
    </Modal>
  );
}

OhsImageViewerModal.defaultProps = {
  startIndex: 0,
};

export default OhsImageViewerModal;
