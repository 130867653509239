import { generatePath } from 'react-router-dom';

import OhsCorrespondenceRouteList from 'correspondence/OhsCorrespondenceRouteList';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';
import { getModuleName } from 'user/OhsUserServices';
import { ModuleType } from 'global-services/constants/OhsObject';

import { OhsUser } from '../../user/OhsUser';
import OhsBreadcrumbsNameAndRef from './OhsBreadcrumbsNameAndRef';

const OhsBreadCrumbCorrespondence = (
  ohsBreadcrumbsNameAndRef: OhsBreadcrumbsNameAndRef,
  modulePath: string,
  subPath: string,
  viewPath: string,
  user: OhsUser
) => {
  const moduleHomeUrl = `${generatePath(OhsCorrespondenceRouteList.home.url)}?${new URLSearchParams(
    {
      tab: OhsTabKeyTypes.Communication,
    }
  ).toString()}`;
  const formHomeUrl = `${generatePath(OhsCorrespondenceRouteList.home.url)}?${new URLSearchParams({
    tab: OhsTabKeyTypes.Form,
  }).toString()}`;
  const templateHomeUrl = `${generatePath(
    OhsCorrespondenceRouteList.home.url
  )}?${new URLSearchParams({
    tab: OhsTabKeyTypes.Template,
  }).toString()}`;
  const corresModuleName = getModuleName(user.configs, ModuleType.Correspondence);
  if ((subPath === 'home' && !viewPath) || (subPath === 'home' && viewPath === 'communications')) {
    ohsBreadcrumbsNameAndRef.leafName = {
      name: corresModuleName,
    };
  } else if (viewPath === 'edit') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: moduleHomeUrl,
      name: corresModuleName,
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View/Edit' };
  } else if (viewPath === 'exchange') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: moduleHomeUrl,
      name: corresModuleName,
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: generatePath(OhsCorrespondenceRouteList.details.url, { id: subPath }),
      name: 'Recipient Register',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Recipient' };
  } else if (subPath === 'template') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: moduleHomeUrl,
      name: corresModuleName,
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: templateHomeUrl,
      name: 'Template',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Edit' };
  } else if (subPath === 'form' && viewPath === 'add') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: moduleHomeUrl,
      name: corresModuleName,
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: formHomeUrl,
      name: 'Form',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Add' };
  } else if (subPath === 'form') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: moduleHomeUrl,
      name: corresModuleName,
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: formHomeUrl,
      name: 'Form',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Edit' };
  }

  return ohsBreadcrumbsNameAndRef;
};
export default OhsBreadCrumbCorrespondence;
