import React from 'react';

import {
  fetchAvailableInspectionListAsync,
  setCurrentRegisterPage,
  getOhsInspectionList,
  fetchAvailableInspectionListCountAsync,
  getAvailableInspectionSearch,
} from 'inspection/OhsInspectionSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getGlobalFilter } from 'dashboard/topFilter/OhsTopFilterSlice';
import { getOhsUser } from 'user/userSlice';

export default function useOhsInspectionListAvailable() {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const filter = useAppSelector(getGlobalFilter);
  const dispatch = useAppDispatch();
  const inspectionReducer = useAppSelector(getOhsInspectionList);
  const searchInfo = useAppSelector(getAvailableInspectionSearch);

  const { availableInspectionList, isLoading } = inspectionReducer;
  const user = useAppSelector(getOhsUser);

  const fetchAvailableInspectionList = async () => {
    if (user) {
      await dispatch(fetchAvailableInspectionListAsync());
      await dispatch(fetchAvailableInspectionListCountAsync());
    }
  };

  React.useEffect(() => {
    dispatch(setCurrentRegisterPage(currentPage));
    fetchAvailableInspectionList();
  }, [user, currentPage, filter, searchInfo]);

  return [
    isLoading,
    availableInspectionList,
    currentPage,
    setCurrentPage,
    fetchAvailableInspectionList,
  ] as const;
}
