import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import { getOhsUser } from 'user/userSlice';
import { useQuery } from 'global-components/tab/OhsTab';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';

import OhsCheckboxFilter from '../filterfields/OhsCheckboxFilter';
import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';
import OhsResidualRiskMultSelectFilter from '../filterfields/OhsResidualRisk';
import OhsCategoriesMultSelectFilter from '../filterfields/OhsCategoriesFilter';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}

const permissions = {
  sortBy: false,
  riskRatings: false,
  workplaceFilter: false,
  archived: false,
  allocatedToOrg: false,
  notAllocated: false,
  workplaceOwned: false,
  allocatedToWorkplace: false,
};

function OhsAssetsFilters(props: Props) {
  const { classes } = useStyles();
  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const user = useAppSelector(getOhsUser);
  const userTierNum = user?.tierNum ?? 0;
  const query = useQuery();
  const tabKey = query.get('tab') ?? OhsTabKeyTypes.Organisation;
  const [filterPermissions, setFilterPermissions] = useState(permissions);

  const getAssetRiskResidualRisks = user?.configs?.asset?.RISK_RATING_LIST ?? [];
  const getModuleAssetRiskCategories = user.configs.asset?.CLASSIFICATION_LIST ?? [];

  const setPermissions = (keys: any) => {
    const updatedPermissions: any = { ...permissions };

    Object.keys(permissions).forEach((key) => {
      updatedPermissions[key] = false;
    });

    keys.forEach((key: any) => {
      if (key in updatedPermissions) {
        updatedPermissions[key] = true;
      }
    });

    return updatedPermissions;
  };

  const managePermissions = useCallback(
    (userTier: any, tabName: any) => {
      let visibleFilterPermissions = { ...permissions };
      if (userTier === 3) {
        if (tabName === OhsTabKeyTypes.Organisation) {
          visibleFilterPermissions = setPermissions(['sortBy', 'riskRatings', 'categories']);
        }
        if (tabName === OhsTabKeyTypes.Workplace) {
          visibleFilterPermissions = setPermissions([
            'sortBy',
            'riskRatings',
            'workplaceFilter',
            'workplaceOwned',
            'allocatedToWorkplace',
            'categories',
          ]);
        }
        if (tabName === OhsTabKeyTypes.List) {
          visibleFilterPermissions = setPermissions([
            'sortBy',
            'riskRatings',
            'allocatedToOrg',
            'allocatedToWorkplace',
            'notAllocated',
            'categories',
          ]);
        }
      }
      if (userTier === 4) {
        visibleFilterPermissions = setPermissions([
          'sortBy',
          'riskRatings',
          'archived',
          'workplaceOwned',
          'allocatedToWorkplace',
        ]);
      }
      if (userTier === 5) {
        visibleFilterPermissions = setPermissions([
          'sortBy',
          'riskRatings',
          'workplaceOwned',
          'allocatedToWorkplace',
        ]);
      }

      setFilterPermissions(visibleFilterPermissions);
    },
    [userTierNum, tabKey]
  );

  useEffect(() => {
    managePermissions(userTierNum, tabKey);
  }, [userTierNum, tabKey]);

  const FilterWrapper = ({ children }: any) => {
    const memoizedWrapper = useMemo(
      () => (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          {children}
        </Grid>
      ),
      [children]
    );

    return memoizedWrapper;
  };

  return (
    <Box className={classes.filterContent}>
      {filterPermissions.sortBy && (
        <FilterWrapper>
          <OhsSelectField
            title="Sort By"
            id="assetModule.sort"
            required
            options={filter.assetModule.tableSortKeyLists?.sortColAscDescOptions}
            ohsFormSettings={props.ohsFormSettings}
          />
        </FilterWrapper>
      )}

      {filterPermissions.sortBy && (
        <OhsCategoriesMultSelectFilter
          ohsFormSettings={props.ohsFormSettings}
          id="assetModule.categories"
          options={getModuleAssetRiskCategories}
        />
      )}

      {filterPermissions.riskRatings && (
        <FilterWrapper>
          <OhsResidualRiskMultSelectFilter
            ohsFormSettings={props.ohsFormSettings}
            id="assetModule.riskRatings"
            options={getAssetRiskResidualRisks}
          />
        </FilterWrapper>
      )}

      {filterPermissions.workplaceFilter && (
        <FilterWrapper>
          <OhsWorkplaceFilter
            id="assetModule.workplaces"
            ohsFormSettings={props.ohsFormSettings}
            required={false}
          />
        </FilterWrapper>
      )}

      {filterPermissions.archived && (
        <FilterWrapper>
          <OhsCheckboxFilter
            fieldid="assetModule.archived"
            label="Archived"
            ohsFormSettings={props.ohsFormSettings}
          />
        </FilterWrapper>
      )}

      {filterPermissions.allocatedToOrg && (
        <FilterWrapper>
          <OhsCheckboxFilter
            fieldid="assetModule.allocatedToOrg"
            label="Allocated to Organisation"
            ohsFormSettings={props.ohsFormSettings}
          />
        </FilterWrapper>
      )}

      {filterPermissions.notAllocated && (
        <FilterWrapper>
          <OhsCheckboxFilter
            fieldid="assetModule.notAllocated"
            label="Not Allocated"
            ohsFormSettings={props.ohsFormSettings}
          />
        </FilterWrapper>
      )}

      {filterPermissions.workplaceOwned && (
        <FilterWrapper>
          <OhsCheckboxFilter
            fieldid="assetModule.workplaceOwned"
            label="Workplace Owned"
            ohsFormSettings={props.ohsFormSettings}
          />
        </FilterWrapper>
      )}
      {filterPermissions.allocatedToWorkplace && (
        <FilterWrapper>
          <OhsCheckboxFilter
            fieldid="assetModule.allocatedToWorkplace"
            label="Allocated to Workplace"
            ohsFormSettings={props.ohsFormSettings}
          />
        </FilterWrapper>
      )}
    </Box>
  );
}

export default OhsAssetsFilters;
