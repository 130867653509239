import { OhsUser } from 'user/OhsUser';
import { ModuleRoute, ModuleUrl } from 'global-services/constants/OhsRoutes';

const commonPermission = (user: OhsUser) => user?.configs?.inspection?.PERMISSIONS.view || false;

const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.Onboardings}/${path}`,
  permission,
});

const OhsOnboardingRouteList = {
  [ModuleRoute.Home]: createRoute('home', commonPermission),
};

export default OhsOnboardingRouteList;
