import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';

import OhsInspectionRecordTemplateMeta from './OhsInspectionRecordTemplateMeta';
import { InspectionTemplate } from './OhsInspectionRecordModel';

class OhsInspectionRecord {
  archived?: boolean;

  title: string;

  frequencyKey: string;

  frequencyValue: number;

  description: string;

  category: string;

  categoryOther: string | null;

  subcategory: string;

  subcategoryOther: string | null;

  createdBy: {
    type: ModuleType.User;
    _id: string;
    name: string;
    email: string;
  };

  tzDateCreated: string;

  dateCreated: string;

  tier?: OhsTier;

  _id?: string;

  subRows: any[];

  allocationOf: { _id: string; type: ModuleType.Inspection } | undefined;

  allocatedToSelf?: boolean;

  notAllocatedCurrently?: boolean;

  templateMeta?: OhsInspectionRecordTemplateMeta;

  isArchived: boolean;

  execute?: {
    task: {
      _id: string;
      dateDue: string;
      inExecution: boolean;
    };
  };

  template: InspectionTemplate | null;

  emailList: string[];

  constructor(tier?: OhsTier) {
    this.tier = tier;
    this.title = '';
    this.frequencyKey = '';
    this.frequencyValue = 0;
    this.description = '';
    this.category = '';
    this.categoryOther = null;
    this.subcategory = '';
    this.subcategoryOther = null;
    this.createdBy = {
      type: ModuleType.User,
      _id: '',
      name: '',
      email: '',
    };
    this.tzDateCreated = '';
    this.dateCreated = '';
    this.subRows = [];
    this.isArchived = false;
    this.template = null;
    this.emailList = [];
  }
}

export default OhsInspectionRecord;
