import React from 'react';

import OtpInput from 'react-otp-input';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  container: {
    'input:not(:first-of-type):not(:last-child)': {
      borderRightWidth: '1px',
      borderLeftWidth: '1px',
    },
    'input:first-of-type': {
      borderRightWidth: '1px',
    },
    'input:last-child': {
      borderLeftWidth: '1px',
    },
  },
  input: {
    height: '64px',
    width: '48px!important',
    borderStyle: 'solid',
    borderColor: '#CECECE',
    borderWidth: '2px',
    fontSize: '20px',
    fontWeight: '500',
    borderRadius: '6px!important',
  },
  focus: {
    border: 'transparent',
    outline: '2px solid #3874CB',
    zIndex: 1,
    outlineOffset: '-2px',
  },
});

interface Props {
  value: string;
  setValue: (value: string) => void;
  shouldAutoFocus?: boolean;
  length: number;
  disabled?: boolean;
}

function OhsOTPInput(props: Props) {
  const { classes } = useStyles();

  const handleChange = (otp: string) => {
    props.setValue(otp);
  };

  return (
    <OtpInput
      value={props.value}
      onChange={handleChange}
      numInputs={props.length}
      containerStyle={classes.container}
      inputStyle={classes.input}
      shouldAutoFocus={props.shouldAutoFocus}
      renderSeparator={<span> &nbsp;&nbsp; </span>}
      renderInput={(inputProps: any) => <input {...inputProps} />}
    />
  );
}

OhsOTPInput.defaultProps = {
  shouldAutoFocus: false,
  disabled: false,
};
export default OhsOTPInput;
