import { OhsUser } from 'user/OhsUser';
import { getWorkplaceOptions } from 'user/OhsUserServices';

export default class OhsHumanResourceTrainingFilterModels {
  viewPreset?: 'view_1' | 'view_2' | 'view_3';

  archived: boolean;

  sort: string;

  workplaces: string[];

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  skipOrgRecords: boolean;

  constructor(user?: OhsUser) {
    this.viewPreset = undefined;
    this.archived = false;
    this.workplaces = [];
    this.skipOrgRecords = false;
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Category: A to Z ', value: '{ "key": "category", "order": 1 }' },
        { name: 'Category: Z to A', value: '{ "key": "category", "order": -1 }' },
        { name: 'Due Date: Newest to Oldest', value: '{ "key": "dateExpiry", "order": -1 }' },
        { name: 'Due Date: Oldest to Newest', value: '{ "key": "dateExpiry", "order": 1 }' },
        { name: 'Reference Number: A to Z', value: '{ "key": "referenceId", "order": 1 }' },
        { name: 'Reference Number: Z to A', value: '{ "key": "referenceId", "order": -1 }' },
        { name: 'Issued Date: Oldest to Newest', value: '{ "key": "dateIssued", "order": 1 }' },
        { name: 'Issued Date: Newest to Oldest', value: '{ "key": "dateIssued", "order": -1 }' },
        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },
      ],
    };
    this.sort = '{ "key": "category", "order": 1 }';

    if (user) {
      const getWorkplaceLists = getWorkplaceOptions(user.configs);
      const workplaceOptions = getWorkplaceLists.map((list: any) => list.id);
      this.workplaces = workplaceOptions;
    }
  }
}
