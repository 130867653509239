import React, { ReactNode, useEffect, useState } from 'react';

import { Box } from '@mui/material';
import _ from 'lodash';

import { OhsFormSettings } from '../services/OhsFormModels';

interface Props {
  hide: boolean;
  children: ReactNode;
  defaultValue?: any;
  id: string;
  ohsFormSettings: OhsFormSettings;
}

function OhsHideConditionalField(props: Props) {
  const [lastValue, setLastValue] = useState(null);
  const defaultVal = _.isUndefined(props.defaultValue) ? null : props.defaultValue;
  const value = props.ohsFormSettings.useFormMethods.watch(props.id) ?? defaultVal;

  useEffect(() => {
    setLastValue(defaultVal);
  }, []);

  useEffect(() => {
    /* is field has value but is already hidden, store that value, then set form to default */
    if (!_.isEqual(value, defaultVal) && props.hide) {
      setLastValue(value);
      props.ohsFormSettings.useFormMethods.setValue(props.id, defaultVal);
    }
    /* if field's value is default but lastValue still holds previous value and field
    is no longer hidden, set field's value to lastValue and reset the lastValue */
    if (!_.isEqual(lastValue, defaultVal) && !props.hide) {
      props.ohsFormSettings.useFormMethods.setValue(props.id, lastValue);
      setLastValue(defaultVal);
    }
  }, [props.hide]);

  return (
    <Box
      style={{
        display: props.hide === true ? 'none' : 'block',
        width: '100%',
      }}
    >
      {props.children}
    </Box>
  );
}
OhsHideConditionalField.defaultProps = {
  defaultValue: undefined,
};
export default OhsHideConditionalField;
