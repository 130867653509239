/* eslint-disable react/require-default-props */
import React from 'react';

import _ from 'lodash';
import { IconButton, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';

interface Props {
  header: any;
  value?: any;
}
function OhsRegisterTableHeaderSearchBar(props: Props) {
  const [searchKey, setSearchKey] = React.useState(props.value);

  const cleanKey = () => {
    setSearchKey('');
    props.header.setGlobalFilter(undefined);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = event.target.value;
    props.header.setGlobalFilter(newValue || undefined);
    setSearchKey(newValue);
  };
  return (
    <OutlinedInput
      sx={{
        width: '150px',
        height: '35px',
        marginTop: '-10px',
        marginBottom: '-10px',
        float: 'right',
        backgroundColor: 'white',
      }}
      value={searchKey}
      onChange={handleChange}
      placeholder="Search"
      endAdornment={
        <InputAdornment position="end">
          {_.isNil(searchKey) || searchKey === '' ? (
            <IconButton type="submit">
              <SearchIcon />
            </IconButton>
          ) : (
            <IconButton onClick={cleanKey}>
              <ClearIcon />
            </IconButton>
          )}
        </InputAdornment>
      }
    />
  );
}

export default OhsRegisterTableHeaderSearchBar;
