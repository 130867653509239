import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';
import {
  NoticeBoardListsRecordState,
  OhsNoticeBoardViewPresets,
} from 'notice-board/model/OhsNoticeBoardModel';
import { OhsGlobalSearchFilter, OhsGlobalSearchPayload } from 'search/OhsSearchModels';
import { initialGlobalSearchPayload } from 'search/store/OhsSearchSlice';
import { ModuleType } from 'global-services/constants/OhsObject';

import {
  fetchNoticeBoardFormListAsync,
  fetchNoticeBoardFullListsAsync,
  fetchNoticeBoardListsAsync,
  fetchNoticeBoardListsCountAsync,
} from './OhsNoticeBoardActions';

const initialState: NoticeBoardListsRecordState = {
  isLoading: false,
  noticeBoardList: null,
  noticeBoardFullList: null,
  noticeBoardFormList: null,
  currentPage: 1,
  currentViewPreset: OhsNoticeBoardViewPresets.View1NoticeBoardList,
};

export const noticeboardSlice = createSlice({
  name: 'noticeboard',
  initialState,
  reducers: {
    setNoticeBoardCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setViewPreset: (state, action: PayloadAction<OhsNoticeBoardViewPresets>) => {
      state.currentViewPreset = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNoticeBoardListsAsync.pending, (state) => {
        state.isLoading = true;
        state.noticeBoardList = {
          items: [],
          pagination: state.noticeBoardList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchNoticeBoardListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.noticeBoardList = action.payload;
          if (state?.noticeBoardList?.pagination?.totalPages) {
            setOhsLocalStorage(
              'noticeBoardListtotalPages',
              state.noticeBoardList.pagination.totalPages
            );
          }

          let setItemsLastId = '';
          const noticeboardItems = [...state.noticeBoardList.items];
          if (noticeboardItems.length > 0) {
            setItemsLastId = noticeboardItems?.pop()?._id ?? '';
          }

          state.noticeBoardList.pagination.next = setItemsLastId;
          state.noticeBoardList.pagination.totalPages =
            getOhsLocalStorage('noticeBoardListtotalPages') ?? 0;
          state.isLoading = false;
        }
      })
      .addCase(fetchNoticeBoardListsAsync.rejected, (state) => {
        state.noticeBoardList = null;
      })
      .addCase(fetchNoticeBoardListsCountAsync.fulfilled, (state, action) => {
        if (state.noticeBoardList && action.payload && action.payload.pagination) {
          setOhsLocalStorage('noticeBoardListtotalPages', action?.payload?.pagination?.totalPages);
          state.noticeBoardList.pagination.totalPages =
            action?.payload?.pagination?.totalPages ?? 0;
        }
      })
      .addCase(fetchNoticeBoardFormListAsync.pending, (state) => {
        state.isLoading = true;
        state.noticeBoardFormList = {
          items: [],
          pagination: state.noticeBoardFormList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchNoticeBoardFormListAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.noticeBoardFormList = action.payload;
          if (state?.noticeBoardFormList?.pagination?.totalPages) {
            setOhsLocalStorage(
              'noticeBoardFormListtotalPages',
              state.noticeBoardFormList.pagination.totalPages
            );
          }

          let setItemsLastId = '';
          const noticeboardItems = [...state.noticeBoardFormList.items];
          if (noticeboardItems.length > 0) {
            setItemsLastId = noticeboardItems?.pop()?._id ?? '';
          }

          state.noticeBoardFormList.pagination.next = setItemsLastId;
          state.noticeBoardFormList.pagination.totalPages =
            getOhsLocalStorage('noticeBoardFormListtotalPages') ?? 0;
          state.isLoading = false;
        }
      })
      .addCase(fetchNoticeBoardFormListAsync.rejected, (state) => {
        state.noticeBoardFormList = null;
      })
      .addCase(fetchNoticeBoardFullListsAsync.pending, (state) => {
        state.noticeBoardFullList = { items: [] };
      })
      .addCase(fetchNoticeBoardFullListsAsync.fulfilled, (state, action) => {
        state.noticeBoardFullList = action.payload;
      })
      .addCase(fetchNoticeBoardFullListsAsync.rejected, (state) => {
        state.noticeBoardFullList = null;
      });
  },
});

const noticeboardState = (state: RootState) => state.noticeBoard;

export const getOhsNoticeBoardModuleState = createSelector([noticeboardState], (moduleState) => {
  const {
    isLoading,
    noticeBoardList,
    noticeBoardFormList,
    currentPage,
    currentViewPreset,
    noticeBoardFullList,
  } = moduleState;
  return {
    isLoading,
    currentPage,
    currentViewPreset,
    noticeBoardList,
    noticeBoardFormList,
    noticeBoardFullList,
  };
});

export const { setNoticeBoardCurrentPage, setViewPreset } = noticeboardSlice.actions;
export const noticeboardModuleReducer = noticeboardSlice.reducer;

export const defaultTaskSearchFilter: OhsGlobalSearchFilter = {
  ...initialGlobalSearchPayload.filter,
  modules: [ModuleType.NoticeboardBoard],
};

export const initialNoticeBoardSearch: OhsGlobalSearchPayload = {
  searchKey: '',
  substringSearch: false,
  highlight: false,
  filter: defaultTaskSearchFilter,
};
