import { OhsUser } from 'user/OhsUser';
import { getWorkplaceOptions } from 'user/OhsUserServices';

export default class OhsHumanResourceFilterModels {
  viewPreset?: 'view_1' | 'view_2' | 'view_3';

  archived: boolean;

  sort: string;

  workplaces: string[];

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  skipOrgRecords: boolean;

  constructor(user?: OhsUser) {
    this.viewPreset = undefined;
    this.archived = false;
    this.workplaces = [];
    this.skipOrgRecords = false;
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Name: A to Z ', value: '{ "key": "name", "order": 1 }' },
        { name: 'Name: Z to A', value: '{ "key": "name", "order": -1 }' },
        { name: 'Start Date: Newest to Oldest', value: '{ "key": "dateStarted", "order": -1 }' },
        { name: 'Start Date: Oldest to Newest', value: '{ "key": "dateStarted", "order": 1 }' },
        { name: 'Position: A to Z ', value: '{ "key": "position", "order": 1 }' },
        { name: 'Position: Z to A', value: '{ "key": "position", "order": -1 }' },
        { name: 'Employee Number: A to Z ', value: '{ "key": "referenceId", "order": 1 }' },
        { name: 'Employee Number: Z to A', value: '{ "key": "referenceId", "order": -1 }' },
        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },
      ],
    };
    this.sort = '{ "key": "name", "order": 1 }';
    if (user) {
      const getWorkplaceLists = getWorkplaceOptions(user.configs);
      const workplaceOptions = getWorkplaceLists.map((list: any) => list.id);
      this.workplaces = workplaceOptions;
    }
  }
}
