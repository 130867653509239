import React from 'react';

import OhsIframeModulePublicHome from 'global-components/angular-iframe/OhsIframeModulePublicHome';

export function OhsFreemiumUserSetup() {
  return (
    <div>
      <OhsIframeModulePublicHome />
    </div>
  );
}

export default OhsFreemiumUserSetup;
