import _ from 'lodash';

import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsApiRequestName, RequestType, ApiResListsType } from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';

import {
  OhsDashboardBannerFilterPayload,
  OhsDashboardBannerRecord,
} from './OhsDashboardBannerModels';

export const getDashboardBannerList = (
  filterInfo?: OhsDashboardBannerFilterPayload,
  enableOnWeb?: boolean
): Promise<ApiResListsType<any[]> | null> => {
  const payload = !_.isEmpty(filterInfo) ? { filter: filterInfo } : { enableOnWeb };

  return OhsApiRequest(
    RequestType.Module,
    ModuleType.DashboardBanner,
    OhsApiRequestName.List,
    payload
  );
};

export const getDashboardBanner = async (_id: string): Promise<OhsDashboardBannerRecord> => {
  const res: OhsDashboardBannerRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.DashboardBanner,
    OhsApiRequestName.Fetch,
    { _id }
  );

  return res || new OhsDashboardBannerRecord();
};

export const addDashboardBanner = async (payload: OhsDashboardBannerRecord) => {
  const res: OhsDashboardBannerRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.DashboardBanner,
    OhsApiRequestName.Create,
    payload
  );
  return res;
};

export const editDashboardBanner = async (
  _id: string | undefined,
  payload: OhsDashboardBannerRecord
) => {
  const res: OhsDashboardBannerRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.DashboardBanner,
    OhsApiRequestName.Edit,
    { _id, ...payload }
  );
  return res;
};
