import React from 'react';

import { useTheme } from '@mui/material';
import { ToastContainer } from 'react-toastify';

function OhsToastContainer() {
  const theme = useTheme();
  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      style={{ zIndex: theme.zIndex.modal + 2 }}
    />
  );
}

export default OhsToastContainer;
