import React, { useMemo } from 'react';

import { getAttachmentList } from './OhsAttachmentServices';
import { OhsAttachment } from './OhsAttachmentModels';
import OhsAttachmentList from './OhsAttachmentList';

interface Props {
  attachmentList: any[];
  disableImageViewer?: boolean;
}
function OhsAttachmentListViewer(props: Props) {
  const [detailedAttachments, setDetailedAttachment] = React.useState<OhsAttachment[]>([]);

  const memoizedDetailedAttachments = useMemo(() => detailedAttachments, [detailedAttachments]);
  const memoizedAttachmentList = useMemo(() => props.attachmentList, [props.attachmentList]);

  const handleAttachments = async () => {
    const attachmentIds = props.attachmentList.map((attachment) => attachment._id);
    let newDetailedAttachments: OhsAttachment[] = [];

    if (attachmentIds.length > 0) {
      newDetailedAttachments = await getAttachmentList(attachmentIds);
    }
    setDetailedAttachment(newDetailedAttachments);
  };

  React.useEffect(() => {
    handleAttachments();
  }, [memoizedAttachmentList]);

  return memoizedDetailedAttachments.length < 1 ? (
    <div className="field" style={{ padding: '10px' }}>
      None
    </div>
  ) : (
    <OhsAttachmentList
      attachments={memoizedDetailedAttachments ?? []}
      disableImageViewer={props.disableImageViewer}
    />
  );
}

OhsAttachmentListViewer.defaultProps = {
  disableImageViewer: false,
};

export default OhsAttachmentListViewer;
