import React from 'react';

import { Box } from '@mui/material';

import OhsMfaHelpModal from 'login/mfa-recovery/OhsMfaHelpModal';
import OhsMfaRecoveryCode from 'login/mfa-recovery/OhsMfaRecoveryCode';

import OhsMfaVerifyMethodLists from './OhsMfaVerifyMethodLists';
import OhsMfaVerifyOTP from './OhsMfaVerifyOTP';

interface Props {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  onSuccess: (methodId?: string) => void;
}
export default function OhsMfaVerifySteps(props: Props) {
  const { activeStep, setActiveStep, onSuccess } = props;
  const [currentStepContent, setCurrentStepContent] = React.useState<React.ReactNode>();
  const [methodToVerify, setMethodToVerify] = React.useState<any>();
  const [recoveryMethodInfo, setRecoveryMethodInfo] = React.useState<any>();
  const [openHelp, setOpenHelp] = React.useState<boolean>(false);

  const getMfaEnrollContent = (step: number): any => {
    switch (step) {
      case 0:
        return (
          <OhsMfaVerifyMethodLists
            setMethodToVerify={setMethodToVerify}
            setRecoveryMethodInfo={setRecoveryMethodInfo}
            setActiveStep={setActiveStep}
          />
        );
      case 1:
        return (
          <OhsMfaVerifyOTP
            onSuccess={onSuccess}
            mfaInfo={methodToVerify}
            setOpenHelp={setOpenHelp}
          />
        );
      case 2:
        return (
          <OhsMfaRecoveryCode
            recoveryMethodInfo={recoveryMethodInfo}
            onSuccess={onSuccess}
            setActiveStep={setActiveStep}
          />
        );
      default:
        return null;
    }
  };

  React.useEffect(() => {
    setCurrentStepContent(getMfaEnrollContent(activeStep));
  }, [activeStep]);

  return (
    <>
      <Box>{currentStepContent}</Box>
      <OhsMfaHelpModal
        open={openHelp}
        setHelpModalOpen={setOpenHelp}
        mfaDetails={methodToVerify}
        useRecoveryCode={() => {
          setActiveStep(2);
          setOpenHelp(false);
        }}
      />
    </>
  );
}
