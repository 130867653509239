import _ from 'lodash';

import { OhsUser } from 'user/OhsUser';
import { ModuleUrl } from 'global-services/constants/OhsRoutes';
import { TierType } from 'global-services/constants/OhsObject';

const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path: `/${path}/*`,
  url: `/${path}`,
  permission,
});

const OhsPrivateModuleRouteList = {
  [ModuleUrl.Admin2]: createRoute(
    ModuleUrl.Admin2,
    (user: OhsUser) => user?.tierNum === 1 || user?.configs?.admin2?.PERMISSIONS.view || false
  ),
  [ModuleUrl.DashboardBanner]: createRoute(
    ModuleUrl.DashboardBanner,
    (user: OhsUser) => user?.tierNum === 1 || false
  ),
  [ModuleUrl.MobileAppAdmin]: createRoute(
    ModuleUrl.MobileAppAdmin,
    (user: OhsUser) => user?.tierNum === 1 || false
  ),
  [ModuleUrl.MFASetting]: createRoute(
    ModuleUrl.MFASetting,
    (user: OhsUser) => user?.tierNum === 1 || false
  ),

  [ModuleUrl.Task]: createRoute(ModuleUrl.Task, (user: OhsUser) => user?.tier.type !== TierType.T2),
  [ModuleUrl.Inspection]: createRoute(
    ModuleUrl.Inspection,
    (user: OhsUser) => user?.configs?.inspection?.PERMISSIONS.view || false
  ),
  [ModuleUrl.SafetyPlan]: createRoute(
    ModuleUrl.SafetyPlan,
    (user: OhsUser) => user?.configs?.safetyplan?.PERMISSIONS.view || false
  ),
  [ModuleUrl.HR]: createRoute(
    ModuleUrl.HR,
    (user: OhsUser) => user?.configs?.hr?.PERMISSIONS.view || false
  ),
  [ModuleUrl.Correspondence]: createRoute(
    ModuleUrl.Correspondence,
    (user: OhsUser) => user?.configs?.correspondence?.PERMISSIONS.view_prose || false
  ),
  [ModuleUrl.Documents]: createRoute(
    ModuleUrl.Documents,
    (user: OhsUser) => user?.configs?.vdoc?.PERMISSIONS.view || false
  ),
  [ModuleUrl.Action]: createRoute(
    ModuleUrl.Action,
    (user: OhsUser) =>
      user?.configs?.action?.PERMISSIONS.view || user?.configs?.action?.PERMISSIONS.create || false
  ),
  [ModuleUrl.Search]: createRoute(ModuleUrl.Search, () => true),
  [ModuleUrl.Contractor]: createRoute(
    ModuleUrl.Contractor,
    (user: OhsUser) => user?.configs?.contractor?.PERMISSIONS.view || false
  ),
  [ModuleUrl.Incident]: createRoute(
    ModuleUrl.Incident,
    (user: OhsUser) =>
      user?.configs?.incident?.PERMISSIONS.view ||
      user?.configs?.incident?.PERMISSIONS.create ||
      false
  ),
  [ModuleUrl.Chemical]: createRoute(
    ModuleUrl.Chemical,
    (user: OhsUser) => user?.configs?.chemical?.PERMISSIONS.view || false
  ),
  [ModuleUrl.Noticeboard]: createRoute(
    ModuleUrl.Noticeboard,
    (user: OhsUser) => user?.configs?.noticeboard?.PERMISSIONS.view || false
  ),
  [ModuleUrl.Reporting]: createRoute(ModuleUrl.Reporting, () => true),
  [ModuleUrl.SuperSet]: createRoute(
    ModuleUrl.SuperSet,
    (user: OhsUser) => user?.configs?.incident?.PERMISSIONS.view || false
  ),
  [ModuleUrl.ReviewPlan]: createRoute(
    ModuleUrl.ReviewPlan,
    (user: OhsUser) => user?.configs?.reviewplan?.PERMISSIONS.view || false
  ),
  [ModuleUrl.Export]: createRoute(
    ModuleUrl.Export,
    (user: OhsUser) => user?.configs?.export?.PERMISSIONS.view || false
  ),
  [ModuleUrl.Visitor]: createRoute(ModuleUrl.Visitor, (user: OhsUser) => {
    const config = user?.configs?.visitor?.PERMISSIONS;
    const validUser = user?.tier.type === TierType.T3 || user?.tier.type === TierType.T4 || false;
    return (validUser && config?.site_view) || false;
  }),
  [ModuleUrl.CorporateRisk]: createRoute(
    ModuleUrl.CorporateRisk,
    (user: OhsUser) => user?.configs?.corporateRisk?.PERMISSIONS.view || false
  ),
  [ModuleUrl.Asset]: createRoute(
    ModuleUrl.Asset,
    (user: OhsUser) => user?.configs?.asset?.PERMISSIONS.view || false
  ),
  [ModuleUrl.Onboardings]: createRoute(ModuleUrl.Onboardings, (user: OhsUser) => {
    const userTier = user?.tier.type === TierType.T2 || false;
    return userTier || false;
  }),
  [ModuleUrl.LinkaccessXsi]: createRoute(ModuleUrl.LinkaccessXsi, (user: OhsUser) => {
    // linkacess xsi is ONLY allowed in T3. But the error page is already
    // handled in `<OhsHrIntegration>`. So we need to allow this route.
    return _.has(user, 'tier');
  }),
  [ModuleUrl.Xsi]: createRoute(ModuleUrl.Xsi, (user: OhsUser) => {
    const userTier = user?.tier.type === TierType.T2 || false;
    return userTier || false;
  }),
  [ModuleUrl.ResourceCentre]: createRoute(ModuleUrl.ResourceCentre, (user: OhsUser) => {
    const userTier =
      user?.tier.type === TierType.T5 ||
      user?.tier.type === TierType.T4 ||
      user?.tier.type === TierType.T3;
    return userTier || false;
  }),
  [ModuleUrl.FeedBack]: createRoute(ModuleUrl.FeedBack, (user: OhsUser) => {
    const userTier =
      user?.tier.type === TierType.T5 ||
      user?.tier.type === TierType.T4 ||
      user?.tier.type === TierType.T3;
    return userTier || false;
  }),
};

export default OhsPrivateModuleRouteList;
