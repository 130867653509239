import { ApiResponseType, OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';

import OhsSuperSetTypes from './OhsSuperSetTypes';

const getSuperSetModuleReport = async (
  moduleType: ModuleType,
  dashboardName: string
): Promise<ApiResponseType<any> | null> =>
  OhsApiRequest(RequestType.Module, ModuleType.SuperSet, OhsApiRequestName.GetToken, {
    moduleType,
    dashboardName,
  });

export const getSuperSetCustomList = async (): Promise<ApiResponseType<any> | null> =>
  OhsApiRequest(RequestType.Module, ModuleType.SuperSet, OhsApiRequestName.CustomList, {});

export const getSuperSetCustomToken = async (key: string): Promise<OhsSuperSetTypes | null> =>
  OhsApiRequest(RequestType.Module, ModuleType.SuperSet, OhsApiRequestName.CustomGetToken, {
    key,
  });

export default getSuperSetModuleReport;
