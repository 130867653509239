import { OhsUser } from 'user/OhsUser';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';
import { withCreatePermission, withSignOffViewPermission } from 'global-services/OhsPermission';
import { ModuleConfig } from 'global-services/constants/OhsObject';

import OhsInspectionRecord from './models/OhsInspectionRecord';

const commonPermission = (user: OhsUser) => user?.configs?.inspection?.PERMISSIONS.view || false;
const addPermission = (user: OhsUser) => {
  return withCreatePermission(user, ModuleConfig.Inspection);
};
const editPermission = (user: OhsUser) => {
  return user?.configs?.inspection?.PERMISSIONS.edit || false;
};
const viewPermission = (user: OhsUser) => {
  return user?.configs?.inspection?.PERMISSIONS.view || false;
};
const createRoute = (
  path: string,
  permission: (user: OhsUser, inspTemplate?: OhsInspectionRecord) => boolean
) => ({
  path,
  url: `/${ModuleUrl.Inspection}/${path}`,
  permission,
});

const InspectionRouteList = {
  [ModuleRoute.Home]: {
    path: 'home/*',
    url: `/${ModuleUrl.Inspection}/home`,
    permission: commonPermission,
  },
  [ModuleRoute.Add]: {
    path: 'template-add',
    url: `/${ModuleUrl.Inspection}/template-add`,
    permission: addPermission,
  },
  [ModuleRoute.View]: createRoute(':inspectionId/view', viewPermission),
  [ModuleRoute.Edit]: createRoute(':inspectionId/edit', editPermission),
  [ModuleRoute.Details]: createRoute(':inspectionId/details', commonPermission),
  [ModuleRoute.SignOff]: createRoute(':inspectionId/task/:taskId/signoff', (user) =>
    withSignOffViewPermission(user, ModuleConfig.Inspection)
  ),
  [ModuleRoute.Evidence]: createRoute(':inspectionId/task/:taskId/completed', commonPermission),
  [ModuleRoute.TasksAggregate]: createRoute(':inspectionId/tasks/aggregate', commonPermission),
};

export default InspectionRouteList;
