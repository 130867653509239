import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';
import { OhsFilterList } from 'dashboard/topFilter/OhsFilterModels';

import { getDashboardBannerList } from './OhsDashboardBannerServices';
import {
  OhsDashboardBannerFilterPayload,
  OhsDashboardBannerRecord,
} from './OhsDashboardBannerModels';

export interface DashboardBannerListRecordState {
  isLoading: boolean;
  dashboardBannerList: ApiResListsType<any[]> | null;
  currentPage: number;
}

const initialState: DashboardBannerListRecordState = {
  isLoading: false,
  dashboardBannerList: null,
  currentPage: 1,
};

const dashboardBannerFilterOptions = (
  state: RootState
): Partial<OhsDashboardBannerFilterPayload> => {
  const { dashboardBanner } = state;
  return {
    next:
      dashboardBanner.currentPage > 10
        ? dashboardBanner.dashboardBannerList?.pagination.next ?? ''
        : '',
  };
};

export const fetchDashboardBannerListAsync = createAsyncThunk<
  ApiResListsType<OhsDashboardBannerRecord[]> | null,
  undefined,
  { state: RootState }
>('dashboardBanners/fetchDashboardBannerList', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const filter = thunkAPI.getState().globalfilter.filterInfo as OhsFilterList;
  // const { user } = thunkAPI.getState();
  // const userTier = user.user?.tierNum ?? 0;

  const setDashboardBannerFilters: OhsDashboardBannerFilterPayload = {
    ...dashboardBannerFilterOptions(state),
    page: state.dashboardBanner.currentPage > 10 ? 0 : state.dashboardBanner.currentPage,
    sort: { ...JSON.parse(String(filter.dashboardBannerModule.sort)) },
    count: false,
  };
  try {
    const response = await getDashboardBannerList(setDashboardBannerFilters);

    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchDashboardBannerListCountAsync = createAsyncThunk<
  ApiResListsType<OhsDashboardBannerRecord[]> | null,
  undefined,
  { state: RootState }
>('dashboardBanners/fetchDashboardBannerListCount', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const actionFiltersWithCount: OhsDashboardBannerFilterPayload = {
    ...dashboardBannerFilterOptions(state),
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  try {
    const response = await getDashboardBannerList(actionFiltersWithCount);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const dashboardBannerSlice = createSlice({
  name: 'dashboardBanners',
  initialState,
  reducers: {
    setDashboardBannerCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setDashboardBannerIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardBannerListAsync.pending, (state) => {
        state.isLoading = true;
        state.dashboardBannerList = {
          items: [],
          pagination: state.dashboardBannerList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchDashboardBannerListAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.dashboardBannerList = action.payload;
          if (state?.dashboardBannerList?.pagination?.totalPages) {
            setOhsLocalStorage(
              'dashboardBannerListTotalPages',
              state.dashboardBannerList.pagination.totalPages
            );
          }
          let setItemsLastId = '';
          const dashboardBannerPlanItems = [...state.dashboardBannerList.items];
          if (dashboardBannerPlanItems.length > 0)
            setItemsLastId = dashboardBannerPlanItems.pop()._id;

          state.dashboardBannerList.pagination.next = setItemsLastId;
          state.dashboardBannerList.pagination.totalPages =
            getOhsLocalStorage('dashboardBannerListTotalPages') ?? 0;
          state.isLoading = false;
        }
      })
      .addCase(fetchDashboardBannerListAsync.rejected, (state) => {
        state.dashboardBannerList = null;
      })
      .addCase(fetchDashboardBannerListCountAsync.fulfilled, (state, action) => {
        if (state.dashboardBannerList && action.payload) {
          setOhsLocalStorage('dashboardBannerListTotalPages', action.payload.pagination.totalPages);

          state.dashboardBannerList.pagination.totalPages =
            action.payload.pagination.totalPages ?? 0;
        }
      });
  },
});

const dashboardBannerState = (state: RootState) => state.dashboardBanner;
export const getOhsDashboardBannerList = createSelector(
  [dashboardBannerState],
  (dashboardBanner) => dashboardBanner
);
export const { setDashboardBannerCurrentPage, setDashboardBannerIsLoading } =
  dashboardBannerSlice.actions;
export const DashboardBannerReducer = dashboardBannerSlice.reducer;
