export default class OhsVdocPermissions {
  view: boolean;

  view_copyable: boolean;

  create: boolean;

  edit: boolean;

  up_version: boolean;

  allocate: boolean;

  copy: boolean;

  signoff: boolean;

  archive: boolean;

  delete: boolean;

  assign_task: boolean;

  form_create: boolean;

  form_edit: boolean;

  form_archive: boolean;

  form_export: boolean;

  pricing: boolean;

  delete_one_off_child: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.create = flag;
    this.edit = flag;
    this.signoff = flag;
    this.archive = flag;
    this.delete = flag;
    this.view_copyable = flag;
    this.up_version = flag;
    this.allocate = flag;
    this.copy = flag;
    this.assign_task = flag;
    this.form_archive = flag;
    this.form_create = flag;
    this.form_edit = flag;
    this.form_export = flag;
    this.pricing = flag;
    this.delete_one_off_child = flag;
  }
}
