import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';

export default class OhsInspectionStart {
  dateCommenced?: string;

  tz: string;

  attachments: OhsAttachment[];

  constructor() {
    this.tz = '';
    this.attachments = new Array<OhsAttachment>();
  }
}
