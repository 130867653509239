import React from 'react';

import './App.css';
import { Theme } from '@mui/material/styles';
import '@mui/styles';

import 'react-image-gallery/styles/css/image-gallery.css';

import 'react-quill/dist/quill.snow.css';

import OhsBackdrop from 'global-components/backdrop/OhsBackdrop';

import OhsModuleRoutes from './routes/OhsModuleRoutes';
import OhsToastContainer from './OhsToastContainer';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

function App() {
  return (
    <div>
      <OhsBackdrop />
      <OhsModuleRoutes />
      <OhsToastContainer />
    </div>
  );
}

export default App;
