import React from 'react';

import { OhsConstants } from 'global-services/constants/OhsConstants';
import { store } from 'app/store';

function OhsAngularIframe(props: any) {
  const bodyUrl = window.location.href.substring(
    window.location.href.indexOf(window.location.host) + window.location.host.length
  );
  const adjustedURL = `/${OhsConstants.LegacyModuleFolder}/#${bodyUrl}`;
  const { user } = store.getState().user;
  let heightAdjust = '0px';
  if (props.isShowingBanner === true) {
    heightAdjust = user != null && user.level === 'free' ? '177.5px' : '137.5px';
  } else {
    heightAdjust = user != null && user.level === 'free' ? '125px' : '85px';
  }

  return (
    <div>
      <iframe
        style={{
          height: `calc(100vh - ${heightAdjust})`,
          width: 'calc(100vw)',
          boxSizing: 'border-box',
          border: '0px',
        }}
        src={adjustedURL}
        id="OhsAngularIframeId"
        title="OhsAngularIframe"
      />
    </div>
  );
}

export default OhsAngularIframe;
