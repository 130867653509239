import React from 'react';

import { Button, Icon } from 'semantic-ui-react';

import { registerButtonStyles } from 'global-components/register/OhsModuleHeader';

interface Props {
  row: any;
  assignFunc: (item: any, callback?: (success: boolean) => void) => Promise<void>;
  isAssigned: boolean;
}

function OhsAssignUnAssignButton({ row, assignFunc, isAssigned }: Props) {
  const { classes } = registerButtonStyles();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleAssign = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await assignFunc(row?.original);
    setIsLoading(false);
  };

  return (
    <Button
      onClick={handleAssign}
      className={classes.button}
      color={isAssigned ? undefined : 'teal'}
      loading={isLoading}
    >
      <Icon className={classes.buttonIcons} name="users" />
      <span className={classes.hideForSmall}>{isAssigned ? 'Unassign' : 'Assign'}</span>
    </Button>
  );
}

export default OhsAssignUnAssignButton;
