import OhsRecordFieldType from 'global-services/constants/record-field/OhsRecordFieldType';

export const OhsDetailsRecordField = new OhsRecordFieldType('description', 'Details');
export const OhsFrequencyRecordField = new OhsRecordFieldType('frequencyValue', 'Frequency');
export const OhsFrequencyKeyRecordField = new OhsRecordFieldType('frequencyKey', 'FrequencyKey');
export const OhsReferencePrefixRecordField = new OhsRecordFieldType(
  'templateMeta.referencePrefix',
  'Reference Prefix'
);
export const OhsScoreRecordField = new OhsRecordFieldType(
  'templateExecution.cumulativeScorePercentage',
  'Score'
);

export const OhsReferenceRecordField = new OhsRecordFieldType('reference', 'Ref ID');
export const OhsInProgressRecordField = new OhsRecordFieldType('inProgress', 'In-Progress');
