import { OhsUser } from 'user/OhsUser';
import { withSignOffViewPermission } from 'global-services/OhsPermission';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';
import { ModuleConfig } from 'global-services/constants/OhsObject';

const commonPermission = (user: OhsUser) => user?.configs?.action?.PERMISSIONS.view || false;
const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.Action}/${path}`,
  permission,
});

const ActionRouteList = {
  [ModuleRoute.Home]: createRoute('home', commonPermission),
  [ModuleRoute.Details]: createRoute(':actionId/view', commonPermission),
  [ModuleRoute.SignOff]: createRoute(':actionId/task/:taskId/signoff', (user) =>
    withSignOffViewPermission(user, ModuleConfig.Action)
  ),
  [ModuleRoute.Evidence]: createRoute(':actionId/task/:taskId/completed', commonPermission),
  [ModuleRoute.Add]: createRoute(
    'add',
    (user) => user?.configs?.action?.PERMISSIONS.create || false
  ),
};

export default ActionRouteList;
