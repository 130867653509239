export default class OhsChemicalFilterModels {
  view1chemicalList: {
    viewPreset: 'view_1';
    archived?: boolean;
    locations?: string[];
    locations_EMPTY?: boolean;
  };

  view2chemicalList: {
    viewPreset: 'view_2';
    workplaces?: string[];
    archived?: boolean;
    locations?: string[];
    locations_EMPTY?: boolean;
    allocatedToWorkplace?: boolean;
    workplaceOwned: boolean;
  };

  view3chemicalList: {
    viewPreset: 'view_3';
    workplaces?: string[];
    archived?: boolean;
    locations?: string[];
    locations_EMPTY?: boolean;
    allocatedToOrg?: boolean;
    allocatedToWorkplace?: boolean;
    notAllocated?: boolean;
  };

  view4chemicalList: {
    viewPreset: 'view_4';
    archived?: boolean;
    locations?: string[];
    locations_EMPTY?: boolean;
    allocatedToWorkplace?: boolean;
    workplaceOwned?: boolean;
  };

  sort: string;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  constructor() {
    this.view1chemicalList = {
      viewPreset: 'view_1',
      archived: false,
      locations: [],
      locations_EMPTY: true,
    };
    this.view2chemicalList = {
      viewPreset: 'view_2',
      workplaces: [],
      archived: false,
      locations: [],
      locations_EMPTY: true,
      workplaceOwned: true,
      allocatedToWorkplace: true,
    };
    this.view3chemicalList = {
      viewPreset: 'view_3',
      workplaces: [],
      archived: false,
      locations: [],
      locations_EMPTY: true,
      allocatedToOrg: true,
      allocatedToWorkplace: true,
      notAllocated: true,
    };

    this.view4chemicalList = {
      viewPreset: 'view_4',
      archived: false,
      locations: [],
      locations_EMPTY: true,
      allocatedToWorkplace: true,
      workplaceOwned: true,
    };

    this.sort = '{ "key": "name", "order": 1 }';
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Chemical Name: A to Z', value: '{ "key": "name", "order": 1 }' },
        { name: 'Chemical Name: Z to A', value: '{ "key": "name", "order": -1 }' },

        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },

        { name: 'Issued Date: Newest to Oldest', value: '{ "key": "dateIssued", "order": -1 }' },
        { name: 'Issued Date: Oldest to Newest', value: '{ "key": "dateIssued", "order": 1 }' },
      ],
    };
  }
}
