import ModuleHomeUrl from 'dashboard/leftSideDrawer/ModuleHomeUrl';

import { OhsUser } from '../../user/OhsUser';
import OhsBreadcrumbsNameAndRef from './OhsBreadcrumbsNameAndRef';

const OhsBreadCrumbInspection = (
  ohsBreadcrumbsNameAndRef: OhsBreadcrumbsNameAndRef,
  modulePath: string,
  subPath: string,
  viewPath: string,
  operationPath: string,
  user: OhsUser
) => {
  const defaultModule = {
    name: user.configs.inspection?.TITLE ?? '',
    href: `${(ModuleHomeUrl[user.tier.type] as any).inspection}?tab=tp`,
  };

  const urlParams = new URLSearchParams(window.location.search);
  const paramType = urlParams.get('tempType') || 'tp';
  const inspectionRoot = urlParams.get('inspectionRoot') || '';

  if (subPath === 'home') {
    ohsBreadcrumbsNameAndRef.leafName = {
      name: defaultModule.name,
    };
  } else if (operationPath.indexOf('/details') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View' };
  } else if (subPath === 'template-add') {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Add' };
  } else if (viewPath === 'edit') {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?tempType=${paramType}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Edit Template' };
  } else if (operationPath.indexOf('view') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?tempType=${paramType}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View Template' };
  } else if (operationPath.indexOf('aggregate') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?tempType=${paramType}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Aggregate' };
  } else if (operationPath.indexOf('completed') > -1) {
    // for evidence that redirects with different inspectionId
    if (inspectionRoot !== '') {
      ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
      ohsBreadcrumbsNameAndRef.parentOperationRef = {
        href: `/${modulePath}/${inspectionRoot}/details?tempType=${paramType}`,
        name: 'View',
      };
    } else {
      ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
      ohsBreadcrumbsNameAndRef.parentOperationRef = {
        href: `/${modulePath}/${subPath}/details?tempType=${paramType}`,
        name: 'View',
      };
    }
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Evidence' };
  } else if (operationPath.indexOf('signoff') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModule;
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?tempType=${paramType}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Signoff' };
  }
  return ohsBreadcrumbsNameAndRef;
};
export default OhsBreadCrumbInspection;
