import React, { ReactNode } from 'react';

import _ from 'lodash';
import { Button, Icon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';

import registerButtonStyles from 'global-components/register/RegisterButtonStyles';

interface ButtonTypes {
  color?: SemanticCOLORS;
  func: () => any;
  name: string | ReactNode;
  icon?: SemanticICONS;
  disabled?: boolean;
  'data-testid'?: string;
}
function OhsHeaderButton(btn: ButtonTypes) {
  const { classes } = registerButtonStyles();
  const buttonProps: any = {
    className: classes.button,
    onClick: btn.func,
    disabled: btn.disabled ?? false,
  };

  if (btn['data-testid']) {
    buttonProps['data-testid'] = btn['data-testid'];
  }

  if (btn.color) {
    _.assign(buttonProps, { color: btn.color });
  }

  return (
    <Button {...buttonProps}>
      {btn.icon && (
        <Icon
          className={
            `${btn.name}` === ''
              ? `${classes.iconOnly} ${classes.buttonIcons}`
              : classes.buttonIcons
          }
          name={btn.icon}
        />
      )}
      {btn.name && <span className={classes.hideForSmall}>{btn.name}</span>}
    </Button>
  );
}

export default OhsHeaderButton;
