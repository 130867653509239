import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
  OhsApiRequestOptions,
  RequestType,
} from 'global-services/api/OhsApiModels';
import { ModuleType } from 'global-services/constants/OhsObject';
import { getCurrentTimezone } from 'global-services/OhsDataParse';
import { OhsAllocationItem } from 'global-components/allocation/OhsAllocatableModels';
import OhsCorrespondenceExchangeRecord from 'correspondence/models/OhsCorrespondenceExchangeRecord';

import OhsApiRequest from '../global-services/api/OhsApiRequest';
import {
  ContractorAllocListRes,
  ContractorLinkedTaskListRes,
  OhsContractorListsFilterPayload,
  OhsOrgTabFilterPayload,
  OhsWorkplaceTabFilterPayload,
  OhsCommunicationTabFilterPayload,
} from './models/OhsContractorModels';
import OhsContractorRecord, { ContractorLinkageType } from './models/OhsContractorRecord';
import parseListAllocations from './utils/OhsParseListAllocations';
import OhsContractorLookup from './models/OhsContractorLookUp';

const getContractorLists = (
  filterInfo:
    | OhsOrgTabFilterPayload
    | OhsWorkplaceTabFilterPayload
    | OhsContractorListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Contractor, OhsApiRequestName.List, {
    filter: filterInfo,
  });
};

export const getContractorDetails = async (
  _id: string,
  options: OhsApiRequestOptions = {}
): Promise<OhsContractorRecord> => {
  const res: OhsContractorRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Contractor,
    OhsApiRequestName.Fetch,
    { _id },
    options
  );
  return res || new OhsContractorRecord();
};
export const archiveContractor = (contractorId: string, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Contractor,
    archived ? OhsApiRequestName.Archive : OhsApiRequestName.UnArchive,
    {
      _id: contractorId,
    }
  );
};
export const deleteContractor = (contractorId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Contractor, OhsApiRequestName.Delete, {
    _id: contractorId,
  });
};
export const allocateContractor = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = { _id, to: [], tzDateCreated: getCurrentTimezone() };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  } else {
    payload.to = [allocationItem];
  }

  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Contractor,
    OhsApiRequestName.Allocate,
    payload
  );
};
export const deAllocateContractor = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = { _id, to: [], tzDateCreated: getCurrentTimezone() };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  } else {
    payload.to = [allocationItem];
  }

  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Contractor,
    OhsApiRequestName.Deallocate,
    payload
  );
};
export const getContractorAllocationList = (
  contractorId: string
): Promise<ApiResponseType<ContractorAllocListRes> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Contractor,
    OhsApiRequestName.AllocationList,
    {
      _id: contractorId,
    }
  );
};
export const getContractorAllocatedList = (
  contractorListIDs: string[]
): Promise<OhsContractorRecord[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Contractor, OhsApiRequestName.AllocatedList, {
    _ids: contractorListIDs,
  });
};

export const getContractorListsWithAllocations = (
  filterInfo:
    | OhsOrgTabFilterPayload
    | OhsWorkplaceTabFilterPayload
    | OhsContractorListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const getContractorListsReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const contractorList = await getContractorLists(filterInfo);
    // exit if list result is empty
    if (contractorList && contractorList?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(contractorList);
      });
    }

    const getIDs = contractorList?.items.map((item: OhsContractorRecord) => item._id) as string[];
    const allocatedList = await getContractorAllocatedList(getIDs);
    const contractorListWithAllocations = parseListAllocations(
      contractorList,
      allocatedList
    ) as ApiResListsType<OhsContractorRecord[]> | null;

    return new Promise((resolve) => {
      resolve(contractorListWithAllocations);
    });
  };

  return getContractorListsReq();
};
export const addContractor = (payload: any, options?: OhsApiRequestOptions) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Contractor,
    OhsApiRequestName.Create,
    payload,
    options
  );
};
export const editContractor = (payload: any, options?: OhsApiRequestOptions) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Contractor,
    OhsApiRequestName.Edit,
    payload,
    options
  );
};

export const getContractorLinkedTasksList = (
  contractorId: string
): Promise<ApiResponseType<ContractorLinkedTaskListRes> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Contractor,
    OhsApiRequestName.ContractorLinkedTasks,
    {
      from: { _id: contractorId },
    }
  );
};

export const editContractorLinkage = async (_id: string, links: any[]) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Contractor,
    OhsApiRequestName.ContractorEditLinkage,
    {
      _id,
      links,
    }
  );
};

export const getLinkedCorrespondenceExchangesLists = (
  filterInfo: OhsCommunicationTabFilterPayload,
  _id: string
): Promise<ApiResponseType<ApiResListsType<OhsCorrespondenceExchangeRecord[]>> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Contractor,
    OhsApiRequestName.ContractorLinkedCorrespondence,
    {
      _id,
      filter: filterInfo,
    }
  );
};

export const getContractorLinkableList = (
  contractorId: string,
  moduleType: ModuleType
): Promise<ApiResponseType<{ items: ContractorLinkageType[] }> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Contractor, OhsApiRequestName.ListLinkable, {
    to: { type: moduleType },
    from: { _id: contractorId },
  });
};
export const contactOptions = (
  _id: string,
  businessName: string,
  contactName: string | null = '',
  contactEmails: string[] | null = []
) => contactEmails?.map((email) => new OhsContractorLookup(_id, businessName, contactName, email));

export const parseContractorLookupList = ({ items }: any) => {
  const contractorList = new Array<OhsContractorLookup>();
  items.forEach((contractor: any) => {
    if (contractor[0] && contractor[2]) {
      contractorList.push(new OhsContractorLookup(contractor[0], contractor[2], null, null));
      if (contractor[7] && contractor[7].length > 0) {
        contractor[7].forEach((contact: any) => {
          if (contact[2] && contact[2].length > 0) {
            contact[2].forEach((email: string) => {
              contractorList.push(
                new OhsContractorLookup(contractor[0], contractor[2], contact[0], email)
              );
            });
          } else {
            contractorList.push(
              new OhsContractorLookup(contractor[0], contractor[2], contact[0], null)
            );
          }
        });
      }
    }
  });
  return contractorList;
};

export default getContractorLists;
