// eslint-disable-next-line max-classes-per-file
import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';

import { OhsAttachment } from '../../global-components/form/fields/attachments/OhsAttachmentModels';

export interface OhsDocumentsFilterPayload {
  viewPreset?: 'view_1' | 'view_2' | 'view_3' | 'view_4';
  page: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;

  archived?: boolean;

  category?: string[];

  workplaces?: string[];

  reviewScheduled?: boolean;

  allocatedToWorkplace?: boolean;

  notAllocated?: boolean;

  workplaceOwned?: boolean;

  copied?: boolean;

  copiedVersionUpdated?: boolean;

  notCopied?: boolean;
}

export class OhsDocumentVersion {
  attachments?: OhsAttachment[];

  type: ModuleType;

  vNumber: number;

  category: string;

  categoryOther?: string;

  customVersion?: string;

  createdBy: OhsModuleCreateBy;

  name: string;

  subcategory: string;

  subcategoryOther?: string;

  dateCreated: string;

  dateIssued: string;

  description: string;

  constructor() {
    this.type = ModuleType.VersionDocument;
    this.vNumber = 0;
    this.category = '';
    this.description = '';
    this.createdBy = new OhsModuleCreateBy();
    this.name = '';
    this.subcategory = '';
    this.category = '';
    this.dateCreated = '';
    this.dateIssued = '';
  }
}
export class OhsVersionReviewTask {
  dateDue: string;

  dateDueReference: string;

  reviewFrequencyKey: string;

  reviewFrequencyValue: number;

  type: ModuleType;

  _id: string;

  constructor() {
    this.type = ModuleType.Task;
    this.reviewFrequencyValue = 0;
    this.dateDue = '';
    this.dateDueReference = '';
    this.reviewFrequencyKey = '';
    this._id = '';
  }
}

export default class OhsDocumentsListsRecord {
  tier?: OhsTier;

  allocationOf?: { _id: string; type: ModuleType.VersionDocument };

  type: ModuleType;

  allocationActive: boolean;

  archived: boolean;

  description?: string;

  version: OhsDocumentVersion;

  review?: { task: OhsVersionReviewTask };

  subRows: any[];

  _id: string;

  copyable?: boolean;

  copyableTo?: { type: ModuleType.VersionDocument; _id: string };

  copyWithoutEdit?: boolean;

  vNumber?: number;

  constructor(tier?: OhsTier) {
    this.tier = tier;
    this.type = ModuleType.VersionDocument;
    this.allocationActive = false;
    this.archived = false;
    this.subRows = [];
    this._id = '';
    this.version = new OhsDocumentVersion();
    this.review = { task: new OhsVersionReviewTask() };
    this.copyWithoutEdit = false;
    this.vNumber = undefined;
  }
}
