import React from 'react';

import { Box, Button, styled } from '@mui/material';

import { MfaAuthenticatorType, OhsMethodList } from 'user/user-settings/mfa/OhsMfaTypes';

const MfaButton = styled(Button)((props: { disabled: boolean }) => ({
  justifyContent: 'left',
  background: '#e9e9e9',
  width: '100%',
  marginTop: '6px',
  padding: '15px',
  color: props.disabled ? 'green' : 'black',
  fontWeight: 'bold',
  '& > div': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  '&.Mui-disabled': {
    background: 'whitesmoke',
    color: props.disabled ? 'green' : '#b2b2b2',
    '* > div': {
      color: props.disabled ? 'green' : '#b2b2b2',
    },
  },
}));

interface Props {
  activeMfaLists: OhsMethodList[];
  hasNoMobileNum: boolean;
  handleEnrollMfaMethod: (authInfo: MfaAuthenticatorType) => Promise<void>;
}
const OhsMfaMethodListsDisplay = (props: Props): any => {
  const { activeMfaLists, hasNoMobileNum, handleEnrollMfaMethod } = props;

  return activeMfaLists.map((method: OhsMethodList, index: number) => {
    if (method.permission === false && method.isEnabled === false) return null;

    return (
      <MfaButton
        data-testid={`mfa-method-${method.method}`}
        key={index}
        disabled={
          (method.isEnabled || (hasNoMobileNum && method.method === 'sms' && !method.isEnabled)) ??
          false
        }
        startIcon={method.icon}
        onClick={() => {
          const authInfo = {
            id: '',
            method: method.method,
            name: method.name,
          } as MfaAuthenticatorType;
          handleEnrollMfaMethod(authInfo);
        }}
      >
        <Box>
          {method.title}{' '}
          {method.isEnabled && <Box color={method.isEnabled ? 'green' : 'black'}>enabled</Box>}
          {hasNoMobileNum && method.method === 'sms' && (
            <Box color="black">Please set your phone number</Box>
          )}
        </Box>
      </MfaButton>
    );
  });
};

export default OhsMfaMethodListsDisplay;
