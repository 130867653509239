import { ModuleType } from 'global-services/constants/OhsObject';

import { getConfigValue, setPermissions } from '../OhsConfigServices';
import OhsModulePermissions from '../OhsModulePermissions';
import {
  OhsModuleCustomInformation,
  OhsModuleLocation,
  OhsModuleSDSReviewFrequency,
} from '../OhsModuleChemical';
import OhsFileBoxConfig from '../filebox/OhsFileBoxConfig';

class OhsChemicalConfig {
  TITLE: string;

  PERMISSIONS: OhsModulePermissions;

  LOCATION_LIST: OhsModuleLocation[];

  SDS_REVIEW_FREQUENCY: OhsModuleSDSReviewFrequency;

  MORPH_PERMISSIONS: OhsModulePermissions;

  CUSTOM_INFORMATION: OhsModuleCustomInformation;

  TYPE = ModuleType.Chemical;

  fileboxConfig?: OhsFileBoxConfig;

  constructor(config: any, fileboxConfig?: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsModulePermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    this.LOCATION_LIST = getConfigValue(config, 'LOCATION_LIST');
    this.SDS_REVIEW_FREQUENCY = getConfigValue(config, 'SDS_REVIEW_FREQUENCY');
    this.CUSTOM_INFORMATION = getConfigValue(config, 'CUSTOM_INFORMATION');
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(this.MORPH_PERMISSIONS, getConfigValue(config, 'MORPH_PERMISSIONS'));

    if (fileboxConfig) {
      this.fileboxConfig = new OhsFileBoxConfig(fileboxConfig);
    }
  }
}

export default OhsChemicalConfig;
