import * as tus from 'tus-js-client';
import Cookies from 'js-cookie';
import shortid from 'shortid';

import {
  OhsLinkAccessAttachmentsUploadHeader,
  OhsSessionToken,
  OhsUploadAttachmentDetailsHeader,
} from 'global-services/constants/OhsStorageNames';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import AttachmentUploadUrl, {
  AttachmentLinkAccessUploadUrl,
} from 'global-services/constants/url/OhsAttachmentUrl';
// import addWatermarkToSignature from 'global-services/watermark/addWatermarkToSignature';

import { OhsSignature } from './OhsSignatureModels';
import { formatBase64String } from '../attachments/OhsAttachmentServices';

export const getSignatureList = async (payload: string[]): Promise<OhsSignature[]> => {
  const res: { items: { _ids: any[] } } | null = await OhsApiRequest(
    RequestType.Attachments,
    ModuleType.Attachment,
    OhsApiRequestName.List,
    { _ids: payload }
  );
  let attachmentList: any[] = [];

  if (res?.items) {
    // check if any attachment null or undefined
    attachmentList = (res.items._ids || []).filter((a) => a);
  }
  return attachmentList;
};

export const uploadSignature = (
  file: any,
  url: string,
  onSuccess: (val: any) => void,
  callback: () => void,
  uploadToken?: string
) => {
  const id = shortid.generate();
  const endpoint = uploadToken ? AttachmentLinkAccessUploadUrl : AttachmentUploadUrl;
  const token = Cookies.get(OhsSessionToken) || '';

  let headers;
  if (uploadToken) {
    headers = { [OhsLinkAccessAttachmentsUploadHeader]: uploadToken };
  } else {
    headers = { [OhsSessionToken]: token };
  }

  const newValue = { id, details: '', url };
  const upload = new tus.Upload(file, {
    endpoint,
    retryDelays: [0, 3000, 5000],
    metadata: {
      fileName: file.name,
    },
    headers,
    onError(error: any) {
      console.error(`Failed because: ${error}`);
      callback();
    },
    onAfterResponse(req: tus.HttpRequest, res: tus.HttpResponse) {
      const xSafetychampionTusResponse = res.getHeader(OhsUploadAttachmentDetailsHeader);

      if (xSafetychampionTusResponse) {
        newValue.details = formatBase64String(xSafetychampionTusResponse);
        onSuccess(newValue);
      }
    },
  });

  upload.start();

  return newValue;
};
