import React, { ReactNode } from 'react';

import OhsMessageBox from 'global-components/message-box/OhsMessageBox';
import OhsModuleBackground from 'global-components/register/OhsModuleBackground';

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error('ErrorBoundary caught an error', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render any custom fallback UI
      return (
        <OhsModuleBackground>
          <OhsMessageBox
            title="Something went wrong."
            description="Please contact customer support for assistance."
          />
        </OhsModuleBackground>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
