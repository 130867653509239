import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';

import { GlobalFilterName, OhsFilterList } from './OhsFilterModels';

const getFilterInfoLS: OhsFilterList =
  (getOhsLocalStorage(GlobalFilterName) as OhsFilterList) ?? new OhsFilterList();

const initialState = {
  filterInfo: getFilterInfoLS,
  onFilterCallback: () => {},
};

const globalFilterSlice = createSlice({
  name: GlobalFilterName,
  initialState,
  reducers: {
    updateFilter: (state, action: PayloadAction<OhsFilterList>) => {
      setOhsLocalStorage(GlobalFilterName, JSON.stringify(action.payload));
      state.filterInfo = action.payload;
    },
    setFilterCallback: (state, action: PayloadAction<() => void>) => {
      state.onFilterCallback = action.payload;
    },
  },
});

export const { updateFilter, setFilterCallback } = globalFilterSlice.actions;

export const updateModuleFilterAsync = createAsyncThunk<void, OhsFilterList, { state: RootState }>(
  'moduleFilter/updateFilter',
  async (filter: OhsFilterList, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    dispatch(updateFilter(filter));
    getState().globalfilter.onFilterCallback(); // call callback
  }
);

const globalFilterState = (state: RootState) => state.globalfilter.filterInfo;
// Memoized Selectors
export const getGlobalFilter = createSelector([globalFilterState], (filter) => filter);

export const globalfilterReducer = globalFilterSlice.reducer;
