import React from 'react';

import { FieldValues } from 'react-hook-form';

import OhsMultipleSelectField from 'global-components/form/fields/OhsMultipleSelectField';
import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { OhsSelectOption } from 'user/OhsUserModels';

interface Props {
  id: string;
  ohsFormSettings: OhsFormSettings<FieldValues>;
  options: any[];
}

export default function OhsChemicalLocationFilterCheckList(props: Props) {
  const locationsLists: OhsSelectOption[] =
    props.options.map((location) => ({
      name: location.category,
      value: location.category,
    })) ?? [];

  return (
    <div>
      <OhsMultipleSelectField
        title="Locations"
        id={props.id}
        required={false}
        options={locationsLists || []}
        ohsFormSettings={props.ohsFormSettings}
      />
    </div>
  );
}
