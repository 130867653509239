import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';
import { ApiResListsType, ApiResponseType } from 'global-services/api/OhsApiModels';
import {
  GlobalSearchState,
  OhsGlobalSearchFilter,
  OhsGlobalSearchPayload,
} from 'search/OhsSearchModels';
import globalModuleSearch from 'search/OhsSearchServices';
import getPayload from 'search/OhsSearchUtils';

const initSearchResults = {
  items: [],
  pagination: {
    page: 1,
    totalPages: 1,
    next: '',
  },
};

export const defaultGlobalSearch: OhsGlobalSearchFilter = {
  page: 1,
  count: false,
  modules: [],
  skipOrgRecords: false,
  workplaces: [],
  task: {
    complete: false,
    includeMasterRecords: false,
  },
  skipAllocatedRecords: false,
  archived: false,
};

export const initialGlobalSearchPayload: OhsGlobalSearchPayload = {
  searchKey: '',
  substringSearch: true,
  highlight: false,
  filter: defaultGlobalSearch,
};

const initialState: GlobalSearchState = {
  isLoading: false,
  searchInfo: initialGlobalSearchPayload,
  searchResults: initSearchResults,
  currentPage: 1,
};

export const fetchGlobalSearchAsync = createAsyncThunk<
  ApiResponseType<ApiResListsType<any[]> | null> | null,
  undefined,
  { state: RootState }
>('globalSearch/fetchGlobalSearchAsync', async (_, thunkAPI) => {
  const { globalSearch } = thunkAPI.getState();

  const payload = getPayload(globalSearch, false);
  try {
    const response = await globalModuleSearch(payload.searchInfo);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchGlobalSearchCountAsync = createAsyncThunk<
  ApiResponseType<ApiResListsType<any[]> | null> | null,
  undefined,
  { state: RootState }
>('globalSearch/fetchGlobalSearchCount', async (_, thunkAPI) => {
  const { globalSearch } = thunkAPI.getState();
  const payload = getPayload(globalSearch, true);

  try {
    const response = await globalModuleSearch(payload.searchInfo);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState,
  reducers: {
    setResultsCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setGlobalSearch: (state, action: PayloadAction<OhsGlobalSearchPayload>) => {
      state.searchInfo = action.payload;
    },
    clearGlobalSearch: (state) => {
      state.searchInfo = initialGlobalSearchPayload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGlobalSearchAsync.pending, (state) => {
        state.isLoading = true;
        state.searchResults = {
          items: [],
          pagination: state.searchResults?.pagination ?? {
            page: 1,
            totalPages: 1,
            next: '',
          },
        };
      })
      .addCase(fetchGlobalSearchAsync.fulfilled, (state, action) => {
        const resResults = action.payload;
        const seachResult = resResults?.result;
        if (resResults?.success && seachResult) {
          // get last _id and set it to pagination next
          seachResult.pagination.next = [...seachResult.items].pop()?._id ?? '';
          seachResult.pagination.totalPages = getOhsLocalStorage('searchListtotalPages');

          state.searchResults = seachResult;
          state.isLoading = false;
        } else {
          state.searchResults = initSearchResults;
          state.isLoading = false;
        }
      })
      .addCase(fetchGlobalSearchAsync.rejected, (state) => {
        state.isLoading = false;
        state.searchResults = initSearchResults;
      })
      .addCase(fetchGlobalSearchCountAsync.pending, (state) => {
        state.isLoading = true;
        state.searchResults = {
          items: state.searchResults?.items ?? [],
          pagination: {
            page: 1,
            totalPages: 1,
            next: '',
          },
        };
      })
      .addCase(fetchGlobalSearchCountAsync.fulfilled, (state, action) => {
        const resResults = action.payload;
        const paginatedResult = resResults?.result;
        if (resResults?.success && paginatedResult) {
          // store total pages to localstorage
          setOhsLocalStorage('searchListtotalPages', paginatedResult.pagination.totalPages);
          paginatedResult.pagination.totalPages = paginatedResult.pagination.totalPages ?? 1;

          state.searchResults = {
            items: state.searchResults?.items ?? [],
            pagination: paginatedResult.pagination,
          };
          state.isLoading = false;
        } else {
          state.searchResults = {
            items: state.searchResults?.items ?? [],
            pagination: initSearchResults.pagination,
          };
          state.isLoading = false;
        }
      });
  },
});

const globalFilterState = (state: RootState) => state.globalSearch;
export const getGlobalSearch = createSelector([globalFilterState], (search) => search);
export const { setGlobalSearch, clearGlobalSearch, setResultsCurrentPage } =
  globalSearchSlice.actions;
export const globalSearchReducer = globalSearchSlice.reducer;
