import React from 'react';

import { Routes, Route } from 'react-router-dom';

import OhsNoTopBarAngularIframe from './OhsNoTopBarAngularIframe';

export default function OhsIframeModulePublicHome() {
  return (
    <div>
      <Routes>
        <Route path="*" element={<OhsNoTopBarAngularIframe />} />
      </Routes>
    </div>
  );
}
