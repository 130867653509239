import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';

const registerButtonStyles = makeStyles()((theme: Theme) => ({
  button: {
    marginRight: '1px!important',

    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      padding: '.78571429em .78571429em .78571429em!important',
    },
  },
  buttonGroup: {
    marginTop: '-10px!important',
    marginBottom: '-10px!important',
  },
  buttonIcons: {
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      opacity: '.9',
      margin: '0!important',
      verticalAlign: 'top',
    },
  },
  iconOnly: {
    margin: '0!important',
  },
  hideForSmall: {
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'inline',
    },
  },
}));
export default registerButtonStyles;
