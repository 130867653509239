class OhsTrainingPermissions {
  view: boolean;

  create: boolean;

  edit: boolean;

  signoff: boolean;

  archive: boolean;

  delete: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.create = flag;
    this.edit = flag;
    this.signoff = flag;
    this.archive = flag;
    this.delete = flag;
  }
}

export default OhsTrainingPermissions;
