import Cookies from 'js-cookie';
import moment from 'moment';

export const getMultiUserConfig = (): any => {
  const config = localStorage.getItem('multi-users');
  if (config == null) return '';

  return JSON.parse(config);
};

export const formatUserData = (userProfileData: any) => {
  const currentLang = Cookies.get('googtrans') || '';

  return {
    ...userProfileData,
    username: userProfileData.name,
    language: currentLang || 'en',
    userphone: `${userProfileData.phoneCountryCode} - ${userProfileData.phone}`,
  };
};

export const formatTime = (notifyTime: { hours: string; minutes: string }) => {
  return notifyTime
    ? moment(`${notifyTime.hours}:${notifyTime.minutes}`, 'h:mm:ss A').format('HH:mm')
    : '';
};

export const getModuleConfigValue = (configLists: any[], moduleType: string, name: string) => {
  const getModule = configLists.find((module: any) => module.type === moduleType);
  if (!getModule) {
    return null;
  }
  const getConfigValue = getModule.values.find((val: any) => val.name === name);
  return getConfigValue.value;
};
