import { OhsObject, TierType } from '../OhsObject';

export class OhsTier implements OhsObject {
  type: TierType;

  _id: string;

  name: string;

  constructor(tierObj: OhsTier) {
    this._id = tierObj?._id;
    this.name = tierObj?.name ?? '';
    switch (tierObj?.type) {
      case 'core.tier.T1': {
        this.type = TierType.T1;
        break;
      }
      case 'core.tier.T2': {
        this.type = TierType.T2;
        break;
      }
      case 'core.tier.T3': {
        this.type = TierType.T3;
        break;
      }
      case 'core.tier.T4': {
        this.type = TierType.T4;
        break;
      }
      default: {
        this.type = TierType.T5;
        break;
      }
    }
  }
}

export interface OhsLowerTier {
  _id: string;
  access: boolean;
  name: string;
  disabled: boolean | undefined;
}
