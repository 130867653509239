export const getAngularIframeWindow = (): any => {
  const iframeWindow = (document.getElementById('OhsAngularIframeId') as any)
    ? ((document.getElementById('OhsAngularIframeId') as any).contentWindow as any)
    : null;
  return iframeWindow;
};

export const getAngularIframeInjector = (objName: string): any => {
  const iframeWindow = getAngularIframeWindow();
  if (iframeWindow)
    return iframeWindow.angular.element(iframeWindow.document.body).injector().get(objName);
  return null;
};

export const openAngularIframeModal = (modalName: string, modalData: any | null) => {
  const $rootScope = getAngularIframeInjector('$rootScope');
  $rootScope.openModal(modalName, modalData);
};

export const broadcastAngularIframe = (name: string, data: any) => {
  const $rootScope = getAngularIframeInjector('$rootScope');
  $rootScope.$broadcast(name, data);
};
