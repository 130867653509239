import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';

export default class OhsReviewPlanTaskRecord {
  complete: boolean;

  reviewNotes: string;

  createdBy: OhsModuleCreateBy;

  dateCreated: string;

  dateDue: string;

  dateCompleted: string;

  dateSignedoff: string;

  signedoffBy: {
    name: string;
    email: string;
  };

  description: string;

  for?: {
    _id: string;
    type: ModuleType.Inspection;
    save?: any;
  };

  type: ModuleType.Task;

  tzDateSignedoff: string;

  _id: string;

  tier?: OhsTier;

  signatures: any[];

  title: string;

  constructor() {
    this.complete = false;
    this.reviewNotes = '';
    this.createdBy = new OhsModuleCreateBy();
    this.dateCreated = '';
    this.dateDue = '';
    this.dateCompleted = '';
    this.dateSignedoff = '';
    this.signedoffBy = {
      name: '',
      email: '',
    };
    this.description = '';
    this.for = undefined;
    this.type = ModuleType.Task;
    this.tzDateSignedoff = '';
    this._id = '';
    this.signatures = [];
    this.title = '';
  }
}
