// eslint-disable-next-line max-classes-per-file
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { ModuleType, OhsModuleCreateBy, OhsObject } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';

class OhsModuleOwner implements OhsObject {
  type: ModuleType.User;

  _id: string;

  name: string;

  position: string;

  referenceId: string;

  constructor() {
    this.type = ModuleType.User;
    this._id = '';
    this.name = '';
    this.position = '';
    this.referenceId = '';
  }
}

export interface OhsHumanResourceTrainingCustomFilters {
  archived?: boolean;
  workplaces?: string[];
  next?: string;
  skipOrgRecords?: boolean;
}

export interface OhsHumanResourceTrainingFilterPayload
  extends OhsHumanResourceTrainingCustomFilters {
  viewPreset?: 'view_1' | 'view_2' | 'view_3';
  page: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
}

export default class OhsHumanResourceTrainingListsRecord {
  attachments?: OhsAttachment[];

  name?: string;

  createdBy?: OhsModuleCreateBy;

  owner: OhsModuleOwner;

  tier?: OhsTier;

  referenceId: string;

  dateCreated?: string;

  dateIssued: string;

  dateExpiry?: string;

  archived?: boolean;

  category: string;

  categoryOther?: string;

  subcategory?: string;

  subcategoryOther?: string;

  _id?: string;

  constructor(tier?: OhsTier) {
    this.attachments = [];
    this.owner = new OhsModuleOwner();
    this.tier = tier;
    this.referenceId = '';
    this.category = '';
    this.subcategory = '';
    this.subcategoryOther = '';
    this.categoryOther = undefined;
    this.dateIssued = '';
    this.dateExpiry = '';
  }
}
