import React from 'react';

import { getLocalStorage, setLocalStorage } from 'global-services/OhsDataParse';
import { getSuperSetCustomList } from 'app/reports/superset/OhsSuperSetServices';

import OhsLeftMenuWithPopoverMenu from './OhsLeftMenuWithPopoverMenu';

function OhsCustomReportsMenu() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const superSetCustomList = getLocalStorage('superSetCustomList') ?? [];
  const getCustomList = async () => {
    setIsLoading(true);
    const response = await getSuperSetCustomList();
    if (response?.success) {
      const genReportLists = response.result.items.map((item: any) => {
        return {
          ...item,
          id: item.key.toString().toLowerCase().replace(/\s/g, '-').trim(),
        };
      });
      setLocalStorage('superSetCustomList', JSON.stringify(genReportLists));
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    setLocalStorage('superSetCustomList', []);
    getCustomList();
  }, []);

  if (superSetCustomList.length === 0) return null;

  return (
    <OhsLeftMenuWithPopoverMenu
      linkTitle="Custom Reports"
      popupoverMenu={superSetCustomList.map((list: any) => ({
        key: list.key,
        title: list.title,
        url: `/reporting/superset/custom/${list.id}/report`,
        permission: true,
      }))}
      isLoading={isLoading}
    />
  );
}

export default OhsCustomReportsMenu;
