import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { Box, SxProps, Theme } from '@mui/material';

interface Props {
  style?: SxProps<Theme>;
}

function OhsComponentLoading({ style }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        background: '#fafafa',
        minHeight: '300px',
        alignItems: 'center',
        width: '100%',
        ...style,
      }}
    >
      <CircularProgress size="30px" />
    </Box>
  );
}

OhsComponentLoading.defaultProps = {
  style: {},
};

export default OhsComponentLoading;
