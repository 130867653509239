import React from 'react';

import _ from 'lodash';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import { makeStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';

const BtnWithTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: 'black',
    border: '2px solid #ccc',
    boxShadow: _.get(theme, 'shadows.0') || '',
    fontSize: '13px',
    padding: '5px 10px 8px 10px',
  },
  arrow: {
    color: '#ccc',
  },
}))(Tooltip);

const useStylesBootstrap = makeStyles()(() => ({
  disabled: {
    '& .button': {
      borderRadius: '0 !important',
      height: '100%',
    },
  },
}));

interface Props {
  tooltipMsg: string;
  disabled?: boolean;
  children: React.ReactElement<any, any>;
}
export default function ButtonWithTooltips(props: Props) {
  const { classes } = useStylesBootstrap();
  const Element = () => props.children;

  return props.disabled ? (
    <span className={classes.disabled}>{props.children}</span>
  ) : (
    <BtnWithTooltip arrow title={props.tooltipMsg}>
      <Box sx={{ display: 'flex' }}>
        <Element />
      </Box>
    </BtnWithTooltip>
  );
}
ButtonWithTooltips.defaultProps = { disabled: false };
