import { ModuleType } from 'global-services/constants/OhsObject';

import { getConfigValue, setPermissions } from '../OhsConfigServices';
import OhsBiPermissions from './OhsBiPermissions';

class OhsBiConfig {
  TITLE: string;

  PERMISSIONS: OhsBiPermissions;

  TYPE = ModuleType.BusinessIntelligence;

  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsBiPermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}

export default OhsBiConfig;
