export default class OhsContractorFilterModels {
  viewPreset?: 'view_1' | 'view_2' | 'view_3' | 'view_4';

  archived: boolean;

  allocatedToOrg: boolean;

  allocatedToWorkplace: boolean;

  notAllocated: boolean;

  categories: string[];

  workplaces: string[];

  workplaceOwned: boolean;

  sort: string;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  constructor() {
    this.viewPreset = undefined;
    this.archived = false;
    this.allocatedToOrg = true;
    this.allocatedToWorkplace = true;
    this.notAllocated = true;
    this.workplaceOwned = true;
    this.workplaces = [];
    this.categories = [];
    this.sort = '{ "key": "businessName", "order": 1 }';
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Business Name: A to Z', value: '{ "key": "businessName", "order": 1 }' },
        { name: 'Business Name: Z to A', value: '{ "key": "businessName", "order": -1 }' },
        { name: 'Category: A to Z', value: '{ "key": "category", "order": 1 }' },
        { name: 'Category: Z to A', value: '{ "key": "category", "order": -1 }' },
        { name: 'Date Created: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Date Created: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },
      ],
    };
  }
}
