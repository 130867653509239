import React, { useMemo, useState } from 'react';

import { Message } from 'semantic-ui-react';
import { generatePath, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';
import { OhsActionColorOrange } from 'global-components/theme/OhsThemeColors';
import OhsRegister from 'global-components/register/OhsRegister';
import OhsRegisterTableServerPagination from 'global-components/register/table/OhsRegisterTableServerPagination';
import OhsModal from 'global-components/modal/OhsModal';
import {
  OhsTitleRecordField,
  OhsCategoryRecordField,
  OhsSubcategoryRecordField,
} from 'global-services/constants/record-field/OhsGlobalRecordFiled';
import { withCreatePermission } from 'global-services/OhsPermission';
import { ModuleConfig } from 'global-services/constants/OhsObject';
import { OhsSearchInputFields } from 'search/OhsSearchModels';
import OhsHeaderButton from 'global-components/register/table/rowButtons/OhsHeaderButton';
import { getAvailableInspectionSearch } from 'inspection/OhsInspectionSlice';

import OhsInspectionRecord from '../models/OhsInspectionRecord';
import {
  OhsFrequencyRecordField,
  OhsInProgressRecordField,
} from '../models/OhsInspectionRecordFields';
import useOhsAvailableInspectionList from '../hooks/OhsInspectionListAvailableHook';
import InspectionRouteList from '../OhsInspectionRouteList';
import OhsInspectionAvailableSearchModal from './OhsInspectionAvailableSearchModal';

const useStyles = makeStyles()(() => ({
  statusHolder: {
    textAlign: 'center',
  },
  statusIcon: {
    fill: OhsActionColorOrange,
    marginRight: '0.5em',
  },
  buttonHolder: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  recurring: {
    whiteSpace: 'nowrap',
  },
}));

interface Props {
  isOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;

  setStartOneOffInspectionModal: React.Dispatch<React.SetStateAction<boolean>>;
  setStartInspectionModal: React.Dispatch<React.SetStateAction<boolean>>;

  setTargetInspection: React.Dispatch<React.SetStateAction<OhsInspectionRecord>>;
}

function OhsInspectionAvailableModal(props: Props) {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const user = useAppSelector(getOhsUser);
  const [isLoading, inspectionList, currentPage, setCurrentPage] = useOhsAvailableInspectionList();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const { searchInfo } = useAppSelector(getAvailableInspectionSearch);
  const hasGlobalSearch = searchInfo?.searchKey !== '';

  const formattedInspectionList = (inspectionList?.items || []).map((item) => ({
    ...item,
    frequencyType: item.frequencyKey == null ? 'Non-recurring' : 'Recurring',
    inProgress:
      user?.tier.type === item?.tier.type && item.execute?.task?.inExecution ? 'yes' : 'no',
  }));

  const checkInProgress = (row: OhsInspectionRecord) => {
    let isInProgress = false;
    if (user?.tier.type === row?.tier?.type && row.execute?.task?.inExecution) {
      isInProgress = true;
    }
    return isInProgress;
  };

  const displayRecurring = (row: any) => (
    <div className={classes.recurring}>{row.original.frequencyType}</div>
  );
  const displayInProgress = (row: any) =>
    checkInProgress(row.original) ? (
      <div className={classes.statusHolder}>
        <FiberManualRecordIcon fontSize="small" className={classes.statusIcon} />
      </div>
    ) : (
      ''
    );
  const columns = useMemo(
    () => [
      {
        Header: OhsTitleRecordField.name,
        accessor: OhsTitleRecordField.key,
        Cell: ({ row }: any) => row.original.title,
      },
      {
        Header: OhsCategoryRecordField.name,
        accessor: OhsCategoryRecordField.key,
      },
      {
        Header: OhsSubcategoryRecordField.name,
        accessor: OhsSubcategoryRecordField.key,
      },
      {
        Header: OhsFrequencyRecordField.name,
        accessor: OhsFrequencyRecordField.key,
        Cell: ({ row }: any) => displayRecurring(row),
        headerAlign: 'center',
      },
      {
        Header: OhsInProgressRecordField.name,
        accessor: OhsInProgressRecordField.key,
        Cell: ({ row }: any) => displayInProgress(row),
        headerAlign: 'center',
      },
    ],
    []
  );

  const handleGoToInspection = (data: OhsInspectionRecord) => {
    const taskId = data?.execute?.task?._id || '';
    const withCreate = withCreatePermission(user, ModuleConfig.Inspection);
    const withSignOff = user?.configs.inspection?.PERMISSIONS.signoff === true;

    if (data.frequencyKey == null && data.frequencyValue == null && withSignOff && withCreate) {
      props.setStartOneOffInspectionModal(true);
      props.setModalOpen(false);
      props.setTargetInspection(data);
    }

    if (data.frequencyKey != null && data.frequencyValue != null && withSignOff) {
      if (!data?.execute?.task.inExecution) {
        props.setStartInspectionModal(true);
        props.setModalOpen(false);
        props.setTargetInspection(data);
      } else {
        props.setModalOpen(false);
        const url = generatePath(InspectionRouteList.signOff.url, {
          inspectionId: data?._id,
          taskId,
        });
        navigate(url);
        navigate(0);
      }
    }
  };

  // close all modal and reset
  const handleCloseModal = () => {
    props.setModalOpen(false);
    props.setTargetInspection(new OhsInspectionRecord());
    props.setStartOneOffInspectionModal(false);
    props.setStartInspectionModal(false);
  };

  const inProgressCount = useMemo(() => {
    let counter = 0;
    (inspectionList?.items ?? []).forEach((item: OhsInspectionRecord) => {
      if (checkInProgress(item)) counter += 1;
    });
    return counter;
  }, [inspectionList]);

  const searchInputFields: OhsSearchInputFields = {
    searchKey: true,
    modules: false,
    substringSearch: true,
    skipAllocatedRecords: false,
    skipOrgRecords: false,
    task: {
      complete: false,
      includeMasterRecords: false,
    },
    workplaces: false,
  };

  return (
    <>
      <OhsModal
        open={props.isOpen}
        title="Available Template"
        onClose={handleCloseModal}
        maxWidth="lg"
        ContentNode={
          <>
            <Message info>
              <b>
                <FiberManualRecordIcon fontSize="small" className={classes.statusIcon} />(
                {inProgressCount}) in progress.
              </b>
            </Message>
            <div
              style={{
                marginLeft: '95%',
                paddingBottom: '5px',
                marginTop: '-9px',
              }}
            >
              <OhsHeaderButton
                color={hasGlobalSearch ? 'orange' : 'blue'}
                func={() => setIsSearchOpen(true)}
                name=""
                icon="search"
              />
            </div>
            <OhsRegister
              columns={columns}
              data={formattedInspectionList}
              isLoading={isLoading}
              rowOperations={{
                inspectionButton: {
                  func: handleGoToInspection,
                },
              }}
            />
            {!isLoading && (
              <OhsRegisterTableServerPagination
                currentPage={currentPage}
                totalPages={inspectionList?.pagination?.totalPages ?? 0}
                setCurrentPage={setCurrentPage}
              />
            )}
          </>
        }
      />

      <OhsInspectionAvailableSearchModal
        moduleOptionLists={[]}
        searchInputFields={searchInputFields}
        open={isSearchOpen}
        setModalOpen={setIsSearchOpen}
        onModalClose={() => setIsSearchOpen(false)}
      />
    </>
  );
}

export default OhsInspectionAvailableModal;
