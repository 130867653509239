import 'jimp';

const { Jimp } = window as any;

const loadLogo = () => {
  return {
    imgUrl: '/img/sc-logo.png',

    // Note that the size has been pre-calculated.
    // If you ever change the logo, be sure to update
    // the size here.
    width: 375,
    height: 342,
  };
};

const addWatermarkToSignature = async (signatureB64: string) => {
  const logo = loadLogo();

  const imageBuffer = Buffer.from(signatureB64.replace(/^data:image\/png;base64,/, ''), 'base64');

  const jLogo = await Jimp.read(logo.imgUrl);

  const jImg = await Jimp.read(imageBuffer);

  const scalePercentage = Math.min(
    jImg.bitmap.width / logo.width,
    jImg.bitmap.height / logo.height
  );

  jLogo.scale(scalePercentage);

  const [X, Y] = [
    jImg.bitmap.width / 2 - jLogo.bitmap.width / 2,
    jImg.bitmap.height - jLogo.bitmap.height,
  ];

  jImg.composite(jLogo, X, Y, {
    mode: Jimp.BLEND_SOURCE_OVER,
    opacitySource: 0.4,
    opacityDest: 1,
  });

  const url = await jImg.getBase64Async('image/png');

  return url;
};

export default addWatermarkToSignature;
