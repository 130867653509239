import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { RootState } from 'app/store';
import { ApiResListsType, OhsApiRequestName } from 'global-services/api/OhsApiModels';
import { OhsUserState } from 'user/userSlice';
import { setOhsLocalStorage } from 'global-services/OhsDataParse';
import globalModuleSearch from 'search/OhsSearchServices';
import { OhsGlobalSearchPayload } from 'search/OhsSearchModels';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';
import { updateSearchInfo } from 'search/OhsSearchUtils';

import getCorporateRiskLists, {
  getCorporateRiskAllocatedList,
  getCorporateRiskListsWithAllocations,
} from './OhsCorporateRiskServices';
import {
  AllocatedListType,
  CorporateRiskListsRecordState,
  OhsCorporateRiskFilterPayload,
  OhsCRiskRootState,
  OhsCRiskViewPresets,
} from './models/OhsCorporateRiskModels';
import OhsCorporateRiskRecord from './models/OhsCorporateRiskRecord';

const initialState: CorporateRiskListsRecordState = {
  isLoading: false,
  allocatedList: null,
  corporateRiskLists: null,
  currentPage: 1,
  currentViewPreset: OhsCRiskViewPresets.View1CRiskList,
  currentTab: OhsTabKeyTypes.List,
};

export const fetchCorporateRiskAllocatedListAsync = createAsyncThunk<
  AllocatedListType[],
  { _ids: string[] },
  { state: RootState }
>(
  'corporateRisk/fetchCorporateRiskAllocatedList',
  async (corporateRisksIDs, thunkAPI): Promise<any> => {
    try {
      const response = await getCorporateRiskAllocatedList(corporateRisksIDs._ids);
      const resVal = response;
      return resVal;
    } catch (err: any) {
      const error: AxiosError<any> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);
const corporateRiskSearch = async (
  crisk: OhsGlobalSearchPayload,
  count: boolean,
  page: number,
  apiRequestName?: OhsApiRequestName
): Promise<ApiResListsType<OhsCorporateRiskRecord[]> | null> => {
  const requiredSearchFilters =
    apiRequestName === OhsApiRequestName.WorkerGroupList
      ? {
          archived: crisk.filter?.archived,
          page: crisk.filter?.page,
          count: crisk.filter?.count,
          modules: crisk.filter?.modules,
        }
      : crisk.filter;
  const searchInfo = { ...crisk, filter: { ...requiredSearchFilters, count, page } };
  const globalSearchRes: any = await globalModuleSearch(searchInfo, apiRequestName);
  return globalSearchRes.result;
};

const corporateRiskListsRPC = async (
  user: OhsUserState,
  corporateRisk: CorporateRiskListsRecordState,
  filters: OhsCorporateRiskFilterPayload,
  searchPayload: OhsGlobalSearchPayload
): Promise<any> => {
  // const apiRequestName = getApiReqNameByTab(corporateRisk.currentTab);
  let apiRequestName = OhsApiRequestName.List;
  if (filters.viewPreset === 'view_1') {
    apiRequestName = OhsApiRequestName.OrgList;
  } else if (filters.viewPreset === 'view_2') {
    apiRequestName = OhsApiRequestName.OrgWorkplaceList;
  }
  const searchInfo = updateSearchInfo(searchPayload, apiRequestName, user);
  const hasGlobalSearch = searchInfo?.searchKey !== '';
  const userTier = user.user?.tierNum ?? 0;
  if (userTier && userTier === 3 && user.user?.configs.corporateRisk?.PERMISSIONS.view) {
    const response = hasGlobalSearch
      ? await corporateRiskSearch(searchInfo, filters.count, filters.page, apiRequestName)
      : await getCorporateRiskListsWithAllocations(filters);
    return response;
  }
  if (
    userTier &&
    (userTier === 2 || userTier === 4) &&
    user.user?.configs.corporateRisk?.PERMISSIONS.view
  ) {
    const response = hasGlobalSearch
      ? await corporateRiskSearch(searchInfo, filters.count, filters.page, apiRequestName)
      : await getCorporateRiskLists(filters);
    return response;
  }
  if (userTier && userTier === 5 && user.user?.configs.corporateRisk?.PERMISSIONS.view) {
    const response = hasGlobalSearch
      ? await corporateRiskSearch(
          searchInfo,
          filters.count,
          filters.page,
          OhsApiRequestName.WorkerGroupList
        )
      : await getCorporateRiskLists(filters);
    return response;
  }
  return null;
};

export const fetchCorporateRiskListsAsync = createAsyncThunk<
  ApiResListsType<OhsCorporateRiskRecord[]> | null,
  undefined
>('corporateRisk/fetchCorporateRiskList', async (_, thunkAPI) => {
  const {
    user,
    corporateRisk,
    globalfilter: { filterInfo },
    globalSearch: { searchInfo },
  } = thunkAPI.getState() as OhsCRiskRootState;

  const { currentViewPreset, currentPage, corporateRiskLists } = corporateRisk;
  const viewPresetFilter = filterInfo.corporateRiskModule[currentViewPreset];

  const customFilter: OhsCorporateRiskFilterPayload = {
    ...viewPresetFilter,
    page: currentPage > 10 ? 0 : currentPage,
    next: currentPage > 10 ? corporateRiskLists?.pagination.next ?? '' : undefined,
    sort: { ...JSON.parse(String(filterInfo.corporateRiskModule.sort)) },
    count: false,
  };

  try {
    const response = corporateRiskListsRPC(user, corporateRisk, customFilter, searchInfo);
    return await response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchCorporateRiskListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsCorporateRiskRecord[]> | null,
  undefined
>('corporateRisk/fetchCorporateRiskListWithCount', async (_, thunkAPI) => {
  const {
    user,
    corporateRisk,
    globalfilter: { filterInfo },
    globalSearch: { searchInfo },
  } = thunkAPI.getState() as OhsCRiskRootState;
  const { currentViewPreset } = corporateRisk;
  const viewPresetFilter = filterInfo.corporateRiskModule[currentViewPreset];

  const customFilter: any = {
    ...viewPresetFilter,
    page: 1,
    sort: { order: 1, key: '_id' },
    count: true,
  };

  try {
    const response = corporateRiskListsRPC(user, corporateRisk, customFilter, searchInfo);
    return await response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const corporateRiskSlice = createSlice({
  name: 'corporateRisk',
  initialState,
  reducers: {
    setCurrentRegisterPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setViewPreset: (state, action: PayloadAction<OhsCRiskViewPresets>) => {
      state.currentViewPreset = action.payload;
    },
    setCorporateRiskIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setCorporateRiskCurrentTab: (state, action: PayloadAction<OhsTabKeyTypes>) => {
      state.currentTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCorporateRiskListsAsync.pending, (state) => {
        state.isLoading = true;
        state.corporateRiskLists = {
          items: [],
          pagination: state.corporateRiskLists?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchCorporateRiskListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.corporateRiskLists = action.payload;
          let setItemsLastId = '';
          const corporateRiskListsItems = [...state.corporateRiskLists.items];
          if (corporateRiskListsItems.length > 0)
            setItemsLastId = corporateRiskListsItems.pop()._id;
          state.corporateRiskLists.pagination.next = setItemsLastId;
          state.corporateRiskLists.pagination.totalPages = 1;
          state.isLoading = false;
        }
      })
      .addCase(fetchCorporateRiskListsAsync.rejected, (state) => {
        state.corporateRiskLists = null;
      })
      .addCase(fetchCorporateRiskAllocatedListAsync.pending, (state) => {
        state.allocatedList = [];
      })
      .addCase(fetchCorporateRiskAllocatedListAsync.fulfilled, (state, action) => {
        state.allocatedList = action.payload ?? [];
        state.isLoading = false;
      })
      .addCase(fetchCorporateRiskListsCountAsync.fulfilled, (state, action) => {
        if (state.corporateRiskLists && action.payload) {
          setOhsLocalStorage('corporateRiskListtotalPages', action.payload.pagination.totalPages);
          state.corporateRiskLists.pagination.totalPages =
            action.payload.pagination.totalPages ?? 0;
        }
      });
  },
});

const corporateRiskState = (state: RootState) => state.corporateRisk;
export const getOhsCorporateRiskModuleState = createSelector(
  [corporateRiskState],
  (corporateRisk) => corporateRisk
);

export const {
  setCurrentRegisterPage,
  setViewPreset,
  setCorporateRiskIsLoading,
  setCorporateRiskCurrentTab,
} = corporateRiskSlice.actions;
export const corporateRiskModuleReducer = corporateRiskSlice.reducer;
