import React from 'react';

import OhsMfaVerifySteps from './verifystepcontent/OhsMfaVerifySteps';
import useOhsMfaEnrollStepsHook from '../hooks/OhsMfaStepsHook';

interface Props {
  onSuccess: (methodId?: string) => void;
}
function OhsMfaVerifyFlow(props: Props) {
  const { activeStep, setActiveStep } = useOhsMfaEnrollStepsHook();

  React.useEffect(() => {
    setActiveStep(0);
  }, []);

  return (
    <OhsMfaVerifySteps
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      onSuccess={props.onSuccess}
    />
  );
}

export default OhsMfaVerifyFlow;
