import OhsRecordFieldType from 'global-services/constants/record-field/OhsRecordFieldType';

export const OhsChemicalNameRecordField = new OhsRecordFieldType('name', 'Chemical Name');
export const OhsChemicalShippingNameRecordField = new OhsRecordFieldType(
  'shippingName',
  'Proper Shipping Name'
);
export const OhsChemicalSupplierNameRecordField = new OhsRecordFieldType(
  'supplierName',
  'Supplier Name'
);

export const OhsChemicalSupplierEmergencyContactNumberRecordField = new OhsRecordFieldType(
  'emergencyContactPhone',
  'Supplier Emergency Contact Number'
);

export const OhsChemicalPurposeRecordField = new OhsRecordFieldType(
  'purpose',
  'Purpose of Chemical'
);

export const OhsChemicalLocationField = new OhsRecordFieldType('locations', 'Location(s)');

export const OhsChemicalPresetLocationField = new OhsRecordFieldType(
  'presetLocations',
  'Pre-set Location(s)'
);

export const OhsChemicalLocationNameField = new OhsRecordFieldType('main', 'Pre-set Location(s)');

export const OhsChemicalUnNumberRecordField = new OhsRecordFieldType(
  'transport.unNumber',
  'UN Number'
);

export const OhsChemicalTransportClassRecordField = new OhsRecordFieldType(
  'transport.class',
  'Class'
);
export const OhsChemicalRisksRecordField = new OhsRecordFieldType(
  'transport.subRisks',
  'Sub Risk(s)'
);
export const OhsChemicalRisksOtherRecordField = new OhsRecordFieldType(
  'transport.subRisksOther',
  'Sub Risk(s) Other'
);
export const OhsChemicalPackingGroupRecordField = new OhsRecordFieldType(
  'transport.packingGroup',
  'Packing Group'
);

export const OhsChemicalSDSAttachmentsRecordField = new OhsRecordFieldType(
  'sds.attachments',
  'Upload SDS'
);
export const OhsChemicalRiskClassificationGHSRecordField = new OhsRecordFieldType(
  'risk.isClassificationGHS',
  'SDS aligned with GHS Classification'
);

export const OhsChemicalRiskDangerousGoodRecordField = new OhsRecordFieldType(
  'risk.dangerousGood',
  'Is the chemical a Dangerous Good?'
);

export const OhsChemicalRiskHazardousSubstanceRecordField = new OhsRecordFieldType(
  'risk.hazardousSubstance',
  'Is the chemical Hazardous?'
);

export const OhsChemicalRiskAssessmentRecordField = new OhsRecordFieldType(
  'risk.assessmentCompleted',
  'Has a risk assessment been completed?'
);

export const OhsChemicalRiskAttachmentsRecordField = new OhsRecordFieldType(
  'risk.attachments',
  'Upload Risk Assessment'
);

// supplierName

export const OhsChemicalMaxRecordField = new OhsRecordFieldType('qMax', 'Max (Kg/L)');
export const OhsChemicalAvgRecordField = new OhsRecordFieldType('qAvg', 'Avg (Kg/L)');

export const OhsChemicalClassRecordField = new OhsRecordFieldType('class', 'Class');
export const OhsChemicalSDSIssueDateRecordField = new OhsRecordFieldType(
  'sds.dateIssued',
  'SDS Issue Date'
);

export const OhsChemicalAllocationRecordField = new OhsRecordFieldType(
  'allocationActive',
  'Allocation'
);

export const OhsChemicalHazardRecordField = new OhsRecordFieldType(
  'risk.classificationGHS',
  'Hazard Statement'
);

export const OhsChemicalNoLongerUse = new OhsRecordFieldType(
  'chemicalNoLongerUse',
  'Is the Chemical no longer in use?'
);
export const OhsChemicalSdsUpdateAvailable = new OhsRecordFieldType(
  'sdsUpdateAvailable',
  'Is Updated SDS available?'
);
export const OhsCheimcalSignOffNotesRecordField = new OhsRecordFieldType(
  'completionNotes',
  'Notes'
);
export const OhsChemicalReviewDateRecordField = new OhsRecordFieldType(
  'dateToReview',
  'Set Review Date'
);
