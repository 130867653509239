import React from 'react';

import _ from 'lodash';

import { OhsCurrentUser } from 'user/OhsUserModels';
import { fetchCurrentUserSettings } from 'user/OhsUserServices';
import { AdminSwitchOption, OhsAdmin2Config } from 'user/configurations/admin2/OhsAdmin2Config';
import { fetchUserMfaList } from 'user/user-settings/mfa/enrollflows/OhsMfaServices';
import { OhsMfaActiveMethodListTypes } from 'user/user-settings/mfa/OhsMfaTypes';
import { ModuleType } from 'global-services/constants/OhsObject';

export default function useEnforceMfaHook() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [userTier, setUserTier] = React.useState<any>();
  const fetchUserInfo = async (): Promise<AdminSwitchOption> => {
    // Check MFA status: "optional" | "disabled" | "required"
    const currentUserData = (await fetchCurrentUserSettings()) as OhsCurrentUser;
    if (currentUserData) setUserTier(currentUserData?.tier);
    const configAdmin2 = _.find(currentUserData.configuration, { type: ModuleType.Admin2 });
    const mfaConfig: AdminSwitchOption = new OhsAdmin2Config(configAdmin2).MFA;
    return mfaConfig;
  };

  const getUserMfaList = async (): Promise<OhsMfaActiveMethodListTypes> => {
    const userMfaList: OhsMfaActiveMethodListTypes = await fetchUserMfaList();
    return userMfaList;
  };

  const checkMfaRequired = async () => {
    setIsLoading(true);
    const mfaStatus: AdminSwitchOption = await fetchUserInfo();
    const mfaActiveList: OhsMfaActiveMethodListTypes = await getUserMfaList();

    const isMfaRequired = mfaStatus === 'required' && mfaActiveList?.authenticators?.length === 0;
    setIsLoading(false);
    return isMfaRequired;
  };

  return { isLoading, userTier, checkMfaRequired, setIsLoading } as const;
}
