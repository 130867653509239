import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';

export enum ResetPwStageTypes {
  Identify = 'identify',
  Verify = 'verify',
  Reset = 'reset',
}
export interface ResetPasswordTypes {
  stage: ResetPwStageTypes;
  token?: string;
  email?: string;
  password?: string;
}
const resetUserPasswordSend = (payload: ResetPasswordTypes) => {
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.User,
    OhsApiRequestName.ResetPassword,
    payload
  );
};

export default resetUserPasswordSend;
