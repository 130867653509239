import React from 'react';

import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    padding: '12px 20px',
    backgroundColor: '#f8ffff',
    color: '#276f86',
    fontSize: '14px',
    fontWeight: 'bold',
    border: '1px solid #a9d5de',
    borderRadius: '5px',
    margin: '-10px 0',
  },
}));

interface Props {
  children: React.ReactNode | string;
}
function OhsNoResultsAlert(props: Props) {
  const { classes } = useStyles();
  const { children } = props;
  return <Box className={classes.root}>{children}</Box>;
}

export default OhsNoResultsAlert;
