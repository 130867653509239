import React from 'react';

import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/material';
import { Button } from 'semantic-ui-react';
import HomeIcon from '@mui/icons-material/Home';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';
import { getOhsDashboard, toggleLeftSideDrawer } from 'dashboard/OhsDashboardSlice';
import { getModuleName } from 'user/OhsUserServices';
import { ModuleType } from 'global-services/constants/OhsObject';

import { ListItemLink, OhsLeftSideDrawerModuleList } from './OhsLeftSideDrawerModuleList';
import { getHomeUrl } from '../../login/OhsLoginServices';
import { openResourceCentre } from '../topbar/OhsTopBar';
import OhsLogoImg from '../OhsLogoImg';
import OhsLeftMenuWithPopoverMenu from './OhsLeftMenuWithPopoverMenu';
import OhsCustomReportsMenu from './OhsCustomReportsMenu';
import { getBusinessIntelligenceRouteList, getReportsRouteList } from './OhsReportsRouteLists';

export const getAdminHomeUrl = (tierNum: number) => {
  if (tierNum > 2) {
    return '/admin2/main';
  }
  return '/admin2/home';
};

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  logo: {
    width: '64px',
  },
}));

export default function OhsLeftSideDrawer() {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { leftSideDrawerToggleOpen } = useAppSelector(getOhsDashboard);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(toggleLeftSideDrawer(open));
  };

  const user = useAppSelector(getOhsUser);
  const homeUrl = getHomeUrl(user?.tier);
  return (
    <Drawer anchor="left" open={leftSideDrawerToggleOpen} onClose={toggleDrawer(false)}>
      <div className={clsx(classes.list)} role="presentation">
        <List>
          <Box m={1}>
            <OhsLogoImg className={classes.logo} />
          </Box>
          <Divider />
          <Box m={2}>
            <p>
              <b>{user?.name}</b>
            </p>
            <p style={{ color: 'blue' }}>
              {user?.tierNum === 2 &&
              user?.isMorph === true &&
              user?.configs.admin2?.LOWER_TIER_ACCESS &&
              user?.configs.admin2?.LOWER_TIER_ACCESS[0]
                ? user?.configs.admin2?.LOWER_TIER_ACCESS[0]?.name
                : user?.tier?.name}
            </p>
          </Box>
          <Divider />
          <ListItemLink button key="homeLink" href={homeUrl}>
            <ListItemText primary="Home" /> <HomeIcon style={{ float: 'right' }} fontSize="small" />
          </ListItemLink>

          <Divider />

          <OhsLeftSideDrawerModuleList />
          {user?.isMorph !== true &&
            user?.email !== 'champ@safetychampion.com.au' &&
            user?.email !== 'safety@safetychampion.com.au' && (
              <ListItemLink button key="user-settings" href="/admin2/user-settings">
                <ListItemText primary="User Settings" />{' '}
              </ListItemLink>
            )}

          <Divider />
          {user?.tierNum &&
            (user?.tierNum > 2 || (user?.tierNum <= 2 && user?.isMorph !== true)) &&
            user?.configs.admin2 != null && (
              <div>
                <Box m={2}>
                  <p>
                    <b>Administration</b>
                  </p>
                </Box>
                <ListItemLink
                  button
                  key="Administration"
                  href={getAdminHomeUrl(user?.tierNum)}
                  dense
                >
                  <ListItemText primary={user?.configs.admin2.TITLE} />
                </ListItemLink>

                {user?.tierNum > 2 && (
                  <OhsLeftMenuWithPopoverMenu
                    linkTitle={getModuleName(user?.configs, ModuleType.BusinessIntelligence)}
                    popupoverMenu={getBusinessIntelligenceRouteList(user)}
                    isLoading={false}
                  />
                )}

                {user?.tierNum > 2 && (
                  <OhsLeftMenuWithPopoverMenu
                    linkTitle="Reporting"
                    popupoverMenu={getReportsRouteList(user)}
                    isLoading={false}
                  />
                )}

                {user?.tierNum > 2 && <OhsCustomReportsMenu />}

                {user?.tierNum <= 2 &&
                  user?.configs.bi &&
                  user?.configs.bi.TITLE &&
                  user?.isMorph !== true && (
                    <ListItemLink button href="/reporting/orgAdminReports" dense>
                      <ListItemText primary={user?.configs.bi.TITLE} />
                    </ListItemLink>
                  )}
                {(user?.tierNum === 3 || user?.tierNum === 4) &&
                  user?.configs.export?.PERMISSIONS.view === true && (
                    <ListItemLink button href="/export-centre/home" dense>
                      <ListItemText primary={user?.configs.export?.TITLE} />
                    </ListItemLink>
                  )}

                <Divider />
              </div>
            )}

          {user?.isMorph !== true && (
            <div>
              <Box m={2}>
                <Button.Group>
                  <Button color="red" onClick={openResourceCentre}>
                    Support
                  </Button>
                  <Button.Or />
                  <Button color="blue" href="/feedback">
                    Feedback
                  </Button>
                </Button.Group>
              </Box>
              <Divider />
            </div>
          )}

          <Box m={2}>
            <p>Version: {(window as any).SAFETY_CHAMPION_VERSION}</p>
          </Box>
        </List>
      </div>
    </Drawer>
  );
}
