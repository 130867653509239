import React from 'react';

import 'semantic-ui-css/semantic.min.css';
import { Box, Divider, List, ListItem, Menu } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import { useAppSelector } from 'app/hooks';
import { multiUserLogin } from 'login/OhsLoginServices';
import { OhsMultiUserLoginOption, refreshOptionsList } from 'login/multi/OhsMultiUserLoginOption';
import { getOhsUser } from 'user/userSlice';
import useEnforceMfaHook from 'login/OhsEnforceMfaHook';
import {
  checkContainsErrorCode,
  OhsApiErrorCodes,
  OhsApiRequestName,
  RequestType,
} from 'global-services/api/OhsApiModels';
import OhsMfaVerifyModal from 'user/user-settings/mfa/verifyflows/OhsMfaVerifyModal';
import OhsMfaEnrollModal from 'user/user-settings/mfa/enrollflows/OhsMfaEnrollModal';
import OhsComponentLoading from 'global-services/utils/OhsLoading';
import { ModuleType } from 'global-services/constants/OhsObject';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { unMorphUser } from 'user/morph/MorphServices';

import { getHomeUrl, OhsLogout } from '../../../login/OhsLoginServices';

export default function OhsSwitchWorkplaceMenu(props: {
  anchorEl: Element;
  setAnchorEl: React.Dispatch<React.SetStateAction<Element | null>>;
}) {
  const [userId, setUserId] = React.useState('');
  const [userOptions, setUserOptions]: [OhsMultiUserLoginOption[] | undefined, any] =
    React.useState();
  const [verifyMfalModalOpen, setVerifyMfalModalOpen] = React.useState(false);
  const [enrollModalOpen, setEnrollModalOpen] = React.useState(false);
  const { isLoading, userTier, checkMfaRequired, setIsLoading } = useEnforceMfaHook();
  const menuId = 'primary-search-account-menu';
  const isMenuOpen = Boolean(props.anchorEl);
  const user = useAppSelector(getOhsUser);
  const navigate = useNavigate();

  let isOptionLoading = false;
  const handleOptionLoad = async () => {
    if (!isOptionLoading && !userOptions) {
      isOptionLoading = true;

      setIsLoading(true);

      const data: {
        options: OhsMultiUserLoginOption[];
      } | null = await OhsApiRequest(
        RequestType.Admin,
        ModuleType.User,
        OhsApiRequestName.MultiUserList,
        {},
        {}
      );

      if (data) {
        setUserOptions(data.options);
        refreshOptionsList(data.options);
        setIsLoading(false);
      }
    }
  };

  const handleDialogClose = async (id: string) => {
    if (user.isMorph) await unMorphUser();
    setIsLoading(true);
    setUserId(id);
    const response: any = await multiUserLogin(id);

    // check if MFA is required and NO active MFA method
    const isMfaEnrollRequired = await checkMfaRequired();

    if (isMfaEnrollRequired) {
      setEnrollModalOpen(true); // enroll if MFA required and NO active MFA method
    } else {
      const hasErrorCode = checkContainsErrorCode(response.error, OhsApiErrorCodes.MfaRequire);
      // RE-OPEN MFA verify modal if "The provided token has an ongoing active session."
      const hasActiveSession = checkContainsErrorCode(
        response.error,
        OhsApiErrorCodes.UserInvalidSession
      );
      if (hasErrorCode || hasActiveSession) {
        setVerifyMfalModalOpen(true);
      } else {
        const homeURL = getHomeUrl(response.tier);
        navigate(homeURL);
        navigate(0);
      }
    }
  };

  const handleMfaEnrollClose = (currentStep: number) => {
    // Do not redirect to dashboard before step 4 (last step)
    if (currentStep === 4) {
      window.open(getHomeUrl(userTier), '_self');
    }
  };

  const handleOtpVerifiedSuccess = async () => {
    const response: any = await multiUserLogin(userId);
    if (response) {
      window.open(getHomeUrl(response.tier), '_self');
    }
  };

  return (
    <>
      <Menu
        anchorEl={props.anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        id={menuId}
        keepMounted
        onFocus={handleOptionLoad}
        open={isMenuOpen}
        onClose={() => {
          isOptionLoading = false;
          props.setAnchorEl(null);
          setUserOptions(undefined);
        }}
      >
        <Box width={400}>
          <Box px={6}>
            <Box py={4}>
              <Typography variant="h4" fontWeight="bold">
                Log out or switch
              </Typography>
              <Typography style={{ color: 'black' }}>
                Pick a user to switch to, or select Log Out below
              </Typography>
            </Box>
          </Box>
          {isLoading && <OhsComponentLoading />}
          <List>
            {!isLoading &&
              userOptions
                ?.sort((x, y) => {
                  if (x.blocked === y.blocked) {
                    return 0;
                  }
                  return x.blocked ? 1 : -1;
                })
                .sort((x, y) => {
                  if (x.inProgress === y.inProgress) {
                    return 0;
                  }
                  return x.inProgress ? 1 : -1;
                })
                .map((item, index) => (
                  <div key={index}>
                    <Divider />
                    <ListItem
                      button
                      onClick={() => handleDialogClose(item.user._id)}
                      disabled={item.blocked === true || item.inProgress === true}
                      style={
                        item.user._id === user?._id
                          ? { backgroundColor: 'rgba(0, 0, 0, 0.05)' }
                          : {}
                      }
                    >
                      <Box px={4} py={2}>
                        {user?._id === item.user._id && (
                          <Box pb={1}>
                            <Typography color="textSecondary">
                              YOU&apos;RE CURRENTLY LOGGED IN AS:
                            </Typography>
                          </Box>
                        )}
                        {(() => {
                          if (item.blocked) {
                            return (
                              <Typography style={{ fontWeight: 'bold', color: 'red' }}>
                                Limited User Access
                              </Typography>
                            );
                          }
                          return null;
                        })()}
                        {(() => {
                          if (item.inProgress) {
                            return (
                              <Typography style={{ fontWeight: 'bold', color: 'red' }}>
                                In Progress
                              </Typography>
                            );
                          }
                          return null;
                        })()}
                        {(() => {
                          if (item.user.tier.type === 'core.tier.T2') {
                            return (
                              <Typography style={{ fontWeight: 'bold' }}>
                                {item.orgAdminTier.name} (Org Admin)
                              </Typography>
                            );
                          }

                          if (item.user.tier.type === 'core.tier.T3') {
                            return (
                              <Typography style={{ fontWeight: 'bold' }}>
                                {item.orgTier.name} (Organisation)
                              </Typography>
                            );
                          }

                          if (item.user.tier.type === 'core.tier.T4') {
                            return (
                              <Typography style={{ fontWeight: 'bold' }}>
                                {item.workplaceTier?.name} (Workplace)
                              </Typography>
                            );
                          }

                          if (item.user.tier.type === 'core.tier.T5') {
                            return (
                              <Typography style={{ fontWeight: 'bold' }}>
                                {item.workerGroupTier?.name} (Worker Group)
                              </Typography>
                            );
                          }
                          return null;
                        })()}
                        <Typography style={{ fontWeight: 'bold' }}>
                          Profile: {item.user.profile.title}
                        </Typography>
                        {(() => {
                          if (item.user.tier.type === 'core.tier.T4') {
                            return (
                              <Typography color="textSecondary">
                                {`${item.orgTier.name}`}
                              </Typography>
                            );
                          }

                          if (item.user.tier.type === 'core.tier.T5') {
                            return (
                              <Typography color="textSecondary">
                                {`${item?.workplaceTier?.name} < ${item.orgTier.name}`}
                              </Typography>
                            );
                          }
                          return null;
                        })()}
                      </Box>
                    </ListItem>
                  </div>
                ))}
          </List>
          <Divider />
          <ListItem
            button
            onClick={() => OhsLogout(user?.isSso === true)}
            style={{ textAlign: 'center', justifyContent: 'center' }}
          >
            <Box display="flex" px={4} py={2}>
              <Typography
                variant="h4"
                color="textSecondary"
                style={{
                  textDecoration: 'underline',
                }}
              >
                LOG OUT
              </Typography>
            </Box>
          </ListItem>
        </Box>
      </Menu>
      <OhsMfaVerifyModal
        title="Multi-factor authentication (MFA)"
        open={verifyMfalModalOpen}
        setModalOpen={setVerifyMfalModalOpen}
        onSuccess={handleOtpVerifiedSuccess}
      />
      <OhsMfaEnrollModal
        title="Multi-factor authentication (MFA)"
        open={enrollModalOpen}
        setModalOpen={setEnrollModalOpen}
        handleOnClose={handleMfaEnrollClose}
      />
    </>
  );
}
