import React, { useEffect } from 'react';

import { Row } from 'react-table';
import { Button, Icon } from 'semantic-ui-react';
import { Box } from '@mui/material';

import { OhsAllocationTierRecord } from 'global-components/allocation/OhsAllocatableModels';
import { registerButtonStyles } from 'global-components/register/OhsModuleHeader';

export interface AllocationButtonsProps {
  row: Row<any>;
  disabled: boolean;
  allocationFunc: (allocationItem: OhsAllocationTierRecord) => Promise<any>;
  deAllocationFunc: (deallocationItem: OhsAllocationTierRecord) => Promise<any>;
}
function OhsBulkAllocationButtons({
  row,
  disabled,
  allocationFunc,
  deAllocationFunc,
}: AllocationButtonsProps) {
  const { classes } = registerButtonStyles();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleAllocation = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await allocationFunc(row.original);
  };

  const handleDeAllocate = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await deAllocationFunc(row.original);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [row]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'end',
        marginLeft: 'auto',
      }}
    >
      {row.original && row.original.allocated !== true && (
        <Button
          onClick={handleAllocation}
          className={classes.button}
          size="small"
          color="green"
          disabled={isLoading || row.original.isLoading || disabled}
          loading={isLoading || row.original.isLoading}
        >
          <Icon className={classes.buttonIcons} name="share" />
          <span className={classes.hideForSmall}>Allocate</span>
        </Button>
      )}
      {row.original && row.original.allocated === true && (
        <Button
          onClick={handleDeAllocate}
          className={classes.button}
          size="small"
          color="red"
          disabled={isLoading || row.original.isLoading || disabled}
          loading={isLoading || row.original.isLoading}
        >
          <Icon className={classes.buttonIcons} name="reply" />
          <span className={classes.hideForSmall}>Deallocate</span>
        </Button>
      )}
    </Box>
  );
}
export default OhsBulkAllocationButtons;
