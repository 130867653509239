import { ApiResListsType } from 'global-services/api/OhsApiModels';
import OhsContractorRecord from 'contractor/models/OhsContractorRecord';

const parseListAllocations = (
  contractorLists: ApiResListsType<any[]> | null,
  allocationLists: OhsContractorRecord[] | null
): ApiResListsType<any[]> | null => {
  const contractorList = contractorLists?.items ?? [];

  if (contractorList.length > 0) {
    const contrctorWithAllocation = contractorList.map((item: OhsContractorRecord) => {
      const contractorRecord: OhsContractorRecord = {
        ...item,
        subRows: [],
      };

      allocationLists?.map((allocItem: OhsContractorRecord) => {
        const { allocationOf } = allocItem;
        if (item._id === allocItem._id) {
          contractorRecord.subRows.push(allocItem);
        }
        if (allocationOf && allocationOf._id === item._id) {
          contractorRecord.subRows.push(allocItem);
        }
        return null;
      });

      return contractorRecord;
    }) as any[];

    const updateContractorListwithAllocation = {
      ...contractorLists,
      items: contrctorWithAllocation,
    } as unknown as ApiResListsType<any[]>;

    return updateContractorListwithAllocation;
  }
  return null;
};

export default parseListAllocations;
