import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';
import OhsHumanResourceListsRecord, {
  OhsHumanResourceFilterPayload,
} from 'human-resource/models/OhsHumanResourceRecord';

import getHumanResourceLists from './OhsHumanResourceServices';
import { OhsUserState } from '../user/userSlice';
import globalModuleSearch from '../search/OhsSearchServices';
import { ohsHasHrPrivacyAccess, ohsParseHrPrivacy } from './models/OhsHumanResourcePrivacyFuncs';
import { OhsUser } from '../user/OhsUser';

export interface HumanResourceListsRecordState {
  isLoading: boolean;
  humanResourceLists: ApiResListsType<OhsHumanResourceListsRecord[]> | null;
  currentPage: number;
}

const initialState: HumanResourceListsRecordState = {
  isLoading: false,
  humanResourceLists: null,
  currentPage: 1,
};

export const getViewPreset = (tierNum: number) => {
  switch (tierNum) {
    case 3:
      return 'view_1';
    case 4:
      return 'view_2';
    default:
      return undefined;
  }
};

const hrSearch = async (
  searchDetails: any
): Promise<ApiResListsType<OhsHumanResourceListsRecord[]> | null> => {
  const globalSearchRes: any = await globalModuleSearch(searchDetails);
  return globalSearchRes.result;
};

const humanResourceFilterOptions = (state: RootState): Partial<OhsHumanResourceFilterPayload> => {
  const { globalfilter, user, humanResources } = state;
  const filter = globalfilter.filterInfo;
  const userTier = user.user?.tierNum ?? 0;
  return {
    viewPreset: getViewPreset(userTier),
    next:
      humanResources.currentPage > 10
        ? humanResources.humanResourceLists?.pagination.next ?? ''
        : '',
    workplaces: filter.humanResourceModule.workplaces ?? [],
    archived: filter.humanResourceModule.archived,
    skipOrgRecords: filter.humanResourceModule.skipOrgRecords,
  };
};

const humanResourcesListsRPC = async (
  user: OhsUserState,
  setHRFilters: OhsHumanResourceFilterPayload
): Promise<any> => {
  if (user.user?.configs.hr?.PERMISSIONS.view) {
    const response = await getHumanResourceLists(setHRFilters);
    return response;
  }
  return null;
};
export const fetchHumanResourceListsAsync = createAsyncThunk<
  ApiResListsType<OhsHumanResourceListsRecord[]> | null,
  undefined,
  { state: RootState }
>('humanResources/fetchHumanResourceList', async (_, thunkAPI) => {
  const { user } = thunkAPI.getState();
  const state = thunkAPI.getState();
  const filter = thunkAPI.getState().globalfilter.filterInfo;
  const searchFilter = thunkAPI.getState().globalSearch.searchInfo;
  const hasGlobalSearch = state.globalSearch.searchInfo.searchKey !== '';

  const setHumanResourceFilters: OhsHumanResourceFilterPayload = {
    ...humanResourceFilterOptions(state),
    page: state.humanResources.currentPage > 10 ? 0 : state.humanResources.currentPage,
    sort: { ...JSON.parse(String(filter.humanResourceModule.sort)) },
    count: false,
  };

  try {
    const response = hasGlobalSearch
      ? await hrSearch({
          ...searchFilter,
        })
      : await humanResourcesListsRPC(user, {
          ...setHumanResourceFilters,
        });
    if (!ohsHasHrPrivacyAccess(user.user as OhsUser)) {
      response.items?.forEach((r: any) => {
        ohsParseHrPrivacy(user.user as OhsUser, r);
      });
    }
    return response as ApiResListsType<OhsHumanResourceListsRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchHumanResourceListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsHumanResourceListsRecord[]> | null,
  undefined,
  { state: RootState }
>('humanResources/fetchHumanResourceCountList', async (_, thunkAPI) => {
  const { user } = thunkAPI.getState();
  const state = thunkAPI.getState();
  const hasGlobalSearch = state.globalSearch.searchInfo.searchKey !== '';
  const searchFilter = thunkAPI.getState().globalSearch.searchInfo;
  const hrFiltersWithCount: OhsHumanResourceFilterPayload = {
    ...humanResourceFilterOptions(state),
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  try {
    const response = hasGlobalSearch
      ? await hrSearch({
          ...searchFilter,
        })
      : await humanResourcesListsRPC(user, {
          ...hrFiltersWithCount,
        });
    return response as ApiResListsType<OhsHumanResourceListsRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const humanResourceSlice = createSlice({
  name: 'humanResources',
  initialState,
  reducers: {
    setHumanResourceCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setHrIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHumanResourceListsAsync.pending, (state) => {
        state.isLoading = true;
        state.humanResourceLists = {
          items: [],
          pagination: state.humanResourceLists?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchHumanResourceListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.humanResourceLists = action.payload;
          if (state?.humanResourceLists?.pagination?.totalPages) {
            // store total pages to localstorage
            setOhsLocalStorage(
              'humanResourceListtotalPages',
              state.humanResourceLists.pagination.totalPages
            );
          }
          // get last _id and set it to pagination next
          const setItemsLastId = [...state.humanResourceLists.items].pop()?._id ?? '';
          state.humanResourceLists.pagination.next = setItemsLastId;
          state.humanResourceLists.pagination.totalPages =
            getOhsLocalStorage('humanResourceListtotalPages') ?? 0;
          state.isLoading = false;
        }
      })
      .addCase(fetchHumanResourceListsAsync.rejected, (state) => {
        state.humanResourceLists = null;
      })
      .addCase(fetchHumanResourceListsCountAsync.fulfilled, (state, action) => {
        if (state.humanResourceLists && action.payload && action.payload.pagination) {
          setOhsLocalStorage(
            'humanResourceListtotalPages',
            action?.payload?.pagination?.totalPages
          );
          state.humanResourceLists.pagination.totalPages =
            action?.payload?.pagination?.totalPages ?? 0;
        }
      });
  },
});

const humanResourcesState = (state: RootState) => state.humanResources;
// Memoized Selectors
export const getOhsHRModuleState = createSelector(
  [humanResourcesState],
  (humanResources) => humanResources
);
export const { setHumanResourceCurrentPage, setHrIsLoading } = humanResourceSlice.actions;
export const humanResourceModuleReducer = humanResourceSlice.reducer;
