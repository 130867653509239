import React from 'react';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';

const useStyles = makeStyles()((theme: Theme) => ({
  buletIcon: {
    display: 'inline-flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      justifyContent: 'left',
      width: 'auto',
      alignItems: 'center',
    },
  },
}));

interface Props {
  value?: boolean;
  color?: string;
  header?: boolean;
  label?: string;
}
function OhsBooleanRowDisplay(props: Props) {
  const { classes } = useStyles();
  const { value, color } = props;

  if (props.header) return <Box className={classes.buletIcon}>{props.label}</Box>;

  return (
    <Box className={classes.buletIcon} data-testid="trueIcon">
      {value && <FiberManualRecordIcon fontSize="small" sx={{ color }} />}
    </Box>
  );
}

OhsBooleanRowDisplay.defaultProps = {
  value: false,
  color: 'orange',
  header: false,
  label: '',
};
export default OhsBooleanRowDisplay;
