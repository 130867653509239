import React from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { useAxiosSpinner } from './useAxiosSpinner';

function OhsBackdrop() {
  const [isOpen] = useAxiosSpinner();

  return (
    <div>
      <Backdrop sx={{ color: '#fff', zIndex: 9999 }} open={isOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default OhsBackdrop;
