import { ApiResListsType } from 'global-services/api/OhsApiModels';
import OhsDocumentsRecord from 'documents/models/OhsDocumentsRecord';

const parseListAllocations = (
  documents: ApiResListsType<any[]> | null,
  allocationLists: OhsDocumentsRecord[] | null
): ApiResListsType<any[]> | null => {
  const spList = documents?.items ?? [];

  if (spList.length > 0) {
    const documentsWithAllocation = spList.map((item: OhsDocumentsRecord) => {
      const documentsRecord: OhsDocumentsRecord = {
        ...item,
        subRows: [],
      };

      allocationLists?.map((allocItem: OhsDocumentsRecord) => {
        // check if the Safety plan is allocated to self
        const { allocationOf } = allocItem;
        if (item._id === allocItem._id) {
          documentsRecord.subRows.push(allocItem);
        }
        // check if the Safety plan is allocated to a workplace
        if (allocationOf && allocationOf._id === item._id) {
          documentsRecord.subRows.push(allocItem);
        }
        return null;
      });

      return documentsRecord;
    }) as any[];

    const updateDocumentswithAllocation = {
      ...documents,
      items: documentsWithAllocation,
    } as unknown as ApiResListsType<any[]>;

    return updateDocumentswithAllocation;
  }
  return null;
};

export default parseListAllocations;
