import React from 'react';

import { TierType } from 'global-services/constants/OhsObject';

export enum OhsTabKeyTypes {
  List = 'li',
  Organisation = 'org',
  Workplace = 'wp',
  Active = 'at',
  History = 'ht',
  Flagged = 'fl',
  Assigned = 'as',
  Details = 'dt',
  Training = 'tr',
  Investigation = 'iv',
  Resources = 're',
  Contacts = 'ct',
  Form = 'fm',
  Scheduled = 'sc',
  Evidence = 'ev',
  Links = 'lk',
  Communication = 'cm',
  FileBox = 'fb',
  Actions = 'ac',
  Template = 'tp',
  Documents = 'docs',
  Versions = 'ver',
  Board = 'brd',
  UserExports = 'uexp',
  ExportTemplates = 'expt',
  Activities = 'act',
  IOS = 'ios',
  Android = 'android',
  MFAReset = 'mfareset',
}

export interface OhsTabItem {
  tabName: string;
  tabKey: OhsTabKeyTypes;
  restrictaccess?: TierType[] | boolean;
  content: React.ReactNode;
}

export interface OhsTabRouteItem {
  tabName: string;
  path: string;
  restrictaccess?: TierType[] | boolean;
  element: React.ReactNode;
}

export enum OhsRegisterTabItemTypes {
  WorkplaceRegister = 'workplace',
  OrgRegister = 'organisation',
  MainRegister = 'mainlist',
}
