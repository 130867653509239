import { ApiResListsType } from 'global-services/api/OhsApiModels';
import OhsSafetyPlanRecord from 'safety-plan/OhsSafetyPlanRecord';

const parseListAllocations = (
  safetyPlanLists: ApiResListsType<any[]> | null,
  allocationLists: OhsSafetyPlanRecord[] | null
): ApiResListsType<any[]> | null => {
  const spList = safetyPlanLists?.items ?? [];

  if (spList.length > 0) {
    const safetyPlanWithAllocation = spList.map((item: OhsSafetyPlanRecord) => {
      const safetyPlanRecord: OhsSafetyPlanRecord = {
        ...item,
        subRows: [],
      };

      allocationLists?.map((allocItem: OhsSafetyPlanRecord) => {
        // check if the Safety plan is allocated to self
        const { allocationOf } = allocItem;
        if (item._id === allocItem._id) {
          safetyPlanRecord.subRows.push(allocItem);
        }
        // check if the Safety plan is allocated to a workplace
        if (allocationOf && allocationOf._id === item._id) {
          safetyPlanRecord.subRows.push(allocItem);
        }
        return null;
      });

      return safetyPlanRecord;
    }) as any[];

    const updateSafetyListwithAllocation = {
      ...safetyPlanLists,
      items: safetyPlanWithAllocation,
    } as unknown as ApiResListsType<any[]>;

    return updateSafetyListwithAllocation;
  }
  return null;
};

export default parseListAllocations;
