import React from 'react';

import { Row } from 'react-table';
import { Button, Icon } from 'semantic-ui-react';

import { registerButtonStyles } from '../../OhsModuleHeader';

export interface CopyButtonsProps {
  row: Row<any>;
  copyFunc?: (item: any) => void;
  showIsCopiedHideButton?: boolean;
}
function OhsCopyButtons({ row, copyFunc, showIsCopiedHideButton }: CopyButtonsProps) {
  const { classes } = registerButtonStyles();
  const [copyLoading, setCopyLoading] = React.useState(false);
  const [copyDisabled, setCopyDisabled] = React.useState(false);

  if (showIsCopiedHideButton && row.original.copied)
    return (
      <Button disabled>
        <Icon className={classes.buttonIcons} name="copy" />
        Copied
      </Button>
    );

  return (
    copyFunc &&
    row.original &&
    !row.original.copied &&
    !row.original.archived &&
    row.original.allocated !== true && (
      <Button
        onClick={async () => {
          setCopyDisabled(true);
          setCopyLoading(true);
          await copyFunc(row.original);
          setCopyDisabled(false);
          setCopyLoading(false);
        }}
        className={classes.button}
        size="small"
        color="green"
        disabled={copyDisabled}
        loading={copyLoading}
      >
        <Icon className={classes.buttonIcons} name="copy" />
        <span className={classes.hideForSmall}>Copy</span>
      </Button>
    )
  );
}

OhsCopyButtons.defaultProps = { copyFunc: undefined, showIsCopiedHideButton: undefined };

export default OhsCopyButtons;
