import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
  OhsApiRequestOptions,
  RequestType,
} from 'global-services/api/OhsApiModels';
import { ModuleType } from 'global-services/constants/OhsObject';
import OhsDocumentsListsRecord, {
  OhsDocumentsFilterPayload,
} from 'documents/models/OhsDocumentsRecord';
import OhsFetchAll from 'global-services/OhsFetchAll';

import OhsApiRequest from '../global-services/api/OhsApiRequest';
import { DocumentAllocatedListType } from './models/OhsDocumentsModels';
import { OhsAllocationItem } from '../global-components/allocation/OhsAllocatableModels';
import { getCurrentTimezone } from '../global-services/OhsDataParse';
import { SafetyPlanAllocListRes } from '../safety-plan/OhsSafetyPlanModels';
import OhsDocumentTaskRecord from './models/OhsDocumentTaskRecord';

const getDocumentsLists = (
  filterInfo: OhsDocumentsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.VersionDocument, OhsApiRequestName.List, {
    filter: filterInfo,
  });
};

export const getCopiedDocumentsLists = (
  filterInfo: OhsDocumentsFilterPayload
): Promise<ApiResponseType<ApiResListsType<OhsDocumentsListsRecord[]>> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.VersionDocument, OhsApiRequestName.Copylist, {
    filter: filterInfo,
  });
};

export const getDocumentsAllocatedList = (
  ids: string[]
): Promise<DocumentAllocatedListType[] | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    OhsApiRequestName.AllocatedList,
    {
      _ids: ids,
    }
  );
};

export const addDocumentResource = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    OhsApiRequestName.Create,
    payload
  );
};

export const editDocument = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    OhsApiRequestName.Edit,
    payload
  );
};

export const addEditDocumentReview = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    OhsApiRequestName.Review,
    payload
  );
};
export const upVersionDocument = (payload: any, options?: OhsApiRequestOptions) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    OhsApiRequestName.UpVersion,
    payload,

    options
  );
};

export const getDocument = async (
  _id: string,
  disableErrorToast?: boolean
): Promise<OhsDocumentsListsRecord> => {
  const res: OhsDocumentsListsRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    OhsApiRequestName.Fetch,
    { _id },
    { disableErrorToast }
  );
  return res || new OhsDocumentsListsRecord();
};

export const archiveDocument = (docId: string, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    archived ? OhsApiRequestName.Archive : OhsApiRequestName.UnArchive,
    {
      _id: docId,
    }
  );
};
export const deleteDocument = (docId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.VersionDocument, OhsApiRequestName.Delete, {
    _id: docId,
  });
};

export const allocateDocument = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = { _id, to: [], tz: getCurrentTimezone() };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  } else {
    payload.to = allocationItem;
  }

  return OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    OhsApiRequestName.Allocate,
    payload
  );
};

export const deAllocateDocument = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = { _id, to: [], tz: getCurrentTimezone() };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  } else {
    payload.to = allocationItem;
  }
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    OhsApiRequestName.Deallocate,
    payload
  );
};

export const getDocumentAllocationList = (
  docId: string
): Promise<ApiResponseType<SafetyPlanAllocListRes> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    OhsApiRequestName.AllocationList,
    {
      _id: docId,
    }
  );
};
export const getDocumentTaskRecord = async (
  _id: string,
  taskId: string,
  disableErrorToast?: boolean
): Promise<OhsDocumentTaskRecord> => {
  const res: OhsDocumentTaskRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Task,
    OhsApiRequestName.Fetch,
    {
      _id: taskId,
      for: {
        _id,
        type: ModuleType.VersionDocument,
      },
    },
    { disableErrorToast }
  );
  return res || new OhsDocumentTaskRecord();
};

const getLinkableDoc = (filterInfo: any): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    OhsApiRequestName.ListLinkable,
    { filter: filterInfo }
  );
};

export const getLinkableDocumentsLists = () => {
  return OhsFetchAll(getLinkableDoc, {
    sort: {
      order: 1,
      key: '_id',
    },
    archived: false,
  });
};

export const getVdocCopySource = async (_id: string): Promise<OhsDocumentsListsRecord> => {
  const res: OhsDocumentsListsRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    OhsApiRequestName.FetchCopySource,
    { _id }
  );
  return res || new OhsDocumentsListsRecord();
};

export const signOffDocument = async (
  docId: string,
  taskId: string,
  payload: any
): Promise<OhsDocumentTaskRecord> => {
  const res: OhsDocumentTaskRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.VersionDocument,
    OhsApiRequestName.SignOff,
    { _id: docId, task: { _id: taskId }, ...payload }
  );
  return res || new OhsDocumentTaskRecord();
};

export default getDocumentsLists;
