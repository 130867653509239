import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { initialGlobalSearchPayload } from 'search/store/OhsSearchSlice';
import { OhsGlobalSearchPayload } from 'search/OhsSearchModels';
import { ModuleType } from 'global-services/constants/OhsObject';
import { RootState } from 'app/store';
import { setOhsLocalStorage } from 'global-services/OhsDataParse';
import { OhsVisitorViewPresets, VisitorListsRecordState } from 'visitor/OhsVisitorModels';

import {
  fetchVisitorSiteListsAsync,
  fetchVisitorSiteListCountAsync,
  fetchVisitorSiteActivityListsAsync,
  fetchVisitorSiteActivityListCountAsync,
  fetchVisitorFormListsAsync,
  fetchVisitorFormListCountAsync,
  fetchVisitorPinListsAsync,
} from './OhsVisitorActions';

const initialState: VisitorListsRecordState = {
  isLoading: false,
  visitorSiteList: null,
  visitorSiteActivityList: null,
  visitorFormList: null,
  visitorPinList: null,
  allocatedList: null,
  currentPage: 1,
  currentViewPreset: OhsVisitorViewPresets.View1VisitorSiteList,
};

export const visitorSlice = createSlice({
  name: 'visitor',
  initialState,
  reducers: {
    setCurrentRegisterPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setVisitorIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setViewPreset: (state, action: PayloadAction<OhsVisitorViewPresets>) => {
      state.currentViewPreset = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // SITE - LIST
      .addCase(fetchVisitorSiteListsAsync.pending, (state) => {
        state.isLoading = true;
        state.visitorSiteList = {
          items: [],
          pagination: state.visitorSiteList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchVisitorSiteListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.visitorSiteList = action.payload;

          // get last _id and set it to pagination next
          let setItemsLastId = '';
          const visitorItems = [...state.visitorSiteList.items];
          if (visitorItems.length > 0) setItemsLastId = visitorItems.pop()._id;

          state.visitorSiteList.pagination.next = setItemsLastId;
          state.visitorSiteList.pagination.totalPages = 1;
          state.isLoading = false;
        }
      })
      .addCase(fetchVisitorSiteListsAsync.rejected, (state) => {
        state.visitorSiteList = null;
      })
      .addCase(fetchVisitorSiteListCountAsync.fulfilled, (state, action) => {
        if (state.visitorSiteList && action.payload) {
          setOhsLocalStorage('visitorSiteListTotalPages', action.payload.pagination.totalPages);
          state.visitorSiteList.pagination.totalPages = action.payload.pagination.totalPages ?? 0;
        }
      })
      // SITE - ACTIVITY LIST
      .addCase(fetchVisitorSiteActivityListsAsync.pending, (state) => {
        state.isLoading = true;
        state.visitorSiteList = {
          items: [],
          pagination: state.visitorSiteActivityList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchVisitorSiteActivityListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.visitorSiteActivityList = action.payload;

          // get last _id and set it to pagination next
          let setItemsLastId = '';
          const visitorItems = [...state.visitorSiteActivityList.items];
          if (visitorItems.length > 0) setItemsLastId = visitorItems.pop()._id;

          state.visitorSiteActivityList.pagination.next = setItemsLastId;
          state.visitorSiteActivityList.pagination.totalPages = 1;
          state.isLoading = false;
        }
      })
      .addCase(fetchVisitorSiteActivityListsAsync.rejected, (state) => {
        state.visitorSiteActivityList = null;
      })
      .addCase(fetchVisitorSiteActivityListCountAsync.fulfilled, (state, action) => {
        if (state.visitorSiteActivityList && action.payload) {
          setOhsLocalStorage(
            'visitorSiteActivityListTotalPages',
            action.payload.pagination.totalPages
          );
          state.visitorSiteActivityList.pagination.totalPages =
            action.payload.pagination.totalPages ?? 0;
        }
      })

      // FORM
      .addCase(fetchVisitorFormListsAsync.pending, (state) => {
        state.isLoading = true;
        state.visitorFormList = {
          items: [],
          pagination: state.visitorFormList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchVisitorFormListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.visitorFormList = action.payload;

          // get last _id and set it to pagination next
          let setItemsLastId = '';
          const visitorItems = [...state.visitorFormList.items];
          if (visitorItems.length > 0) setItemsLastId = visitorItems.pop()._id;

          state.visitorFormList.pagination.next = setItemsLastId;
          state.visitorFormList.pagination.totalPages = 1;
          state.isLoading = false;
        }
      })
      .addCase(fetchVisitorFormListsAsync.rejected, (state) => {
        state.visitorFormList = null;
      })
      .addCase(fetchVisitorFormListCountAsync.fulfilled, (state, action) => {
        if (state.visitorFormList && action.payload) {
          setOhsLocalStorage('visitorFormListTotalPages', action.payload.pagination.totalPages);
          state.visitorFormList.pagination.totalPages = action.payload.pagination.totalPages ?? 0;
        }
      })

      // PIN
      .addCase(fetchVisitorPinListsAsync.pending, (state) => {
        state.isLoading = true;
        state.visitorPinList = [];
      })
      .addCase(fetchVisitorPinListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.visitorPinList = action.payload;
          state.isLoading = false;
        }
      })
      .addCase(fetchVisitorPinListsAsync.rejected, (state) => {
        state.visitorPinList = null;
      });
  },
});

// Memoized Selectors
const visitorState = (state: RootState) => state.visitor;
export const getOhsVisitorLists = createSelector([visitorState], (visitor) => visitor);

export const { setCurrentRegisterPage, setVisitorIsLoading, setViewPreset } = visitorSlice.actions;
export const visitorModuleReducer = visitorSlice.reducer;

export const initialVisitorSiteSearch: OhsGlobalSearchPayload = {
  searchKey: '',
  substringSearch: false,
  highlight: false,
  filter: { ...initialGlobalSearchPayload.filter, modules: [ModuleType.VisitorSite] },
};

export const initialVisitorActivitySearch: any = {
  searchKey: '',
  substringSearch: false,
  highlight: false,
  filter: {
    page: 1,
    leave: false,
    archived: false,
    count: false,
    workplaces: [],
  },
};
