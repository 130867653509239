import { ModuleType } from 'global-services/constants/OhsObject';

import { getConfigValue, setPermissions } from '../OhsConfigServices';
import { OhsCusValCategory, OhsTitleCategory } from '../OhsModuleCategory';
import OhsModulePermissions from '../OhsModulePermissions';
import OhsFileBoxConfig from '../filebox/OhsFileBoxConfig';

class OhsCorporateRiskConfig {
  TITLE: string;

  PERMISSIONS: OhsModulePermissions;

  MORPH_PERMISSIONS: OhsModulePermissions;

  CONFIDENTIAL_ACCESS: boolean;

  SIGNOFF_HAND_SIGNATURES: boolean;

  ENABLE_RISK_RATING_INHERENT: boolean;

  ENABLE_RISK_RATING_FUTURE: boolean;

  TYPE = ModuleType.CorporateRisk;

  CLASSIFICATION_LIST: OhsCusValCategory[];

  RISK_RATING_LIST: OhsTitleCategory[];

  RISK_OWNER_LIST: string[];

  RISK_MATRIX_IMAGE_LINK: string;

  fileboxConfig?: OhsFileBoxConfig;

  constructor(config: any, fileboxConfig?: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.CONFIDENTIAL_ACCESS = getConfigValue(config, 'CONFIDENTIAL_ACCESS') === true;
    this.SIGNOFF_HAND_SIGNATURES = getConfigValue(config, 'SIGNOFF_HAND_SIGNATURES') === true;
    this.ENABLE_RISK_RATING_INHERENT =
      getConfigValue(config, 'ENABLE_RISK_RATING_INHERENT') === true;
    this.ENABLE_RISK_RATING_FUTURE = getConfigValue(config, 'ENABLE_RISK_RATING_FUTURE') === true;
    this.CLASSIFICATION_LIST = getConfigValue(config, 'CLASSIFICATION_LIST');
    this.RISK_RATING_LIST = getConfigValue(config, 'RISK_RATING_LIST');
    this.RISK_OWNER_LIST = getConfigValue(config, 'RISK_OWNER_LIST');
    this.RISK_MATRIX_IMAGE_LINK = getConfigValue(config, 'RISK_MATRIX_IMAGE_LINK');

    this.PERMISSIONS = new OhsModulePermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(this.MORPH_PERMISSIONS, getConfigValue(config, 'MORPH_PERMISSIONS'));
    if (fileboxConfig) {
      this.fileboxConfig = new OhsFileBoxConfig(fileboxConfig);
    }
  }
}

export default OhsCorporateRiskConfig;
