import React, { useEffect } from 'react';

import { Row } from 'react-table';
import { Button, Icon } from 'semantic-ui-react';

import { isValidDateString } from 'global-services/OhsDataParse';
import { OhsAllocationTierRecord } from 'global-components/allocation/OhsAllocatableModels';

import { registerButtonStyles } from '../../OhsModuleHeader';

export interface AllocationButtonsProps {
  row: Row<any>;
  allocationFunc: (allocationItem: OhsAllocationTierRecord) => Promise<any>;
  deAllocationFunc: (deallocationItem: OhsAllocationTierRecord) => Promise<any>;
}
function OhsAllocationButtons({ row, allocationFunc, deAllocationFunc }: AllocationButtonsProps) {
  const { classes } = registerButtonStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const hasDueDate =
    !(row.allCells.length === 3 && row.allCells.some((a: any) => a.column.Header === 'Status')) &&
    row.allCells.length >= 3;
  const hasValidDate = hasDueDate ? isValidDateString(row.original.dateDue) : true;

  const handleAllocation = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await allocationFunc(row.original);
  };

  const handleDeAllocate = async () => {
    if (isLoading) return;
    setIsLoading(true);
    await deAllocationFunc(row.original);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [row]);

  return (
    <>
      {row.original && row.original.allocated !== true && (
        <Button
          onClick={handleAllocation}
          className={classes.button}
          size="small"
          color="green"
          disabled={isLoading || (hasDueDate === true && !hasValidDate)}
          loading={isLoading}
        >
          <Icon className={classes.buttonIcons} name="share" />
          <span className={classes.hideForSmall}>Allocate</span>
        </Button>
      )}
      {row.original && row.original.allocated === true && (
        <Button
          onClick={handleDeAllocate}
          className={classes.button}
          size="small"
          color="red"
          disabled={isLoading && hasDueDate === false && isLoading}
          loading={isLoading}
        >
          <Icon className={classes.buttonIcons} name="reply" />
          <span className={classes.hideForSmall}>Deallocate</span>
        </Button>
      )}
    </>
  );
}
export default OhsAllocationButtons;
