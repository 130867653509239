import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';
import OhsInspectionTaskRecord from 'inspection/models/OhsInspectionTaskRecord';
import { getAttachment } from 'linkaccess/correspondence/OhsCorrespondenceLinkAccessServices';

const getAttachmentList = async (ids: string[], token: string) => {
  const res: any = await OhsApiRequest(
    RequestType.Attachments,
    ModuleType.Attachment,
    OhsApiRequestName.linkAccessFetch,
    { _ids: ids ?? [] },
    { linkAccessDownloadToken: token }
  );

  if (res) {
    return res;
  }
  return null;
};
async function inspectionFetch(token: string) {
  const payload = {
    token,
    moduleType: 'core.module.inspection',
    accessType: 'TASK_SIGNOFF',
  };
  const res: any = await OhsApiRequest(
    RequestType.Admin,
    ModuleType.Linkaccess,
    OhsApiRequestName.Access,
    payload
  );
  const inspection = res.result.item;
  if (inspection && inspection.templateExecution) {
    for (let index = 0; index < inspection.templateExecution.sections.length; index += 1) {
      const section = inspection.templateExecution.sections[index];
      for (let indexQuestion = 0; indexQuestion < section.questions.length; indexQuestion += 1) {
        const question = section.questions[indexQuestion];
        if (question && question.answer) {
          if (question.answer.attachments)
            for (
              let answerIndex = 0;
              answerIndex < question.answer.attachments.length;
              answerIndex += 1
            ) {
              const item = question.answer.attachments[answerIndex];
              // eslint-disable-next-line no-await-in-loop
              const att = await getAttachment(item._id, inspection.downloadToken);
              question.answer.attachments[answerIndex] = { ...att, ...att.details };
            }
        }
      }
    }
  }
  if (inspection && inspection.signatures) {
    for (let index = 0; index < inspection.signatures.length; index += 1) {
      const element = inspection.signatures[index];
      if (element && element.attachments && element.attachments.length) {
        // eslint-disable-next-line no-await-in-loop
        const att = await getAttachment(element.attachments[0]._id, inspection.downloadToken);
        inspection.signatures[index].url = att.url;
      }
    }
  }
  return inspection || new OhsInspectionTaskRecord();
}

const OhsInspectionLinkAccessServices = { inspectionFetch, getAttachmentList };
export default OhsInspectionLinkAccessServices;
