import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';
import { OhsUser } from 'user/OhsUser';
import { getWorkplaceOptions } from 'user/OhsUserServices';

export enum OhsSafetyPlanViewPresets {
  View1SafetyPlanList = 'view1safetyPlanList',
  View2SafetyPlanList = 'view2safetyPlanList',
  View3SafetyPlanList = 'view3safetyPlanList',
  View1SafetyPlanSchedList = 'view1safetyPlanScheduledList',
  View2SafetyPlanSchedList = 'view2safetyPlanScheduledList',
}

export default class OhsSafetyPlanFilterModels {
  [OhsSafetyPlanViewPresets.View1SafetyPlanList]: {
    viewPreset: 'view_1';
    categories?: string[];
    archived?: boolean;
  };

  [OhsSafetyPlanViewPresets.View2SafetyPlanList]: {
    viewPreset: 'view_2';
    categories?: string[];
    workplaces?: string[];
    archived?: boolean;
    allocatedToOrg?: boolean;
    allocatedToWorkplace?: boolean;
    notAllocated?: boolean;
    workplaceOwned?: boolean;
  };

  [OhsSafetyPlanViewPresets.View3SafetyPlanList]: {
    viewPreset: 'view_3';
    categories?: string[];
    archived?: boolean;
    allocatedToWorkplace?: boolean;
    workplaceOwned?: boolean;
  };

  [OhsSafetyPlanViewPresets.View1SafetyPlanSchedList]: {
    viewPreset: 'view_1';
    archived?: boolean;
    categories?: string[];
    workplaces?: string[];
    allocatedToOrg?: boolean;
    workplaceOwned?: boolean;
    allocatedToWorkplace?: boolean;
    dateDue?: { start: string; end: string };
  };

  [OhsSafetyPlanViewPresets.View2SafetyPlanSchedList]: {
    viewPreset: 'view_2';
    archived?: boolean;
    categories?: string[];
    allocatedToWorkplace?: boolean;
    workplaceOwned?: boolean;
    dateDue?: { start: string; end: string };
  };

  sort: string;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  constructor(user?: OhsUser, tabKey?: string | null) {
    this[OhsSafetyPlanViewPresets.View1SafetyPlanList] = {
      viewPreset: 'view_1',
      categories: [],
      archived: false,
    };
    this[OhsSafetyPlanViewPresets.View2SafetyPlanList] = {
      viewPreset: 'view_2',
      categories: [],
      workplaces: [],
      archived: false,
      allocatedToOrg: true,
      allocatedToWorkplace: true,
      notAllocated: true,
      workplaceOwned: true,
    };
    this[OhsSafetyPlanViewPresets.View3SafetyPlanList] = {
      viewPreset: 'view_3',
      categories: [],
      archived: false,
      allocatedToWorkplace: true,
      workplaceOwned: true,
    };
    this[OhsSafetyPlanViewPresets.View1SafetyPlanSchedList] = {
      viewPreset: 'view_1',
      archived: false,
      categories: [],
      workplaces: [],
      allocatedToOrg: true,
      workplaceOwned: true,
      allocatedToWorkplace: true,
      dateDue: undefined,
    };
    this[OhsSafetyPlanViewPresets.View2SafetyPlanSchedList] = {
      viewPreset: 'view_2',
      archived: false,
      categories: [],
      allocatedToWorkplace: true,
      workplaceOwned: true,
      dateDue: undefined,
    };

    this.sort = '{ "key": "dateDue", "order": 1 }';
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Due Date: Newest to Oldest', value: '{ "key": "dateDue", "order": -1 }' },
        { name: 'Due Date: Oldest to Newest', value: '{ "key": "dateDue", "order": 1 }' },
        { name: 'Date Created: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Date Created: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },
      ],
    };

    if (user && tabKey) {
      const getWorkplaceLists = getWorkplaceOptions(user.configs);
      const workplaceOptions = getWorkplaceLists.map((list: any) => list.id);

      this[OhsSafetyPlanViewPresets.View2SafetyPlanList] = {
        ...this[OhsSafetyPlanViewPresets.View2SafetyPlanList],
        workplaces: workplaceOptions,
      };

      this[OhsSafetyPlanViewPresets.View1SafetyPlanSchedList] = {
        ...this[OhsSafetyPlanViewPresets.View1SafetyPlanSchedList],
        workplaces: workplaceOptions,
      };
      if (user?.tierNum !== 5 && tabKey === OhsTabKeyTypes.Template) {
        this.sort = '{ "key": "name", "order": 1 }';
        this.tableSortKeyLists = {
          sortColAscDescOptions: [
            { name: 'Name: A to Z', value: '{ "key": "name", "order": 1 }' },
            { name: 'Name: Z to A', value: '{ "key": "name", "order": -1 }' },
            { name: 'Category: A to Z', value: '{ "key": "category", "order": 1 }' },
            { name: 'Category: Z to A', value: '{ "key": "category", "order": -1 }' },
            { name: 'Date Created: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
            { name: 'Date Created: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },
          ],
        };
      }
    }
  }
}
