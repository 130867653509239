import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';
import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsUser } from 'user/OhsUser';
import { getModuleOptions, getWorkplaceOptions } from 'user/OhsUserServices';

export default class OhsTaskFilterModels {
  modules: ModuleType[];

  workplaces: string[];

  skipOrgRecords: boolean;

  dateDue?: { start: string; end: string };

  taskHideOverdue: boolean;

  signOffTaskOnly: boolean;

  sort: string;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  constructor(user?: OhsUser, tabKey?: string | null) {
    this.modules = [];
    this.workplaces = [];
    this.skipOrgRecords = false;
    this.taskHideOverdue = false;
    this.signOffTaskOnly = false;
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Deadline: Newest to Oldest', value: '{ "key": "dateDue", "order": -1 }' },
        { name: 'Deadline: Oldest to Newest', value: '{ "key": "dateDue", "order": 1 }' },
        { name: 'Date Created: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Date Created: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },
      ],
    };
    this.sort = '{ "key": "dateDue", "order": 1 }';

    if (user) {
      // set ALL modules and workplaces (id) to respective filters
      const moduleOptionLists = getModuleOptions(user?.configs);
      const moduleOptions = moduleOptionLists.map((list: any) => list.key);

      const getWorkplaceLists = getWorkplaceOptions(user.configs);
      const workplaceOptions = getWorkplaceLists.map((list: any) => list.id);

      const T5workplace = user?.workplace?._id ? Array.of(user?.workplace?._id) : [];
      const currentFilterWorkplaces = user?.tierNum === 5 ? T5workplace : workplaceOptions;

      this.modules = moduleOptions;
      this.workplaces = currentFilterWorkplaces;
      if (tabKey && tabKey === OhsTabKeyTypes.History) {
        this.sort = '{ "key": "dateSignedoff", "order": -1 }';
        this.tableSortKeyLists = {
          sortColAscDescOptions: [
            {
              name: 'Sign Off: Newest to Oldest',
              value: '{ "key": "dateSignedoff", "order": -1 }',
            },
            { name: 'Sign Off: Oldest to Newest', value: '{ "key": "dateSignedoff", "order": 1 }' },
            { name: 'Deadline: Newest to Oldest', value: '{ "key": "dateDue", "order": -1 }' },
            { name: 'Deadline: Oldest to Newest', value: '{ "key": "dateDue", "order": 1 }' },
            { name: 'Date Created: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
            { name: 'Date Created: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },
          ],
        };
      }
    }
  }
}
