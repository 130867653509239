import { createSelector, createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResponseType } from 'global-services/api/OhsApiModels';
import { OhsMobileAppAdminVersionRecord } from 'mobile-app-admin/version/OhsMobileAppAdminVersionRecord';
import { getMobileAppAdminVersionLists } from 'mobile-app-admin/version/OhsMobileAppAdminVersionServices';

export interface MobileAppAdminListsRecordState {
  isLoading: boolean;
  versionList: OhsMobileAppAdminVersionRecord[] | null;
}

const initialState: MobileAppAdminListsRecordState = {
  isLoading: false,
  versionList: null,
};

export const fetchMobileAppAdminVersionListsAsync = createAsyncThunk<
  ApiResponseType<{
    items: OhsMobileAppAdminVersionRecord[];
  }> | null,
  string,
  { state: RootState }
>(
  'mobile-app-admin/version/fetchMobileAppAdminVersionLists',
  async (platformName: string, thunkAPI) => {
    try {
      const response = await getMobileAppAdminVersionLists(platformName);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const mobileAppAdminSlice = createSlice({
  name: 'mobile-app-admin',
  initialState,
  reducers: {
    setMobileAppAdminIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchMobileAppAdminVersionListsAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMobileAppAdminVersionListsAsync.fulfilled, (state, action) => {
        if (action.payload?.success) {
          state.versionList = action.payload.result?.items ?? [];
          state.isLoading = false;
        }
      })
      .addCase(fetchMobileAppAdminVersionListsAsync.rejected, (state) => {
        state.versionList = null;
      });
  },
});

// Memoized Selectors
const mobileAppAdminState = (state: RootState) => state.mobileAppAdmin;
export const getOhsMobileAppAdminVersionLists = createSelector(
  [mobileAppAdminState],
  (mobileAppAdmin) => mobileAppAdmin
);

export const { setMobileAppAdminIsLoading } = mobileAppAdminSlice.actions;
export const mobileAppAdminModuleReducer = mobileAppAdminSlice.reducer;
