import * as React from 'react';

import usePagination, { UsePaginationItem } from '@mui/material/usePagination';
import { styled } from '@mui/material/styles';
import { Box, PaginationItem } from '@mui/material';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
});

interface Props {
  currentPage: number;
  setCurrentPage: (pageNum: number) => void;
  totalPages: number;
}
export default function OhsRegisterTableServerPagination(props: Props) {
  const { currentPage, setCurrentPage, totalPages } = props;

  const { items } = usePagination({
    count: totalPages,
    page: currentPage,
    onChange: (event: React.ChangeEvent<unknown>, page: number) => {
      setCurrentPage(page);
    },
  });

  const checkIsDisabled = (pageItem: UsePaginationItem) => {
    const page = pageItem.page ?? 0;
    let isDisabled = false;

    if (page >= 10 && page !== currentPage && currentPage + 1 !== page) isDisabled = true;
    if (currentPage + 1 < page) isDisabled = true;
    if (page + 1 === currentPage && !(page <= 10)) isDisabled = true;
    if (page <= 10) isDisabled = false;
    if (pageItem.type === 'previous' && currentPage === 1) isDisabled = true;
    if (pageItem.type === 'next' && currentPage === totalPages) isDisabled = true;

    return isDisabled;
  };

  if (totalPages <= 1) return null;

  return (
    <Box mt={2}>
      <List>
        {items.map((pageItem, index) => (
          <PaginationItem
            key={index}
            {...pageItem}
            disabled={checkIsDisabled(pageItem)}
            variant="outlined"
            shape="rounded"
            sx={{ background: 'white' }}
          />
        ))}
      </List>
    </Box>
  );
}
