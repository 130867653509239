import React from 'react';

import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import OhsRecoverCodesActions from './OhsRecoverCodesActions';

const useStyles = makeStyles()(() => ({
  modalTexts: {
    fontSize: '14px',
    textAlign: 'center',
  },
  modalsubTexts: {
    fontSize: '14px',
    textAlign: 'center',
    textDecoration: 'underline',
    fontWeight: 'bold',
    margin: '10px',
  },
  recoveryCodes: {
    padding: '8px 0 2px 0',
    width: '100%',
    display: 'block',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '18px',
  },
  actionCodesWrap: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0 5px 0',
    width: '100%',
  },
  codeCopied: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '35px',
  },
  verifyActions: {
    bottom: '0',
    left: '0',
    position: 'absolute',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: 'end',
    background: '#fafafa',
    borderTop: '1px solid #eaeaea',
    width: '100%',
    button: {
      fontWeight: 'bold',
    },
  },
}));

interface Props {
  recoveryCodes: string[];
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}
function OhsMfaRecoveryCode(props: Props) {
  const [codeCopied, setCodeCopied] = React.useState(false);
  const [newRecoveryCode, setNewRecoveryCode] = React.useState<string>('');
  const { classes } = useStyles();

  const handleIsCodeCopied = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodeCopied(event.target.checked);
  };

  React.useEffect(() => {
    setNewRecoveryCode(props.recoveryCodes[0]);
  }, [props.recoveryCodes]);

  return (
    <>
      <Typography variant="body1" className={classes.modalTexts}>
        Save this new backup code and store it somewhere safe. If you lose your phone, you can use
        backup codes to sign in.
      </Typography>
      <Typography variant="body1" className={classes.modalsubTexts}>
        This code will only be provided once.
      </Typography>

      <Box className={classes.recoveryCodes}>{newRecoveryCode}</Box>
      <Box className={classes.actionCodesWrap}>
        <OhsRecoverCodesActions recoveryCode={newRecoveryCode} />
      </Box>

      <FormControlLabel
        className={classes.codeCopied}
        control={<Checkbox onClick={(e: any) => handleIsCodeCopied(e)} checked={codeCopied} />}
        label="I have saved this code."
      />

      <Box className={classes.verifyActions}>
        <Button disabled={!codeCopied} onClick={() => props.setActiveStep(3)}>
          Next
        </Button>
      </Box>
    </>
  );
}

export default OhsMfaRecoveryCode;
