import React, { ReactNode } from 'react';

import { Box } from '@mui/material';

import OhsTooltip from './OhsTooltip';
import '../OhsFormField.css';

interface Props {
  title?: string;
  renderTitle?: (display: ReactNode) => ReactNode;
  description?: string | ReactNode;
  labelEle?: ReactNode;
  required: boolean;
  inputEle: ReactNode;
  style?: React.CSSProperties;
  renderAction?: ReactNode;
  renderLayout?: (display: ReactNode) => ReactNode;
}

export default function AngularPageFormLayoutFields(props: Props) {
  const renderLabel = () => (
    <>
      <b>
        {props.title} {props.title && props.required === true ? '*' : ''}{' '}
        {props.description && <OhsTooltip message={props.description} iconColor="black" />}
      </b>
      {props.labelEle}
    </>
  );

  const renderField = () =>
    props.renderAction ? (
      <Box display="flex" alignItems="center">
        {props.inputEle}
        {props.renderAction}
        <OhsTooltip
          message={`
            This field has been removed in the admin configuration and can no longer be
             edited, you can either leave it for now or delete it.`}
          iconColor="blue"
        />
      </Box>
    ) : (
      props.inputEle
    );

  return (
    <div style={props.style} className="angularLayoutFields">
      <div className="angularLayoutLabel">
        {props.renderTitle ? props.renderTitle(renderLabel()) : renderLabel()}
      </div>
      <div className="angularLayoutInput">
        {props.renderLayout ? props.renderLayout(renderField()) : renderField()}
      </div>
    </div>
  );
}

AngularPageFormLayoutFields.defaultProps = {
  title: '',
  renderTitle: undefined,
  description: '',
  labelEle: undefined,
  style: {},
  renderAction: undefined,
  renderLayout: undefined,
};
