import React from 'react';

import { Icon } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

interface Props {
  title: string;
  onCloseFn: () => void;
  headerColor?: string;
  headerTextColor?: string;
}

function OhsModalHeader({ title, onCloseFn, headerColor, headerTextColor }: Props) {
  const useStyles = makeStyles()((theme: Theme) => {
    return {
      copyTierWrapper: {
        backgroundColor: headerColor || theme.palette.custom?.HEADER_BAR_BG_COLOR,
        borderBottom: '1px solid #eaeaea',
        minHeight: '40px',
      },
      formDialogTitle: {
        padding: '0.8rem 1rem !important',
        width: '95%',
      },
      copyTierTitle: {
        color: headerTextColor || theme.palette.custom?.HEADER_BAR_TEXT_COLOR,
        fontSize: '1.2em !important',
        lineHeight: '1.28571429em !important',
        fontWeight: 'bold',
      },
      copyTierClose: {
        position: 'absolute',
        right: '1rem',
        top: '0.2em',
        color: headerTextColor || 'rgba(255, 255, 255, 0.9)',
        opacity: '0.8 !important',
        width: '2.25rem !important',
        height: '2.25rem !important',
        padding: '0.625rem 0 0 0',
        fontSize: '1.25em !important',
        cursor: 'pointer',
        '&:hover': {
          opacity: '1 !important',
        },
      },
    };
  });
  const { classes } = useStyles();

  return (
    <div className={classes.copyTierWrapper}>
      <div className={classes.formDialogTitle}>
        <span className={classes.copyTierTitle}>{title || ' '}</span>
      </div>

      <Icon
        onClick={onCloseFn}
        className={classes.copyTierClose}
        name="close"
        data-testid="modal-close"
      />
    </div>
  );
}

OhsModalHeader.defaultProps = {
  headerColor: undefined,
  headerTextColor: undefined,
};

export default OhsModalHeader;
