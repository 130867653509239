import React from 'react';

import { Box, IconButton, List, ListItem, ListItemText, Popover } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'app/hooks';
import { toggleLeftSideDrawer } from 'dashboard/OhsDashboardSlice';

import { ListItemLink } from './OhsLeftSideDrawerModuleList';

export const useStyles = makeStyles()(() => ({
  root: {
    display: 'block',
    wordBreak: 'break-all',
    maxWidth: '300px',
  },
  loading: {
    display: 'flex',
    padding: '20px 0 20px 20px',
  },
  assignToMore: {
    fontWeight: 'bold',
    color: '#00b5ad',
  },
  arrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderBottomColor: 'white',
    borderWidth: '10px',
    marginLeft: '-10px',
    rotate: '-90deg',
    top: '-5px',
    left: '-10px',
    transform: 'translateX(-50%)',
  },
  popover: {
    overflow: 'unset',
    width: '300px',
  },
  popoverContent: {
    position: 'relative',
    padding: '5px',
    color: 'black',
    textAlign: 'center',
  },

  list: {
    padding: 0,
    maxHeight: '500px',
    overflow: 'auto',
  },
  listItem: {
    backgroundColor: '#f4f4f4',
    color: 'black',
    padding: '0 8px',
    margin: '0 0 2px 0',
    '&:hover': {
      backgroundColor: '#fafafa',
      cursor: 'pointer',
    },
  },
  listItemText: {
    fontSize: '12px',
    padding: '0',
  },
  cancel: {
    position: 'absolute',
    top: '-10px',
    background: 'white',
    padding: '0',
    right: '-10px',
    zIndex: '999',
    '&:hover': {
      backgroundColor: '#fafafa',
      cursor: 'pointer',
    },
  },
}));

interface PopoverMenu {
  url: string;
  title: string;
  permission: boolean;
}
interface Props {
  linkTitle: string;
  popupoverMenu?: PopoverMenu[];
  handleOpenMenu?: () => void;
  isLoading?: boolean;
}
function OhsLeftMenuWithPopoverMenu(props: Props) {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMoreClose = () => {
    dispatch(toggleLeftSideDrawer(true));
    setAnchorEl(null);
  };

  const handleMoreClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    if (props.handleOpenMenu) props.handleOpenMenu();
  };

  const handleListItemClick = (url: string) => {
    dispatch(toggleLeftSideDrawer(false));
    setAnchorEl(null);
    navigate(url);
  };

  const popOverMenu =
    (props.popupoverMenu &&
      props.popupoverMenu.filter((task: PopoverMenu) => task.permission === true)) ??
    [];

  return (
    <>
      <ListItemLink button onClick={handleMoreClick} dense>
        <ListItemText primary={props.linkTitle} />
      </ListItemLink>
      {props.popupoverMenu && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleMoreClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 35,
            horizontal: -25,
          }}
          classes={{ paper: classes.popover }}
        >
          {props.isLoading && <div className={classes.loading}>Loading ...</div>}
          {!props.isLoading && popOverMenu.length > 0 && (
            <Box sx={{ position: 'relative', minHeight: '60px' }}>
              <div className={classes.arrow} />
              <div className={classes.popoverContent}>
                <IconButton className={classes.cancel} onClick={handleMoreClose}>
                  <CancelIcon />
                </IconButton>
                <List className={classes.list}>
                  {popOverMenu.map((task: PopoverMenu, index: number) => (
                    <ListItem
                      onClick={() => handleListItemClick(task.url)}
                      className={classes.listItem}
                      key={index}
                    >
                      <ListItemText className={classes.listItemText} primary={task.title} />
                    </ListItem>
                  ))}
                </List>
              </div>
            </Box>
          )}
          {!props.isLoading && popOverMenu.length === 0 && (
            <div className={classes.loading}>No results</div>
          )}
        </Popover>
      )}
    </>
  );
}

OhsLeftMenuWithPopoverMenu.defaultProps = {
  popupoverMenu: [],
  handleOpenMenu: undefined,
  isLoading: false,
};
export default OhsLeftMenuWithPopoverMenu;
