import mixpanel, { Mixpanel } from 'mixpanel-browser';

import { store } from 'app/store';

const getMixpanelTracker = (): Mixpanel | null => {
  mixpanel.init(
    (window as any).MIXPANEL_ID ? (window as any).MIXPANEL_ID : '03776c63a39a3c98908d60ac2e2859db'
  );

  try {
    const { user } = store.getState().user;
    let morph = 'None';
    if (user?.isMorph === true) {
      if (user.tierNum === 2) {
        morph = 'Up';
      } else {
        morph = 'Down';
      }
    }
    mixpanel.register({
      ApplicationType: 'Web',
      ApplicationVersion: (window as any).SAFETY_CHAMPION_VERSION,
      OrganisationName: user?.org?.name,
      OrganisationId: user?.org?._id,
      Username: user?.name,
      UserEmail: user?.email.toLowerCase(),
      UserId: user?._id,
      UserPlanType: user?.level,
      LoginType: user?.isSso === true ? 'Azure SSO' : 'Username Password',
      TierName: user?.tier.name,
      TierId: user?.tier._id,
      TierLevel: user?.tierNum,
      Morph: morph,
    });

    mixpanel.people.set({
      OrganisationName: user?.org?.name,
      OrganisationId: user?.org?._id,
      $name: user?.name,
      $email: user?.email.toLowerCase(),
      UserId: user?._id,
      'UserPlan Type': user?.level,
      TierName: user?.tier.name,
      TierId: user?.tier._id,
      TierLevel: user?.tierNum,
      Morph: morph,
    });

    mixpanel.identify(user?.email.toLowerCase());
    mixpanel.track('Reload Application Page');
    return mixpanel;
  } catch (e) {
    return null;
  }
};

export default getMixpanelTracker;
