import { ModuleType } from 'global-services/constants/OhsObject';

import OhsModulePermissions from '../OhsModulePermissions';
import { getConfigValue, setPermissions } from '../OhsConfigServices';
import { OhsCusValCategory } from '../OhsModuleCategory';
import OhsFileBoxConfig from '../filebox/OhsFileBoxConfig';
import { OhsModuleLocation } from '../OhsModuleChemical';

class OhsContractorConfig {
  TITLE: string;

  CLASSIFICATION_LIST: OhsCusValCategory[];

  PERMISSIONS: OhsModulePermissions;

  MORPH_PERMISSIONS: OhsModulePermissions;

  TYPE = ModuleType.Contractor;

  fileboxConfig?: OhsFileBoxConfig;

  BUSINESS_NUMBER_TYPE_LIST: [];

  APPROVAL_STATUS_LIST: [];

  CONTACT_ROLE_LIST: [];

  LOCATION_LIST: OhsModuleLocation[];

  RECORDS_LINKAGE: [];

  constructor(config: any, fileboxConfig?: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.CLASSIFICATION_LIST = getConfigValue(config, 'CLASSIFICATION_LIST');
    this.PERMISSIONS = new OhsModulePermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    this.BUSINESS_NUMBER_TYPE_LIST = getConfigValue(config, 'BUSINESS_NUMBER_TYPE_LIST');
    this.APPROVAL_STATUS_LIST = getConfigValue(config, 'APPROVAL_STATUS_LIST');
    this.CONTACT_ROLE_LIST = getConfigValue(config, 'CONTACT_ROLE_LIST');
    this.LOCATION_LIST = getConfigValue(config, 'LOCATION_LIST');
    this.RECORDS_LINKAGE = getConfigValue(config, 'RECORDS_LINKAGE');
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(this.MORPH_PERMISSIONS, getConfigValue(config, 'MORPH_PERMISSIONS'));
    if (fileboxConfig) {
      this.fileboxConfig = new OhsFileBoxConfig(fileboxConfig);
    }
  }
}

export default OhsContractorConfig;
