import React from 'react';

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import { Box, Typography } from '@mui/material';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import OhsTextField from 'global-components/form/fields/OhsTextField';

const useStyles = makeStyles()((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  title: {
    fontWeight: 'bold',
    fontSize: '22px',
    margin: '20px 0 20px 0',
  },
  field: {
    margin: '5px 0 0 0',
  },
}));

export type LoginInputs = {
  email: string;
  password: string;
};

interface Props {
  ohsFormSettings: OhsFormSettings;
  resetPassword: (confirmPassword: any) => Promise<void>;
}

function OhsResetPasswordForm(props: Props) {
  const { classes } = useStyles();
  const [newPassword]: [string] = props.ohsFormSettings.useFormMethods.watch(['newPassword']);
  return (
    <>
      <Typography variant="h2" component="div" className={classes.title}>
        Safety Champion Password Reset
      </Typography>
      <Box className={classes.form}>
        <Box className={classes.field}>
          <OhsTextField
            type="password"
            title="New Password"
            id="newPassword"
            required={false}
            ohsFormSettings={props.ohsFormSettings}
            passwordValidation
          />
        </Box>
        <Box className={classes.field}>
          <OhsTextField
            type="password"
            title="Confirm New Password"
            id="confirmNewPassword"
            required={false}
            comparePasswords={newPassword}
            ohsFormSettings={props.ohsFormSettings}
            passwordValidation
          />
        </Box>

        <Button
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          id="loginButtonId"
          data-testid="loginButtonTestId"
          className={classes.submit}
          onClick={props.ohsFormSettings.useFormMethods.handleSubmit(props.resetPassword)}
        >
          Reset Password
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/login" variant="body2">
              back
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default OhsResetPasswordForm;
