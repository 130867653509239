import React from 'react';

import { Box } from '@mui/material';

import OhsMfaRecoveryCode from './stepcontent/recoveryCode/OhsMfaRecoveryCode';
import OhsMfaEnrollMethodLists from './stepcontent/OhsMfaEnrollMethodLists';
import OhsMfaEnrollSuccess from './stepcontent/OhsMfaEnrollSuccess';
import OhsMfaEnrollMsg from './stepcontent/OhsMfaEnrollMsg';
import { OhsMfaMethod } from '../OhsMfaTypes';
import OhsMfaEnrollEmailSmsOtp from './stepcontent/OhsMfaEnrollEmailSmsOtp';
import OhsMfaVerifyFlow from '../verifyflows/OhsMfaVerifyFlow';

interface Props {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
}
export default function OhsMfaEnrollMain(props: Props) {
  const { activeStep, setActiveStep } = props;

  const [currentStepContent, setCurrentStepContent] = React.useState<React.ReactNode>();
  const [methodToEnroll, setMethodToEnroll] = React.useState<OhsMfaMethod>();

  const handleVerifySuccess = () => {
    setActiveStep(1);
  };
  const getMfaEnrollContent = (step: number): any => {
    switch (step) {
      case 0:
        return <OhsMfaEnrollMsg setActiveStep={setActiveStep} />;
      case 1:
        return (
          <OhsMfaEnrollMethodLists
            setActiveStep={setActiveStep}
            setMfaEnrollMethodInfo={setMethodToEnroll}
          />
        );
      case 2:
        return (
          <OhsMfaRecoveryCode
            recoveryCodes={methodToEnroll?.item.recoveryCodes ?? []}
            setActiveStep={setActiveStep}
          />
        );
      case 3:
        return <OhsMfaEnrollEmailSmsOtp setActiveStep={setActiveStep} mfaInfo={methodToEnroll} />;
      case 4:
        return <OhsMfaEnrollSuccess mfaInfo={methodToEnroll?.item.authenticator} />;
      case 5:
        return <OhsMfaVerifyFlow onSuccess={handleVerifySuccess} />;

      default:
        return null;
    }
  };

  React.useEffect(() => {
    setCurrentStepContent(getMfaEnrollContent(activeStep));
  }, [activeStep]);

  return <Box>{currentStepContent}</Box>;
}
