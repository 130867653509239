import React from 'react';

import OhsModal from 'global-components/modal/OhsModal';

import OhsMfaVerifyFlow from './OhsMfaVerifyFlow';

interface Props {
  open: boolean;
  title: string;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (methodId?: string) => void;
}

function OhsMfaEnrollModal(props: Props) {
  return (
    <OhsModal
      open={props.open}
      title={props.title}
      setModalOpenFunc={props.setModalOpen}
      maxWidth="xs"
      headerTextColor="black"
      headerColor="#fafafa"
      ContentNode={<OhsMfaVerifyFlow onSuccess={props.onSuccess} />}
    />
  );
}

export default OhsMfaEnrollModal;
