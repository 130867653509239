import React from 'react';

import { MenuItem, Theme } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';

import { OhsFormFieldBaseProps } from '../services/OhsFormModels';
import OhsFormFieldLayout from '../components/OhsFormFieldLayout';
import { returnFieldStyle, returnVariant } from '../components/OhsFieldLayout';

export interface SelectFieldOptionType {
  name: string;
  value: string | number;
}
export interface Props extends OhsFormFieldBaseProps {
  options: Array<SelectFieldOptionType>;
}

const useStyles = makeStyles()((theme: Theme) => ({
  optionContent: {
    overflowWrap: 'break-word',
    whiteSpace: 'break-spaces',
  },
  optionContainer: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: '60% !important', // Apply width only on large screens (lg and up)
    },
  },
}));

function InputEle(props: Props) {
  const { classes } = useStyles();

  const error = _.get(props.ohsFormSettings.useFormMethods.formState.errors, props.id);

  const result = props.ohsFormSettings.useFormMethods.watch(props.id) ?? '';
  const handleChange = (event: SelectChangeEvent) => {
    const value = (event.target.value as string) ?? '';

    props.ohsFormSettings.useFormMethods.setValue(props.id, value);
    props.ohsFormSettings.useFormMethods.trigger(props.id);
  };

  return (
    <Select
      data-testid={props.id}
      disabled={props.disabled}
      error={error != null}
      fullWidth
      margin="dense"
      size="small"
      required={props.required}
      displayEmpty
      value={result}
      variant={returnVariant(props.ohsFormSettings.layout)}
      style={returnFieldStyle(props.ohsFormSettings.layout, {
        marginTop: '8px',
      })}
      {...props.ohsFormSettings.useFormMethods.register(props.id, {
        onChange: handleChange,
        required: props.required === true,
      })}
      MenuProps={{
        PaperProps: {
          className: classes.optionContainer,
        },
      }}
    >
      <MenuItem value="" disabled={props.required}>
        <span style={{ color: 'gray' }}>-- Please Select --</span>
      </MenuItem>
      {props.options.map((item) => {
        return (
          <MenuItem key={item.name} value={item.value}>
            <div className={classes.optionContent}>{item.name}</div>
          </MenuItem>
        );
      })}
    </Select>
  );
}

export default function OhsSelectField(props: Props) {
  return <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />;
}
