import * as React from 'react';

import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { Box, Grid } from '@mui/material';
import moment from 'moment';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import OhsDateRangePicker from 'global-components/form/fields/OhsDateRangePicker';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';

interface Props {
  fieldId: string;
  ohsFormSettings: OhsFormSettings<FieldValues>;
  label: string;
}

const useStyles = makeStyles()(() => ({
  dateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '10px',
  },
  dateLabel: {
    fontFamily: 'Lato',
    fontSize: '14px',
    lineHeight: '1.4285em',
    fontWeight: 'bold',
  },
}));

enum DateFilterOptionValueTypes {
  hideOverdue = 'hideOverdue',
  dueToday = 'dueToday',
  approachingDueDate = 'approachingDueDate',
  custom = 'custom',
}

const dueDateFilterOptions = [
  { name: 'Hide Overdue', value: DateFilterOptionValueTypes.hideOverdue },
  { name: 'Due Today', value: DateFilterOptionValueTypes.dueToday },
  { name: 'Approaching Due Date ', value: DateFilterOptionValueTypes.approachingDueDate },
  { name: 'Custom Date Range', value: DateFilterOptionValueTypes.custom },
];

export default function OhsTaskDueDateFilter(props: Props) {
  const { classes } = useStyles();
  const taskDueDate = props.ohsFormSettings.useFormMethods.watch('taskDueDate') ?? null;
  const currentDate = moment().startOf('day').format('YYYY-MM-DD');

  const getEndDate = (taskDueOptionValue: DateFilterOptionValueTypes) => {
    switch (taskDueOptionValue) {
      case DateFilterOptionValueTypes.hideOverdue:
        return moment(currentDate).add(1000, 'months').format('YYYY-MM-DD');
      case DateFilterOptionValueTypes.dueToday:
        return moment().endOf('day').format('YYYY-MM-DD');
      case DateFilterOptionValueTypes.approachingDueDate:
        return moment(currentDate).add(14, 'days').format('YYYY-MM-DD');
      default:
        return '';
    }
  };

  React.useEffect(() => {
    if (taskDueDate !== DateFilterOptionValueTypes.custom) {
      props.ohsFormSettings.useFormMethods.setValue(props.fieldId, {
        start: currentDate,
        end: getEndDate(taskDueDate),
      });
      props.ohsFormSettings.useFormMethods.trigger();
    }
    if (!taskDueDate) {
      props.ohsFormSettings.useFormMethods.unregister(props.fieldId);
    }
  }, [taskDueDate]);

  return (
    <Grid item xs={12}>
      <OhsSelectField
        title={props.label}
        id="taskDueDate"
        required={false}
        options={dueDateFilterOptions}
        ohsFormSettings={props.ohsFormSettings}
      />
      {taskDueDate === DateFilterOptionValueTypes.custom && (
        <Box className={classes.dateWrapper}>
          <OhsDateRangePicker ohsFormSettings={props.ohsFormSettings} id={props.fieldId} />
        </Box>
      )}
    </Grid>
  );
}
