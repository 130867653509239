import _ from 'lodash';

import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import getMixpanelTracker from 'global-services/mixpanel/OhsMixpanel';
import { OhsLocalStoragePrefix } from 'global-services/OhsDataParse';
import { GlobalFilterName } from 'dashboard/topFilter/OhsFilterModels';
import OhsUserDataServices from 'user/OhsUserDataServices';

import OhsApiRequest from '../global-services/api/OhsApiRequest';
import { getOhsMultiUserLoginOptionList } from './multi/OhsMultiUserLoginOption';

export const loginByUsernamePassword = (email: string, password: string) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.Authenticate, {
    email,
    password,
  });
};

export const loginBySSO = (token: string) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.SsoLogin, {
    token,
  });
};

export const InactiveSession = () => {
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.User,
    OhsApiRequestName.AuthenticateInactive,
    {}
  );
};

export const getHomeUrl = (userTier?: OhsTier): string => {
  if (userTier) {
    if (
      userTier.type === 'core.tier.T3' ||
      userTier.type === 'core.tier.T4' ||
      userTier.type === 'core.tier.T5'
    ) {
      return '/task/active';
    }
    return '/admin2/home';
  }
  return '/login';
};
export const removeGlobalFilter = () => {
  localStorage.removeItem(OhsLocalStoragePrefix + GlobalFilterName);
};
export const multiUserLogin = (userId: string) => {
  let sessionToken;
  const userOptions = getOhsMultiUserLoginOptionList();
  const option = _.find(userOptions, { user: { _id: userId } });
  if (option && option.sessionToken) {
    sessionToken = option.sessionToken;
  }
  removeGlobalFilter();

  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.User,
    OhsApiRequestName.MultiUser,
    {
      user: {
        _id: userId,
      },
    },
    { sessionToken }
  );
};

export const OhsLogout = (isSso: boolean) => {
  InactiveSession();
  removeGlobalFilter();
  OhsUserDataServices.resetUserData();
  const mixpanel = getMixpanelTracker();
  mixpanel?.track('Sign out');
  mixpanel?.reset();
  if (isSso === true) {
    window.open('/unlogin.html#logout', '_self');
  } else {
    window.open('/login', '_self');
  }
};
