import React from 'react';

import { Theme } from '@mui/material/styles';
import { Container, CssBaseline, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { forwardToDynamicLink, isMobileDevice } from 'global-services/OhsMobileServices';

const useStyles = makeStyles()((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function OhsVisitorUnLoginSignInHome() {
  const { orgId, siteId } = useParams<{ orgId: string; siteId: string }>();
  const { classes } = useStyles();
  forwardToDynamicLink(`https://safetychampion.online/m/visitor/org/${orgId}/site/${siteId}`);
  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper} />
      {isMobileDevice() !== true && (
        <Typography align="center" variant="h4" component="h4">
          Please open this page on a mobile device.
        </Typography>
      )}
    </Container>
  );
}

export default OhsVisitorUnLoginSignInHome;
