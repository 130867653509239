import ModuleHomeUrl from 'dashboard/leftSideDrawer/ModuleHomeUrl';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';

import { OhsUser } from '../../user/OhsUser';
import OhsBreadcrumbsNameAndRef from './OhsBreadcrumbsNameAndRef';

const OhsBreadCrumbNoticeboard = (
  ohsBreadcrumbsNameAndRef: OhsBreadcrumbsNameAndRef,
  modulePath: string,
  subPath: string,
  viewPath: string,
  user: OhsUser
) => {
  if (subPath === 'home') {
    ohsBreadcrumbsNameAndRef.leafName = {
      name: user.configs.noticeboard?.TITLE ?? '',
    };
  } else if (subPath === 'admin') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).noticeboard,
      name: user.configs.noticeboard?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Manage' };
  } else if (viewPath === 'view') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).noticeboard,
      name: user.configs.noticeboard?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/admin`,
      name: 'Manage',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View' };
  } else if (subPath === 'form' && viewPath === 'add') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).noticeboard,
      name: user.configs.noticeboard?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/admin?tab=${OhsTabKeyTypes.Form}`,
      name: 'Manage',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Add' };
  } else if (subPath === 'form') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).noticeboard,
      name: user.configs.noticeboard?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/admin?tab=${OhsTabKeyTypes.Form}`,
      name: 'Manage',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Edit' };
  }
  return ohsBreadcrumbsNameAndRef;
};

export default OhsBreadCrumbNoticeboard;
