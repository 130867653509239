class OhsPrivacyPermissions {
  dateOfBirth: boolean;

  email: boolean;

  phone: boolean;

  gender: boolean;

  personalEmail: boolean;

  personalPhone: boolean;

  postalAddress: boolean;

  emergencyContactName: boolean;

  emergencyContactPhone: boolean;

  referenceId: boolean;

  position: boolean;

  dateStarted: boolean;

  managerName: boolean;

  managerEmail: boolean;

  notes: boolean;

  attachments: boolean;

  constructor(flag: boolean) {
    this.dateOfBirth = flag;
    this.email = flag;
    this.phone = flag;
    this.gender = flag;
    this.personalEmail = flag;
    this.personalPhone = flag;
    this.postalAddress = flag;
    this.emergencyContactName = flag;
    this.emergencyContactPhone = flag;
    this.referenceId = flag;
    this.position = flag;
    this.dateStarted = flag;
    this.managerName = flag;
    this.managerEmail = flag;
    this.notes = flag;
    this.attachments = flag;
  }
}

export default OhsPrivacyPermissions;
