import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import { OhsTime } from 'global-services/constants/OhsTimeModels';

export interface OhsCorrespondenceCustomFilters {
  archived?: boolean;
  next?: string;
  workplaces?: string[];
}

export interface OhsCorrespondenceFilterPayload extends OhsCorrespondenceCustomFilters {
  viewPreset?: 'view_1' | 'view_2' | 'view_3' | 'view_4';
  page: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
}

export default class OhsCorrespondenceListsRecord {
  tier?: OhsTier;

  attachments?: OhsAttachment[];

  type: ModuleType;

  category: string;

  categoryOther?: string;

  createdBy: OhsModuleCreateBy;

  updated?: OhsModuleCreateBy;

  title: string;

  timeUpdated?: OhsTime;

  subcategory: string;

  subcategoryOther?: string;

  dateCreated: string;

  description: string;

  tzDateCreated: string;

  archived: boolean;

  _id: string;

  constructor() {
    this.type = ModuleType.Correspondence;
    this.category = '';
    this.description = '';
    this.createdBy = new OhsModuleCreateBy();
    this.title = '';
    this.subcategory = '';
    this.category = '';
    this.dateCreated = '';
    this.tzDateCreated = '';
    this.archived = false;
    this._id = '';
  }
}
