import React from 'react';

import { FieldValues } from 'react-hook-form';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import OhsMultipleSelectField from 'global-components/form/fields/OhsMultipleSelectField';
import { OhsSelectOption } from 'user/OhsUserModels';
import { OhsCusValCategory } from 'user/configurations/OhsModuleCategory';

interface Props {
  id: string;
  ohsFormSettings: OhsFormSettings<FieldValues>;
  options: OhsCusValCategory[];
}
export default function OhsCategoriesMultSelectFilter(props: Props) {
  const categoryLists: OhsSelectOption[] =
    props.options.map((cat) => ({
      name: cat.category,
      value: cat.category,
    })) ?? [];

  return (
    <div>
      <OhsMultipleSelectField
        title="Categories"
        id={props.id}
        required={false}
        options={categoryLists}
        ohsFormSettings={props.ohsFormSettings}
      />
    </div>
  );
}
