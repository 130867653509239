import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
// import { getOhsUser } from 'user/userSlice';
import { getOhsVisitorLists } from 'visitor/store/OhsVisitorSlice';
// import OhsMultipleSelectField from 'global-components/form/fields/OhsMultipleSelectField';
import { OhsVisitorViewPresets } from 'visitor/OhsVisitorModels';

import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}

function OhsVisitorFilters(props: Props) {
  const { classes } = useStyles();

  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  // const user = useAppSelector(getOhsUser);
  const visitor = useAppSelector(getOhsVisitorLists);
  // const getModuleCategoryLists = user.configs.visitor?.SITE_CATEGORY_LIST ?? [];
  // const categoryOptions =
  //   getModuleCategoryLists.map((item) => ({ name: item.category, value: item.category })) ?? [];

  const viewPreset = visitor.currentViewPreset;
  const isSitePreset =
    viewPreset === OhsVisitorViewPresets.View1VisitorSiteList ||
    viewPreset === OhsVisitorViewPresets.View2VisitorSiteList;

  // show options for site or site activity
  const sortOptions = isSitePreset
    ? filter.visitorModule.tableSortKeyLists?.sortSiteOptions
    : filter.visitorModule.tableSortKeyLists?.sortActivityOptions;
  return (
    <Box className={classes.filterContent}>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id={`visitorModule.${viewPreset}.sort`}
          required
          options={sortOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {/* <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsMultipleSelectField
          title="Categories"
          id={`visitorModule.${viewPreset}.categories`}
          required
          options={categoryOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid> */}
    </Box>
  );
}

export default OhsVisitorFilters;
