import _ from 'lodash';

import { getLocalStorage, setLocalStorage } from 'global-services/OhsDataParse';
import { OhsMultiUserOptionName } from 'global-services/constants/OhsStorageNames';

export interface OhsMultiUserLoginOption {
  user: {
    type: string;
    _id: string;
    tier: { _id: string; type: string };
    profile: { title: string; type: string; _id: string };
  };
  orgAdminTier: { type: string; _id: string; name: string };
  orgTier: { type: string; _id: string; name: string };
  workplaceTier?: { type: string; _id: string; name: string };
  workerGroupTier?: { type: string; _id: string; name: string };
  sessionToken?: string;
  blocked?: boolean;
  inProgress?: boolean;
}

export const getOhsMultiUserLoginOptionList = (): OhsMultiUserLoginOption[] => {
  const list = getLocalStorage(OhsMultiUserOptionName);
  if (list) return list;
  return [];
};

export const refreshOptionsList = (userOptions: OhsMultiUserLoginOption[]) => {
  if (userOptions) {
    const list = getOhsMultiUserLoginOptionList();
    const userLookup = _.keyBy(list, 'user._id');

    const arr: OhsMultiUserLoginOption[] = [];

    userOptions.forEach((item) => {
      const user = userLookup[item.user._id];

      if (user && user.sessionToken) {
        item.sessionToken = user.sessionToken;
      }

      arr.push(item);
    });

    setLocalStorage(OhsMultiUserOptionName, arr);
  }
};
