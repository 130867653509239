import React from 'react';

import { Box } from '@mui/material';
import { isNull } from 'lodash';

import { RiskType } from 'chemical/models/OhsChemicalRecord';
import {
  getFilterGHSEnvironmentValues,
  getFilterGHSHealthValues,
  getFilterGHSPhysicalValues,
} from 'chemical/utils/hazardUtils';

import HazardIcon from './HazardIcon';

interface Props {
  data: RiskType;
}

function HazardStatement(props: Props) {
  const { classificationGHS, dangerousGood, hazardousSubstance } = props.data || {};

  return (
    <Box sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
      {!!classificationGHS?.length && classificationGHS?.join().indexOf('_NA_') === -1 && (
        <>
          <HazardIcon
            classificationGHS={classificationGHS}
            getGHSFunc={getFilterGHSPhysicalValues}
            icon="warning sign"
            type="GHS"
          />
          <HazardIcon
            classificationGHS={classificationGHS}
            getGHSFunc={getFilterGHSHealthValues}
            icon="ambulance"
            type="GHS"
          />
          <HazardIcon
            classificationGHS={classificationGHS}
            getGHSFunc={getFilterGHSEnvironmentValues}
            icon="globe"
            type="GHS"
          />
        </>
      )}
      {(!isNull(hazardousSubstance) || !isNull(dangerousGood)) && (
        <>
          <HazardIcon
            getGHSFunc={getFilterGHSEnvironmentValues}
            type="hazardous"
            isDangrousOrHazardous={hazardousSubstance}
          />
          <HazardIcon
            getGHSFunc={getFilterGHSEnvironmentValues}
            type="dangrousGood"
            isDangrousOrHazardous={dangerousGood}
          />
        </>
      )}
    </Box>
  );
}

export default HazardStatement;
