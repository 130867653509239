import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { OhsActionsRecord } from 'action/OhsActionModels';
import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import OhsCusvalValue from 'global-components/form/cusvals/OhsCusvalValue';
import { OhsCusval } from 'global-components/form/cusvals/OhsCusvalModel';

export default class OhsSafetyPlanTaskRecord {
  attachments: OhsAttachment[];

  complete: boolean;

  completionNotes: string;

  createdBy: OhsModuleCreateBy;

  dateCreated: string;

  dateDue: string;

  dateCompleted: string;

  dateSignedoff: string;

  signedoffBy: {
    name: string;
    email: string;
  };

  description: string;

  for?: { _id: string; type: ModuleType.SafetyPlan };

  links: OhsActionsRecord[];

  type: ModuleType.Task;

  tzDateCreated: string;

  templateExecution?: {
    cumulativeScorePercentage: number | string;
    sections: {
      _id: string;
      cumulativeScorePercentage: number | string;
      questions: {
        _id: string;
        answer: { links?: OhsActionsRecord[]; attachments?: OhsAttachment[] };
      }[];
    }[];
  };

  _id: string;

  constructor() {
    this.attachments = [];
    this.complete = false;
    this.completionNotes = '';
    this.createdBy = new OhsModuleCreateBy();
    this.dateCreated = '';
    this.dateDue = '';
    this.dateCompleted = '';
    this.dateSignedoff = '';
    this.signedoffBy = {
      name: '',
      email: '',
    };
    this.description = '';
    this.for = undefined;
    this.links = [];
    this.type = ModuleType.Task;
    this.tzDateCreated = '';
    this._id = '';
  }
}

export interface OhsSafetyPlanTaskResponse extends OhsSafetyPlanTaskRecord {
  categoryCusvals?: OhsCusvalValue;

  subcategoryCusvals?: OhsCusvalValue;
}

export interface OhsSafetyPlanTaskFormatted extends OhsSafetyPlanTaskRecord {
  categoryCusvals?: OhsCusval[];

  subcategoryCusvals?: OhsCusval[];
}
