import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ModuleType } from 'global-services/constants/OhsObject';
import { initialGlobalSearchPayload } from 'search/store/OhsSearchSlice';
import { OhsGlobalSearchFilter, OhsGlobalSearchPayload } from 'search/OhsSearchModels';
import handleRegisterAsyncData, {
  handleRegisterCountAsyncData,
} from 'global-components/register/OhsModuleRegisterUtils';

import { ChemicalListsRecordState, OhsChemicalViewPresets } from '../models/OhsChemicalModel';
import { fetchChemicalListsAsync, fetchChemicalListsCountAsync } from './OhsChemicalActions';

const initialState: ChemicalListsRecordState = {
  isLoading: false,
  chemicalList: null,
  currentPage: 1,
  currentViewPreset: OhsChemicalViewPresets.View1ChemicalList,
};

export const chemicalSlice = createSlice({
  name: 'chemical',
  initialState,
  reducers: {
    setChemicalCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setViewPreset: (state, action: PayloadAction<OhsChemicalViewPresets>) => {
      state.currentViewPreset = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChemicalListsAsync.pending, (state) => {
        state.isLoading = true;
        state.chemicalList = {
          items: [],
          pagination: state.chemicalList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchChemicalListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const chemicalListsRes = handleRegisterAsyncData(
            action.payload,
            'chemicalListtotalPages'
          );
          state.isLoading = false;
          state.chemicalList = chemicalListsRes;
        }
      })
      .addCase(fetchChemicalListsAsync.rejected, (state) => {
        state.chemicalList = null;
      })
      .addCase(fetchChemicalListsCountAsync.fulfilled, (state, action) => {
        if (state.chemicalList) {
          const countTaskLists = handleRegisterCountAsyncData(
            action.payload,
            state.chemicalList,
            'chemicalListtotalPages'
          );
          state.chemicalList = countTaskLists;
        }
      });
  },
});

const chemicalState = (state: RootState) => state.chemical;

export const getOhsChemicalModuleState = createSelector([chemicalState], (chemicals) => chemicals);
export const { setChemicalCurrentPage, setViewPreset } = chemicalSlice.actions;
export const chemicalModuleReducer = chemicalSlice.reducer;
export const defaultTaskSearchFilter: OhsGlobalSearchFilter = {
  ...initialGlobalSearchPayload.filter,
  modules: [ModuleType.Chemical],
};
export const initialChemicalSearch: OhsGlobalSearchPayload = {
  searchKey: '',
  substringSearch: true,
  highlight: false,
  filter: defaultTaskSearchFilter,
};
