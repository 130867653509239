// This will hold constant variables throughout `DashboardBanner` module/folder

export const dashboardBannerColumns = [
  {
    Header: 'Content Message',
    accessor: 'content.message',
  },
  {
    Header: 'Tier Type',
    accessor: 'tierType',
  },
  {
    Header: 'Start Date',
    accessor: 'startDate',
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
  },
];

export const bannerContentButtonColumns = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Label',
    accessor: 'label',
  },
  {
    Header: 'Text Color',
    accessor: 'fgColor',
  },
  {
    Header: 'Background Color',
    accessor: 'bgColor',
  },
];

export type DashboardBannerFormButtonType = {
  type: string;
  label: string;
  fgColor: string;
  bgColor: string;
};

export type DashboardBannerFormType = {
  tierType: string;
  startDate: string;
  endDate: string;
  platform: string;
  countryAndState: Array<{
    country: string;
    state: string;
  }>;
  content: {
    message: string;
    messageColor: string;
    bannerColors: Array<string>;
    buttons: Array<DashboardBannerFormButtonType>;
  };
  contentButtonType: string;
};

export const tierTypeOptions = [
  { name: 'Tier 2', value: 'core.tier.T2' },
  { name: 'Tier 3', value: 'core.tier.T3' },
  { name: 'Tier 4', value: 'core.tier.T4' },
  { name: 'Tier 5', value: 'core.tier.T5' },
];

export const allowedTierForLocationForm = ['core.tier.T3', 'core.tier.T4', 'core.tier.T5'];

export type ActionButtonType = {
  message: string;
  label: string;
  fgColor: string;
  bgColor: string;
  click?: {
    actionType: 'link' | 'popup' | 'slide';
    target: string;

    external?: boolean;

    overlay?: boolean;
    overlayOpacity?: number;

    // `slide` properties
    position?: string;
    transition?: string;
    speed?: number;
  };
  solo?: boolean;
};

export const localStorageKey = '__removed_banner';

export type RemovedBannerCookieRecordType = {
  _id: string;
  endDate: string;
  userEmail: string;
  userOrgId: string;
  dateRemoved: string;
};

export const platformList = ['enableOnWeb', 'enableOnAndroid', 'enableOnIOS'];

export const validUrlsToShow = ['/admin2/home', '/task/active'];
