import React from 'react';

import styled from '@emotion/styled';
import { Theme, Paper, IconButton, ButtonGroup } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import {
  Delete as DeleteIcon,
  Add as AddIcon,
  Close as CloseIcon,
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigatePrevIcon,
} from '@mui/icons-material';
import Carousel, { ControlProps } from 'nuka-carousel';

import GreenButton from 'global-components/buttons/OhsGreenButton';
import { OhsMaterialIconButton } from 'global-components/buttons/OhsMaterialButton';
import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';

type HeaderProps = {
  bgColor: string;
};

export const OhsDashboardBannerHeaderWrap = styled.div<HeaderProps>`
  background: ${(props) => props.bgColor || '#e9ebee'};
  margin-bottom: 6px;
`;

const registerComponentstyles = makeStyles()((theme: Theme) => ({
  paperContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  buttonIcons: {
    // height: '100%!important',

    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      opacity: '.9',
      margin: '0!important',
      verticalAlign: 'top',
    },
  },
  hideForSmall: {
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'inline',
    },
  },
  buttonFormWrapper: {
    display: 'flex',
    borderRadius: '5px',
    marginBottom: '10px',
    padding: '20px 10px',
    alignItems: 'center',
  },
  bannerItemContainerStyle: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(1),
    minHeight: '36px',
    borderRadius: 0,
  },
  carouselStyle: {
    backgroundColor: '#3b5998',
    '& button[aria-label="Next"]': {
      marginRight: '1em',
    },
  },
  bannerMessageStyle: {
    fontWeight: 'bold',
    fontSize: '1.2em',
  },
  bannerCloseButtonStyle: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    backgroundColor: '#f50057',
    padding: '2px',
    zIndex: 99,
  },
  smallIconStyle: {
    fontSize: '0.90rem',
  },
  bannerButtonGroupStyle: {
    marginLeft: '10px',

    '& button': {
      borderRadius: 0,
      borderRight: '1px solid rgba(0, 0, 0, 0.2)',
    },

    '& button:first-child': {
      borderRadius: '4px 0 0 4px',
    },

    '& button:last-child': {
      borderRadius: '0 4px 4px 0',
      borderRight: 0,
    },
  },
}));

interface AddButtonProps {
  onClick?: () => void;
  style?: object;
  label?: string;
}

export function AddButton(props: AddButtonProps) {
  const { classes } = registerComponentstyles();

  return (
    <div style={props.style}>
      <GreenButton onClick={props.onClick}>
        <AddIcon fontSize="small" className={classes.buttonIcons} />
        <span className={classes.hideForSmall}>{props.label}</span>
      </GreenButton>
    </div>
  );
}

AddButton.defaultProps = {
  onClick: () => {},
  style: {},
  label: 'Add',
};

interface FormContainerProps {
  children?: React.ReactNode;
}

export function FormContainer({ children }: FormContainerProps) {
  const { classes } = registerComponentstyles();
  return <Paper className={classes.paperContainer}>{children}</Paper>;
}

FormContainer.defaultProps = {
  children: '',
};

interface RemoveButtonProps {
  onClick: () => void;
}

export function RemoveButtonIcon(props: RemoveButtonProps) {
  return (
    <OhsMaterialIconButton onClick={props.onClick}>
      <DeleteIcon color="error" />
    </OhsMaterialIconButton>
  );
}

interface ButtonFormWrapperProps {
  children: React.ReactNode;
}
export function ButtonFormWrapper(props: ButtonFormWrapperProps) {
  const { classes } = registerComponentstyles();

  return (
    <Paper elevation={3} className={classes.buttonFormWrapper}>
      {props.children}
    </Paper>
  );
}

interface BannerItemContainerProps {
  children: React.ReactNode;
  style: any;
}

export function BannerItemContainer(props: BannerItemContainerProps) {
  const { classes } = registerComponentstyles();

  return (
    <Paper elevation={0} style={props.style} className={classes.bannerItemContainerStyle}>
      {props.children}
    </Paper>
  );
}

interface BannerCarouselProps {
  children: React.ReactNode;
  itemCount: number;
  slidesToShow: number;
}
export function BannerCarousel(props: BannerCarouselProps) {
  const hideArrowButtons = props.itemCount > 1 && props.itemCount !== props.slidesToShow;

  const defaultControlsConfig = {
    pagingDotsStyle: {
      display: 'none',
    },
  };

  const renderNext = ({ nextSlide }: ControlProps) =>
    hideArrowButtons ? (
      <IconButton
        data-testid="next-button"
        onClick={nextSlide}
        sx={{
          position: 'absolute',
          right: '2em',
          transform: 'translateY(-50%)',
          top: '50%',
          color: 'white',
          bgcolor: 'rgb(73, 73, 73)',
          ':hover': {
            bgcolor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        size="small"
        aria-label="next"
      >
        <NavigateNextIcon />
      </IconButton>
    ) : (
      ''
    );

  const renderPrev = ({ previousSlide }: ControlProps) =>
    hideArrowButtons ? (
      <IconButton
        data-testid="previous-button"
        onClick={previousSlide}
        sx={{
          color: 'white',
          bgcolor: 'rgb(73, 73, 73)',
          left: '0.5em',
          ':hover': {
            bgcolor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
        size="small"
        aria-label="prev"
      >
        <NavigatePrevIcon />
      </IconButton>
    ) : (
      ''
    );

  return (
    <Carousel
      defaultControlsConfig={defaultControlsConfig}
      renderCenterRightControls={renderNext}
      renderCenterLeftControls={renderPrev}
      animation="fade"
      wrapAround
      slidesToShow={props.slidesToShow}
      adaptiveHeight
    >
      {props.children}
    </Carousel>
  );
}

interface BannerMessageWrapperProps {
  children: React.ReactNode;
}
export function BannerMessageWrapper(props: BannerMessageWrapperProps) {
  const { classes } = registerComponentstyles();

  return <span className={classes.bannerMessageStyle}>{props.children}</span>;
}

export function BannerCloseButton(props: { onClick: () => void }) {
  const { classes } = registerComponentstyles();

  return (
    <IconButton onClick={props.onClick} className={classes.bannerCloseButtonStyle}>
      <CloseIcon
        classes={{ fontSizeSmall: classes.smallIconStyle }}
        fontSize="small"
        style={{ color: 'white' }}
      />
    </IconButton>
  );
}

export function BannerButtonGroup(props: { children: React.ReactNode }) {
  const { classes } = registerComponentstyles();

  return (
    <ButtonGroup className={classes.bannerButtonGroupStyle} variant="contained" size="small">
      {props.children}
    </ButtonGroup>
  );
}
