import React from 'react';

import { Skeleton, Table, TableHead, TableRow } from '@mui/material';
import { TableBody } from 'semantic-ui-react';

import OhsRegisterTableRow from 'global-components/register/table/OhsRegisterTableRow';
import OhsRegisterTableBodyCell from 'global-components/register/table/OhsRegisterTableBodyCell';
import OhsRegisterTableHeader from 'global-components/register/table/OhsRegisterTableHeader';

interface TableLoadingProps {
  rowCount: number; // Number of rows to display skeletons for
  columnCount: number; // Number of columns in the table
}

// eslint-disable-next-line react/function-component-definition
const TableLoading: React.FC<TableLoadingProps> = ({ rowCount, columnCount }) => {
  const renderSkeletonRows = () => {
    const rows: JSX.Element[] = [];
    Array.from({ length: rowCount }).forEach((row, rowIndex) => {
      rows.push(
        <OhsRegisterTableRow key={rowIndex}>
          {Array.from({ length: columnCount }).map((_, index) => (
            <OhsRegisterTableBodyCell key={index}>
              <Skeleton animation="wave" width="50%" />
            </OhsRegisterTableBodyCell>
          ))}
        </OhsRegisterTableRow>
      );
    });
    return rows;
  };

  return (
    <Table data-testid="table-loading-skeleton">
      <TableHead>
        <TableRow>
          {Array.from({ length: columnCount }).map((_, index) => (
            <OhsRegisterTableHeader key={index}>
              <Skeleton animation="wave" />
            </OhsRegisterTableHeader>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>{renderSkeletonRows()}</TableBody>
    </Table>
  );
};

export default TableLoading;
