/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from '../../global-components/backdrop/useAxiosSpinner';

const Dev1Url = 'https://api.dev1.safetychampion.dev';

const Dev2Url = 'https://api.dev2.safetychampion.dev';

const LegacyProdRoot = 'https://api.safetychampion.online';

const ProdRoot = 'https://api3.safetychampion.online';

export const getApiRoot = () => {
  const { API_ROOT } = window as any;

  if (API_ROOT) {
    if (API_ROOT === LegacyProdRoot) return ProdRoot;
    return API_ROOT;
  }
  return Dev1Url;
  // return Dev2Url;
};
axios.defaults.baseURL = getApiRoot();

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axios;
