import React from 'react';

export interface MfaAuthenticatorType {
  id: string;
  method: 'email' | 'sms' | 'app';
  name: string;
}
export interface OhsMfaActiveMethodListTypes {
  authenticators: MfaAuthenticatorType[];
  recovery: MfaAuthenticatorType[];
  recoveryCodes: [];
}

export interface OhsMfaActiveMethodListType {
  authenticator?: MfaAuthenticatorType;
  barcodeUri?: string;
  recoveryCodes: [];
}

export class OhsMfaMethod {
  item: OhsMfaActiveMethodListType;

  constructor(auth?: MfaAuthenticatorType) {
    this.item = {
      authenticator: {
        id: auth?.id ?? '',
        method: auth?.method ?? 'email',
        name: auth?.name ?? '',
      },
      recoveryCodes: [],
    };
  }
}

export interface OhsMfaEnrollStepsTypes {
  id: number;
  title: string;
  content: React.ReactNode;
}

export interface OhsMethodList {
  id?: string;
  title: string;
  method: 'email' | 'sms' | 'app';
  name?: string;
  isEnabled?: boolean;
  icon?: any;
  data?: MfaAuthenticatorType;
  permission: boolean;
}

export type OhsMethods = 'email' | 'sms' | 'app';
