import React from 'react';

import OhsAttachmentListViewer from 'global-components/form/fields/attachments/OhsAttachmentListViewer';

interface Props {
  attachments: any[];
}

export default function Attachments(props: Props) {
  const { attachments } = props;
  return <OhsAttachmentListViewer attachmentList={attachments || []} />;
}
