import _ from 'lodash';

import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import { OhsApiRequestName, RequestType, ApiResponseType } from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';

import OhsXsiRecord from './OhsXsiModels';

export const getXsiList = async (
  orgId: string
): Promise<ApiResponseType<{ items: OhsXsiRecord[] }> | null> => {
  const payload = {
    orgTier: {
      type: TierType.T3,
      _id: orgId,
    },
  };

  return OhsApiRequest(RequestType.Module, ModuleType.Xsi, OhsApiRequestName.List, payload);
};

export const setupXsiIntegration = async (
  email: string,
  xsiId: string,
  orgId: string
): Promise<ApiResponseType<any> | null> => {
  const payload = {
    orgTier: {
      type: TierType.T3,
      _id: orgId,
    },
    _id: xsiId,
    email,
  };

  return OhsApiRequest(RequestType.Module, ModuleType.Xsi, OhsApiRequestName.XsiSetupLink, payload);
};

export const createXsiIntegration = async (payload: any): Promise<ApiResponseType<any> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Xsi, OhsApiRequestName.Create, payload);
};

export const disableEnableXsiIntegration = (xsiId: string, orgId: string, isDisable: boolean) => {
  const payload = {
    orgTier: {
      type: TierType.T3,
      _id: orgId,
    },
    _id: xsiId,
  };

  if (!isDisable) {
    // Setting 'unset' to true will enable
    // a disabled xsi record.
    // Making this absent will disable it.
    _.assign(payload, { unset: true });
  }

  return OhsApiRequest(RequestType.Module, ModuleType.Xsi, OhsApiRequestName.Disable, payload);
};
