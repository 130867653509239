import React from 'react';

import { Box, Link, Typography } from '@mui/material';

import OhsCopyright from './OhsCopyright';

function OhsLoginFooter() {
  return (
    <>
      {/* <Box mt={8}>
        <Typography align="center" variant="h4">
          Scheduled Maintenance
        </Typography>
        <Typography align="center" variant="h6">
          9 PM - 1 AM 6 Oct. 2022 (AEST)
        </Typography>
      </Box> */}
      <Box mt={8}>
        <Typography variant="body2" color="textSecondary" align="center">
          {'Looking for the Safety Champion website? '}
          <Link color="inherit" underline="always" href="https://www.safetychampion.com.au/">
            Click here
          </Link>
        </Typography>
        <OhsCopyright />
      </Box>
    </>
  );
}

export default OhsLoginFooter;
