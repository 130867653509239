import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Box, Container, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import OhsLoginFooter from 'login/OhsLoginFooter';
import { OhsFormLayoutType, OhsFormSettings } from 'global-components/form/services/OhsFormModels';

import scLogo from '../../angular-modules/build/img/sc-logo.png';
import resetUserPasswordSend, {
  ResetPasswordTypes,
  ResetPwStageTypes,
} from './OhsResetPasswordServices';
import OhsResetPasswordForm from './OhsResetPasswordForm';

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '50%',
  },
  error: {
    fontSize: '20px',
    margin: '20px 20px 5px 20px',
  },
  errorReason: {
    fontSize: '14px',
  },
}));

export default function OhsResetPasswordRequest() {
  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();
  const [verifiedToken, setVerifiedToken] = React.useState<string>('');
  const [tokenError, setTokenError] = React.useState<string>('');
  const [verifyingToken, setVerifyingToken] = React.useState<boolean>(false);

  const { classes } = useStyles();

  const ohsFormSettings = new OhsFormSettings(
    { confirmNewPassword: '', newPassword: '' },
    OhsFormLayoutType.AngularModalForm
  );
  const handleResetPassword = async (payload: ResetPasswordTypes) => {
    setVerifyingToken(true);
    const response: any = await resetUserPasswordSend(payload);
    if (token && response.success) {
      setVerifiedToken(token);
      setVerifyingToken(false);
    } else {
      setTokenError(response.error?.message[1]);
      setVerifyingToken(false);
    }
    return response;
  };

  const resetPassword = async (data: any) => {
    const resetPasswordReq = await handleResetPassword({
      stage: ResetPwStageTypes.Reset,
      token: verifiedToken,
      password: data.newPassword,
    });

    if (resetPasswordReq.success) {
      toast.success('Password has been changed successfully !');
      navigate('/login');
    }
  };

  const verifyUserToken = async (currentToken: string) => {
    const verifyTokenReq = await handleResetPassword({
      stage: ResetPwStageTypes.Verify,
      token: currentToken,
    });

    if (verifyTokenReq.success) {
      setVerifiedToken(currentToken);
    }
  };

  React.useEffect(() => {
    if (token) {
      verifyUserToken(token);
    }
  }, [token]);

  return (
    <Container component="main" maxWidth="xs">
      <Box className={classes.paper}>
        <img className={classes.logo} src={scLogo} alt="Safety Champion" />
        {!verifyingToken && verifiedToken !== '' ? (
          <OhsResetPasswordForm ohsFormSettings={ohsFormSettings} resetPassword={resetPassword} />
        ) : (
          !verifyingToken && (
            <>
              <Typography variant="h6" component="div" className={classes.error}>
                Reset password link is no longer valid.
              </Typography>
              <Typography variant="h6" component="div" className={classes.errorReason}>
                {tokenError}
              </Typography>
            </>
          )
        )}

        <OhsLoginFooter />
      </Box>
    </Container>
  );
}
