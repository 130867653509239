import { OhsUser } from 'user/OhsUser';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';
import { TierType } from 'global-services/constants/OhsObject';

import {
  withVisitorSignOutPermission,
  withVisitorSiteEditViewPermission,
  withVisitorFormEditViewPermission,
} from './utils/OhsVisitorPermission';

const commonPermission = (user: OhsUser) => {
  const validUser = user.tier.type === TierType.T3 || user.tier.type === TierType.T4;
  return (validUser && user?.configs?.visitor?.PERMISSIONS.site_view) || false;
};

// visitor module follows a different permission pattern from other modules
const checkPermission = (type: ModuleRoute, user: OhsUser): boolean => {
  const permission = user?.configs?.visitor?.PERMISSIONS;
  const withPermission = () => {
    switch (type) {
      case ModuleRoute.SiteAdd:
        return permission?.site_create;
      case ModuleRoute.FormAdd:
        return permission?.form_create;
      default:
        return false;
    }
  };
  return withPermission() || false;
};

const createRoute = (path: string, permission?: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.Visitor}/${path}`,
  permission,
});

const VisitorRouteList = {
  [ModuleRoute.Home]: createRoute('home/*', commonPermission),

  // QR REDIRECT
  [ModuleRoute.MobileRedirect]: createRoute('org/:orgId/site/:siteId'),

  // SITE
  [ModuleRoute.Site]: createRoute('sites', commonPermission),
  [ModuleRoute.SiteAdd]: createRoute('site/add', (user) =>
    checkPermission(ModuleRoute.SiteAdd, user)
  ),
  [ModuleRoute.SiteView]: createRoute('site/:visitorSiteId/view', commonPermission),
  [ModuleRoute.SiteEdit]: createRoute(
    'site/:visitorSiteId/edit',
    withVisitorSiteEditViewPermission
  ),
  [ModuleRoute.SiteSignInOut]: createRoute('site/:visitorSiteId/sign-in-out/:tierId?', (user) =>
    withVisitorSignOutPermission(user, false)
  ),
  [ModuleRoute.SiteSignOut]: createRoute('site/:visitorSiteId/signout/:visitId', (user) =>
    withVisitorSignOutPermission(user, false)
  ),
  [ModuleRoute.SignOut]: createRoute('signout', (user) =>
    withVisitorSignOutPermission(user, false)
  ),
  [ModuleRoute.UnLoginSign]: createRoute('org/:orgId/site/:siteId', (user) =>
    withVisitorSignOutPermission(user, false)
  ),

  // FORM
  [ModuleRoute.Form]: createRoute('forms', commonPermission),
  [ModuleRoute.FormAdd]: createRoute('form/add', (user) =>
    checkPermission(ModuleRoute.FormAdd, user)
  ),
  [ModuleRoute.FormView]: createRoute('form/:visitorFormId/view', commonPermission),
  [ModuleRoute.FormEdit]: createRoute(
    'form/:visitorFormId/edit',
    withVisitorFormEditViewPermission
  ),

  // PIN
  [ModuleRoute.Pin]: createRoute(
    'pins',
    (user) => (commonPermission(user) && user?.configs?.visitor?.PERMISSIONS.site_view) || false
  ),
};

export default VisitorRouteList;
