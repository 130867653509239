import { ModuleType } from 'global-services/constants/OhsObject';

import { getConfigValue, setPermissions } from '../OhsConfigServices';
import { OhsCusValCategory, OhsTitleCategory } from '../OhsModuleCategory';
import OhsModulePermissions from '../OhsModulePermissions';
import OhsFileBoxConfig from '../filebox/OhsFileBoxConfig';

class OhsAssetConfig {
  TITLE: string;

  PERMISSIONS: OhsModulePermissions;

  MORPH_PERMISSIONS: OhsModulePermissions;

  CONFIDENTIAL_ACCESS: boolean;

  SIGNOFF_HAND_SIGNATURES: boolean;

  TYPE = ModuleType.Asset;

  CLASSIFICATION_LIST: OhsCusValCategory[];

  CREATE_ASSET_LOCATION_LIST: string[];

  RISK_RATING_LIST: OhsTitleCategory[];

  STATUS_LIST: OhsTitleCategory[];

  ISSUED_TO_LIST: string[];

  RISK_MATRIX_IMAGE_LINK: string;

  fileboxConfig?: OhsFileBoxConfig;

  constructor(config: any, fileboxConfig?: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.CONFIDENTIAL_ACCESS = getConfigValue(config, 'CONFIDENTIAL_ACCESS') === true;

    this.SIGNOFF_HAND_SIGNATURES = getConfigValue(config, 'SIGNOFF_HAND_SIGNATURES') === true;

    this.CLASSIFICATION_LIST = getConfigValue(config, 'CLASSIFICATION_LIST');
    this.RISK_RATING_LIST = getConfigValue(config, 'RISK_RATING_LIST');
    this.STATUS_LIST = getConfigValue(config, 'STATUS_LIST');
    this.ISSUED_TO_LIST = getConfigValue(config, 'ISSUED_TO_LIST');
    this.RISK_MATRIX_IMAGE_LINK = getConfigValue(config, 'RISK_MATRIX_IMAGE_LINK');
    this.PERMISSIONS = new OhsModulePermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    this.CREATE_ASSET_LOCATION_LIST = getConfigValue(config, 'CREATE_ASSET_LOCATION_LIST');
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(this.MORPH_PERMISSIONS, getConfigValue(config, 'MORPH_PERMISSIONS'));
    if (fileboxConfig) {
      this.fileboxConfig = new OhsFileBoxConfig(fileboxConfig);
    }
  }
}

export default OhsAssetConfig;
