export default class OhsDocumentsFilterModels {
  documents: {
    viewPreset?: 'view_1' | 'view_2' | 'view_3';

    archived: boolean;

    sort: string;

    categories?: string[];

    subcategories?: string[];

    reviewScheduled?: boolean;

    allocatedToWorkplace?: boolean;

    notAllocated?: boolean;

    workplaceOwned?: boolean;

    tableSortKeyLists: {
      sortColAscDescOptions: { name: string; value: string }[];
    };
  };

  workplace: {
    viewPreset?: 'view_1' | 'view_2' | 'view_3';

    archived: boolean;

    sort: string;

    categories?: string[];

    subcategories?: string[];

    tableSortKeyLists: {
      sortColAscDescOptions: { name: string; value: string }[];
    };
  };

  template: {
    viewPreset?: 'view_1' | 'view_2' | 'view_3';

    archived: boolean;

    sort: string;

    copied?: boolean;

    copiedVersionUpdated?: boolean;

    notCopied?: boolean;

    tableSortKeyLists: {
      sortColAscDescOptions: { name: string; value: string }[];
    };
  };

  constructor() {
    this.documents = {
      viewPreset: undefined,
      archived: false,
      reviewScheduled: false,
      allocatedToWorkplace: true,
      notAllocated: true,
      workplaceOwned: true,
      tableSortKeyLists: {
        sortColAscDescOptions: [
          { name: 'Name: A to Z ', value: '{ "key": "name", "order": 1 }' },
          { name: 'Name: Z to A', value: '{ "key": "name", "order": -1 }' },
          {
            name: 'Date Created: Newest to Oldest',
            value: '{ "key": "_id", "order": -1 }',
          },
          { name: 'Date Created: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },
          { name: 'Document Type: A to Z ', value: '{ "key": "category", "order": 1 }' },
          { name: 'Document Type: Z to A', value: '{ "key": "category", "order": -1 }' },
          { name: 'Issue Date: Newest to Oldest', value: '{ "key": "dateIssued", "order": -1 }' },
          { name: 'Issue Date: Oldest to Newest', value: '{ "key": "dateIssued", "order": 1 }' },
          { name: 'Version Number: A to Z ', value: '{ "key": "vNumber", "order": 1 }' },
          { name: 'Version Number: Z to A', value: '{ "key": "vNumber", "order": -1 }' },
        ],
      },
      sort: '{ "key": "name", "order": 1 }',
    };
    this.workplace = {
      viewPreset: undefined,
      archived: false,
      tableSortKeyLists: {
        sortColAscDescOptions: [
          { name: 'Name: A to Z ', value: '{ "key": "name", "order": 1 }' },
          { name: 'Name: Z to A', value: '{ "key": "name", "order": -1 }' },
          {
            name: 'Date Created: Newest to Oldest',
            value: '{ "key": "_id", "order": -1 }',
          },
          { name: 'Date Created: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },
          { name: 'Document Type: A to Z ', value: '{ "key": "category", "order": 1 }' },
          { name: 'Document Type: Z to A', value: '{ "key": "category", "order": -1 }' },
          { name: 'Issue Date: Newest to Oldest', value: '{ "key": "dateIssued", "order": -1 }' },
          { name: 'Issue Date: Oldest to Newest', value: '{ "key": "dateIssued", "order": 1 }' },
          { name: 'Version Number: A to Z ', value: '{ "key": "vNumber", "order": 1 }' },
          { name: 'Version Number: Z to A', value: '{ "key": "vNumber", "order": -1 }' },
        ],
      },
      sort: '{ "key": "name", "order": 1 }',
    };
    this.template = {
      viewPreset: undefined,
      archived: false,
      copied: true,
      copiedVersionUpdated: true,
      notCopied: true,
      tableSortKeyLists: {
        sortColAscDescOptions: [
          {
            name: 'Date Created: Newest to Oldest',
            value: '{ "key": "_id", "order": -1 }',
          },
          { name: 'Date Created: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },
        ],
      },
      sort: '{ "key": "_id", "order": -1 }',
    };
  }
}
