import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
  OhsApiRequestOptions,
  RequestType,
} from 'global-services/api/OhsApiModels';
import { ModuleType } from 'global-services/constants/OhsObject';
import OhsReviewPlanRecord, {
  OhsReviewPlansFilterPayload,
} from 'review-plan/models/OhsReviewPlanRecord';
import {
  ReviewPlanArchiveResponce,
  OhsReviewPlanTaskActiveList,
  ReviewPlanAllocatedListType,
} from 'review-plan/models/OhsReviewPlanModels';
import { parseHrLookupList } from 'human-resource/OhsHumanResourceServices';
import { parseContractorLookupList } from 'contractor/OhsContractorService';
import OhsContractorLookup from 'contractor/models/OhsContractorLookUp';
import { OhsHrLookup } from 'human-resource/models/OhsHumanResourceModels';
import { SafetyPlanAllocListRes } from 'safety-plan/OhsSafetyPlanModels';
import { OhsAllocationItem } from 'global-components/allocation/OhsAllocatableModels';
import { getCurrentTimezone } from 'global-services/OhsDataParse';

import OhsApiRequest from '../global-services/api/OhsApiRequest';
import { OhsReviewPlanSignOffRecord } from './models/OhsReviewPlanSignOffRecord';
import OhsReviewPlanTaskRecord from './models/OhsReviewPlanTaskRecord';

const getReviewPlansList = (
  filterInfo: OhsReviewPlansFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.ReviewPlan, OhsApiRequestName.List, {
    filter: filterInfo,
  });
};

export const getReviewPlansAllocatedList = (
  ids: string[]
): Promise<ReviewPlanAllocatedListType[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.ReviewPlan, OhsApiRequestName.AllocatedList, {
    _ids: ids,
  });
};

export const getReviewPlan = async (
  _id: string,
  disableErrorToast?: boolean
): Promise<OhsReviewPlanRecord> => {
  const res: OhsReviewPlanRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.ReviewPlan,
    OhsApiRequestName.Fetch,
    { _id },
    { disableErrorToast }
  );
  return res || new OhsReviewPlanRecord();
};

export const addReviewPlanService = (
  payload: OhsReviewPlanRecord,
  options?: OhsApiRequestOptions
): Promise<OhsReviewPlanRecord | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.ReviewPlan,
    OhsApiRequestName.Create,
    payload,
    options
  );
};

export const editReviewPlanService = (
  payload: OhsReviewPlanRecord,
  options?: OhsApiRequestOptions
): Promise<OhsReviewPlanRecord | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.ReviewPlan,
    OhsApiRequestName.Edit,
    payload,
    options
  );
};

export const getHrLookupList = async (): Promise<OhsHrLookup[] | null> => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.ReviewPlan,
    OhsApiRequestName.HRLookup
  );
  const parsedList = parseHrLookupList(res?.length ? res : []);
  return parsedList;
};

export const getContractorLookupList = async (): Promise<OhsContractorLookup[] | null> => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.ReviewPlan,
    OhsApiRequestName.ContractorLookup
  );

  const parsedList = parseContractorLookupList(res);
  return parsedList;
};

export const archiveReviewPlan = async (_id: string): Promise<ReviewPlanArchiveResponce | null> => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.ReviewPlan,
    OhsApiRequestName.Archive,
    {
      _id,
    }
  );
  return res;
};

export const unArchiveReviewPlan = async (
  _id: string
): Promise<ReviewPlanArchiveResponce | null> => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.ReviewPlan,
    OhsApiRequestName.UnArchive,
    {
      _id,
    }
  );
  return res;
};
export const allocateReviewPlan = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = { _id, to: [], tzDateCreated: getCurrentTimezone() };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  } else {
    payload.to = [allocationItem];
  }
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.ReviewPlan,
    OhsApiRequestName.Allocate,
    payload
  );
};

export const deAllocateReviewPlan = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = { _id, to: [], tz: getCurrentTimezone() };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  } else {
    payload.to = allocationItem;
  }
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.ReviewPlan,
    OhsApiRequestName.Deallocate,
    payload
  );
};

export const getReviewPlanAllocationList = (
  docId: string
): Promise<ApiResponseType<SafetyPlanAllocListRes> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.ReviewPlan,
    OhsApiRequestName.AllocationList,
    {
      _id: docId,
    }
  );
};

export const getReviewPlanLinkedActionList = async (_id: string) => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.ReviewPlan,
    OhsApiRequestName.LinkedActions,
    {
      _id,
    }
  );
  return res;
};

// export const getReviewPlanTaskRecord = async (_id: string, taskId: string): Promise<any> => {
//   const res: any | null = await OhsApiRequest(
//     RequestType.Module,
//     ModuleType.Task,
//     OhsApiRequestName.Fetch,
//     {
//       _id: taskId,
//       for: {
//         _id,
//         type: ModuleType.ReviewPlan,
//       },
//     }
//   );
//   return res || new OhsReviewPlanRecord();
// };

export const deleteReviewPlan = (reviewPlanId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.ReviewPlan, OhsApiRequestName.Delete, {
    _id: reviewPlanId,
  });
};

export const getReviewPlanTasksList = async (
  reviewPlanIds: string[]
): Promise<OhsReviewPlanTaskActiveList[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Task, OhsApiRequestName.ListActive, {
    _ids: reviewPlanIds,
  });
};

export const signOffReviewPlan = (
  payload: OhsReviewPlanSignOffRecord
): Promise<OhsReviewPlanSignOffRecord | null> =>
  OhsApiRequest(RequestType.Module, ModuleType.ReviewPlan, OhsApiRequestName.SignOff, payload);

export const getReviewPlanTaskRecord = async (
  _id: string,
  taskId: string,
  disableErrorToast?: boolean
): Promise<OhsReviewPlanTaskRecord> => {
  const res: OhsReviewPlanTaskRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Task,
    OhsApiRequestName.Fetch,
    {
      _id: taskId,
      for: {
        _id,
        type: ModuleType.ReviewPlan,
      },
    },
    { disableErrorToast }
  );
  if (!res) {
    return new OhsReviewPlanTaskRecord();
  }

  return res;
};

export const signOffReviewPlanCancelSave = (reviewPlanId: string, taskId: string) => {
  const payload = {
    _id: reviewPlanId,
    task: {
      _id: taskId,
    },
  };
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.ReviewPlan,
    OhsApiRequestName.SignOffRemoveSave,
    payload
  );
};

export default getReviewPlansList;
