// eslint-disable-next-line max-classes-per-file
import { OhsFilterList } from 'dashboard/topFilter/OhsFilterModels';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { GlobalSearchState } from 'search/OhsSearchModels';
import { OhsUserState } from 'user/userSlice';

export interface OhsTaskType {
  complete: boolean;
  dateDueReference: string;
  _id: string;
}
export interface OhsArchivalDetailsType {
  notes: string;
  date: string;
  tz: string;
}

export interface ArchiveDisplayType extends OhsArchivalDetailsType {
  status: 'Activate' | 'Archive';
  by: OhsModuleCreateBy;
}

export interface OhsTaskForType {
  type: ModuleType.Task;
  _id: string;
  attachments: OhsAttachment[];
  title: string;
  dateExpiry: string;
  dateIssued: string;
  notes: string;
  referenceId: string;
}

export interface OhsOrgTabFilterPayload {
  viewPreset?: 'view_1' | 'view_2' | 'view_3';
  riskRatings: string[];
}

export interface OhsAssetListsFilterPayload {
  viewPreset?: 'view_1' | 'view_2' | 'view_3' | 'view_4';
  categories?: string[] | [];
  riskRatings?: string[] | [];
  workplaces: string[];
  allocatedToOrg?: boolean;
  allocatedToWorkplace?: boolean;
  notAllocated?: boolean;
  archived?: boolean;
  workplaceOwned?: boolean;
}

export interface OhsWorkplaceTabFilterPayload {
  viewPreset?: 'view_1' | 'view_2' | 'view_3';
  categories?: string[] | [];
  riskRatings: string[];
  workplaces: [];
  workplaceOwned: boolean;
  allocatedToWorkplace: boolean;
}

export interface AssetAllocListRes {
  items: AllocatedListType[];
}
export interface AllocatedListType {
  _id: string;
  type: ModuleType;
  allocated: boolean;
}

export class OhsAssetTaskReview {
  reviewDate: string;

  reviewNotes: string;

  reviewDateOption: number;

  constructor() {
    this.reviewDateOption = 0;
    this.reviewNotes = '';
    this.reviewDate = '';
  }
}

export interface OhsAssetTaskActiveList {
  type?: string;
  _id?: string;
  task: {
    type?: string;
    _id: string;
  };
}

export class OhsAssetArchive {
  _id: string;

  title: string;

  referenceId: string;

  archiveDate: any;

  isArchive: boolean;

  notes: string;

  tz: string;

  constructor() {
    this._id = '';
    this.title = '';
    this.referenceId = '';
    this.archiveDate = '';
    this.isArchive = false;
    this.notes = '';
    this.tz = '';
  }
}

export interface OhsAssetsRootState {
  user: OhsUserState;
  globalfilter: {
    filterInfo: OhsFilterList;
  };
  globalSearch: GlobalSearchState;
  asset: AssetListsRecordState;
}

export enum OhsAssetsViewPresets {
  View1AssetsList = 'view_1',
  View2AssetsList = 'view_2',
  View3AssetsList = 'view_3',
  View4AssetsList = 'view_4',
}

export interface AssetListsRecordState {
  isLoading: boolean;
  assetLists: ApiResListsType<any[]> | null;
  allocatedList: AllocatedListType[] | null;
  currentPage: number;
  currentViewPreset: 'view_1' | 'view_2' | 'view_3' | 'view_4';
}
