import { ApiResListsType } from 'global-services/api/OhsApiModels';

import OhsReviewPlanRecord from '../models/OhsReviewPlanRecord';

const parseListAllocations = (
  reviewPlans: ApiResListsType<any[]> | null,
  allocationLists: OhsReviewPlanRecord[] | null
): ApiResListsType<any[]> | null => {
  const spList = reviewPlans?.items ?? [];

  if (spList.length > 0) {
    const reviewPlansWithAllocation = spList.map((item: OhsReviewPlanRecord) => {
      const reviewPlansRecord: OhsReviewPlanRecord = {
        ...item,
        subRows: [],
      };

      allocationLists?.map((allocItem: OhsReviewPlanRecord) => {
        const { allocationOf } = allocItem;
        if (item._id === allocItem._id) {
          reviewPlansRecord.subRows?.push(allocItem);
        }
        if (allocationOf && allocationOf._id === item._id) {
          reviewPlansRecord.subRows?.push(allocItem);
        }
        return null;
      });

      return reviewPlansRecord;
    }) as any[];

    const updateReviewPlansWithAllocation = {
      ...reviewPlans,
      items: reviewPlansWithAllocation,
    } as unknown as ApiResListsType<any[]>;

    return updateReviewPlansWithAllocation;
  }
  return null;
};

export default parseListAllocations;
