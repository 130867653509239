import React from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { Typography } from '@mui/material';

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: '50%',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '22px',
    margin: '20px 0 0 0',
  },
  subtitle: {
    fontSize: '14px',
    margin: '10px 0 10px 0',
    textAlign: 'center',
  },
}));

export type LoginInputs = {
  email: string;
  password: string;
};

type Props = { submitLogin: (data: LoginInputs) => void };

function OhsResetPasswordRequestForm(props: Props) {
  const { submitLogin } = props;
  const { classes } = useStyles();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>({
    defaultValues: {
      email: '',
    },
  });

  let errorMessage = '';

  if (errors.email?.type) {
    if (errors.email?.type === 'isEmail') {
      errorMessage = 'Please provide a valid recovery email address.';
    } else errorMessage = 'Email cannot be empty.';
  }

  return (
    <>
      <Typography variant="h2" component="div" className={classes.title}>
        Safety Champion Password Recovery
      </Typography>
      <Typography variant="h2" component="div" className={classes.subtitle}>
        Enter your account email below and we&apos;ll send you a recovery link.
      </Typography>
      <form className={classes.form}>
        <TextField
          error={errors.email != null}
          variant="outlined"
          margin="normal"
          fullWidth
          label="Username"
          autoComplete="email"
          autoFocus
          {...register('email', {
            required: true,
            validate: { isEmail: (value) => validator.isEmail(value) },
          })}
          helperText={errorMessage || 'The email address you use to login to Safety Champion'}
        />
        <Button
          disabled={errors.email != null}
          fullWidth
          size="large"
          variant="contained"
          color="primary"
          id="loginButtonId"
          data-testid="loginButtonTestId"
          className={classes.submit}
          onClick={handleSubmit((data) => {
            submitLogin(data);
          })}
        >
          Send Recovery Email
        </Button>
        <Grid container>
          <Grid item xs>
            <Link href="/login" variant="body2">
              back
            </Link>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default OhsResetPasswordRequestForm;
