import React from 'react';

import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { FormControl } from '@mui/material';

import { OhsFormSettings, OhsFormLayoutType } from '../services/OhsFormModels';
import OhsTooltip from './OhsTooltip';
import OhsFormFieldWarningMessage from './OhsFormFieldWarningMessage';

interface MessageFrameProps {
  title?: string;
  description?: string | React.ReactNode;
  labelEle?: React.ReactNode;
  noLabel?: boolean;
  required: boolean;
  id: string;
  ohsFormSettings: OhsFormSettings;
  inputEle: React.ReactNode;
  style?: React.CSSProperties;
}

const defaultProps = {
  title: '',
  description: '',
  labelEle: undefined,
  noLabel: false,
  style: {},
};

export enum OhsFormFieldWarningTypes {
  Required = 'required',
  Email = 'isEmail',
  NoEmpty = 'notEmpty',
  NoDuplicated = 'noDuplicated',
  ValidDate = 'validDate',
  MaxDate = 'maxDate',
  MinDate = 'minDate',
  MaxAmount = 'maxAmount',
  MinAmount = 'minAmount',
  MaxLength = 'maxLength',
  MinLength = 'minLength',
  OverSize = 'overSize',
  isNumber = 'isNumber',
  phoneCodeNumComplete = 'phoneCodeNumComplete',
  comparePasswords = 'comparePasswords',
  passwordValidation = 'passwordValidation',
  IsURL = 'isURL',
  ValidMaxFiles = 'validMaxFiles',
  ValidVersion = 'validVersion',
  UploadIncomplete = 'uploadIncomplete',
}

export const useStyles = makeStyles()(() => ({
  errorContainer: {
    background: '#fff6f6',
    borderColor: '#e0b4b4',
    color: '#e0b4b4',
  },
}));

export const invalidUrlErrorMessage =
  'Please provide a valid URL which starts with "https://" or "http://".';

export const invalidEmailErrorMessage = (title: string) => `${title} must be an email.`;
export const invalidEmailErrorDefaultMessage = 'The field must be an email.';

export function formHelperMessage(props: MessageFrameProps): string {
  const error = _.get(props.ohsFormSettings.useFormMethods.formState.errors, props.id);
  if (error != null) {
    switch (error.type) {
      case OhsFormFieldWarningTypes.NoEmpty:
      case OhsFormFieldWarningTypes.Required:
        if (_.isString(error.message) && error.message) {
          return error.message;
        }
        if (props.title) {
          return `${props.title} required.`;
        }
        return 'The field is required.';

      case OhsFormFieldWarningTypes.UploadIncomplete:
        return 'Please wait until file upload is complete';
      case OhsFormFieldWarningTypes.NoDuplicated:
        return 'Only one Contractor can be added as a Risk Owner.';
      case OhsFormFieldWarningTypes.Email:
        if (props.title) return invalidEmailErrorMessage(props.title);
        return invalidEmailErrorDefaultMessage;
      case OhsFormFieldWarningTypes.ValidDate:
      case OhsFormFieldWarningTypes.MaxDate:
      case OhsFormFieldWarningTypes.MinDate:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.MaxLength:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.MinLength:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.MaxAmount:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.MinAmount:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.OverSize:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.isNumber:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.phoneCodeNumComplete:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.comparePasswords:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.passwordValidation:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.IsURL:
        return invalidUrlErrorMessage;
      case OhsFormFieldWarningTypes.ValidMaxFiles:
        return error.message?.toString() ?? '';
      case OhsFormFieldWarningTypes.ValidVersion:
        return error.message?.toString() ?? '';
      default:
        break;
    }
  }

  return '';
}

formHelperMessage.defaultProps = defaultProps;

export default function OhsFormFieldMessageFrame(props: MessageFrameProps) {
  const { classes } = useStyles();
  const error = _.get(props.ohsFormSettings.useFormMethods.formState.errors, props.id);

  return (
    <FormControl style={props.style} component="fieldset" fullWidth>
      {!props.noLabel && props.ohsFormSettings.layout !== OhsFormLayoutType.AngularPageForm && (
        <>
          <span
            style={
              props.ohsFormSettings.layout === OhsFormLayoutType.AngularModalUnderlinedForm
                ? {}
                : { fontWeight: 'bolder' }
            }
          >
            {props.title ? props.title : ''}
            {props.title && props.required === true ? ' * ' : '\u00A0'}
            {props.description && <OhsTooltip message={props.description} iconColor="black" />}
          </span>
          {props.labelEle}
        </>
      )}

      <div className={error != null ? classes.errorContainer : ''}>{props.inputEle}</div>

      {error && <OhsFormFieldWarningMessage>{formHelperMessage(props)}</OhsFormFieldWarningMessage>}
    </FormControl>
  );
}

OhsFormFieldMessageFrame.defaultProps = defaultProps;
