import moment from 'moment';

import { store } from 'app/store';
import { parseOtherDisplayValue } from 'global-services/OhsDataParse';
import { OhsTime } from 'global-services/constants/OhsTimeModels';
import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { ModuleRoute } from 'global-services/constants/OhsRoutes';

export const limitStrLength = (text: string, max_length: number) => {
  if (text.length > max_length - 3) {
    return `${text.substring(0, max_length).trimEnd()} ...`;
  }
  return text;
};

export const removeNonNumeric = (str: string | number) => {
  if (typeof str === 'number') return str;
  const newValue = str.replace(/[^\d.-]/g, '');
  return +newValue;
};

export const removeInvalidExcelCharacters = (title: string) => {
  // Characters not allowed as Excel titles
  const invalidChars = /[[\]:*?,/\\]/g;

  // Replace invalid characters with an empty string
  return title.replace(invalidChars, '');
};

export const getOrganizationByID = (id: string) => {
  const { user } = store.getState().user;
  const availableWorkplaces = user?.configs.admin2?.LOWER_TIER_ACCESS;

  if (id) {
    let workplaceName = '';
    if (id === user?.tier._id) {
      workplaceName = user.tier.name;
    } else if (availableWorkplaces) {
      availableWorkplaces.forEach((workplaceObj: { _id: string; name: string }) => {
        if (id === workplaceObj._id) {
          workplaceName = workplaceObj.name;
        }
      });
    } else if (id === user?.org?._id) {
      workplaceName = user.org.name;
    }
    return workplaceName;
  }
  return '';
};

export const shortenName = (name: string | null | undefined): string => {
  if (name && name.length) {
    if (name.length > 30) return `${name.substring(0, 30)}...`;
    return name;
  }
  return '';
};

export const displayTime = ({ hours, minutes }: OhsTime) =>
  moment({ hours, minutes }).format('hh:mmA');

export const displayBoolean = (value: boolean) => (value ? 'Yes' : 'No');

export const displayDateAndTZ = (date: string, tz: string) => `${date} (${tz})`;

export const displayDateTimeTZ = (date: string, time: OhsTime, tz: string) =>
  `${date} ${displayTime(time)} ${tz}`;

export const parseOtherDisplayValuePairs = (
  value1: string | null,
  otherValue1: string | null,
  value2: string | null,
  otherValue2: string | null,
  valueOnly?: boolean | undefined
) => {
  return `${parseOtherDisplayValue(value1, otherValue1, valueOnly)}${
    value2 ? `: ${parseOtherDisplayValue(value2, otherValue2, valueOnly)}` : ''
  }`;
};

export const base64ToBlob = (base64String: string, contentType = '') => {
  const byteCharacters = atob(base64String);
  const byteArrays = [];

  for (let i = 0; i < byteCharacters.length; i += 1) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }

  const byteArray = new Uint8Array(byteArrays);
  return new Blob([byteArray], { type: contentType });
};

export const removeEmptyFields = (obj: any, skipKeys?: string[]) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      // Skip if the key is in the skipKeys list
      if (skipKeys && skipKeys.includes(key)) {
        return true;
      }
      // Remove if the value is "", null, undefined, or an empty array
      return (
        value !== '' &&
        value !== null &&
        value !== undefined &&
        !(Array.isArray(value) && value.length === 0)
      );
    })
  );
};

// triggers all target fields and set focus on each errors
export const triggerCustomValidation = async (
  ohsFormSettings: OhsFormSettings,
  target: string | string[],
  onSuccess: () => void
) => {
  await ohsFormSettings.useFormMethods.trigger(target);
  const { errors } = ohsFormSettings.useFormMethods.formState;
  const firstError = Object.keys(errors)[0];

  if (!firstError) {
    onSuccess();
  } else {
    ohsFormSettings.useFormMethods.setFocus(firstError);
  }
};

export const getRouteMessages = (articleAndModuleName: string, moduleName: string) => {
  const routeMessages = [
    { route: ModuleRoute.Home, message: `view ${moduleName} records` },
    { route: ModuleRoute.Details, message: `view ${articleAndModuleName} detail` },
    { route: ModuleRoute.View, message: `view ${articleAndModuleName} detail` },
    { route: ModuleRoute.Add, message: `create ${articleAndModuleName} record` },
    { route: ModuleRoute.Edit, message: `edit ${articleAndModuleName} record` },
    { route: ModuleRoute.Allocate, message: `edit ${articleAndModuleName} record` },
    { route: 'sign-off', message: `sign-off this ${moduleName} task` },
    { route: 'signoff', message: `sign-off this ${moduleName} task` },
    { route: 'completed', message: `view ${articleAndModuleName} task` },
    { route: ModuleRoute.Settings, message: 'view settings' },
    { route: 'task/active', message: 'view tasks' },
    { route: 'ai/report', message: `create ${articleAndModuleName} report` },
    { route: 'report', message: `edit ${articleAndModuleName} report` },
  ];

  return routeMessages;
};
