import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
  RequestType,
} from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import { OhsAllocationItem } from 'global-components/allocation/OhsAllocatableModels';
import { getCurrentTimezone } from 'global-services/OhsDataParse';

import {
  NoticeBoardFormArchiveResponce,
  NoticeboardAllocListRes,
  OhsNoticeBoardListsFilterPayload,
  OhsNoticeboardFormRecord,
} from './model/OhsNoticeBoardModel';
import parseListAllocations from './utils/OhsParseAllocationList';
import OhsNoticeBoardRecord from './model/OhsNoticeBoardRecord';

export const addNoticeBoard = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.NoticeBoardCreate,
    payload
  );
};

export const editNoticeBoard = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.NoticeBoardEdit,
    payload
  );
};

const getNoticeBoardLists = (
  filterInfo: OhsNoticeBoardListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.NoticeBoardList,
    {
      filter: filterInfo,
    }
  );
};

export const getNoticeBoardAllocatedList = (
  noticeBoardListIDs: string[]
): Promise<OhsNoticeBoardRecord[] | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.NoticeBoardAllocatedList,
    {
      _ids: noticeBoardListIDs,
    }
  );
};

export const archiveUnarchiveNoticeBoard = (
  noticeboardId: string | undefined,
  archived: boolean
) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    archived ? OhsApiRequestName.NoticeBoardAchive : OhsApiRequestName.NoticeBoardUnArchive,
    {
      _id: noticeboardId,
    }
  );
};

export const editFormBlockList = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.BlockListEdit,
    payload
  );
};

export const fetchFormNoticeBoard = (formId: string | undefined) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Noticeboard, OhsApiRequestName.FormFetch, {
    _id: formId,
  });
};

export const getNoticeBoardListsWithAllocations = (
  filterInfo: OhsNoticeBoardListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const getNoticeBoardListsReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const noticeBoardLists = await getNoticeBoardLists(filterInfo);

    // exit if list result is empty
    if (noticeBoardLists && noticeBoardLists?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(noticeBoardLists);
      });
    }
    // get allocated records from noticeBoardlist _ids
    const getIDs: string[] = [];
    noticeBoardLists?.items.forEach((item: OhsNoticeBoardRecord) => {
      if (item._id && item.tier?.type === TierType.T3) getIDs.push(item._id);
    });
    if (getIDs && getIDs.length === 0) {
      return new Promise((resolve) => {
        resolve(noticeBoardLists);
      });
    }
    const allocatedList = await getNoticeBoardAllocatedList(getIDs);
    // map allocated records to noticeboardlist
    const noticeBoardListWithAllocations = parseListAllocations(
      noticeBoardLists,
      allocatedList
    ) as ApiResListsType<OhsNoticeBoardRecord[]> | null;

    return new Promise((resolve) => {
      resolve(noticeBoardListWithAllocations);
    });
  };

  return getNoticeBoardListsReq();
};

export const getNoticeBoardRecordConfig = (_id: string): Promise<any> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Noticeboard, OhsApiRequestName.BlockList, {
    _id,
  });
};

export const fetchNoticeBoardForm = (_id: string): Promise<any> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Noticeboard, OhsApiRequestName.FormFetch, {
    _id,
  });
};

export const formSubmit = (payload: any): Promise<any> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.FormSubmit,
    payload
  );
};

export const getNoticeBoardRecordDetails = async (_id: string): Promise<OhsNoticeBoardRecord> => {
  const res: OhsNoticeBoardRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.NoticeBoardFetch,
    { _id }
  );
  return res || new OhsNoticeBoardRecord();
};

export const getNoticeBoardFormList = (
  filterInfo: OhsNoticeBoardListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Noticeboard, OhsApiRequestName.ListForms, {
    filter: filterInfo,
  });
};

export const getNoticeBoardAllocationList = (
  noticeboardId: string
): Promise<ApiResponseType<NoticeboardAllocListRes> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.NoticeBoardAllocationList,
    {
      _id: noticeboardId,
    }
  );
};

export const allocateNoticeBoard = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = {
    _id,
    to: [],
    tzDateCreated: getCurrentTimezone(),
  };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  } else {
    payload.to = [allocationItem];
  }

  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.NoticeBoardAllocate,
    payload
  );
};

export const deAllocateNoticeBoard = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = {
    _id,
    to: [],
    tz: getCurrentTimezone(),
  };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  } else {
    payload.to = [allocationItem];
  }
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.NoticeBoardDeallocate,
    payload
  );
};

export const getNoticeBoardBlockList = async (_id: string): Promise<any> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.BlockListFetch,
    { _id }
  );
};

export const addNoticeBoardFormService = (
  payload: OhsNoticeboardFormRecord
): Promise<OhsNoticeboardFormRecord | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.FormCreate,
    payload
  );
};

export const editNoticeBoardFormService = (
  payload: OhsNoticeboardFormRecord
): Promise<OhsNoticeboardFormRecord | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.FormEdit,
    payload
  );
};

export const getSingleAttachmentDetail = async (id: string) => {
  const res: { items: any } | null = await OhsApiRequest(
    RequestType.Attachments,
    ModuleType.Attachment,
    OhsApiRequestName.Fetch,
    { _id: id }
  );
  return res;
};

export const bulkFetchVdoc = async (id: string) => {
  const res: { items: any } | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    OhsApiRequestName.BulkFetchVdoc,
    { _id: id }
  );
  return res;
};

export const archiveUnarchiveNoticeBoardForm = async (
  _id: string,
  archived: boolean
): Promise<NoticeBoardFormArchiveResponce | null> => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Noticeboard,
    archived ? OhsApiRequestName.FormArchive : OhsApiRequestName.FormUnArchive,
    {
      _id,
    }
  );
  return res;
};

export default getNoticeBoardLists;
