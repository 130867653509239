import { OhsUser } from 'user/OhsUser';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';

const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.Task}/${path}`,
  permission,
});

const OhsTaskRouteList = {
  [ModuleRoute.Active]: createRoute('active', () => true),
  [ModuleRoute.LinkRedirect]: createRoute('link/redirect', () => true),
};

export default OhsTaskRouteList;
