import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsUserState } from 'user/userSlice';
import { OhsFilterList } from 'dashboard/topFilter/OhsFilterModels';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { GlobalSearchState } from 'search/OhsSearchModels';

import OhsChemicalRecord from './OhsChemicalRecord';

export interface OhsChemicalCustomFilters {
  workplaceOwned?: boolean;
  allocatedToOrg?: boolean;
  allocatedToWorkplace?: boolean;
  notAllocated?: boolean;
  archived?: boolean;
  workplaces?: string[];
  locations?: string[];
  locations_EMPTY?: boolean;
  next?: string;
}

export interface OhsChemicalListsFilterPayload extends OhsChemicalCustomFilters {
  viewPreset?: 'view_1' | 'view_2' | 'view_3' | 'view_4';
  page?: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
}

export interface OhsChemicalRootState {
  user: OhsUserState;
  globalfilter: {
    filterInfo: OhsFilterList;
  };
  globalSearch: GlobalSearchState;
  chemical: ChemicalListsRecordState;
}

export interface ChemicalAllocListRes {
  items: AllocatedListType[];
}
export interface AllocatedListType extends OhsChemicalRecord {
  _id: string;
  type: ModuleType;
  allocated: boolean;
}

export enum OhsChemicalViewPresets {
  View1ChemicalList = 'view1chemicalList',
  View2ChemicalList = 'view2chemicalList',
  View3ChemicalList = 'view3chemicalList',
  View4ChemicalList = 'view4chemicalList',
}
export interface ChemicalListsRecordState {
  isLoading: boolean;
  chemicalList: ApiResListsType<any[]> | null;
  currentPage: number;
  currentViewPreset: OhsChemicalViewPresets;
}

export enum ChemicalSignoffStatus {
  Active = 'active', // save or sign off
  Completed = 'completed', // update sign off
  Invalid = 'invalid', // invalid
}
