import { ModuleType } from 'global-services/constants/OhsObject';
import {
  OhsApiRequestName,
  RequestType,
  ApiResListsType,
  OhsApiRequestOptions,
} from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';

import {
  OhsActionFilterPayload,
  OhsActionTaskActiveList,
  OhsActionsRecord,
} from './OhsActionModels';
import OhsActionAddModel from './OhsActionAddModel';
import { OhsActionTaskRecord } from './OhsActionTaskRecord';

export const addAction = async (payload: OhsActionAddModel, options: OhsApiRequestOptions = {}) => {
  const res: OhsActionsRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Action,
    OhsApiRequestName.Create,
    payload,
    options
  );
  return res;
};

export const editAction = (payload: OhsActionsRecord, options: OhsApiRequestOptions = {}) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Action,
    OhsApiRequestName.Edit,
    payload,
    options
  );
};

export const editActionTask = (
  payload: OhsActionTaskRecord,
  options: OhsApiRequestOptions = {}
) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Action,
    OhsApiRequestName.EditTask,
    payload,
    options
  );
};

export const getActionLists = (
  filterInfo: OhsActionFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Action, OhsApiRequestName.List, {
    filter: filterInfo,
  });
};
export const getActionTasksList = (
  actionIDs: string[]
): Promise<OhsActionTaskActiveList[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Task, OhsApiRequestName.ListActive, {
    _ids: actionIDs,
  });
};
const parseActionTaskLists = (
  actionLists: ApiResListsType<any[]> | null,
  taskList: OhsActionTaskActiveList[] | null
): ApiResListsType<any[]> | null => {
  const acList = actionLists?.items ?? [];

  if (acList.length > 0) {
    const actionWithTasks = acList.map((item: OhsActionsRecord) => {
      const actionRecord: OhsActionsRecord = {
        ...item,
        taskId: '',
        taskActive: false,
      };

      taskList?.map((taskItem: OhsActionTaskActiveList) => {
        if (item._id === taskItem._id) {
          actionRecord.taskId = taskItem.task._id;
          actionRecord.taskActive = true;
        }
        return null;
      });

      return actionRecord;
    }) as any[];
    const updateActionTasksList = {
      ...actionLists,
      items: actionWithTasks,
    } as unknown as ApiResListsType<any[]>;

    return updateActionTasksList;
  }
  return null;
};
export const getActionListsWithTasks = (
  filterInfo: OhsActionFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const getActionListsReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const actionLists = await getActionLists(filterInfo);
    if (actionLists && actionLists?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(actionLists);
      });
    }
    const getIDs = actionLists?.items.map((item: OhsActionsRecord) => item._id) as string[];
    const taskList = await getActionTasksList(getIDs);

    const actionListsWithTasks = parseActionTaskLists(actionLists, taskList) as ApiResListsType<
      OhsActionsRecord[]
    > | null;

    return new Promise((resolve) => {
      resolve(actionListsWithTasks);
    });
  };

  return getActionListsReq();
};
export const moveAction = (payload: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Action, OhsApiRequestName.Move, payload);
};

export const getAction = async (
  _id: string,
  disableErrorToast?: boolean
): Promise<OhsActionsRecord> => {
  const res: OhsActionsRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Action,
    OhsApiRequestName.Fetch,
    { _id },
    { disableErrorToast }
  );
  return res || new OhsActionsRecord();
};

export const archiveAction = (
  actionId: string,
  archived: boolean,
  timeZone: string,
  actionComment: string
) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Action,
    archived ? OhsApiRequestName.Archive : OhsApiRequestName.UnArchive,
    {
      _id: actionId,
      tz: timeZone,
      comment: actionComment,
    }
  );
};
export const getActionTaskRecord = async (
  _id: string,
  taskId: string,
  disableErrorToast?: boolean
): Promise<OhsActionTaskRecord> => {
  const res: OhsActionTaskRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Task,
    OhsApiRequestName.Fetch,
    {
      _id: taskId,
      for: {
        _id,
        type: ModuleType.Action,
      },
    },
    { disableErrorToast }
  );
  return res || new OhsActionTaskRecord();
};
export const signOffAction = (payload: any) =>
  OhsApiRequest(RequestType.Module, ModuleType.Action, OhsApiRequestName.SignOff, payload);

export const getActionLinksByIds = async (_ids: string[]): Promise<OhsActionsRecord[]> => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Action,
    OhsApiRequestName.Enhance,
    { _ids }
  );
  return res;
};
