import React from 'react';

import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';

export interface OhsTooltipProps {
  message: string | React.ReactNode;
  backgroundColor?: string;
  textColor?: string;
  iconColor?: string;
  children?: React.ReactElement<any, any>;
}

const HtmlTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ),
  {
    shouldForwardProp(propName) {
      return propName !== 'backgroundColor' && propName !== 'textColor';
    },
  }
)<{ backgroundColor?: string; textColor?: string }>(({ theme, backgroundColor, textColor }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: backgroundColor || theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: backgroundColor || theme.palette.common.white,
    color: textColor || theme.palette.common.black,
    fontSize: '13px',
    padding: '8px',
    lineHeight: '18px',
    border: '1px solid #d4d4d5',
    boxShadow: '0 2px 4px 0 rgba(34, 36, 38, 0.12), 0 2px 10px 0 rgba(34, 36, 38, 0.15)',
  },
}));

export default function OhsTooltip(props: OhsTooltipProps) {
  return (
    <HtmlTooltip
      title={props.message}
      placement="top"
      arrow
      backgroundColor={props.backgroundColor}
      textColor={props.textColor}
    >
      {props.children ? (
        props.children
      ) : (
        <HelpOutlinedIcon sx={{ fontSize: '16px', color: props.iconColor, cursor: 'pointer' }} />
      )}
    </HtmlTooltip>
  );
}

OhsTooltip.defaultProps = {
  iconColor: 'gray',
  children: undefined,
  backgroundColor: 'white',
  textColor: 'black',
};
