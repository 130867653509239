class OhsImportPermissions {
  view: boolean;

  create: boolean;

  edit: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.create = flag;
    this.edit = flag;
  }
}

export default OhsImportPermissions;
