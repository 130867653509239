export default class OhsDashboardBannerFilterModels {
  sort: string;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  constructor() {
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'ID: High to Low ', value: '{ "key": "_id", "order": -1 }' },
        { name: 'ID: Low to High', value: '{ "key": "_id", "order": 1 }' },
        { name: 'Start Date: Newest to Oldest', value: '{ "key": "startDate", "order": -1 }' },
        { name: 'Start Date: Oldest to Newest', value: '{ "key": "startDate", "order": 1 }' },
        { name: 'End Date: Newest to Oldest', value: '{ "key": "endDate", "order": -1 }' },
        { name: 'End Date: Oldest to Newest', value: '{ "key": "endDate", "order": 1 }' },
      ],
    };
    this.sort = '{ "key": "_id", "order": -1 }';
  }
}
