import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { UseFormRegisterReturn } from 'react-hook-form';

const useStyles = makeStyles()({
  container: {
    position: 'relative',
    opacity: 0,
    height: '0px',
    width: '0px',
  },
  hiddenInput: {
    position: 'absolute',
    opacity: 0,
    height: '0px',
    width: '0px',
  },
});

interface Props {
  register: UseFormRegisterReturn;
}

function OhsHiddenField(props: Props) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <input
        className={classes.hiddenInput}
        {...props.register}
        autoComplete="off"
        onChange={() => {}}
      />
    </div>
  );
}

export default OhsHiddenField;
