import moment from 'moment';
import _ from 'lodash';

export const dateDiffOnly = (date: string) => {
  const now = moment(new Date()).startOf('day');
  const due = moment(new Date(date)).startOf('day');
  return moment(due).diff(now, 'days');
};

export const dateDiffBetween = (date1: string, date2: string) => {
  const now = moment(new Date(date1)).startOf('day');
  const due = moment(new Date(date2)).startOf('day');
  return moment(due).diff(now, 'days');
};

export const getTodayDateString = () => {
  const d = new Date();
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export const getCurrentTime = () => {
  const currentTime = moment();

  const formattedTime = {
    hours: currentTime.hours(),
    minutes: currentTime.minutes(),
  };

  return formattedTime;
};

export const isValidDate = (dateString: string) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;
  if (!dateString.match(regEx)) return false; // Invalid format
  const d = new Date(dateString);
  const dNum = d.getTime();
  if (!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0, 10) === dateString;
};

export const toDate = (input: string) => {
  if (input === undefined) return moment();
  const date = new Date(input);
  if (date.toString() === 'Invalid Date') {
    return input;
  }
  return moment(date).format('YYYY-MM-DD');
};

export const toLocalTime = (utcString: string) => {
  if (utcString) {
    const time = moment(new Date(utcString));
    return time.format('YYYY-MM-DD HH:mm:ss');
  }
  return '';
};

export const compareStrings = (
  options: Array<'ignoreInBetweenSpace' | 'ignoreWhitespace' | 'ignoreCase'>,
  arg1: string,
  arg2: string
) => {
  if (!_.isString(arg1) || !_.isString(arg2)) {
    return false;
  }

  let a = _.cloneDeep(arg1);
  let b = _.cloneDeep(arg2);

  if (options && _.includes(options, 'ignoreInBetweenSpace')) {
    b = b.replace(/\s{2,}/g, ' ');
  }

  if (options && _.includes(options, 'ignoreWhitespace')) {
    a = a.replace(/\s/g, '');
    b = b.replace(/\s/g, '');
  }

  if (options && _.includes(options, 'ignoreCase')) {
    a = a.toLowerCase();
    b = b.toLowerCase();
  }

  return a === b;
};

export const formatDateTime = (time: string) => moment(time).format('YYYY-MM-DD hh:mm a');
