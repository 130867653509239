import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsFilterList } from 'dashboard/topFilter/OhsFilterModels';
import { OhsUserState } from 'user/userSlice';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { GlobalSearchState } from 'search/OhsSearchModels';

import OhsVisitorSiteRecord from './site/models/OhsVisitorSiteRecord';

export enum OhsVisitorViewPresets {
  View1VisitorSiteList = 'view1VisitorSiteList',
  View2VisitorSiteList = 'view2VisitorSiteList',
  View1VisitorSiteActivityList = 'view1VisitorSiteActivityList',
  View1VisitorFormList = 'view1VisitorFormList',
  View1VisitorPinList = 'view1VisitorPinList',
}

export interface OhsVisitorRootState {
  user: OhsUserState;
  globalfilter: {
    filterInfo: OhsFilterList;
  };
  visitor: VisitorListsRecordState;
  globalSearch: GlobalSearchState;
}

export interface AllocatedListType extends OhsVisitorSiteRecord {
  _id: string;
  type: ModuleType;
  allocated: boolean;
  latestCompletedTask: string | null;
}

export interface VisitorListsRecordState {
  isLoading: boolean;
  visitorSiteList: ApiResListsType<any[]> | null;
  visitorSiteActivityList: ApiResListsType<any[]> | null;
  visitorFormList: ApiResListsType<any[]> | null;
  visitorPinList: any[] | null;
  allocatedList: AllocatedListType[] | null;
  currentPage: number;
  currentViewPreset: OhsVisitorViewPresets;
}

export interface OhsVisitorCustomFilters {
  workplaceOwned?: boolean;
  allocatedToOrg?: boolean;
  allocatedToWorkplace?: boolean;
  notAllocated?: boolean;
  archived?: boolean;
  categories?: string[];
  workplaces?: string[];
  next?: string;
}

export interface OhsVisitorFilterPayload extends OhsVisitorCustomFilters {
  viewPreset?: 'view_1' | 'view_2';
  page: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
}

export interface VisitorAllocListRes {
  items: AllocatedListType[];
}
