import { ModuleType } from 'global-services/constants/OhsObject';

import { getConfigValue, setPermissions } from '../OhsConfigServices';
import OhsTrainingPermissions from './OhsTrainingPermissions';

class OhsTrainingConfig {
  TITLE: string;

  PERMISSIONS: OhsTrainingPermissions;

  NAME_LIST: string[];

  TYPE = ModuleType.Training;

  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsTrainingPermissions(false);
    this.NAME_LIST = getConfigValue(config, 'NAME_LIST');
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}

export default OhsTrainingConfig;
