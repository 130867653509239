import _ from 'lodash';

import { ModuleConfig, ModuleType } from 'global-services/constants/OhsObject';
import { OhsUser } from 'user/OhsUser';
import { getModuleName } from 'user/OhsUserServices';

export interface ReportsRouteTypes {
  key?: string;
  title: string;
  url: string;
  permission: boolean;
}

export const getReportsRouteList = (user: OhsUser) =>
  [
    {
      title: getModuleName(user?.configs, ModuleType.Incident),
      url: `/reporting/superset/${ModuleConfig.Incident}`,
      permission: user?.configs.incident?.PERMISSIONS.view === true,
    },
    {
      title: getModuleName(user?.configs, ModuleType.Inspection),
      url: `/reporting/superset/${ModuleConfig.Inspection}`,
      permission: user?.configs.inspection?.PERMISSIONS.view === true,
    },
    {
      title: getModuleName(user?.configs, ModuleType.Action),
      url: `/reporting/superset/${ModuleConfig.Action}`,
      permission: user?.configs.action?.PERMISSIONS.view === true,
    },
    {
      title: _.startCase(`${ModuleConfig.Task}s`),
      url: `/reporting/superset/${ModuleConfig.Task}`,
      permission: true,
    },
  ] as ReportsRouteTypes[];

export const getBusinessIntelligenceRouteList = (user: OhsUser) =>
  [
    {
      title: _.startCase(`${ModuleConfig.Task}s`),
      url: `/reporting/powerbi/${ModuleConfig.Task}`,
      permission: true,
    },
    {
      title: getModuleName(user?.configs, ModuleType.Incident),
      url: '/reporting/powerbi/incident',
      permission: user?.configs.incident?.PERMISSIONS.view === true,
    },
    {
      title: getModuleName(user?.configs, ModuleType.Action),
      url: '/reporting/powerbi/action',
      permission: user?.configs.action?.PERMISSIONS.view === true,
    },
    {
      title: getModuleName(user?.configs, ModuleType.Inspection),
      url: '/reporting/powerbi/inspection',
      permission: user?.configs.inspection?.PERMISSIONS.view === true,
    },
  ] as ReportsRouteTypes[];
