class OhsCorrespondencePermissions {
  view_prose: boolean;

  create_prose: boolean;

  edit_prose: boolean;

  archive_prose: boolean;

  view_roll: boolean;

  create_roll: boolean;

  edit_roll: boolean;

  archive_roll: boolean;

  delete_roll: boolean;

  setup_exchange: boolean;

  send_exchange: boolean;

  archive_exchange: boolean;

  form_archive: boolean;

  form_create: boolean;

  form_edit: boolean;

  delete_exchange: boolean;

  constructor(flag: boolean) {
    this.view_prose = flag;
    this.create_prose = flag;
    this.edit_prose = flag;
    this.archive_prose = flag;
    this.view_roll = flag;
    this.create_roll = flag;
    this.edit_roll = flag;
    this.archive_roll = flag;
    this.delete_roll = flag;
    this.setup_exchange = flag;
    this.send_exchange = flag;
    this.archive_exchange = flag;
    this.delete_exchange = flag;
    this.form_archive = flag;
    this.form_create = flag;
    this.form_edit = flag;
  }
}

export default OhsCorrespondencePermissions;
