import ModuleHomeUrl from 'dashboard/leftSideDrawer/ModuleHomeUrl';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';

import { OhsUser } from '../../user/OhsUser';
import OhsBreadcrumbsNameAndRef from './OhsBreadcrumbsNameAndRef';

const OhsBreadCrumbReviewplan = (
  ohsBreadcrumbsNameAndRef: OhsBreadcrumbsNameAndRef,
  modulePath: string,
  subPath: string,
  viewPath: string,
  operationPath: string,
  user: OhsUser
) => {
  if (subPath === 'home') {
    ohsBreadcrumbsNameAndRef.leafName = {
      name: user.configs.reviewplan?.TITLE ?? '',
    };
  } else if (operationPath.indexOf('/details') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).reviewplan,
      name: user.configs.reviewplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View' };
  } else if (operationPath.indexOf('completed') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).reviewplan,
      name: user.configs.reviewplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?tab=${OhsTabKeyTypes.Details}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Evidence' };
  } else if (subPath === 'add') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).reviewplan,
      name: user.configs.reviewplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'New' };
  } else if (viewPath === 'edit') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).reviewplan,
      name: user.configs.reviewplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?tab=${OhsTabKeyTypes.Details}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Edit' };
  } else if (viewPath === 'view') {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).reviewplan,
      name: user.configs.reviewplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View' };
  } else if (operationPath.indexOf('signoff') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).reviewplan,
      name: user.configs.reviewplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?tab=${OhsTabKeyTypes.Details}&type=orgReviewPlan`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Signoff' };
  }

  return ohsBreadcrumbsNameAndRef;
};
export default OhsBreadCrumbReviewplan;
