import ModuleHomeUrl from 'dashboard/leftSideDrawer/ModuleHomeUrl';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';

import { OhsUser } from '../../user/OhsUser';
import OhsBreadcrumbsNameAndRef from './OhsBreadcrumbsNameAndRef';

const OhsBreadcrumbAsset = (
  ohsBreadcrumbsNameAndRef: OhsBreadcrumbsNameAndRef,
  modulePath: string,
  subPath: string,
  viewPath: string,
  operationPath: string,
  user: OhsUser
) => {
  const defaultModuleRef = {
    href: (ModuleHomeUrl[user.tier.type] as any).asset,
    name: user.configs.asset?.TITLE ?? '',
  };

  if (subPath === 'home') {
    ohsBreadcrumbsNameAndRef.leafName = {
      name: defaultModuleRef?.name,
    };
  } else if (viewPath === 'view') {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModuleRef;
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View' };
  } else if (operationPath.indexOf('edit') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModuleRef;
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/view`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Edit' };
  } else if (operationPath.indexOf('signoff') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModuleRef;
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/view`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Signoff' };
  } else if (operationPath.indexOf('completed') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModuleRef;
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/view?tab=${OhsTabKeyTypes.History}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Evidence' };
  } else if (subPath === 'add') {
    ohsBreadcrumbsNameAndRef.moduleRef = defaultModuleRef;
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Add' };
  }
  return ohsBreadcrumbsNameAndRef;
};
export default OhsBreadcrumbAsset;
