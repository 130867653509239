import { Message } from '../../models/OhsVisitorMessage';

class FormMessages {
  pre: Message | null;

  in: Message | null;

  post: Message | null;

  constructor() {
    this.pre = null;
    this.in = null;
    this.post = null;
  }
}

export default FormMessages;
