import { OhsFilterList } from 'dashboard/topFilter/OhsFilterModels';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { GlobalSearchState } from 'search/OhsSearchModels';
import { OhsUserState } from 'user/userSlice';

export interface OhsTaskType {
  complete: boolean;
  dateDueReference: string;
  _id: string;
}
export interface OhsArchivalDetailsType {
  notes: string;
  date: string;
  tz: string;
}

export interface ArchiveDisplayType extends OhsArchivalDetailsType {
  status: 'Activate' | 'Archive';
  by: OhsModuleCreateBy;
}

export interface OhsTaskForType {
  type: ModuleType.Task;
  _id: string;
  attachments: OhsAttachment[];
  title: string;
  dateExpiry: string;
  dateIssued: string;
  notes: string;
  referenceId: string;
}

export interface OhsCorporateRiskCustomFilters {
  workplaceOwned?: boolean;
  allocatedToOrg?: boolean;
  allocatedToWorkplace?: boolean;
  notAllocated?: boolean;
  archived?: boolean;
  workplaces?: string[];
  categories?: string[] | [];
  residualRisks?: string[];
  next?: string;
}

export interface OhsCorporateRiskFilterPayload extends OhsCorporateRiskCustomFilters {
  viewPreset?: 'view_1' | 'view_2' | 'view_3' | 'view_4';
  page: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
}

export interface CorporateRiskAllocListRes {
  items: AllocatedListType[];
}
export interface AllocatedListType {
  _id: string;
  type: ModuleType;
  allocated: boolean;
}

export enum OhsCRiskViewPresets {
  View1CRiskList = 'view1CRiskList',
  View2CRiskList = 'view2CRiskList',
  View3CRiskList = 'view3CRiskList',
  View4CRiskList = 'view4CRiskList',
}
export interface CorporateRiskListsRecordState {
  isLoading: boolean;
  corporateRiskLists: ApiResListsType<any[]> | null;
  allocatedList: AllocatedListType[] | null;
  currentPage: number;
  currentViewPreset: OhsCRiskViewPresets;
  currentTab: OhsTabKeyTypes;
}
export const getCRiskListViewPreset = (view: string): OhsCRiskViewPresets | undefined => {
  switch (view) {
    case 'view_1':
      return OhsCRiskViewPresets.View1CRiskList;
    case 'view_2':
      return OhsCRiskViewPresets.View2CRiskList;
    case 'view_3':
      return OhsCRiskViewPresets.View3CRiskList;
    case 'view_4':
      return OhsCRiskViewPresets.View4CRiskList;
    default:
      return undefined;
  }
};
export const OhsCRiskModalButtons = [
  { label: 'Allocate Only', param: 'Allocate Only' },
  { label: 'Allocate & Assign', param: 'Allocate & Assign' },
  { label: 'Assign Only', param: 'Assign Only' },
];

export interface OhsCRiskRootState {
  user: OhsUserState;
  globalfilter: {
    filterInfo: OhsFilterList;
  };
  globalSearch: GlobalSearchState;
  corporateRisk: CorporateRiskListsRecordState;
}
