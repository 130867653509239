import { ModuleType } from 'global-services/constants/OhsObject';
import OhsVisitorRole from 'visitor/models/OhsVisitorRole';

import { OhsModuleCategory } from '../OhsModuleCategory';
import { getConfigValue, setPermissions } from '../OhsConfigServices';
import OhsVisitorPermissions from './OhsVisitorPermissions';

class OhsVisitorConfig {
  TITLE: string;

  VISIT_TERMS: { arrive: string; leave: string };

  PERMISSIONS: OhsVisitorPermissions;

  MORPH_PERMISSIONS: OhsVisitorPermissions;

  SITE_CATEGORY_LIST: OhsModuleCategory[];

  TYPE = ModuleType.Visitor;

  ROLE_LIST: OhsVisitorRole[];

  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.VISIT_TERMS = getConfigValue(config, 'VISIT_TERMS');
    this.PERMISSIONS = new OhsVisitorPermissions(false);
    this.MORPH_PERMISSIONS = new OhsVisitorPermissions(false);
    this.SITE_CATEGORY_LIST = getConfigValue(config, 'SITE_CATEGORY_LIST');
    this.ROLE_LIST = getConfigValue(config, 'ROLE_LIST');
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}

export default OhsVisitorConfig;
