import React from 'react';

import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';

import logo from '../angular-modules/build/img/sc-logo.png';

interface IProps {
  className?: string;
  style?: React.CSSProperties;
}
export default function OhsLogoImg(props: IProps) {
  const user = useAppSelector(getOhsUser);
  if (
    user &&
    user.configs.theme &&
    user.configs.theme.ORGANIZATION_LOGO !== 'http://example.com/undefined.png'
  ) {
    return (
      <img
        className={props.className}
        style={props.style}
        src={user.configs.theme.ORGANIZATION_LOGO}
        alt=""
      />
    );
  }
  return <img className={props.className} style={props.style} src={logo} alt="" />;
}
OhsLogoImg.defaultProps = { className: undefined, style: undefined };
