import { ApiResListsType, OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';

import { OhsOnboardingFilterPayload } from './OhsOnboardingModels';

const getOnboargingList = async (
  filterInfo: OhsOnboardingFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const response: any = await OhsApiRequest(
    RequestType.Admin,
    ModuleType.Freemium,
    OhsApiRequestName.List,
    {
      filter: filterInfo,
    }
  );
  return response;
};

export default getOnboargingList;
