import _ from 'lodash';

import { ModuleType } from 'global-services/constants/OhsObject';

import OhsActionConfig from './action/OhsActionConfig';
import { OhsAdmin2Config } from './admin2/OhsAdmin2Config';
import OhsAssetConfig from './asset/OhsAssetConfig';
import OhsBiConfig from './bi/OhsBiConfig';
import OhsBixConfig from './bi/OhsBixConfig';
import OhsChemicalConfig from './chemical/OhsChemicalConfig';
import OhsContractorConfig from './contractor/OhsContractorConfig';
import OhsCorrespondenceConfig from './correspondence/OhsCorrespondenceConfig';
import OhsExportConfig from './export/OhsExportConfig';
import OhsHrConfig from './hr/OhsHrConfig';
import OhsImportConfig from './import/OhsImportConfig';
import OhsIncidentConfig from './incident/OhsIncidentConfig';
import OhsInspectionConfig from './inspection/OhsInspectionConfig';
import OhsNoticeboardConfig from './noticeboard/OhsNoticeboardConfig';
import OhsReviewplanConfig from './reviewplan/OhsReviewplanConfig';
import OhsCorporateRiskConfig from './risk/OhsCorporateRiskConfig';
import OhsSafetyplanConfig from './safetyplan/OhsSafetyplanConfig';
import OhsThemeConfig from './theme/OhsThemeConfig';
import OhsVdocConfig from './vdoc/OhsVdocConfig';
import OhsVisitorConfig from './visitor/OhsVisitorConfig';
import OhsTrainingConfig from './training/OhsTrainingConfig';
import OhsBookmarkConfig from './bookmark/OhsBookmarkConfig';

export default class OhsConfigurations {
  admin2: OhsAdmin2Config | null;

  incident: OhsIncidentConfig | null;

  action: OhsActionConfig | null;

  inspection: OhsInspectionConfig | null;

  theme: OhsThemeConfig;

  visitor: OhsVisitorConfig | null;

  chemical: OhsChemicalConfig | null;

  correspondence: OhsCorrespondenceConfig | null;

  reviewplan: OhsReviewplanConfig | null;

  safetyplan: OhsSafetyplanConfig | null;

  contractor: OhsContractorConfig | null;

  hr: OhsHrConfig | null;

  vdoc: OhsVdocConfig | null;

  import: OhsImportConfig | null;

  bi: OhsBiConfig | null;

  bix: OhsBixConfig | null;

  noticeboard: OhsNoticeboardConfig | null;

  corporateRisk: OhsCorporateRiskConfig | null;

  asset: OhsAssetConfig | null;

  export: OhsExportConfig | null;

  training: OhsTrainingConfig | null;

  task: any | null;

  bookmark: OhsBookmarkConfig | null;

  constructor(configuration: any) {
    this.admin2 = null;
    const admin2Config = _.find(configuration, { type: ModuleType.Admin2 });
    if (admin2Config) {
      this.admin2 = new OhsAdmin2Config(admin2Config);
    }

    this.action = null;
    const configAction = _.find(configuration, { type: ModuleType.Action });
    if (configAction != null) {
      const actionFileBoxConfig = _.find(configuration, { type: ModuleType.ActionFileBox });
      this.action = new OhsActionConfig(configAction, actionFileBoxConfig);
    }

    this.incident = null;
    const configIncident = _.find(configuration, { type: ModuleType.Incident });
    if (configIncident != null) {
      const incidentFileBoxConfig = _.find(configuration, { type: ModuleType.IncidentFileBox });
      this.incident = new OhsIncidentConfig(configIncident, incidentFileBoxConfig);
    }

    this.inspection = null;
    const configInspection = _.find(configuration, { type: ModuleType.Inspection });
    if (configInspection) {
      this.inspection = new OhsInspectionConfig(configInspection);
    }

    const configTheme = _.find(configuration, { type: ModuleType.Theme });
    this.theme = new OhsThemeConfig(configTheme);

    this.visitor = null;
    const configVisitor = _.find(configuration, { type: ModuleType.Visitor });
    if (configVisitor) {
      this.visitor = new OhsVisitorConfig(configVisitor);
    }

    this.chemical = null;
    const configChemical = _.find(configuration, { type: ModuleType.Chemical });
    if (configChemical != null) {
      const chemicalFileBoxConfig = _.find(configuration, { type: ModuleType.ChemicalFileBox });
      this.chemical = new OhsChemicalConfig(configChemical, chemicalFileBoxConfig);
    }

    this.correspondence = null;
    const configCorrespondence = _.find(configuration, { type: ModuleType.Correspondence });
    if (configCorrespondence) {
      this.correspondence = new OhsCorrespondenceConfig(configCorrespondence);
    }

    this.reviewplan = null;
    const configReviewplan = _.find(configuration, { type: ModuleType.ReviewPlan });
    if (configReviewplan) {
      const reviewPlanFileBoxConfig = _.find(configuration, { type: ModuleType.ReviewPlanFileBox });
      this.reviewplan = new OhsReviewplanConfig(configReviewplan, reviewPlanFileBoxConfig);
    }

    this.safetyplan = null;
    const configSafetyplan = _.find(configuration, { type: ModuleType.SafetyPlan });
    if (configSafetyplan) {
      this.safetyplan = new OhsSafetyplanConfig(configSafetyplan);
    }

    this.contractor = null;
    const configContractor = _.find(configuration, { type: ModuleType.Contractor });
    if (configContractor) {
      const contractorFileBoxConfig = _.find(configuration, { type: ModuleType.ContractorFileBox });
      this.contractor = new OhsContractorConfig(configContractor, contractorFileBoxConfig);
    }

    this.hr = null;
    const configHr = _.find(configuration, { type: ModuleType.HR });
    if (configHr) {
      const hrFileBoxConfig = _.find(configuration, { type: ModuleType.HumanResourceFileBox });
      this.hr = new OhsHrConfig(configHr, hrFileBoxConfig);
    }

    this.vdoc = null;
    const configVdoc = _.find(configuration, { type: ModuleType.VersionDocument });
    if (configVdoc != null) {
      const vdocFileBoxConfig = _.find(configuration, { type: ModuleType.VersionDocumentFileBox });
      this.vdoc = new OhsVdocConfig(configVdoc, vdocFileBoxConfig);
    }

    this.import = null;
    const configImport = _.find(configuration, { type: ModuleType.ImportManager });
    if (configImport) {
      this.import = new OhsImportConfig(configImport);
    }

    this.bi = null;
    const configBi = _.find(configuration, { type: ModuleType.BusinessIntelligence });
    if (configBi) {
      this.bi = new OhsBiConfig(configBi);
    }

    this.bix = null;
    const configBix = _.find(configuration, { type: 'bixConfig' });
    if (configBix) {
      this.bix = new OhsBixConfig(configBix);
    }

    this.noticeboard = null;
    const noticeConfig = _.find(configuration, { type: ModuleType.Noticeboard });
    if (noticeConfig) {
      this.noticeboard = new OhsNoticeboardConfig(noticeConfig);
    }

    this.corporateRisk = null;
    const corporateRiskConfig = _.find(configuration, { type: ModuleType.CorporateRisk });
    if (corporateRiskConfig) {
      const corporateRiskFileBoxConfig = _.find(configuration, {
        type: ModuleType.CorporateRiskFileBox,
      });
      this.corporateRisk = new OhsCorporateRiskConfig(
        corporateRiskConfig,
        corporateRiskFileBoxConfig
      );
    }

    this.asset = null;
    const assetConfig = _.find(configuration, { type: ModuleType.Asset });
    if (assetConfig) {
      const assetFileBoxConfig = _.find(configuration, { type: ModuleType.AssetFileBox });
      this.asset = new OhsAssetConfig(assetConfig, assetFileBoxConfig);
    }

    this.export = null;
    const exportConfig = _.find(configuration, { type: ModuleType.Export });
    if (exportConfig) {
      this.export = new OhsExportConfig(exportConfig);
    }

    this.training = null;
    const trainingConfig = _.find(configuration, { type: ModuleType.Training });
    if (trainingConfig) {
      this.training = new OhsTrainingConfig(trainingConfig);
    }

    this.bookmark = null;
    const bookmarkConfig = _.find(configuration, { type: ModuleType.Bookmark });
    if (bookmarkConfig) {
      this.bookmark = new OhsBookmarkConfig(bookmarkConfig);
    }
  }
}
