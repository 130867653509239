import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';

import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';

const useStyles = makeStyles()(() => ({
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}

function OhsOnboardingFilters(props: Props) {
  const { classes } = useStyles();
  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;

  return (
    <Box className={classes.filterContent}>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id="onboardingModule.sort"
          required
          options={filter.onboardingModule.tableSortKeyLists?.sortColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
    </Box>
  );
}

export default OhsOnboardingFilters;
