import { OhsActionLink } from 'action/OhsActionModels';
import { OhsCusval } from 'global-components/form/cusvals/OhsCusvalModel';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { OhsHRContractorLinkItem } from 'global-components/form/fields/linkHrContractor/OhsHrContractorLinkModels';
import { OhsStatusFilterOption } from 'global-components/register/table/filters/OhsFilterTypes';
import { getCurrentTimezone } from 'global-services/OhsDataParse';
import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import { AdminSwitchOptionMap } from 'user/configurations/admin2/OhsAdmin2Config';
import { OhsTaskType, OhsArchivalDetailsType } from 'corporate-risk/models/OhsCorporateRiskModels';
import OhsCusvalDefinition from 'global-components/form/cusvals/OhsCusvalDefinition';

export default class OhsCorporateRiskRecord {
  allocated: boolean;

  tier?: OhsTier;

  name: string;

  _id: string;

  type: ModuleType.CorporateRisk;

  title: string;

  referenceId: string;

  description: string;

  category: string;

  categoryOther: string | null;

  subcategory: string;

  subcategoryOther: string | null;

  subRows: Array<OhsCorporateRiskRecord>;

  task: OhsTaskType | null;

  inherentRiskRating: string | null;

  inherentRiskRatingOther: string | null;

  futureRiskRating: string | null;

  futureRiskRatingOther: string | null;

  currentMitigation: string;

  futureMitigation: string | null;

  residualRisk: string;

  residualRiskOther: string | null;

  futureControl: boolean;

  riskOwner: string;

  riskOwnerOther: string | null;

  riskOwnerLinks: OhsHRContractorLinkItem[];

  dateIssued: string;

  dateExpiry: string | null;

  cusvals: OhsCusval[];

  cusvalsDefinition?: OhsCusvalDefinition;

  subcategoryCusvals: OhsCusval[];

  subcategoryCusvalsDefinition?: OhsCusvalDefinition;

  notes: string;

  attachments: OhsAttachment[];

  previousAttachments?: OhsAttachment[];

  control?: { signatures?: string };

  actionLinks: OhsActionLink[];

  tzDateCreated: string;

  allocationActive: boolean;

  allocationOf: { _id: string; type: ModuleType.CorporateRisk } | undefined;

  createdBy: OhsModuleCreateBy;

  signatures?: OhsAttachment[];

  complete: boolean;

  for?: OhsCorporateRiskRecord;

  save: boolean;

  dateDue: string;

  reviewNotes: string;

  tzDateSignedoff: string;

  dateCompleted: string;

  dateSignedoff: string;

  signedoffBy: OhsModuleCreateBy;

  dateCreated: string;

  confidential?: boolean;

  recurrent: boolean;

  reviewDateRequired: string[];

  archivalDetails?: OhsArchivalDetailsType;

  partialChronology?: OhsCorporateRiskRecord[];

  allocationStatus?: OhsStatusFilterOption;

  constructor(tier?: OhsTier) {
    this.allocated = false;
    this.tier = tier;
    this.name = '';
    this._id = '';
    this.type = ModuleType.CorporateRisk;
    this.title = '';
    this.referenceId = '';
    this.description = '';
    this.category = '';
    this.categoryOther = null;
    this.subcategory = '';
    this.subcategoryOther = null;
    this.subRows = new Array<OhsCorporateRiskRecord>();
    this.createdBy = new OhsModuleCreateBy();
    this.tzDateCreated = getCurrentTimezone();
    this.dateCreated = '';
    this.inherentRiskRating = null;
    this.inherentRiskRatingOther = null;
    this.futureRiskRating = null;
    this.futureRiskRatingOther = null;
    this.currentMitigation = '';
    this.futureMitigation = null;
    this.residualRisk = '';
    this.residualRiskOther = null;
    this.futureControl = false;
    this.riskOwner = '';
    this.riskOwnerOther = null;
    this.riskOwnerLinks = new Array<OhsHRContractorLinkItem>();
    this.dateIssued = '';
    this.dateExpiry = null;
    this.task = null;
    this.cusvals = new Array<OhsCusval>();
    this.subcategoryCusvals = new Array<OhsCusval>();
    this.notes = '';
    this.attachments = new Array<OhsAttachment>();
    this.control = { signatures: AdminSwitchOptionMap.disabled };
    this.actionLinks = new Array<OhsActionLink>();
    this.complete = true;
    this.for = undefined;
    this.dateCompleted = '';
    this.dateDue = '';
    this.dateSignedoff = '';
    this.description = '';
    this.reviewNotes = '';
    this.signatures = new Array<OhsAttachment>();
    this.signedoffBy = new OhsModuleCreateBy();
    this.title = '';
    this.tzDateSignedoff = '';
    this.save = true;
    this.recurrent = true;
    this.reviewDateRequired = [];
    this.allocationActive = false;
  }
}
