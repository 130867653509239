export default class OhsActionFilterModels {
  view1T3: {
    viewPreset: 'view_1';
    archived: boolean;
    workplaces: string[];
    sort: string;
    skipOrgRecords: boolean;
    severities?: string[];
  };

  view2T4: {
    viewPreset: 'view_2';
    archived: boolean;
    sort: string;
    skipOrgRecords: boolean;
    severities?: string[];
  };

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  categories?: { name: string; value: string }[];

  constructor() {
    this.view1T3 = {
      viewPreset: 'view_1',
      archived: false,
      workplaces: [],
      sort: '{ "key": "_id", "order": -1 }',
      skipOrgRecords: false,
    };
    this.view2T4 = {
      viewPreset: 'view_2',
      archived: false,
      sort: '{ "key": "_id", "order": -1 }',
      skipOrgRecords: false,
    };
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest ', value: '{ "key": "_id", "order": 1 }' },
        { name: 'Reference: A to Z ', value: '{ "key": "reference", "order": 1 }' },
        { name: 'Reference: Z to A', value: '{ "key": "reference", "order": -1 }' },
        {
          name: 'Identified Date: Newest to Oldest',
          value: '{ "key": "dateIdentified", "order": -1 }',
        },
        {
          name: 'Identified Date: Oldest to Newest ',
          value: '{ "key": "dateIdentified", "order": 1 }',
        },
        {
          name: 'Due Date: Newest to Oldest',
          value: '{ "key": "dateDue", "order": -1 }',
        },
        {
          name: 'Due Date: Oldest to Newest ',
          value: '{ "key": "dateDue", "order": 1 }',
        },
        {
          name: 'Generated By: A to Z',
          value: '{ "key": "category", "order": 1 }',
        },
        {
          name: 'Generated By: Z to A',
          value: '{ "key": "category", "order": -1 }',
        },
      ],
    };
    this.categories = [];
  }
}
