import { styled, TableCell } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

interface Props {
  column?: any;
  width?: string;
}

const OhsRegisterTableHeader = styled(TableCell)((props: Props) => {
  const { hideheader, headerAlign } = props.column ?? {};

  const useStyles = makeStyles()((theme: Theme) => {
    return {
      header: {
        backgroundColor: theme.palette.custom?.HEADER_BAR_BG_COLOR,
        color: theme.palette.custom?.HEADER_BAR_TEXT_COLOR,
        fontSize: '1em',
        fontFamily: 'Lato, "Helvetica Neue", Arial, Helvetica, sans-serif',
        fontWeight: 700,
        paddingBottom: '0.928571em',
        paddingTop: '0.928571em',
        paddingLeft: '10px',
        paddingRight: '10px',
        whiteSpace: 'nowrap',
        ...(hideheader === true && { display: 'none' }),
        ...(props.width && { width: props.width }),
        textAlign: headerAlign || 'left',
      },
    };
  });

  const { classes } = useStyles();

  return classes.header;
});

export default OhsRegisterTableHeader;
