import React from 'react';

import { Button, Icon } from 'semantic-ui-react';

import registerButtonStyles from 'global-components/register/RegisterButtonStyles';

import { OhsButtonProps } from './OhsButtonModels';

export default function OhsExportButton(props: OhsButtonProps) {
  const { onClick, loading } = props;
  const { classes } = registerButtonStyles();
  return (
    <Button
      className={classes.button}
      color="green"
      style={{ backgroundColor: '#1e6e42' }}
      onClick={onClick}
      loading={loading}
    >
      <Icon className={classes.buttonIcons} name="download" />
      <span className={classes.hideForSmall}>Export Aggregate</span>
    </Button>
  );
}
