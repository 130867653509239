import _ from 'lodash';

import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import { OhsUser } from 'user/OhsUser';

export const addVisitorPin = (payload: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Visitor, OhsApiRequestName.PinSet, payload);
};

export const getVisitorPinLists = async (user: OhsUser): Promise<any> => {
  const res: any[] | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Visitor,
    OhsApiRequestName.PinList,
    {}
  );

  const formatRes = () => {
    const workplaceList = res || [];
    workplaceList.forEach((element: any) => {
      if (element.tier.type === TierType.T3) {
        element.workplace = user?.org?.name!;
      } else {
        element.workplace = _.find(user?.configs.admin2?.LOWER_TIER_ACCESS, [
          '_id',
          element.tier?._id,
        ])?.name;
      }
    });
    return workplaceList;
  };

  return formatRes();
};
