export const ChemicalPackingGroup = [
  { value: 'I', name: 'I' },
  { value: 'II', name: 'II' },
  { value: 'III', name: 'III' },
];

export const ChemicalDangerousGoodsClass = [
  {
    value: '1.1',
    name: '1.1: Substances and articles which have a mass explosion hazard',
  },
  {
    value: '1.2',
    name: `1.2: Substances and articles which have a projection hazard but
     not a mass explosion hazard`,
  },
  {
    value: '1.3',
    name:
      '1.3: Substances and articles which have a fire hazard and either a minor blast hazard or a' +
      ' minor projection hazard or both',
  },
  {
    value: '1.4',
    name:
      '1.4: Substances and articles which present no significant hazard; only a small hazard in' +
      ' the event of ignition or initiation during transport with any effects largely confined' +
      ' to the package',
  },
  {
    value: '1.5',
    name: '1.5: Very insensitive substances which have a mass explosion hazard',
  },
  {
    value: '1.6',
    name: '1.6: Extremely insensitive articles which do not have a mass explosion hazard',
  },
  { value: '2.1', name: '2.1: Flammable gases' },
  { value: '2.2', name: '2.2: Non-flammable, non-toxic gases' },
  { value: '2.3', name: '2.3: Toxic gases' },
  { value: '3', name: '3: Flammable liquids' },
  { value: '4.1', name: '4.1: Flammable solids' },
  { value: '4.2', name: '4.2: Substances liable to spontaneous combustion' },
  {
    value: '4.3',
    name: '4.3: Substances which, in contact with water, emit flammable gases',
  },
  { value: '5.1', name: '5.1: Oxidizing substances' },
  { value: '5.2', name: '5.2: Organic peroxides' },
  { value: '6.1', name: '6.1: Toxic substances' },
  { value: '6.2', name: '6.2: Infectious substances' },
  { value: '8', name: '8: Corrosives' },
  { value: '9', name: '9: Miscellaneous dangerous goods' },
];

export const ChemicalSubRiskOptions = [
  { value: '2.1', name: '2.1: Flammable gases' },
  { value: '3', name: '3: Flammable liquids' },
  { value: '4.2', name: '4.2: Substances liable to spontaneous combustion' },
  {
    value: '4.3',
    name: '4.3: Substances which, in contact with water, emit flammable gases',
  },
  { value: '5.1', name: '5.1: Oxidizing substances' },
  { value: '6.1', name: '6.1: Toxic substances' },
  { value: '8', name: '8: Corrosives' },
  { value: 'Other', name: 'Other' },
];

export const ChemicalRiskClassificationGHSOptions = [
  { value: 'Yes', name: 'Yes, GHS Classification' },
  { value: 'No', name: 'No, Other Classification' },
];

export const ChemicalRiskHazardousSubstanceOptions = [
  { value: 'Yes', name: 'Yes' },
  { value: 'No', name: 'No' },
  { value: 'Not Sure', name: 'Not Sure' },
];

export const ChemicalRiskDangerousGoodOptions = [
  { value: 'Yes', name: 'Yes' },
  { value: 'No', name: 'No' },
  { value: 'Not Sure', name: 'Not Sure' },
];

export const ChemicalRiskAssessmentOptions = [
  { value: 'Yes', name: 'Yes' },
  { value: 'No', name: 'No' },
];

export const RiskAddVariables: any = {
  Yes: true,
  No: false,
  'Not Sure': null,
};

export const RiskEditVariables: any = {
  true: true,
  false: false,
  null: null,
};

export const ChemicalGhsClassification = [
  {
    title: 'Physical Properties',
    list: [
      {
        code: 'H200',
        title: 'Unstable explosive',
      },
      {
        code: 'H201',
        title: 'Explosive; mass explosion hazard',
      },
      {
        code: 'H202',
        title: 'Explosive; severe projection hazard',
      },
      {
        code: 'H203',
        title: 'Explosive; fire, blast or projection hazard',
      },
      {
        code: 'H204',
        title: 'Fire or projection hazard',
      },
      {
        code: 'H205',
        title: 'May mass explode in fire',
      },
      {
        code: 'H206',
        title:
          'Fire, blast or projection hazard: increased risk of explosion' +
          ' if desensitizing agent is reduced',
      },
      {
        code: 'H207',
        title:
          'Fire or projection hazard: increased risk of explosion if' +
          ' desensitizing agent is reduced',
      },
      {
        code: 'H208',
        title: 'Fire hazard: increased risk of explosion if desensitizing agent is reduced',
      },
      {
        code: 'H220',
        title: 'Extremely flammable gas',
      },
      {
        code: 'H221',
        title: 'Flammable gas',
      },
      {
        code: 'H222',
        title: 'Extremely flammable aerosol',
      },
      {
        code: 'H223',
        title: 'Flammable aerosol',
      },
      {
        code: 'H224',
        title: 'Extremely flammable liquid and vapour',
      },
      {
        code: 'H225',
        title: 'Highly flammable liquid and vapour',
      },
      {
        code: 'H226',
        title: 'Flammable liquid and vapour',
      },
      {
        code: 'H227',
        title: 'Combustible liquid',
      },
      {
        code: 'H228',
        title: 'Flammable solid',
      },
      {
        code: 'H229',
        title: 'Pressurized container: may burst if heated',
      },
      {
        code: 'H230',
        title: 'May react explosively even in the absence of air',
      },
      {
        code: 'H231',
        title:
          'May react explosively even in the absence of air at' +
          ' elevated pressure and/or temperature',
      },
      {
        code: 'H232',
        title: 'May ignite spontancously if exposed to air',
      },
      {
        code: 'H240',
        title: 'Heating may cause an explosion',
      },
      {
        code: 'H241',
        title: 'Heating may cause a fire or explosion',
      },
      {
        code: 'H242',
        title: 'Heating may cause a fire',
      },
      {
        code: 'H250',
        title: 'Catches fire spontaneously if exposed to air',
      },
      {
        code: 'H251',
        title: 'Self-heating; may catch fire',
      },
      {
        code: 'H252',
        title: 'Self-heating in large quantities; may catch fire',
      },
      {
        code: 'H260',
        title: 'In contact with water releases flammable gases which may ignite spontaneously',
      },
      {
        code: 'H261',
        title: 'In contact with water releases flammable gas',
      },
      {
        code: 'H270',
        title: 'May cause or intensify fire; oxidizer',
      },
      {
        code: 'H271',
        title: 'May cause fire or explosion; strong oxidizer',
      },
      {
        code: 'H272',
        title: 'May intensify fire; oxidizer',
      },
      {
        code: 'H280',
        title: 'Contains gas under pressure; may explode if heated',
      },
      {
        code: 'H281',
        title: 'Contains refrigerated gas; may cause cryogenic burns or injury',
      },
      {
        code: 'H290',
        title: 'May be corrosive to metals',
      },
      {
        code: 'AUH001',
        title: '(AU) Explosive when dry',
      },
      {
        code: 'EUH001',
        title: '(EU) Explosive when dry',
      },
      {
        code: 'AUH006',
        title: '(AU) Explosive with or without contact with air',
      },
      {
        code: 'EUH006',
        title:
          '(EU) Explosive with or without contact with air, deleted' +
          ' in the fourth adaptation to technical',
      },
      {
        code: 'AUH014',
        title: '(AU) Reacts violently with water',
      },
      {
        code: 'EUH014',
        title: '(EU) Reacts violently with water',
      },
      {
        code: 'AUH018',
        title: '(AU) In use may form flammable/explosive vapour-air mixture',
      },
      {
        code: 'EUH018',
        title: '(EU) In use may form flammable/explosive vapour-air mixture',
      },
      {
        code: 'AUH019',
        title: '(AU) May form explosive peroxides',
      },
      {
        code: 'EUH019',
        title: '(EU) May form explosive peroxides',
      },
      {
        code: 'AUH044',
        title: '(AU) Risk of explosion if heated under confinement',
      },
      {
        code: 'EUH044',
        title: '(EU) Risk of explosion if heated under confinement',
      },
    ],
  },
  {
    title: 'Health Properties',
    list: [
      {
        code: 'H300',
        title: 'Fatal if swallowed',
      },
      {
        code: 'H301',
        title: 'Toxic if swallowed',
      },
      {
        code: 'H302',
        title: 'Harmful if swallowed',
      },
      {
        code: 'H303',
        title: 'May be harmful if swallowed',
      },
      {
        code: 'H304',
        title: 'May be fatal if swallowed and enters airways',
      },
      {
        code: 'H305',
        title: 'May be harmful if swallowed and enters airways',
      },
      {
        code: 'H310',
        title: 'Fatal in contact with skin',
      },
      {
        code: 'H311',
        title: 'Toxic in contact with skin',
      },
      {
        code: 'H312',
        title: 'Harmful in contact with skin',
      },
      {
        code: 'H313',
        title: 'May be harmful in contact with skin',
      },
      {
        code: 'H314',
        title: 'Causes severe skin burns and eye damage',
      },
      {
        code: 'H315',
        title: 'Causes skin irritation',
      },
      {
        code: 'H316',
        title: 'Causes mild skin irritation',
      },
      {
        code: 'H317',
        title: 'May cause an allergic skin reaction',
      },
      {
        code: 'H318',
        title: 'Causes serious eye damage',
      },
      {
        code: 'H319',
        title: 'Causes serious eye irritation',
      },
      {
        code: 'H320',
        title: 'Causes eye irritation',
      },
      {
        code: 'H330',
        title: 'Fatal if inhaled',
      },
      {
        code: 'H331',
        title: 'Toxic if inhaled',
      },
      {
        code: 'H332',
        title: 'Harmful if inhaled',
      },
      {
        code: 'H333',
        title: 'May be harmful if inhaled',
      },
      {
        code: 'H334',
        title: 'May cause allergy or asthma symptoms or breathing difficulties if inhaled',
      },
      {
        code: 'H335',
        title: 'May cause respiratory irritation',
      },
      {
        code: 'H336',
        title: 'May cause drowsiness or dizziness',
      },
      {
        code: 'H340',
        title: 'May cause genetic defects',
      },
      {
        code: 'H341',
        title: 'Suspected of causing genetic defects',
      },
      {
        code: 'H350',
        title: 'May cause cancer',
      },
      {
        code: 'H351',
        title: 'Suspected of causing cancer',
      },
      {
        code: 'H360',
        title: 'May damage fertility or the unborn child',
      },
      {
        code: 'H361',
        title: 'Suspected of damaging fertility or the unborn child',
      },
      {
        code: 'H361d',
        title: 'Suspected of damaging the unborn child',
      },
      {
        code: 'H361f',
        title: 'Suspected of damaging fertility',
      },
      {
        code: 'H362',
        title: 'May cause harm to breast-fed children',
      },
      {
        code: 'H370',
        title: 'Causes damage to organs',
      },
      {
        code: 'H371',
        title: 'May cause damage to organs',
      },
      {
        code: 'H372',
        title: 'Causes damage to organs through prolonged or repeated exposure',
      },
      {
        code: 'H373',
        title: 'May cause damage to organs through prolonged or repeated exposure',
      },
      {
        code: 'H300+H310',
        title: 'Fatal if swallowed or in contact with skin',
      },
      {
        code: 'H300+H330',
        title: 'Fatal if swallowed or if inhaled',
      },
      {
        code: 'H310+H330',
        title: 'Fatal in contact with skin or if inhaled',
      },
      {
        code: 'H300+H310+H330',
        title: 'Fatal if swallowed, in contact with skin or if inhaled',
      },
      {
        code: 'H301+H311',
        title: 'Toxic if swallowed or in contact with skin',
      },
      {
        code: 'H301+H331',
        title: 'Toxic if swallowed or if inhaled',
      },
      {
        code: 'H311+H331',
        title: 'Toxic in contact with skin or if inhaled',
      },
      {
        code: 'H301+H311+H331',
        title: 'Toxic if swallowed, in contact with skin or if inhaled',
      },
      {
        code: 'H302+H312',
        title: 'Harmful if swallowed or in contact with skin',
      },
      {
        code: 'H302+H332',
        title: 'Harmful if swallowed or if inhaled',
      },
      {
        code: 'H312+H332',
        title: 'Harmful in contact with skin or if inhaled',
      },
      {
        code: 'H302+H312+H332',
        title: 'Harmful if swallowed, in contact with skin or if inhaled',
      },
      {
        code: 'H303+H313',
        title: 'May be harmful if swallowed or in contact with skin',
      },
      {
        code: 'H303+H333',
        title: 'May be harmful if swallowed or if inhaled',
      },
      {
        code: 'H313+H333',
        title: 'May be harmful in contact with skin or if inhaled',
      },
      {
        code: 'H303+H313+H333',
        title: 'May be harmful if swallowed, in contact with skin or if inhaled',
      },
      {
        code: 'H315+H320',
        title: 'Causes skin and eye irritation',
      },
      {
        code: 'AUH029',
        title: '(AU) Contact with water liberates toxic gas',
      },
      {
        code: 'EUH029',
        title: '(EU) Contact with water liberates toxic gas',
      },
      {
        code: 'AUH031',
        title: '(AU) Contact with acids liberates toxic gas',
      },
      {
        code: 'EUH031',
        title: '(EU) Contact with acids liberates toxic gas',
      },
      {
        code: 'AUH032',
        title: '(AU) Contact with acids liberates very toxic gas',
      },
      {
        code: 'EUH032',
        title: '(EU) Contact with acids liberates very toxic gas',
      },
      {
        code: 'AUH066',
        title: '(AU) Repeated exposure may cause skin dryness or cracking',
      },
      {
        code: 'EUH066',
        title: '(EU) Repeated exposure may cause skin dryness or cracking',
      },
      {
        code: 'AUH070',
        title: '(AU) Toxic by eye contact',
      },
      {
        code: 'EUH070',
        title: '(EU) Toxic by eye contact',
      },
      {
        code: 'AUH071',
        title: '(AU) Corrosive to the respiratory tract',
      },
      {
        code: 'EUH071',
        title: '(EU) Corrosive to the respiratory tract',
      },
    ],
  },
  {
    title: 'Environmental Properties',
    list: [
      {
        code: 'H400',
        title: 'Very toxic to aquatic life',
      },
      {
        code: 'H401',
        title: 'Toxic to aquatic life',
      },
      {
        code: 'H402',
        title: 'Harmful to aquatic life',
      },
      {
        code: 'H410',
        title: 'Very toxic to aquatic life with long-lasting effects',
      },
      {
        code: 'H411',
        title: 'Toxic to aquatic life with long-lasting effects',
      },
      {
        code: 'H412',
        title: 'Harmful to aquatic life with long-lasting effects',
      },
      {
        code: 'H413',
        title: 'May cause long-lasting harmful effects to aquatic life',
      },
      {
        code: 'H420',
        title:
          'Harms public health and the environment by destroying ozone in the upper atmosphere',
      },
      {
        code: 'H421',
        title: 'Very toxic to the soil environment',
      },
      {
        code: 'H422',
        title: 'Toxic to the soil environment',
      },
      {
        code: 'H423',
        title: 'Harmful to the soil environment',
      },
      {
        code: 'H431',
        title: 'Very toxic to terrestrial vertebrates',
      },
      {
        code: 'H432',
        title: 'Toxic to terrestrial vertebrates',
      },
      {
        code: 'H433',
        title: 'Harmful to terrestrial vertebrates',
      },
      {
        code: 'H441',
        title: 'Very toxic to terrestrial invertebrates',
      },
      {
        code: 'H442',
        title: 'Toxic to terrestrial invertebrates',
      },
      {
        code: 'H443',
        title: 'Harmful to terrestrial invertebrates',
      },
      {
        code: 'EUH059',
        title:
          'Hazardous to the ozone layer, superseded by GHS Class 5.1 in the second' +
          ' adaptation to technical progress of CLP',
      },
    ],
  },
];
