import { getConfigValue, setPermissions } from '../OhsConfigServices';
import OhsModulePermissions from '../OhsModulePermissions';

class OhsExportConfig {
  TITLE: string;

  PERMISSIONS: OhsModulePermissions;

  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsModulePermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}

export default OhsExportConfig;
