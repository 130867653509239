import { OhsUser } from 'user/OhsUser';
import { withSignOffViewPermission, withCreatePermission } from 'global-services/OhsPermission';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';
import { ModuleConfig } from 'global-services/constants/OhsObject';

const commonPermission = (user: OhsUser) => user?.configs?.incident?.PERMISSIONS.view || false;
const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.Incident}/${path}`,
  permission,
});

const IncidentRouteList = {
  [ModuleRoute.Home]: createRoute('home', commonPermission),
  [ModuleRoute.Add]: createRoute('report', (user) =>
    withCreatePermission(user, ModuleConfig.Incident)
  ),
  [ModuleRoute.Edit]: createRoute(':incidentId/report', commonPermission),
  [ModuleRoute.Details]: createRoute(':incidentId/view', commonPermission),
  [ModuleRoute.SignOff]: createRoute(':incidentId/task/sign-off/:taskId?', (user) =>
    withSignOffViewPermission(user, ModuleConfig.Incident)
  ),
  [ModuleRoute.Evidence]: createRoute(':incidentId/task/:taskId/completed', commonPermission),
};

export default IncidentRouteList;
