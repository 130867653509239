class LeftDrawerModule {
  key: string;

  title: string;

  href: string;

  constructor(key: string, title: string, href: string) {
    this.key = key;
    this.title = title;
    this.href = href;
  }
}
export default LeftDrawerModule;
