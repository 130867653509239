import React from 'react';

import { QRCodeCanvas } from 'qrcode.react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';

import { OTP_LENGTH } from 'global-services/constants/OhsConstants';

import { MfaAuthenticatorType, OhsMfaMethod } from '../../OhsMfaTypes';
import { MfaSettingsStatus } from '../../verifyflows/OhsMfaVerifyTypes';
import OhsUserMFAInput from '../../form/OhsUserMFAInput';
import { userMfaEnrollConfirmRequest, userMfaEnrollRequest } from '../OhsMfaServices';

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  text: {
    textAlign: 'center',
    margin: '0 0 10px 0',
  },
  link: {
    textDecoration: 'none',
    margin: '15px',
    cursor: 'pointer',
    fontSize: '12px',
    textTransform: 'inherit',
  },
  otpError: {
    color: 'red',
    margin: '15px 0 -5px 0',
    fontSize: '14px',
    textAlign: 'center',
  },
  note: {
    marginBottom: '15px',
    textAlign: 'center',
  },
  header: {
    fontWeight: 'bold',
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '20px',
    color: '#4c4c4c',
  },
  errorIcon: {
    verticalAlign: '-4px',
    fontSize: '18px',
    marginRight: '4px',
  },
  validating: {
    fontSize: '14px',
    color: 'green',
    margin: '15px 0 -5px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  validatingIcon: {
    verticalAlign: '-4px',
    fontSize: '14px',
    marginRight: '8px',
    width: '16px !important',
    height: '16px !important',
    color: 'green',
  },
  qRcontainer: {
    textAlign: 'center',
  },
  qrCode: {
    width: '170px !important',
    height: 'auto !important',
    border: '1px solid #cccccc',
    padding: '5px',
    margin: '10px',
  },
  methodname: {
    textTransform: 'uppercase',
  },
});
interface Props {
  mfaInfo?: OhsMfaMethod;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}
function OhsMfaEnrollEmailSmsOtp(props: Props) {
  const { authenticator, barcodeUri } = props.mfaInfo?.item ?? {};
  const { classes } = useStyles();
  const [mfaInfo, setMFAInfo] = React.useState<MfaAuthenticatorType>();
  const [oTPvalidStatus, setOTPvalidStatus] = React.useState<MfaSettingsStatus>('idle');
  const [currentOTP, setOTP] = React.useState('');
  const [QRcode, setQRcode] = React.useState<string>();

  React.useEffect(() => {
    if (authenticator) setMFAInfo(authenticator);
  }, [authenticator]);

  const resendOTPcode = async () => {
    setOTPvalidStatus('resendingcode');
    const enrollUserReq = await userMfaEnrollRequest(
      authenticator?.method ?? '',
      authenticator?.name ?? ''
    );
    if (enrollUserReq) setOTPvalidStatus('otpResent');
  };

  const enrollMethod = async (otp: string) => {
    setOTPvalidStatus('otpValidating');
    const enrollUserReq = await userMfaEnrollConfirmRequest(otp);
    setOTPvalidStatus(enrollUserReq?.success ? 'valid' : 'otpInvalid');
    if (enrollUserReq?.success) props.setActiveStep(4);
  };

  const getVerifyMsg = (oTPStatus: string) => {
    switch (oTPStatus) {
      case 'otpValidating':
        return (
          <>
            <CircularProgress className={classes.validatingIcon} />
            Verifiying authentication code ...
          </>
        );
      case 'otpInvalid':
        return (
          <>
            <ErrorIcon className={classes.errorIcon} />
            Incorrect verification code.
          </>
        );

      case 'resendingcode':
        return (
          <>
            <CircularProgress className={classes.validatingIcon} />
            Resending authentication code ...
          </>
        );
      case 'otpResent':
        return <>New authentication code has been resent.</>;
      default:
        return '';
    }
  };

  React.useEffect(() => {
    if (currentOTP.length === OTP_LENGTH) enrollMethod(currentOTP);
  }, [currentOTP]);

  React.useEffect(() => {
    if (barcodeUri) setQRcode(barcodeUri);
  }, [barcodeUri]);

  const processClassName = clsx({
    [classes.link]: true,
    [classes.validating]:
      oTPvalidStatus === 'otpResent' ||
      oTPvalidStatus === 'otpValidating' ||
      oTPvalidStatus === 'resendingcode',
    [classes.otpError]: oTPvalidStatus === 'otpInvalid',
  });

  return (
    <Box className={classes.container}>
      {QRcode && authenticator?.method === 'app' && (
        <Box className={classes.qRcontainer}>
          <Typography variant="body1" gutterBottom className={classes.header}>
            To complete this process, scan the QR code using your authenticator app.
          </Typography>
          {QRcode && <QRCodeCanvas value={QRcode} className={classes.qrCode} />}
          <Typography
            variant="body1"
            gutterBottom
            sx={{ textAlign: 'center', margin: '0 0 10px 0' }}
          >
            Enter the 6-digit authentication code generated by your app.
          </Typography>
          <Divider sx={{ width: '100%', marginBottom: '15px' }} />
        </Box>
      )}
      {!QRcode && authenticator?.method === 'app' && (
        <Typography variant="body1" gutterBottom className={classes.header}>
          Please enter the authentication code generated by your authenticator app.
        </Typography>
      )}

      {authenticator?.method !== 'app' && (
        <Typography variant="body1" gutterBottom className={classes.note}>
          Please enter the authentication code that we sent to your{' '}
          <span className={classes.methodname}>{mfaInfo?.method}</span>:{' '}
          <span className={classes.header}>{mfaInfo?.name}</span>.
        </Typography>
      )}
      <OhsUserMFAInput setOTP={setOTP} currentOTP={currentOTP} />

      {oTPvalidStatus !== 'idle' && (
        <Typography variant="body1" gutterBottom className={processClassName}>
          {getVerifyMsg(oTPvalidStatus)}
        </Typography>
      )}

      {authenticator?.method !== 'app' ? (
        <Button className={classes.link} onClick={() => resendOTPcode()}>
          Resend Code
        </Button>
      ) : (
        <Box mb={2} />
      )}
    </Box>
  );
}

OhsMfaEnrollEmailSmsOtp.defaultProps = {
  mfaInfo: null,
};
export default OhsMfaEnrollEmailSmsOtp;
