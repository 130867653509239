import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
  RequestType,
} from 'global-services/api/OhsApiModels';
import { getCurrentTimezone } from 'global-services/OhsDataParse';
import { OhsAllocationItem } from 'global-components/allocation/OhsAllocatableModels';

import OhsSafetyPlanTaskRecord from './models/OhsSafetyPlanTaskRecord';
import OhsSafetyPlanRecord from './OhsSafetyPlanRecord';
import {
  SafetyPlanAllocListRes,
  AllocatedListType,
  OhsSafetyPlanFilterPayload,
  OhsSafetyPlanRootState,
} from './OhsSafetyPlanModels';
import parseListAllocations from './register/utils/OhsParseListAllocations';
import { safetyPlanSearch } from './store/OhsSafetyPlanActions';

export const addSafetyPlan = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.SafetyPlan,
    OhsApiRequestName.Create,
    payload
  );
};

export const editSafetyPlan = (payload: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.SafetyPlan, OhsApiRequestName.Edit, payload);
};

export const getSafetyPlan = async (
  _id: string,
  disableErrorToast?: boolean
): Promise<OhsSafetyPlanRecord> => {
  const res: OhsSafetyPlanRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.SafetyPlan,
    OhsApiRequestName.Fetch,
    { _id },
    { disableErrorToast }
  );
  return res || new OhsSafetyPlanRecord();
};

export const getSafetyPlanLists = (
  filterInfo: OhsSafetyPlanFilterPayload,
  orgAdminTierId?: string
): Promise<ApiResListsType<any[]> | null> => {
  const payload: any = {
    filter: filterInfo,
  };
  if (orgAdminTierId) {
    payload.orgAdminTier = {
      type: TierType.T2,
      _id: orgAdminTierId,
    };
  }
  return OhsApiRequest(RequestType.Module, ModuleType.SafetyPlan, OhsApiRequestName.List, payload);
};

export const getSafetyPlanScheduledLists = (
  filterInfo: OhsSafetyPlanFilterPayload
): Promise<any | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.SafetyPlan, OhsApiRequestName.ListScheduled, {
    filter: filterInfo,
  });
};

export const getSafetyPlanTaskRecord = async (
  _id: string,
  taskId: string,
  disableErrorToast?: boolean
): Promise<OhsSafetyPlanTaskRecord> => {
  const res: OhsSafetyPlanTaskRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Task,
    OhsApiRequestName.Fetch,
    {
      _id: taskId,
      for: {
        _id,
        type: ModuleType.SafetyPlan,
      },
    },
    { disableErrorToast }
  );
  return res || new OhsSafetyPlanTaskRecord();
};

export const signOffSafetyPlan = (payload: any) =>
  OhsApiRequest(RequestType.Module, ModuleType.SafetyPlan, OhsApiRequestName.SignOff, payload);

export const archiveSafetyPlan = (safetyPlanId: string, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.SafetyPlan,
    archived ? OhsApiRequestName.Archive : OhsApiRequestName.UnArchive,
    {
      _id: safetyPlanId,
    }
  );
};

export const deleteSafetyPlan = (safetyPlanId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.SafetyPlan, OhsApiRequestName.Delete, {
    _id: safetyPlanId,
  });
};

export const getSafetyPlanAllocatedList = (
  safetyPlanListIDs: string[]
): Promise<AllocatedListType[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.SafetyPlan, OhsApiRequestName.AllocatedList, {
    _ids: safetyPlanListIDs,
  });
};

export const getSafetyPlanListsWithAllocations = (
  safetyPlanState: OhsSafetyPlanRootState,
  safetyPlanFilters: OhsSafetyPlanFilterPayload,
  searchInfo: any
): Promise<ApiResListsType<any[]> | null> => {
  const hasGlobalSearch = safetyPlanState.globalSearch.searchInfo.searchKey !== '';
  const getSafetyPlanListsReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const safetyPlanLists = hasGlobalSearch
      ? await safetyPlanSearch(searchInfo)
      : await getSafetyPlanLists(safetyPlanFilters);

    // exit if list result is empty
    if (safetyPlanLists && safetyPlanLists?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(safetyPlanLists);
      });
    }

    // get allocated records from safetylist _ids
    const getIDs: string[] = [];
    safetyPlanLists?.items.forEach((item: OhsSafetyPlanRecord) => {
      if (item._id && item.tier?.type === TierType.T3) getIDs.push(item._id);
    });
    if (getIDs && getIDs.length === 0) {
      return new Promise((resolve) => {
        resolve(safetyPlanLists);
      });
    }
    const allocatedList = await getSafetyPlanAllocatedList(getIDs);

    // map allocated records to safetylist
    const safetyPlanListWithAllocations = parseListAllocations(
      safetyPlanLists,
      allocatedList
    ) as ApiResListsType<OhsSafetyPlanRecord[]> | null;

    return new Promise((resolve) => {
      resolve(safetyPlanListWithAllocations);
    });
  };

  return getSafetyPlanListsReq();
};

export const getSafetyPlanAllocationList = (
  safetyPlanId: string
): Promise<ApiResponseType<SafetyPlanAllocListRes> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.SafetyPlan,
    OhsApiRequestName.AllocationList,
    {
      _id: safetyPlanId,
    }
  );
};

export const getSafetyPlanCopyList = (): Promise<OhsSafetyPlanRecord[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.SafetyPlan, OhsApiRequestName.Copylist, {});
};

export const copySafetyPlan = (safetyPlan: OhsSafetyPlanRecord): Promise<any | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.SafetyPlan, OhsApiRequestName.Copy, {
    _id: safetyPlan._id,
    category: safetyPlan.category,
    categoryOther: safetyPlan.categoryOther,
    subcategory: safetyPlan.subcategory,
    subcategoryOther: safetyPlan.subcategoryOther,
    tzDateCreated: safetyPlan.tzDateCreated,
  });
};

export const allocateSafetyPlan = (
  _id: string,
  allocationItem: OhsAllocationItem
): Promise<any> => {
  return OhsApiRequest(RequestType.Module, ModuleType.SafetyPlan, OhsApiRequestName.Allocate, {
    _id,
    to: [allocationItem],
    tz: getCurrentTimezone(),
  });
};

export const deAllocateSafetyPlan = (
  _id: string,
  allocationItem: OhsAllocationItem
): Promise<any> => {
  return OhsApiRequest(RequestType.Module, ModuleType.SafetyPlan, OhsApiRequestName.Deallocate, {
    _id,
    to: allocationItem,
    tz: getCurrentTimezone(),
  });
};

export const getSafetyPlanTasksAggregate = (_id: string, matrix: boolean) => {
  return OhsApiRequest(RequestType.Module, ModuleType.SafetyPlan, OhsApiRequestName.TaskAggregate, {
    _id,
    matrix,
  });
};
