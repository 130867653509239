import { ApiResListsType } from './api/OhsApiModels';

interface FilterPayload {
  archived: boolean;
  categories?: string[];
  workplaces?: string[];
  viewPreset?: 'view_1' | 'view_2' | 'view_3' | 'view_4';
  sort?: {
    order: number;
    key: string;
  };
}

const OhsFetchAll = async (
  apiFunc: (filterInfo: any) => Promise<ApiResListsType<any[]> | null>,
  payload: FilterPayload
) => {
  const fetchCountPayload = { ...payload, count: true, page: 0, next: '' };
  const countResp = (await apiFunc(fetchCountPayload)) ?? {};

  const { pagination: { totalPages } = { totalPages: 0 } } = countResp as any;

  const responses: any = [];
  const list: any[] = [];
  for (let i = 1; i <= Math.min(10, totalPages); i += 1) {
    const setFilters = {
      ...payload,
      page: i,
      count: false,
    };
    responses.push(apiFunc(setFilters));
  }

  const settledResponses = await Promise.allSettled(responses);
  settledResponses.forEach((result) => {
    if (result?.status === 'fulfilled' && result?.value?.items) {
      list.push(...result.value.items);
    }
  });

  // fetch one by one after page 10

  if (totalPages > 10) {
    let resultList: any[] = [];
    let next = list[list.length - 1]._id;

    do {
      const setFilters = {
        ...payload,
        next,
        count: false,
      };
      // eslint-disable-next-line no-await-in-loop
      const res = await apiFunc(setFilters);
      if (res && res.items && res.items.length) {
        resultList = res.items;
        next = resultList[resultList.length - 1]._id;
        list.push(...resultList);
      }
    } while (resultList.length === 100);
  }

  const fullList = { items: list };
  return fullList as ApiResListsType<any[]> | null;
};

export default OhsFetchAll;
