import { getConfigValue, setPermissions } from '../OhsConfigServices';
import OhsImportPermissions from './OhsImportPermissions';

class OhsImportConfig {
  TITLE: string;

  PERMISSIONS: OhsImportPermissions;

  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsImportPermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}

export default OhsImportConfig;
