import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import { getOhsUser } from 'user/userSlice';
import { getOhsIncidentLists } from 'incident/store/OhsIncidentSlice';
import { ModuleType } from 'global-services/constants/OhsObject';
import { getModuleName } from 'user/OhsUserServices';
import OhsMultipleSelectField from 'global-components/form/fields/OhsMultipleSelectField';

import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}

function OhsIncidentFilters(props: Props) {
  const { classes } = useStyles();

  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const user = useAppSelector(getOhsUser);
  const incident = useAppSelector(getOhsIncidentLists);
  const userTierNum = user?.tierNum ?? 0;
  const moduleName = getModuleName(user?.configs, ModuleType.Incident);
  const getModuleCategoryLists = user.configs.incident?.CREATE_INCIDENT_CATEGORY_LIST ?? [];
  const incidentTypeOptions =
    getModuleCategoryLists.map((item: any) => ({ name: item.title, value: item.title })) ?? [];

  const viewPreset = incident.currentViewPreset;
  const severityOptions = (user.configs.incident?.TASK_SIGNOFF_SEVERITY_LIST || []).map((item) => ({
    name: item,
    value: item,
  }));

  return (
    <Box className={classes.filterContent}>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id="incidentModule.sort"
          required
          options={filter.incidentModule.tableSortKeyLists?.sortColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsMultipleSelectField
          title={`Type of ${moduleName}`}
          id={`incidentModule.${viewPreset}.categories`}
          required={false}
          options={incidentTypeOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {userTierNum <= 3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter
            id={`incidentModule.${viewPreset}.workplaces`}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsMultipleSelectField
          title="Risk Level"
          id={`incidentModule.${viewPreset}.severities`}
          required={false}
          options={severityOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
    </Box>
  );
}

export default OhsIncidentFilters;
