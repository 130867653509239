import React from 'react';

import { OhsConstants } from 'global-services/constants/OhsConstants';

export default function OhsNoTopBarAngularIframe() {
  const bodyUrl = window.location.href.substring(
    window.location.href.indexOf(window.location.host) + window.location.host.length
  );
  const adjustedURL = `/${OhsConstants.LegacyModuleFolder}/#${bodyUrl}`;

  return (
    <div>
      <iframe
        style={{
          height: 'calc(100vh )',
          width: 'calc(100vw)',
          boxSizing: 'border-box',
        }}
        src={adjustedURL}
        id="OhsAngularIframeId"
        title="OhsAngularIframe"
      />
    </div>
  );
}
