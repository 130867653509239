import { getConfigValue, setPermissions } from '../OhsConfigServices';
import OhsBixPermissions from './OhsBixPermissions';

class OhsBixConfig {
  TITLE: string;

  PERMISSIONS: OhsBixPermissions;

  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsBixPermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
  }
}

export default OhsBixConfig;
