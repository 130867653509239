import React, { ReactNode } from 'react';

import { Box, Grid } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Tab } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';

import OhsSearchParams from 'global-services/constants/OhsSearchParams';
import useWindowSize from 'global-components/screenSize/useWindowSize';
import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';

import OhsModuleContent from '../register/OhsModuleContent';
import { OhsTabItem, OhsTabKeyTypes } from './OhsTabModels';

import './OhsTab.css';

export const useStyles = makeStyles<{ screenSize: number }>()((_theme, { screenSize }) => ({
  // for right item when more than 1 tab, if buttons are shared
  moreThanOneTab: {
    position: screenSize < SmallScreenWidthBreakpoint ? 'relative' : 'absolute',
    right: '0',
    top: '-3px',
  },
  onlyOneTab: { minHeight: '40px', position: 'relative' },
  mobileTabs: {
    '.ui.vertical.menu': { width: '100% !important' },
    '.ui.grid>[class*="twelve wide"].column': { width: '100% !important' },
    '.ui.grid>[class*="four wide"].column': { width: '100% !important' },
  },
}));

interface Props {
  tabItems: OhsTabItem[];
  header?: ReactNode;
  rightSide?: ReactNode;
  onTabChange?: (tabkey: OhsTabKeyTypes) => void;
}
const getIndexByTabkey = (tabKey: string | null, tabItems: OhsTabItem[]): number => {
  if (tabKey) {
    return tabItems.findIndex((item) => item.tabKey === tabKey);
  }
  return 0;
};

export const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export default function OhsTab(props: Props) {
  const screenSize = useWindowSize();
  const { classes } = useStyles({ screenSize: screenSize.width });
  const [searchParams, setSearchParams] = useSearchParams();

  const panes = props.tabItems.map((item) => {
    return {
      menuItem: item.tabName,
      render: () => <Tab.Pane>{item.content}</Tab.Pane>,
    };
  });

  const tabKey = searchParams.get(OhsSearchParams.Tab);
  const index = getIndexByTabkey(tabKey, props.tabItems);
  const [activeIndex, setActiveIndex] = React.useState<string | number>('');

  React.useEffect(() => {
    setActiveIndex(index);
  }, [index]);

  const handleTabChange = (event: any, data: any) => {
    if (activeIndex !== data.activeIndex) {
      props.onTabChange?.(props.tabItems[data.activeIndex].tabKey);
      searchParams.set(OhsSearchParams.Tab, props.tabItems[data.activeIndex].tabKey);
      setSearchParams(searchParams);
      setActiveIndex(data.activeIndex);
    }
  };

  const renderHeader = () => props.header || '';
  const renderRightSide = () => props.rightSide || '';
  const renderTopSection = () => {
    // Without Panes
    if (panes.length <= 1) {
      return (
        <Grid container>
          <Grid item xs={12} md={5}>
            <Box display="flex" alignItems="center" sx={{ height: '100%' }}>
              {renderHeader()}
            </Box>
          </Grid>
          {props.rightSide && (
            <Grid item xs={12} md={7}>
              <Box
                display="flex"
                justifyContent="end"
                alignItems="center"
                sx={{ height: '100%' }}
                pb={1}
              >
                {renderRightSide()}
              </Box>
            </Grid>
          )}
        </Grid>
      );
    }

    // With Panes
    return (
      <Box sx={{ width: '100%' }}>
        {props.header && <Box sx={{ width: '100%' }}>{renderHeader()}</Box>}
        <Box sx={{ width: '100%', position: 'relative' }}>
          {props.rightSide && <Box className={classes.moreThanOneTab}>{renderRightSide()}</Box>}
          <Tab
            menu={
              screenSize.width < SmallScreenWidthBreakpoint
                ? { attached: false, tabular: false, vertical: true }
                : undefined
            }
            onTabChange={handleTabChange}
            panes={panes}
            activeIndex={activeIndex}
            className={clsx({
              [classes.mobileTabs]: screenSize.width < SmallScreenWidthBreakpoint,
            })}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ position: 'relative' }}>
      {renderTopSection()}
      {panes.length === 1 && <OhsModuleContent>{props.tabItems[0].content}</OhsModuleContent>}
    </Box>
  );
}

OhsTab.defaultProps = {
  header: undefined,
  rightSide: undefined,
  onTabChange: undefined,
};
