import React from 'react';

import { TextField } from '@mui/material';
import _ from 'lodash';
import validator from 'validator';

import { OhsFormFieldBaseProps } from '../services/OhsFormModels';
import { returnFieldStyle, returnVariant } from '../components/OhsFieldLayout';
import OhsFormFieldLayout from '../components/OhsFormFieldLayout';
import '../OhsFormField.css';

export type OhsCusvalTextType = 'text' | 'textarea' | 'number' | 'time' | 'password' | 'color';

export interface Props extends OhsFormFieldBaseProps {
  type: OhsCusvalTextType;
  rows?: number;
  maxLength?: number;
  minLength?: number;
  comparePasswords?: string;
  passwordValidation?: boolean;
  maxAmount?: number;
  minAmount?: number;
  placeholder?: string;
  validVersion?: boolean;
  minRows?: number;
  maxRows?: number;
  resizeable?: boolean;
}

const defaultProps = {
  rows: undefined,
  maxLength: undefined,
  minLength: undefined,
  comparePasswords: undefined,
  passwordValidation: undefined,
  maxAmount: undefined,
  minAmount: undefined,
  placeholder: undefined,
  validVersion: undefined,
  minRows: undefined,
  maxRows: undefined,
  resizeable: true,
};

function InputEle(props: Props) {
  const error = _.get(props.ohsFormSettings.useFormMethods.formState.errors, props.id);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;
    if (props.type === 'number') {
      let inputValue = value;

      // Remove leading zeros and keep negative sign if present
      if (/^-?0[0-9]/.test(inputValue)) {
        inputValue = inputValue.replace(/^(-?)0+/, '$1');
      }

      // Remove trailing hyphens
      inputValue = inputValue.replace(/-+$/, '');

      // Ensure that only valid numbers and one negative sign are allowed
      if (/^-?[0-9]*\.?[0-9]*$/.test(inputValue)) {
        props.ohsFormSettings.useFormMethods.setValue(props.id, inputValue);
      }
    } else {
      props.ohsFormSettings.useFormMethods.setValue(props.id, value);
    }
    if (error != null) props.ohsFormSettings.useFormMethods.trigger(props.id);
  };

  return (
    <TextField
      id={props.id}
      type={props.type}
      error={error != null}
      fullWidth
      multiline={props.type === 'textarea'}
      inputProps={{
        style: props.type === 'textarea' && props.resizeable ? { resize: 'vertical' } : {},
        'data-testid': props.id,
      }}
      rows={props.rows}
      // minRows and maxRows is used for textarea only. Don't use rows when using both these props.
      minRows={props.minRows}
      maxRows={props.maxRows}
      size="small"
      margin="dense"
      InputLabelProps={{
        shrink: true,
      }}
      placeholder={props.placeholder}
      variant={returnVariant(props.ohsFormSettings.layout)}
      required={props.required}
      disabled={props.disabled}
      {...props.ohsFormSettings.useFormMethods.register(props.id, {
        valueAsNumber: props.type === 'number',
        onChange: handleChange,
        validate: {
          maxLength: (value: any) => {
            if (value && props.maxLength) {
              if (value.length > props.maxLength) {
                return `The length must not be more than ${props.maxLength}.`;
              }
            }

            return true;
          },
          minLength: (value: any) => {
            if (props.minLength && props.required !== false) {
              if (value.length < props.minLength) {
                return `The length must not be less than ${props.minLength}.`;
              }
            }

            return true;
          },
          comparePasswords: (value: any) => {
            if (props.comparePasswords) {
              if (value !== props.comparePasswords) {
                return 'The passwords do not match';
              }
            }

            return true;
          },
          notEmpty: (value: any) => {
            if (props.required === true) {
              if (props.type === 'number' && Number.isNaN(value)) {
                return false;
              }
              if (value == null || (typeof value === 'string' ? value.trim() === '' : false)) {
                return false;
              }
            }

            return true;
          },
          passwordValidation: (value: any) => {
            if (props.passwordValidation && value) {
              const strongRegex = /((?=.*d)(?=.*D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))^.{8,200}$/;
              if (!strongRegex.test(value)) {
                return `The password must be at least 8 characters and
                has any combination of letters, numbers, and symbols.`;
              }
            }

            return true;
          },
          minAmount: (value: any) => {
            if (!_.isNil(value) && props.type === 'number' && !_.isUndefined(props.minAmount)) {
              return !Number.isNaN(value) && value < props.minAmount
                ? `Value should be more than ${props.minAmount}`
                : true;
            }
            return true;
          },
          maxAmount: (value: any) => {
            if (!_.isNil(value) && props.type === 'number' && !_.isUndefined(props.maxAmount)) {
              return !Number.isNaN(value) && value > props.maxAmount
                ? `Value should be less than ${props.maxAmount}`
                : true;
            }
            return true;
          },
          validVersion: (value: any) => {
            if (props.validVersion && value) {
              if (validator.matches(value, '^[0-9]+.[0-9]+.[0-9]+$')) {
                return true;
              }
              return 'Version specifier should follow semantic versioning scheme.';
            }
            return true;
          },
        },
      })}
      style={returnFieldStyle(props.ohsFormSettings.layout)}
    />
  );
}
InputEle.defaultProps = defaultProps;

export default function OhsTextField(props: Props) {
  return <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />;
}

OhsTextField.defaultProps = defaultProps;
