import OhsCusvalDefinition from 'global-components/form/cusvals/OhsCusvalDefinition';
import OhsNoticeBoardRecord from 'notice-board/model/OhsNoticeBoardRecord';

export class OhsModuleCategory {
  category: string;

  subcategory: Array<string>;

  children?: Array<OhsModuleSubCategory>;

  collapsed?: Boolean;

  constructor(category: string, subcategory: Array<string>) {
    this.category = category;
    this.subcategory = subcategory;
  }
}

export interface OhsModuleSubCategory {
  category: string;
  records: Array<OhsNoticeBoardRecord>;
  collapsed: Boolean;
}

export interface OhsCusValCategory {
  category: string;
  subcategory: Array<OhsCusValSubcategory>;
  cusvals: OhsCusvalDefinition;
  options: Array<OhsCategoryOption>;
}
export interface OhsTitleCategory {
  title: string;
}
export interface OhsCusValSubcategory {
  title: string;
  cusvals: OhsCusvalDefinition;
  options: Array<OhsCategoryOption>;
}
export interface OhsCategoryOption {
  opt: OhsCategoryOptionType;
  value: boolean;
}

export enum OhsCategoryOptionType {
  ExpiryDateRequired = 'EXPIRY_DATE_REQUIRED',
}
