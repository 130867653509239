import { OhsUser } from 'user/OhsUser';
import { getWorkplaceOptions } from 'user/OhsUserServices';

export default class OhsReviewPlanFilterModels {
  viewPreset?: 'view_1' | 'view_2' | 'view_3' | 'view_4';

  archived: boolean;

  allocatedToOrg: boolean;

  allocatedToWorkplace: boolean;

  notAllocated: boolean;

  categories: string[];

  sort: string;

  workplaceOwned?: boolean;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  workplaces: string[];

  constructor(user?: OhsUser) {
    this.viewPreset = undefined;
    this.archived = false;
    this.allocatedToOrg = true;
    this.allocatedToWorkplace = true;
    this.notAllocated = true;
    this.categories = [];
    this.workplaces = [];
    this.sort = '{ "key": "category", "order": 1 }';
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Category: A to Z', value: '{ "key": "category", "order": 1 }' },
        { name: 'Category: Z to A', value: '{ "key": "category", "order": -1 }' },
        { name: 'Issue Date: Newest to Oldest', value: '{ "key": "dateIssued", "order": -1 }' },
        { name: 'Issue Date: Oldest to Newest', value: '{ "key": "dateIssued", "order": 1 }' },
        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest ', value: '{ "key": "_id", "order": 1 }' },
      ],
    };

    if (user) {
      const getWorkplaceLists = getWorkplaceOptions(user.configs);
      const workplaceOptions = getWorkplaceLists.map((list: any) => list.id);

      this.workplaces = workplaceOptions;
    }
  }
}
