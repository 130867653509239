import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';

export interface TransportType {
  unNumber: string | null;
  class: string | null;
  subRisks: any[];
  subRisksOther: string[] | null;
  packingGroup: null;
  packingGroupOther: null;
}

export interface SDSType {
  dateIssued: string;
  attachments: OhsAttachment[];
}

export interface PresetLocationType {
  main: string | null;
  mainOther: string | null;
  qMax: number | null;
  qAvg: number | null;
  sub: string | null;
  subOther: string | null;
}

export interface RiskType {
  assessmentCompleted: boolean | null;
  dangerousGood: boolean | null;
  hazardousSubstance: boolean | null;
  classificationGHS: string[];
  attachments: OhsAttachment[];
}

export default class OhsChemicalRecord {
  _id: string;

  tier?: OhsTier;

  allocationActive: boolean;

  presetLocations: PresetLocationType[];

  locations: PresetLocationType[];

  createdBy: OhsModuleCreateBy;

  dateCreated: string;

  emergencyContactPhone: string | null;

  name: string;

  purpose: string | null;

  risk: RiskType;

  shippingName: string | null;

  supplierName: string | null;

  subRows: any[];

  allocationOf: { _id: string; type: ModuleType.Chemical } | undefined;

  allocatedToSelf?: boolean;

  transport: TransportType;

  tzDateCreated: string;

  sds: SDSType;

  attachments?: OhsAttachment[];

  complete?: boolean | null;

  constructor(tier?: OhsTier) {
    this._id = '';
    this.tier = tier;
    this.allocationActive = true;
    this.presetLocations = [];
    this.locations = [];
    this.createdBy = {
      type: ModuleType.User,
      _id: '',
      name: '',
      email: '',
    };
    this.dateCreated = '';
    this.emergencyContactPhone = '';

    this.name = '';
    this.purpose = '';
    this.risk = {
      assessmentCompleted: null,
      classificationGHS: [],
      dangerousGood: null,
      hazardousSubstance: null,
      attachments: [],
    };
    this.subRows = [];
    this.shippingName = '';
    this.supplierName = null;
    this.transport = {
      unNumber: null,
      class: null,
      subRisks: [],
      subRisksOther: null,
      packingGroup: null,
      packingGroupOther: null,
    };
    this.tzDateCreated = '';
    this.sds = {
      dateIssued: '',
      attachments: [],
    };
    this.complete = false;
    this.attachments = [];
  }
}
