export const OhsSessionToken = 'x-safetychampion-token';

export const OhsMultiUserSessionToken = 'x-safetychampion-multiuser-options-token';

export const OhsUploadAttachmentDetailsHeader = 'x-safetychampion-tus-response';

export const OhsLinkAccessAttachmentsDownloadHeader =
  'x-safetychampion-linkaccess-attachments-download-token';

export const OhsLinkAccessAttachmentsUploadHeader =
  'x-safetychampion-linkaccess-attachments-upload-token';

export const OhsMultiUserOptionName = 'multi-users';

export const CookiesExpiresDays = 365;
