import React from 'react';

import { Box, Button } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  userMfaVerifyChallenge,
  userMfaVerifyRecoveryCode,
} from 'user/user-settings/mfa/enrollflows/OhsMfaServices';
import OhsAdmin2RouteList from 'admin2/OhsAdmin2RouteList';
import { ModuleRoute } from 'global-services/constants/OhsRoutes';

import OhsMfaRecoveryCodeForm from './OhsMfaRecoveryCodeForm';

const useStyles = makeStyles()(() => ({
  mainContainer: {
    minHeight: '250px',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    justifyContent: 'center',
  },
  modalTexts: {
    fontSize: '14px',
    textAlign: 'center',
  },
  inputBox: {
    margin: '20px',
  },
  input: {
    width: '100%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    button: {
      height: '30px',
    },
  },
  actionLink: {
    textDecoration: 'none',
    margin: '5px',
    fontSize: '14px',
    cursor: 'pointer',
  },
  recoveryCodes: {
    padding: '2px 0 2px 0',
    width: '100%',
    display: 'block',
    textAlign: 'center',
  },
  actionCodesWrap: {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0 5px 0',
    width: '100%',
  },
  validating: {
    color: '#606060',
    margin: '10px 0 15px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  validatingIcon: {
    verticalAlign: '-4px',
    fontSize: '16px',
    marginRight: '4px',
    width: '18px !important',
    height: '18px !important',
    color: '#606060',
    marginTop: '2px',
  },
  error: {
    color: '#DB2828',
  },
  errorIcon: {
    color: '#DB2828',
  },
}));

interface Props {
  recoveryMethodInfo: any[];
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  onSuccess: (methodId?: string | undefined) => void;
}
function OhsMfaRecoveryCode(props: Props) {
  const navigate = useNavigate();
  const [recoveryCode, setRecoveryCode] = React.useState('');
  const [verifyingCode, setVerifyingCode] = React.useState(false);
  const [codeVerifiedSuccess, setCodeVerifiedSuccess] = React.useState(false);
  const [codeVerifiedResponse, setCodeVerifiedResponse] = React.useState<any>();
  const { classes } = useStyles();
  const handleVerifySuccess = () => {
    props.onSuccess();
  };

  const handleMfaResetOnRecoveryCode = () => {
    const url = generatePath(OhsAdmin2RouteList[ModuleRoute.Settings].url);
    navigate(url);
  };

  const verifyOtp = async () => {
    setVerifyingCode(true);
    const userMfaRecoveryChallenge = await userMfaVerifyChallenge(props.recoveryMethodInfo[0].id);
    if (userMfaRecoveryChallenge) {
      const verifyRecoveryCodeReq = await userMfaVerifyRecoveryCode(recoveryCode, false);
      setVerifyingCode(false);
      if (verifyRecoveryCodeReq) {
        setCodeVerifiedSuccess(verifyRecoveryCodeReq.success);
        setCodeVerifiedResponse(verifyRecoveryCodeReq);
      }
    }
  };

  return (
    <>
      <OhsMfaRecoveryCodeForm
        verifyingCode={verifyingCode}
        codeVerifiedSuccess={codeVerifiedSuccess}
        setRecoveryCode={setRecoveryCode}
        codeVerifiedResponse={codeVerifiedResponse}
      />
      <Box className={classes.footer}>
        {codeVerifiedSuccess ? (
          <>
            <Button
              onClick={() => handleMfaResetOnRecoveryCode()}
              size="small"
              variant="contained"
              color="warning"
              sx={{ color: 'white' }}
            >
              Go to reset MFA
            </Button>
            <Button
              onClick={() => handleVerifySuccess()}
              size="small"
              variant="contained"
              color="primary"
            >
              Done
            </Button>
          </>
        ) : (
          <>
            <Button onClick={() => props.setActiveStep(1)}>Cancel</Button>
            <Button
              disabled={recoveryCode === ''}
              onClick={() => verifyOtp()}
              size="small"
              variant="contained"
              color="primary"
            >
              Next
            </Button>
          </>
        )}
      </Box>
    </>
  );
}

export default OhsMfaRecoveryCode;
