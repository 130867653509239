import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { OhsSignature } from 'global-components/form/fields/signature/OhsSignatureModels';
import { OhsActionsRecord } from 'action/OhsActionModels';
import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsCusval } from 'global-components/form/cusvals/OhsCusvalModel';
import OhsCusvalDefinition from 'global-components/form/cusvals/OhsCusvalDefinition';

import { ExternalBodiesNotified, Severity, EditLogs } from '../OhsIncidentModels';

export default class OhsIncidentTaskRecord {
  attachments: OhsAttachment[];

  signatures?: OhsSignature[];

  complete: boolean | null;

  completionNotes: string;

  createdBy: OhsModuleCreateBy;

  cusvals?: OhsCusval[];

  cusvalsDefinition?: OhsCusvalDefinition;

  dateCreated: string;

  dateDue: string;

  dateCompleted: string;

  dateSignedoff: string;

  signedoffBy: {
    name: string;
    email: string;
  };

  description: string;

  for?: { _id: string; type: ModuleType.Incident };

  links: OhsActionsRecord[];

  type: ModuleType.Task;

  tzDateCreated: string;

  tzDateSignedoff: string;

  _id: string;

  title: string;

  changeNotes?: string;

  changeImplemented?: string;

  lostTimeInjury?: string;

  hazardCategory: string;

  hazardCategoryOther?: string;

  severity: Severity;

  controlReviewed: string;

  controlReviewedOther?: string;

  controlLevel: string;

  controlLevelOther?: string;

  externalBodiesNotified: ExternalBodiesNotified[];

  externalBodiesNotifiedYesNo?: string;

  editComments?: EditLogs[];

  constructor() {
    this.attachments = [];
    this.complete = null;
    this.completionNotes = '';
    this.createdBy = new OhsModuleCreateBy();
    this.dateCreated = '';
    this.dateDue = '';
    this.dateCompleted = '';
    this.dateSignedoff = '';
    this.signedoffBy = {
      name: '',
      email: '',
    };
    this.description = '';
    this.for = undefined;
    this.links = [];
    this.type = ModuleType.Task;
    this.tzDateCreated = '';
    this.tzDateSignedoff = '';
    this._id = '';
    this.title = '';
    this.hazardCategory = '';
    this.severity = null;
    this.controlReviewed = '';
    this.controlLevel = '';
    this.externalBodiesNotified = [];
  }
}
