import OhsCorporateRiskRecord from 'corporate-risk/models/OhsCorporateRiskRecord';
import { ApiResListsType } from 'global-services/api/OhsApiModels';

const parseListAllocations = (
  corporateRiskLists: ApiResListsType<any[]> | null,
  allocationLists: OhsCorporateRiskRecord[] | null
): ApiResListsType<any[]> | null => {
  const crList = corporateRiskLists?.items ?? [];

  if (crList.length > 0) {
    const corporateRiskWithAllocation = crList.map((item: OhsCorporateRiskRecord) => {
      const corporateRiskRecord: OhsCorporateRiskRecord = {
        ...item,
        subRows: [],
      };

      allocationLists?.map((allocItem: OhsCorporateRiskRecord) => {
        const { allocationOf } = allocItem;
        if (item._id === allocItem._id) {
          corporateRiskRecord.subRows.push(allocItem);
        }
        if (allocationOf && allocationOf._id === item._id) {
          corporateRiskRecord.subRows.push(allocItem);
        }
        return null;
      });

      return corporateRiskRecord;
    }) as any[];

    const updateCorporateRiskListwithAllocation = {
      ...corporateRiskLists,
      items: corporateRiskWithAllocation,
    } as unknown as ApiResListsType<any[]>;

    return updateCorporateRiskListwithAllocation;
  }
  return null;
};

export default parseListAllocations;
