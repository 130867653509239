import React from 'react';

import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from 'tss-react/mui';

import { MfaAuthenticatorType } from '../../OhsMfaTypes';

const useStyles = makeStyles()({
  contentWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
  successTitle: {
    color: '#1fba45',
    margin: '5px 0 20px 0',
  },
  content: {
    display: 'flex',
    margin: '0 auto 1px auto',
    flexDirection: 'row',
    textAlign: 'center',
    padding: '0 0 20px 0',
  },
  icon: {
    color: '#1fba45',
  },
  mfamethod: {
    padding: '0 4px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
});

interface Props {
  mfaInfo?: MfaAuthenticatorType;
}
function OhsMfaEnrollSuccess(props: Props) {
  const { mfaInfo } = props;
  const { classes } = useStyles();
  const mfaMethod = mfaInfo?.method ?? '';
  return (
    <Box className={classes.contentWrap}>
      <CheckCircleIcon fontSize="large" className={classes.icon} />
      <Typography variant="body1" gutterBottom className={classes.successTitle}>
        Successfully Activated
      </Typography>

      {mfaMethod === 'app' ? (
        <Box className={classes.content}>Your Authentication App is set.</Box>
      ) : (
        <>
          <Box className={classes.content}>
            Your
            <Box className={classes.mfamethod}>{mfaMethod}</Box>
            Multi-factor authentication (MFA) is set to
          </Box>
          <Box className={classes.content}>
            <strong>{mfaInfo?.name}</strong>
          </Box>
        </>
      )}

      <Box className={classes.content} pt={2} pb={2}>
        {mfaMethod === 'app'
          ? ' Authentication codes will be generated by your authentication app for logging in.'
          : ` Authentication codes will be sent to this ${mfaMethod} for logging in.`}
      </Box>
    </Box>
  );
}

OhsMfaEnrollSuccess.defaultProps = {
  mfaInfo: undefined,
};
export default OhsMfaEnrollSuccess;
