import _ from 'lodash';

import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';
import { ApiResListsType, OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import { OhsHrLookup } from 'human-resource/models/OhsHumanResourceModels';
import { parseHrLookupList } from 'human-resource/OhsHumanResourceServices';
import { fetchCusvalValue } from 'global-components/form/cusvals/OhsCusvalServices';
import { OhsCusval } from 'global-components/form/cusvals/OhsCusvalModel';
import { OhsConfiguredLocationsByTier } from 'global-components/form/fields/geoLocation/OhsGeoLocationModels';

import OhsIncidentTaskRecord from './models/OhsIncidentTaskRecord';
import OhsIncidentRecord from './OhsIncidentRecord';
import { OhsIncidentFilterPayload } from './OhsIncidentModels';

export const setBoolean = (val: any) => {
  const isBoolean = _.isBoolean(val);
  if (isBoolean) {
    if (val) return 'Yes';
    return 'No';
  }
  return val;
};

export const getHrLookupList = async (): Promise<OhsHrLookup[] | null> => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Incident,
    OhsApiRequestName.HRLookup
  );
  if (res?.error) return null;
  const parsedList = parseHrLookupList(res);
  return parsedList;
};

export const listIncidentLocations = (): Promise<OhsConfiguredLocationsByTier | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Incident,
    OhsApiRequestName.ListConfiguredLocations
  );
};

export const addIncident = (payload: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Incident, OhsApiRequestName.Create, payload);
};

export const editIncident = (payload: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Incident, OhsApiRequestName.Edit, payload);
};

export const getIncident = async (
  _id: string,
  disableErrorToast?: boolean
): Promise<OhsIncidentRecord> => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Incident,
    OhsApiRequestName.Fetch,
    { _id },
    { disableErrorToast }
  );
  if (!res) return new OhsIncidentRecord();

  let incidentRecord = {
    ...res,
    categoryCusvalsDefinition: res.categoryCusvals,
    propOrEnvDamageCusvalsDefinition: res.propOrEnvDamageCusvals,
    cusvalsDefinition: res.cusvals,
    categoryCusvals: [],
    propOrEnvDamageCusvals: [],
    cusvals: [],
  };

  const categoryCusvals = fetchCusvalValue(incidentRecord.categoryCusvalsDefinition?._id || '');
  const propOrEnvDamageCusvals = fetchCusvalValue(
    incidentRecord.propOrEnvDamageCusvalsDefinition?._id || ''
  );
  const cusvals = fetchCusvalValue(incidentRecord.cusvalsDefinition?._id || '');

  const [resCategoryCusvals, resPropOrEnvDamageCusvals, resCusvals]: [
    OhsCusval[],
    OhsCusval[],
    OhsCusval[]
  ] = await Promise.all([categoryCusvals, propOrEnvDamageCusvals, cusvals]);
  incidentRecord = {
    ...incidentRecord,
    categoryCusvals: resCategoryCusvals || [],
    propOrEnvDamageCusvals: resPropOrEnvDamageCusvals || [],
    cusvals: resCusvals || [],
  };

  return incidentRecord;
};

export const getIncidentLists = (
  filterInfo: OhsIncidentFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Incident, OhsApiRequestName.List, {
    filter: filterInfo,
  });
};

export const getIncidentTaskRecord = async (
  _id: string,
  taskId: string
): Promise<OhsIncidentTaskRecord> => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Task,
    OhsApiRequestName.Fetch,
    {
      _id: taskId,
      for: {
        _id,
        type: ModuleType.Incident,
      },
    }
  );
  if (!res) {
    return new OhsIncidentTaskRecord();
  }

  const newRes = {
    ...res,
    cusvalsDefinition: res.cusvals,
    cusvals: [],
  };

  return newRes;
};

export const signOffIncident = (payload: any) =>
  OhsApiRequest(RequestType.Module, ModuleType.Incident, OhsApiRequestName.SignOff, payload);

export const editTaskIncident = (payload: any) =>
  OhsApiRequest(RequestType.Module, ModuleType.Incident, OhsApiRequestName.EditTask, payload);

export const archiveIncident = (payload: any, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Incident,
    archived ? OhsApiRequestName.Archive : OhsApiRequestName.UnArchive,
    payload
  );
};

export const deleteIncident = (incidentId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Incident, OhsApiRequestName.Delete, {
    _id: incidentId,
  });
};

export const moveIncident = (payload: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Incident, OhsApiRequestName.Move, payload);
};
