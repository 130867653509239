export default class OhsRecordFieldType {
  key: string;

  name: string;

  description?: string;

  hide?: boolean;

  constructor(key: string, name: string, description?: string) {
    this.key = key;
    this.name = name;
    this.description = description;
  }
}
