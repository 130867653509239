import { styled, TableRow } from '@mui/material';

const OhsRegisterTableRow = styled(TableRow)(({ theme, onClick }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  ...(onClick && {
    '&:hover': {
      backgroundColor: '#0000001a',
    },
  }),
}));

export default OhsRegisterTableRow;
