import { getConfigValue } from '../OhsConfigServices';

class OhsBookmarkConfig {
  TITLE: string;

  ENABLE_FOR_USER: string;

  OPTIONS_LIST: {
    name: string;
    value: { category: 'RED_FLAG' | 'RED_STAR' | 'RED_STAR'; description: string }[];
  };

  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.ENABLE_FOR_USER = getConfigValue(config, 'ENABLE_FOR_USER');
    this.OPTIONS_LIST = getConfigValue(config, 'OPTIONS_LIST');
  }
}

export default OhsBookmarkConfig;
