export default class OhsVisitorFilterModels {
  view1VisitorSiteList: {
    viewPreset: 'view_1';
    categories?: string[];
    archived?: boolean;
    sort: string;
  };

  view2VisitorSiteList: {
    viewPreset: 'view_2';
    sort: string;
  };

  view1VisitorSiteActivityList: {
    viewPreset: 'view_1';
    archived?: boolean;
    sort: string;
  };

  view1VisitorFormList: {
    viewPreset: 'view_1';
    categories?: string[];
    archived?: boolean;
    sort: string;
  };

  view1VisitorPinList: {};

  tableSortKeyLists: {
    sortSiteOptions: { name: string; value: string }[];
    sortActivityOptions: { name: string; value: string }[];
  };

  constructor() {
    this.view1VisitorSiteList = {
      viewPreset: 'view_1',
      categories: [],
      archived: false,
      sort: '{ "key": "_id", "order": -1 }',
    };

    this.view2VisitorSiteList = {
      viewPreset: 'view_2',
      sort: '{ "key": "_id", "order": -1 }',
    };

    this.view1VisitorSiteActivityList = {
      viewPreset: 'view_1',
      archived: false,
      sort: '{ "key": "arriveDate", "order": -1 }',
    };

    this.view1VisitorFormList = {
      viewPreset: 'view_1',
      categories: [],
      archived: false,
      sort: '{ "key": "dateCreated", "order": -1 }',
    };

    this.view1VisitorPinList = {};

    this.tableSortKeyLists = {
      sortSiteOptions: [
        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },
      ],
      sortActivityOptions: [
        {
          name: 'Date/Time Arrived: Newest to Oldest',
          value: '{ "key": "arriveDate", "order": -1 }',
        },
        {
          name: 'Date/Time Arrived: Oldest to Newest',
          value: '{ "key": "arriveDate", "order": 1 }',
        },

        { name: 'Leave Date/Time: Newest to Oldest', value: '{ "key": "leaveDate", "order": -1 }' },
        { name: 'Leave Date/Time: Oldest to Newest', value: '{ "key": "leaveDate", "order": 1 }' },
      ],
    };
  }
}
