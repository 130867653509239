export default class OhsQuickInspection {
  _id?: string;

  dateDue: string;

  notes: string;

  tzDateCreated: string;

  constructor() {
    this.dateDue = '';
    this.notes = '';
    this.tzDateCreated = '';
  }
}
