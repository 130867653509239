import React, { useEffect } from 'react';

import { Box, Grid } from '@mui/material';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import { generatePath, useNavigate } from 'react-router-dom';

import { getOhsUser } from 'user/userSlice';
import { useAppSelector } from 'app/hooks';
import OhsModal from 'global-components/modal/OhsModal';
import OhsTextField from 'global-components/form/fields/OhsTextField';
import OhsDateField from 'global-components/form/fields/OhsDateField';
import OhsRadioField from 'global-components/form/fields/OhsRadioField';
import { OhsFormLayoutType, OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { getCurrentTimezone } from 'global-services/OhsDataParse';
import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import { getModuleName } from 'user/OhsUserServices';

import InspectionRouteList from '../OhsInspectionRouteList';
import OhsQuickInspection from '../models/OhsQuickInspectionModel';
import { createNewOneOffChild, startInspection } from '../OhsInspectionServices';

interface Props {
  isOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeAllModal?: () => void;
  recordData: any;
}

function OhsOneOffInspectionModal(props: Props) {
  const user = useAppSelector(getOhsUser);
  const { isOpen, setModalOpen, recordData, closeAllModal } = props;
  const recordId = recordData._id;
  const tier = recordData?.tier.type || '';
  const navigate = useNavigate();
  const [ohsOhsInspection, setOhsInspection] = React.useState(new OhsQuickInspection());
  const ohsFormSettings = new OhsFormSettings(ohsOhsInspection, OhsFormLayoutType.AngularModalForm);

  const isRecurring = !_.isNil(recordData.frequencyKey) && !_.isNil(recordData.frequencyValue);

  const [inspectionCommencedDate, dateDue]: [string, string] = ohsFormSettings.useFormMethods.watch(
    ['inspectionCommencedDate', 'dateDue']
  );

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleViewInspection = () => {
    const url = generatePath(InspectionRouteList.details.url, {
      inspectionId: recordId,
    });
    navigate(url);
    navigate(0);
  };

  const formSubmit = async (type: string, formData: any) => {
    if (type === 'LATER' && _.isEmpty(formData?.notes.trim())) {
      ohsFormSettings.useFormMethods.setError('notes', {
        type: 'notEmpty',
        message: `
          To ensure visibility of who is expected to complete this inspection,
          instructions are required when Start Later has been selected.
        `,
      });
      return;
    }

    const newFormData = {
      _id: recordData._id,
      dateDue: formData.dateDue,
      notes: formData.notes,
      tzDateCreated: getCurrentTimezone(),
    };
    setOhsInspection(newFormData);

    const callback = (res: any) => {
      const inspectionId = res?.for._id;
      const taskId = res?._id;
      if (closeAllModal) {
        closeAllModal();
      } else {
        handleClose();
      }
      if (type === 'NOW') {
        const payload = {
          _id: inspectionId,
          task: {
            _id: taskId,
          },
          tz: getCurrentTimezone(),
          dateCommenced: formData.dateDue,
        };
        startInspection(payload);
        const url = generatePath(InspectionRouteList.signOff.url, {
          inspectionId,
          taskId,
        });
        navigate(url);
        navigate(0);
      }
    };
    const oneOffRes = await createNewOneOffChild(newFormData);
    if (oneOffRes?._id) {
      callback(oneOffRes);
    }
  };

  useEffect(() => {
    if (inspectionCommencedDate === 'today' && dateDue !== moment().format('YYYY-MM-DD')) {
      const newForm = {
        inspectionCommencedDate,
        dateDue: moment().format('YYYY-MM-DD'),
      };
      ohsFormSettings.useFormMethods.reset(newForm);
    }
  }, [inspectionCommencedDate]);

  useEffect(() => {
    if (isOpen) {
      const newForm = {
        inspectionCommencedDate: 'today',
        dateDue: moment().format('YYYY-MM-DD'),
        notes: '',
      };
      ohsFormSettings.useFormMethods.reset(newForm);
    }
  }, [isOpen]);

  return (
    <OhsModal
      open={isOpen}
      title={`${isRecurring ? 'Recurring ' : ''} Inspection: ${recordData.title}`}
      onClose={handleClose}
      maxWidth="md"
      ContentNode={
        <Box minHeight="360px">
          <Grid container rowSpacing={4}>
            <Grid item xs={4}>
              <OhsRadioField
                title="Due Date"
                id="inspectionCommencedDate"
                required
                options={[
                  { name: 'Today', value: 'today' },
                  { name: 'Other', value: 'other' },
                ]}
                ohsFormSettings={ohsFormSettings}
                row
              />
            </Grid>
            <Grid item xs={8}>
              <OhsDateField
                id="dateDue"
                required
                ohsFormSettings={ohsFormSettings}
                disabled={inspectionCommencedDate === 'today'}
              />
            </Grid>
            <Grid item xs={12}>
              <OhsTextField
                type="textarea"
                title="Instructions"
                description={`
                  Consider including details regarding “by-who” or “where”
                  the inspection is to be completed`}
                id="notes"
                required={false}
                rows={5}
                ohsFormSettings={ohsFormSettings}
              />
            </Grid>
          </Grid>
        </Box>
      }
      actionNode={
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <Button onClick={handleViewInspection} color="blue">
              View {getModuleName(user?.configs, ModuleType.Inspection)}
            </Button>
          </Grid>
          <Grid item xs={12} md={6} container justifyContent="flex-end" spacing={2}>
            <Grid item>
              {tier !== TierType.T5 && (
                <Button
                  onClick={ohsFormSettings.useFormMethods.handleSubmit((res: any) =>
                    formSubmit('LATER', res)
                  )}
                  color="blue"
                >
                  Start Later
                </Button>
              )}
            </Grid>
            <Grid item>
              {user?.tier.type !== TierType.T5}
              <Button
                onClick={ohsFormSettings.useFormMethods.handleSubmit((res: any) =>
                  formSubmit('NOW', res)
                )}
                color="green"
              >
                Start {tier === TierType.T5 ? 'Inspection' : 'Now'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}

OhsOneOffInspectionModal.defaultProps = {
  closeAllModal: undefined,
};
export default OhsOneOffInspectionModal;
