import { getConfigValue } from '../OhsConfigServices';

export default class OhsThemeConfig {
  TITLE!: string;

  ORGANIZATION_LOGO!: string;

  MAIN_BG_COLOR!: string;

  HEADER_BAR_BG_COLOR!: string;

  HEADER_BAR_TEXT_COLOR!: string;

  ANALYTICS_COLOR_THEME!: string;

  constructor(config: any) {
    if (config) {
      this.TITLE = getConfigValue(config, 'TITLE');
      this.ORGANIZATION_LOGO = getConfigValue(config, 'ORGANIZATION_LOGO');
      this.MAIN_BG_COLOR = getConfigValue(config, 'MAIN_BG_COLOR');
      this.HEADER_BAR_BG_COLOR = getConfigValue(config, 'HEADER_BAR_BG_COLOR');
      this.HEADER_BAR_TEXT_COLOR = getConfigValue(config, 'HEADER_BAR_TEXT_COLOR');
      this.ANALYTICS_COLOR_THEME = getConfigValue(config, 'ANALYTICS_COLOR_THEME');
    } else {
      this.MAIN_BG_COLOR = '#e9ebee';
      this.HEADER_BAR_BG_COLOR = '#3b5998';
      this.HEADER_BAR_TEXT_COLOR = '#ffffff';
    }
  }
}
