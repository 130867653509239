import { ApiResListsType } from 'global-services/api/OhsApiModels';
import OhsInspectionRecord from 'inspection/models/OhsInspectionRecord';

const parseListAllocations = (
  inspectionList: ApiResListsType<any[]> | null,
  allocationList: OhsInspectionRecord[] | null
): ApiResListsType<any[]> | null => {
  const spList = inspectionList?.items ?? [];

  if (spList.length > 0) {
    const inspectionWithAllocation = spList.map((item: OhsInspectionRecord) => {
      const inspectionRecord: OhsInspectionRecord = {
        ...item,
        subRows: [],
      };

      const subRowIds = new Set<string>();

      allocationList?.map((allocItem: OhsInspectionRecord) => {
        /* check if the Inspection is allocated to self 
        or it is a signedOff record which is currently deAllocated */
        const { allocatedToSelf, notAllocatedCurrently, allocationOf } = allocItem;
        if ((allocatedToSelf || notAllocatedCurrently) && item._id === allocItem._id) {
          if (!subRowIds.has(allocItem?._id || '')) {
            subRowIds.add(allocItem?._id || '');
            inspectionRecord.subRows.push(allocItem);
          }
        }
        // check if the Inspection is allocated to a workplace
        if (allocationOf && allocationOf._id === item._id) {
          if (!subRowIds.has(allocItem._id || '')) {
            subRowIds.add(allocItem._id || '');
            inspectionRecord.subRows.push(allocItem);
          }
        }
        return null;
      });

      return inspectionRecord;
    }) as any[];

    const updateInspectionWithAllocation = {
      ...inspectionList,
      items: inspectionWithAllocation,
    } as unknown as ApiResListsType<any[]>;

    return updateInspectionWithAllocation;
  }
  return null;
};

export default parseListAllocations;
