import moment from 'moment';

import { ModuleType, TierType } from 'global-services/constants/OhsObject';

export interface OhsDashboardBannerContentButtonClick {
  actionType: 'link' | 'popup' | 'slide';
  target: string;

  // `link` property
  external?: boolean;

  // `popup` properties
  overlay?: boolean;
  overlayOpacity?: number;

  // `slide` properties
  position?: string;
  transition?: string;
  speed?: number;
}

export interface OhsDashboardBannerContentButton {
  type: 'action' | 'menu';
  label: string;
  fgColor: string;
  bgColor: string;
  click?: OhsDashboardBannerContentButtonClick;
  items?: Array<{
    label: string;
    click: OhsDashboardBannerContentButtonClick;
  }>;
}

export interface OhsDashboardBannerCountryAndState {
  country: string;
  state: string;
}

export class OhsDashboardBannerRecord {
  _id?: string;

  type: ModuleType.DashboardBanner;

  tierType: TierType.T2 | TierType.T3 | TierType.T4 | TierType.T5 | '';

  enableOnWeb: boolean;

  enableOnAndroid: boolean;

  enableOnIOS: boolean;

  countryAndState: Array<OhsDashboardBannerCountryAndState>;

  startDate: string;

  endDate: string;

  platform: string;

  content: {
    message: string;
    messageColor: string;
    bannerColors: Array<string>;
    buttons?: Array<OhsDashboardBannerContentButton>;
  };

  constructor() {
    this.type = ModuleType.DashboardBanner;
    this.tierType = '';
    this.enableOnWeb = false;
    this.enableOnAndroid = false;
    this.enableOnIOS = false;

    this.countryAndState = [];

    this.startDate = moment().format('YYYY-MM-DD');
    this.endDate = moment().format('YYYY-MM-DD');
    this.platform = '';

    this.content = {
      message: '(Preview) New Banner Message',
      messageColor: '#FFFFFF',
      bannerColors: ['#204a87', '#000000'],
      buttons: [
        {
          type: 'action',
          label: 'Action Btn',
          fgColor: '#FFFFFF',
          bgColor: '#43A047',
          click: {
            actionType: 'link',
            target: '',
            external: true,
          },
        },
      ],
    };
  }
}

export interface OhsDashboardBannerCustomFilters {
  archived?: boolean;
  next?: string;
}

export interface OhsDashboardBannerFilterPayload extends OhsDashboardBannerCustomFilters {
  page: number;
  count: boolean;
  sort: {
    order: number;
    key: string;
  };
}
