import OhsRecordFieldType from 'global-services/constants/record-field/OhsRecordFieldType';

const OhsTaskNameRecordField = new OhsRecordFieldType('title', 'Name');

export const OhsTaskDeadlineRecordField = new OhsRecordFieldType('dateDue', 'Deadline');

export const OhsTaskSignOffRecordField = new OhsRecordFieldType('signoffDate', 'Sign-off');

export const OhsTaskAssignToRecordField = new OhsRecordFieldType('assignTo', 'Assigned To');

export const OhsTaskFlagRecordField = new OhsRecordFieldType('bookmark', 'Flag');

export const OhsTaskStartDateRecordField = new OhsRecordFieldType('start', 'Start Date');

export const OhsTaskEndDateRecordField = new OhsRecordFieldType('end', 'End Date');

export default OhsTaskNameRecordField;
