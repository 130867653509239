import { ModuleType } from 'global-services/constants/OhsObject';

import { getConfigValue, setPermissions } from '../OhsConfigServices';
import OhsModulePermissions from '../OhsModulePermissions';
import { OhsCusValCategory } from '../OhsModuleCategory';
import OhsFileBoxConfig from '../filebox/OhsFileBoxConfig';

export default class OhsReviewplanConfig {
  TITLE: string;

  PERMISSIONS: OhsModulePermissions;

  CLASSIFICATION_LIST: OhsCusValCategory[];

  MORPH_PERMISSIONS: OhsModulePermissions;

  TYPE = ModuleType.ReviewPlan;

  HOLDER_LIST: string[];

  HOLDER_LOOKUP: { type: string; scope: string }[];

  NOTIFICATION_DUE_TYPE_ENABLED: { name: string; value: string[] }[];

  ORG_RECORDS_ACCESS: { name: string; value: string };

  SIGNOFF_HAND_SIGNATURES: { name: string; value: string };

  fileboxConfig?: OhsFileBoxConfig;

  constructor(config: any, fileboxConfig?: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.CLASSIFICATION_LIST = getConfigValue(config, 'CLASSIFICATION_LIST');
    this.PERMISSIONS = new OhsModulePermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    this.HOLDER_LIST = getConfigValue(config, 'HOLDER_LIST');
    this.HOLDER_LOOKUP = getConfigValue(config, 'HOLDER_LOOKUP');
    this.NOTIFICATION_DUE_TYPE_ENABLED = getConfigValue(config, 'NOTIFICATION_DUE_TYPE_ENABLED');
    this.ORG_RECORDS_ACCESS = getConfigValue(config, 'ORG_RECORDS_ACCESS');
    this.SIGNOFF_HAND_SIGNATURES = getConfigValue(config, 'SIGNOFF_HAND_SIGNATURES');
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(this.MORPH_PERMISSIONS, getConfigValue(config, 'MORPH_PERMISSIONS'));
    if (fileboxConfig) {
      this.fileboxConfig = new OhsFileBoxConfig(fileboxConfig);
    }
  }
}
