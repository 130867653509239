import React, { useEffect, useState } from 'react';

import { setLocalStorage } from 'global-services/OhsDataParse';
import { OhsMultiUserOptionName } from 'global-services/constants/OhsStorageNames';

import { OhsMultiUserLoginOption } from './OhsMultiUserLoginOption';
import OhsMultiLoginPlaceDialog from './OhsMultiLoginPlaceDialog';

interface Props {
  userOptions: OhsMultiUserLoginOption[];
}
export default function OhsMultiLogin(props: Props) {
  const { userOptions } = props;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (userOptions && userOptions.length > 0) {
      setLocalStorage(OhsMultiUserOptionName, userOptions);
      setOpen(true);
    }
  });
  return <OhsMultiLoginPlaceDialog open={open} onClose={() => {}} userOptions={userOptions} />;
}
