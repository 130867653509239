import { ApiResListsType, OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import OhsApiRequest from 'global-services/api/OhsApiRequest';

import { AllocatedListType, OhsChemicalListsFilterPayload } from './models/OhsChemicalModel';
import OhsChemicalAddRecord from './models/OhsChemicalAddRecord';
import OhsChemicalRecord from './models/OhsChemicalRecord';
import OhsChemicalTaskRecord from './models/OhsChemicalTaskRecord';
import parseListAllocations from './utils/OhsParseListAllocations';
import {
  OhsChemicalAllocationLocations,
  OhsChemicalAllocationRecord,
} from './models/OhsChemicalAllocation';

export const addChemical = async (payload: OhsChemicalAddRecord) => {
  const res: OhsChemicalAddRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Chemical,
    OhsApiRequestName.Create,
    payload
  );
  return res;
};

export const editChemical = (payload: OhsChemicalAddRecord) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.Edit, payload);
};

export const editChemicalLocations = (payload: Partial<OhsChemicalAddRecord>) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Chemical,
    OhsApiRequestName.EditLocations,
    payload
  );
};

const getChemicalLists = (
  filterInfo: OhsChemicalListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.List, {
    filter: filterInfo,
  });
};

export const getChemicalAllocatedList = (
  chemicalListIDs: string[]
): Promise<AllocatedListType[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.AllocatedList, {
    _ids: chemicalListIDs,
  });
};

export const archiveUnarchiveChemical = (chemicalId: string | undefined, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Chemical,
    archived ? OhsApiRequestName.Archive : OhsApiRequestName.UnArchive,
    {
      _id: chemicalId,
    }
  );
};
export const deleteChemicalRecord = (chemicalId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.Delete, {
    _id: chemicalId,
  });
};

export const getChemicalDetails = async (
  _id: string,
  disableErrorToast?: boolean
): Promise<OhsChemicalRecord> => {
  const res: OhsChemicalRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Chemical,
    OhsApiRequestName.Fetch,
    { _id },
    { disableErrorToast }
  );
  return res || new OhsChemicalRecord();
};

export const getChemicalTaskRecord = async (
  _id: string,
  taskId: string
): Promise<OhsChemicalTaskRecord> => {
  const res: OhsChemicalTaskRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Task,
    OhsApiRequestName.Fetch,
    {
      _id: taskId,
      for: {
        _id,
        type: ModuleType.Chemical,
      },
    }
  );
  if (!res) {
    return new OhsChemicalTaskRecord();
  }

  return res;
};
export const signOffChemical = (payload: any) =>
  OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.SignOff, payload);

export const editTaskChemical = (payload: any) =>
  OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.EditTask, payload);

export const getChemicalListsWithAllocations = (
  filterInfo: OhsChemicalListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const getChemicalListsReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const chemicalList = await getChemicalLists(filterInfo);
    // exit if list result is empty
    if (chemicalList && chemicalList?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(chemicalList);
      });
    }

    // get allocated records from chemicals _ids
    const getIDs = chemicalList?.items.map((item: OhsChemicalRecord) => item._id) as string[];
    const allocatedList = await getChemicalAllocatedList(getIDs);
    // map allocated records to chemicallist
    const chemicalListWithAllocations = parseListAllocations(
      chemicalList,
      allocatedList
    ) as ApiResListsType<OhsChemicalRecord[]> | null;

    return new Promise((resolve) => {
      resolve(chemicalListWithAllocations);
    });
  };

  return getChemicalListsReq();
};

export const getChemicalAllocationLists = (
  _id: string
): Promise<OhsChemicalAllocationRecord[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.AllocationList, {
    _id,
  });
};

interface AllocateChemicalToWorkplaceProps {
  _id: string;
  to: {
    type: TierType;
    _id: string;
  };
  locations?: OhsChemicalAllocationLocations[];
}
export const allocateChemicalToWorkplaceSelf = (
  payload: AllocateChemicalToWorkplaceProps
): Promise<OhsChemicalAllocationRecord[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.Allocate, {
    ...payload,
  });
};

export const deAllocateChemicalToWorkplaceSelf = (
  payload: AllocateChemicalToWorkplaceProps
): Promise<OhsChemicalAllocationRecord[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Chemical, OhsApiRequestName.Deallocate, {
    ...payload,
  });
};

export default getChemicalLists;
