import { ModuleType } from 'global-services/constants/OhsObject';

import { getConfigValue, setPermissions } from '../OhsConfigServices';
import OhsModulePermissions from '../OhsModulePermissions';
import OhsVdocPermissions from './OhsVdocPermissions';
import { OhsModuleConfig } from '../OhsModuleConfig';
import OhsFileBoxConfig from '../filebox/OhsFileBoxConfig';
import { OhsModuleCategory } from '../OhsModuleCategory';

export default class OhsVdocConfig implements OhsModuleConfig {
  TITLE: string;

  PERMISSIONS: OhsVdocPermissions;

  MORPH_PERMISSIONS: OhsModulePermissions;

  TYPE = ModuleType.VersionDocument;

  CATEGORY_LIST: OhsModuleCategory[];

  fileboxConfig?: OhsFileBoxConfig;

  constructor(config: any, fileboxConfig?: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsVdocPermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(this.MORPH_PERMISSIONS, getConfigValue(config, 'MORPH_PERMISSIONS'));

    this.CATEGORY_LIST = new Array<OhsModuleCategory>();
    const categoryObj = getConfigValue(config, 'CATEGORY_LIST');
    if (categoryObj) {
      const keys = Object.keys(categoryObj);
      keys.forEach((key) => {
        this.CATEGORY_LIST.push(new OhsModuleCategory(key, categoryObj[key]));
      });
    }

    if (fileboxConfig) {
      this.fileboxConfig = new OhsFileBoxConfig(fileboxConfig);
    }
  }
}
