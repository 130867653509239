/* eslint-disable max-len */
export const isMobileDevice = (): boolean => {
  let isMobile = false;
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    isMobile = true;
  }
  return isMobile;
};

export const forwardToDynamicLink = (content: string): void => {
  let forwardDomainUrl;
  if (window.location.href.indexOf('safetychampion.tech') > 0) {
    forwardDomainUrl = 'https://devsafetychampionsoftware.page.link';
  } else {
    forwardDomainUrl = 'https://safetychampionsoftware.page.link';
  }
  window.open(
    `${forwardDomainUrl}/?link=${encodeURIComponent(
      content
    )}&apn=au.com.safetychampion.scmobile&isi=1476478938&ibi=com.safetychampion.SafetyChampionSoftware&ivm=3.13.0&amv=22`,
    '_self'
  );
};
