/* eslint-disable prefer-destructuring */
import { replaceCountryCodeByName } from 'global-services/OhsDataParse';
import { ModuleType, OhsModule, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
// import { getOhsUser } from '../user/OhsUser';

export interface OhsHrRecord extends OhsModule {
  type: ModuleType.HR;
  tier: OhsTier;

  name: string;
  email: string;
  phone: string;
  personalEmail: string;
  personalPhone: string;
  postalAddress: string;
  emergencyContactName: string;
  emergencyContactPhone: string;
  referenceId: string;
  position: string;
  managerName: string;
  managerEmail: string;
  notes: string;

  attachments: OhsAttachment[];
  profilePhoto?: OhsAttachment;
  gender: string;
  genderOther: string | null;
  dateOfBirth: string;
  dateStarted: string;
  createdBy: OhsModuleCreateBy;
  tzDateCreated: string;
  dateCreated: string;
}

export class OhsHrLookup {
  _id: string;

  type: ModuleType.HR;

  // moduleName: string;

  businessName: string;

  workplaceId: string;

  name: string;

  email: string | null;

  position: string;

  constructor(item: string[]) {
    // const user = getOhsUser();
    this._id = item[0];
    this.type = ModuleType.HR;
    // this.moduleName = user?.configs.hr?.TITLE ?? '';
    this.businessName = replaceCountryCodeByName(item[1]);
    this.workplaceId = item[2];
    this.name = item[3];
    this.email = null;
    this.position = item[4];
  }
}
