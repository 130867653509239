import React from 'react';

import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';

import OhsLeftSideDrawer from './leftSideDrawer/OhsLeftSideDrawer';
import OhsRightSideDrawer from './OhsRightSideDrawer';
import OhsTopBar from './topbar/OhsTopBar';
import OhsTopFilterDrawer from './topFilter/OhsTopFilterDrawer';

export default function OhsDashboardBars() {
  const user = useAppSelector(getOhsUser);
  let isDisplay = true;
  if (
    window.location.pathname == null ||
    window.location.pathname.indexOf('/login') === 0 ||
    window.location.pathname.indexOf('/visitor/signout') === 0 ||
    (window.location.pathname.indexOf('/visitor') === 0 &&
      window.location.pathname.indexOf('/sign-in-out') > 0) ||
    (window.location.pathname.indexOf('/visitor') === 0 &&
      window.location.pathname.indexOf('/signout/') > 0)
  ) {
    isDisplay = false;
  }
  return (
    <div>
      {isDisplay === true && user && (
        <div>
          <OhsTopBar />
          <OhsLeftSideDrawer />
          <OhsRightSideDrawer />
          <OhsTopFilterDrawer />
        </div>
      )}
    </div>
  );
}
