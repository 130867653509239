import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { OhsCommunicationTabFilterPayload } from 'human-resource/models/OhsHumanResourceRecord';
import OhsCorrespondenceExchangeRecord from 'correspondence/models/OhsCorrespondenceExchangeRecord';

import {
  getLinkedCorrespondenceExchangesLists,
  getLinkedCorrespondenceExchangesListById,
  getLinkedCorrespondenceRollLists,
} from './OhsHumanResourceServices';
import { OhsUserState } from '../user/userSlice';
import { getOhsLocalStorage, setOhsLocalStorage } from '../global-services/OhsDataParse';

export interface CommunicationListsRecordState {
  isLoading: boolean;
  hrCorrespondences: ApiResListsType<any[]> | null;
  currentPage: number;
}

const initialState: CommunicationListsRecordState = {
  isLoading: false,
  hrCorrespondences: null,
  currentPage: 1,
};

export const getViewPreset = (tierNum: number) => {
  switch (tierNum) {
    case 3:
    case 4:
      return 'view_1';
    default:
      return undefined;
  }
};
const filterOptions = (state: RootState): Partial<OhsCommunicationTabFilterPayload> => {
  const { user, hrCorrespondences } = state;
  const userTier = user.user?.tierNum ?? 0;
  return {
    viewPreset: getViewPreset(userTier),
    next:
      hrCorrespondences.currentPage > 10
        ? hrCorrespondences.hrCorrespondences?.pagination.next ?? ''
        : '',
  };
};

const hrCorrespondenceListsRPC = async (
  user: OhsUserState,
  setHrFilters: OhsCommunicationTabFilterPayload,
  _id?: string
): Promise<any> => {
  let response: any;
  if (_id) {
    response = await getLinkedCorrespondenceExchangesListById(setHrFilters, _id);
  } else {
    response = await getLinkedCorrespondenceExchangesLists(setHrFilters);
  }
  const ids: string[] = Array.from(new Set(response.items.map((r: any) => r.roll._id)));
  if (ids.length > 0) {
    const rollData: any = await getLinkedCorrespondenceRollLists(ids);
    response.items.forEach((r: any) => {
      r.roll = rollData.find((roll: any) => roll._id === r.roll._id);
    });
  }
  // Filter out items where roll is undefined
  response.items = response.items?.filter((r: any) => r.roll !== undefined);
  return response;
};

export const fetchHrCorrespondenceListsAsync = createAsyncThunk<
  ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null,
  string | undefined,
  { state: RootState }
>('hr/fetchHrCorrespondenceList', async (id, thunkAPI) => {
  const { user } = thunkAPI.getState();
  const state = thunkAPI.getState();

  const setHrFilters: OhsCommunicationTabFilterPayload = {
    ...filterOptions(state),
    page: state.hrCorrespondences.currentPage > 10 ? 0 : state.hrCorrespondences.currentPage,
    sort: { order: 1, key: '_id' },
    count: false,
  };

  try {
    const response = await hrCorrespondenceListsRPC(user, setHrFilters, id);
    return response as ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchHrCorrespondenceListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null,
  string | undefined,
  { state: RootState }
>('hr/fetchHrCorrespondenceCountList', async (id, thunkAPI) => {
  const state = thunkAPI.getState();

  const hrFiltersWithCount: OhsCommunicationTabFilterPayload = {
    ...filterOptions(state),
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  try {
    const response = id
      ? await getLinkedCorrespondenceExchangesListById(hrFiltersWithCount, id)
      : await getLinkedCorrespondenceExchangesLists(hrFiltersWithCount);
    return response as ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const hrCorrespondenceSlice = createSlice({
  name: 'hrCorrespondences',
  initialState,
  reducers: {
    setHrCorrespondenceCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHrCorrespondenceListsAsync.pending, (state) => {
        state.isLoading = true;
        state.hrCorrespondences = {
          items: [],
          pagination: state.hrCorrespondences?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchHrCorrespondenceListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.hrCorrespondences = action.payload;
          if (state?.hrCorrespondences?.pagination?.totalPages) {
            // store total pages to localstorage
            setOhsLocalStorage(
              'hrCorrespondencesListtotalPages',
              state.hrCorrespondences.pagination.totalPages
            );
          }
          // get last _id and set it to pagination next
          let setItemsLastId = '';
          const hrCorrespondenceListsItems = [...state.hrCorrespondences.items];
          if (hrCorrespondenceListsItems.length > 0) {
            setItemsLastId = hrCorrespondenceListsItems.pop()._id;
          }

          state.hrCorrespondences.pagination.next = setItemsLastId;

          state.hrCorrespondences.pagination.totalPages =
            getOhsLocalStorage('hrCorrespondencesListtotalPages') ?? 0;
          state.isLoading = false;
        }
      })
      .addCase(fetchHrCorrespondenceListsAsync.rejected, (state) => {
        state.hrCorrespondences = null;
      })
      .addCase(fetchHrCorrespondenceListsCountAsync.fulfilled, (state, action) => {
        if (state.hrCorrespondences && action.payload && action.payload.pagination) {
          setOhsLocalStorage(
            'hrCorrespondencesListtotalPages',
            action?.payload?.pagination?.totalPages
          );
          state.hrCorrespondences.pagination.totalPages =
            action?.payload?.pagination?.totalPages ?? 0;
        }
      });
  },
});

const communicationState = (state: RootState) => state.hrCorrespondences;
// Memoized Selectors
export const getOhsHrCorrespondenceModuleState = createSelector(
  [communicationState],
  (hrCorrespondences) => hrCorrespondences
);
export const { setHrCorrespondenceCurrentPage } = hrCorrespondenceSlice.actions;
export const hrCorrespondenceModuleReducer = hrCorrespondenceSlice.reducer;
