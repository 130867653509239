import { ModuleType, TierType } from 'global-services/constants/OhsObject';

import OhsStandardCountries from '../../global-services/constants/code/OhsStandardCountries';

export default class OhsCorrespondenceContractorLookUp {
  _id: string;

  type: ModuleType.Contractor;

  moduleName: string;

  businessName: string;

  contactName: string | null;

  contactEmail: string | null;

  classifications?: string[];

  primary: boolean;

  tier: {
    type: TierType;

    name: string;

    state: string | null;

    country: string | null;
  };

  constructor(
    _id: string,
    businessName: string,
    contactName: string | null,
    contactEmail: string | null,
    classification: string[],
    tier: any,
    primary: boolean
  ) {
    this._id = _id;
    this.type = ModuleType.Contractor;
    this.moduleName = '';
    this.businessName = businessName;
    this.contactEmail = contactEmail;
    this.contactName = contactName;
    this.primary = primary;
    this.classifications = classification;
    this.tier = {
      ...tier,
      country: OhsStandardCountries.find((c) => c.id.toString() === tier.country)?.name,
    };
  }
}
