import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsApiRequestName, RequestType, ApiResponseType } from 'global-services/api/OhsApiModels';
import { OhsMobileAppAdminVersionRecord } from 'mobile-app-admin/version/OhsMobileAppAdminVersionRecord';

export const addMobileAppAdminVersion = (payload: any) => {
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.MobileAppAdmin,
    OhsApiRequestName.MobileAppAdminVersionCreate,
    payload
  );
};

export const editMobileAppAdminVersion = (payload: any) => {
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.MobileAppAdmin,
    OhsApiRequestName.MobileAppAdminVersionEdit,
    payload
  );
};

export const getMobileAppAdminVersionLists = async (
  platformName: string
): Promise<ApiResponseType<{ items: OhsMobileAppAdminVersionRecord[] }> | null> => {
  const a: any = await OhsApiRequest(
    RequestType.Admin,
    ModuleType.MobileAppAdmin,
    OhsApiRequestName.MobileAppAdminVersionList,
    {
      platform: {
        name: platformName,
      },
    }
  );
  return a;
};

export const editMobileAppAdminVersionUpdateMarker = (
  payload: any
): Promise<ApiResponseType<{ items: OhsMobileAppAdminVersionRecord[] }> | null> => {
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.MobileAppAdmin,
    OhsApiRequestName.MobileAppAdminVersionUpdateMarker,
    payload
  );
};
