/* eslint-disable react/require-default-props */
import React from 'react';

import OhsRegisterTableMoveRow from '../OhsRegisterTableMoveRow';
import OhsRegisterTableSubRow from '../OhsRegisterTableSubRow';

interface Props {
  dataLists: any[];
  moveRowFunc?: any;
  prepareRow: any;
  onRowClick?: (item: any) => void;
}

function OhsRegisterTableRowList(props: Props) {
  return (
    <>
      {props.dataLists.map((row: any, index: number) => {
        props.prepareRow(row);

        const cellNum = row.cells.length;
        return (
          <React.Fragment key={index}>
            {row.depth === 0 ? (
              <OhsRegisterTableMoveRow
                row={row}
                index={index}
                key={index}
                onRowClick={props.onRowClick}
              />
            ) : (
              <OhsRegisterTableSubRow {...row.getRowProps()} row={row} cellNum={cellNum} />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}

export default OhsRegisterTableRowList;
