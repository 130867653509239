import { TableSortLabel } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';

const OhsRegisterTableSortLabel = withStyles(() =>
  createStyles({
    root: {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
      '&$active': {
        color: 'white',
      },
    },
    active: {},
    icon: {
      color: 'inherit !important',
    },
  })
)(TableSortLabel);

export default OhsRegisterTableSortLabel;
