/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { OhsFilterList } from './OhsFilterModels';

export const getViewPreset = (tierNum: number) => {
  switch (tierNum) {
    case 1:
      return 'view_1';
    case 2:
      return 'view_1';
    case 3:
      return 'view_2';
    case 4:
      return 'view_3';
    default:
      return undefined;
  }
};

export const getViewPresetFilters = (tierNum: number, filter: OhsFilterList) => {
  switch (tierNum) {
    case 3:
      return {};
    case 4:
      return {};
    default:
      return {};
  }
};
