import React from 'react';

import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material/styles';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.custom?.MAIN_BG_COLOR,
    minHeight: 'calc(100vh - 80px)',
  },
}));

type Props = {
  children: React.ReactNode;
};

export default function OhsModuleBackground(props: Props) {
  const { classes } = useStyles();
  return (
    <Box p={3} className={classes.root}>
      {props.children}
    </Box>
  );
}
