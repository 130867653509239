import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';

export default class OhsNoticeBoardRecord {
  _id: string;

  tier?: OhsTier;

  allocationActive: boolean;

  allocated: boolean;

  createdBy: OhsModuleCreateBy;

  dateCreated: string;

  title: string;

  category: string | null;

  categoryOther: string | null;

  subcategory: string | null;

  subcategoryOther: string | null;

  description: string | null;

  subRows: any[];

  allocationOf: { _id: string; type: ModuleType.Noticeboard } | undefined;

  allocatedToSelf?: boolean;

  tzDateCreated: string;

  attachments?: OhsAttachment[];

  constructor(tier?: OhsTier) {
    this._id = '';
    this.tier = tier;
    this.allocationActive = true;
    this.allocated = false;
    this.createdBy = {
      type: ModuleType.User,
      _id: '',
      name: '',
      email: '',
    };
    this.dateCreated = '';
    this.title = '';
    this.description = '';
    this.category = null;
    this.categoryOther = null;
    this.subcategory = null;
    this.subcategoryOther = null;
    this.subRows = [];
    this.tzDateCreated = '';
    this.attachments = [];
  }
}
