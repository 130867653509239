import { OhsUser } from 'user/OhsUser';
import { getModuleOptions, getWorkplaceOptions } from 'user/OhsUserServices';
import { ModuleType } from 'global-services/constants/OhsObject';

import OhsSafetyPlanFilterModels from './OhsSafetyPlanFilterModels';
import OhsTaskFilterModels from './OhsTaskFilterModels';
import OhsActionFilterModels from './OhsActionFilterModels';
import OhsContractorFilterModels from './OhsContractorFilterModels';
import OhsHumanResourceFilterModels from './OhsHumanResourceFilterModels';
import OhsInspectionFilterModels from './OhsInspectionFilterModels';
import OhsHumanResourceTrainingFilterModels from './OhsHumanResourceTrainingFilterModels';
import OhsIncidentFilterModels from './OhsIncidentFilterModels';
import OhsDocumentsFilterModels from './OhsDocumentsFilterModels';
import OhsVisitorFilterModels from './OhsVisitorFilterModels';
import OhsCorporateRiskFilterModels from './OhsCorporateRiskFilterModels';
import OhsReviewPlanFilterModels from './OhsReviewPlanFilterModels';
import OhsChemicalFilterModels from './OhsChemicalFilterModels';
import OhsCorrespondenceFilterModels from './OhsCorrespondenceFilterModels';
import OhsDashboardBannerFilterModels from './OhsDashboardBannerFilterModels';
import OhsAssetFilterModels from './OhsAssetFilterModels';
import OhsNoticeBoardFilterModels from './OhsNoticeBoardFilterModels';
import OhsMobileAppAdminFilterModels from './OhsMobileAppAdminFilterModels';
import OhsOnboardingFilterModels from './OhsOnboardingFilterModels';
import OhsContractorCorrespondenceFilterModels from './OhsContractorCorrespondenceFilterModels';
import OhsHrCorrespondenceFilterModels from './OhsHrCorrespondenceFilterModels';
import OhsCorrespondenceExchangeFilterModels from './OhsCorrespondenceExchangeFilterModels';

export class OhsFilterList {
  modules: string[];

  workplaces: string[];

  taskModule: OhsTaskFilterModels;

  safetyPlanModule: OhsSafetyPlanFilterModels;

  actionModule: OhsActionFilterModels;

  contractorModule: OhsContractorFilterModels;

  contractorCorrespondenceModule: OhsContractorCorrespondenceFilterModels;

  hrCorrespondenceModule: OhsHrCorrespondenceFilterModels;

  humanResourceModule: OhsHumanResourceFilterModels;

  correspondenceModule: OhsCorrespondenceFilterModels;

  correspondenceExchangeModule: OhsCorrespondenceExchangeFilterModels;

  hrTrainingModule: OhsHumanResourceTrainingFilterModels;

  documentsModule: OhsDocumentsFilterModels;

  chemicalModule: OhsChemicalFilterModels;

  inspectionModule: OhsInspectionFilterModels;

  incidentModule: OhsIncidentFilterModels;

  visitorModule: OhsVisitorFilterModels;

  corporateRiskModule: OhsCorporateRiskFilterModels;

  reviewPlanModule: OhsReviewPlanFilterModels;

  dashboardBannerModule: OhsDashboardBannerFilterModels;

  assetModule: OhsAssetFilterModels;

  noticeboardModule: OhsNoticeBoardFilterModels;

  mobileAppAdminModule: OhsMobileAppAdminFilterModels;

  onboardingModule: OhsOnboardingFilterModels;

  constructor(user?: OhsUser, tabKey?: string | null) {
    this.modules = [
      ModuleType.SafetyPlan,
      ModuleType.Action,
      ModuleType.HR,
      ModuleType.Correspondence,
      ModuleType.VersionDocument,
      ModuleType.Inspection,
      ModuleType.Incident,
      ModuleType.Contractor,
      ModuleType.Visitor,
      ModuleType.ReviewPlan,
      ModuleType.Chemical,
      ModuleType.CorporateRisk,
      ModuleType.Asset,
      ModuleType.Noticeboard,
      ModuleType.Freemium,
    ];
    this.workplaces = [];
    this.taskModule = new OhsTaskFilterModels(user, tabKey);
    this.safetyPlanModule = new OhsSafetyPlanFilterModels(user, tabKey);
    this.actionModule = new OhsActionFilterModels();
    this.humanResourceModule = new OhsHumanResourceFilterModels(user);
    this.correspondenceModule = new OhsCorrespondenceFilterModels();
    this.correspondenceExchangeModule = new OhsCorrespondenceExchangeFilterModels();
    this.correspondenceExchangeModule = new OhsCorrespondenceExchangeFilterModels();
    this.inspectionModule = new OhsInspectionFilterModels();
    this.incidentModule = new OhsIncidentFilterModels();
    this.hrTrainingModule = new OhsHumanResourceTrainingFilterModels(user);
    this.documentsModule = new OhsDocumentsFilterModels();
    this.chemicalModule = new OhsChemicalFilterModels();
    this.contractorModule = new OhsContractorFilterModels();
    this.contractorCorrespondenceModule = new OhsContractorCorrespondenceFilterModels();
    this.hrCorrespondenceModule = new OhsHrCorrespondenceFilterModels();
    this.visitorModule = new OhsVisitorFilterModels();
    this.reviewPlanModule = new OhsReviewPlanFilterModels();
    this.corporateRiskModule = new OhsCorporateRiskFilterModels(user);
    this.dashboardBannerModule = new OhsDashboardBannerFilterModels();
    this.assetModule = new OhsAssetFilterModels(user);
    this.noticeboardModule = new OhsNoticeBoardFilterModels();
    this.mobileAppAdminModule = new OhsMobileAppAdminFilterModels();
    this.onboardingModule = new OhsOnboardingFilterModels();
    if (user) {
      // set ALL modules and workplaces (id) to respective filters
      const moduleOptionLists = getModuleOptions(user?.configs);
      const moduleOptions = moduleOptionLists.map((list: any) => list.key);

      const getWorkplaceLists = getWorkplaceOptions(user.configs);
      const workplaceOptions = getWorkplaceLists.map((list: any) => list.id);
      this.modules = moduleOptions;
      this.workplaces = workplaceOptions;
    }
  }
}

export interface OhsTaskFilterTypes {
  key: string;
  name: string;
  value: boolean | string;
  type: string;
}

export const OhsPotentialFilterModuleList: string[] = [
  'core.module.action',
  'core.module.contractor',
  'core.module.incident',
  'core.module.reviewplan',
  'core.module.safetyplan',
  'core.module.training',
  'core.module.vdoc',
  'core.module.inspection',
  'core.module.asset',
  'core.module.visitor',
  'core.module.chemical',
  'core.module.noticeboard',
  'core.module.crisk',
];

export const GlobalFilterName = 'globalfilter';
