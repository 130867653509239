export default class OhsMobileAppAdminFilterModels {
  view1MobileAppAdminVersionList: {};

  sort: string;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  constructor() {
    this.view1MobileAppAdminVersionList = {};

    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'ID: High to Low ', value: '{ "key": "_id", "order": 1 }' },
        { name: 'ID: Low to High', value: '{ "key": "_id", "order": -1 }' },
      ],
    };
    this.sort = '{ "key": "_id", "order": 1 }';
  }
}
