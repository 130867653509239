class OhsBreadcrumbsNameAndRef {
  homeRef: { href: string } | null;

  moduleRef: { href: string; name: string } | null;

  parentOperationRef: { href: string; name: string } | null;

  leafName: { name: string } | null;

  constructor() {
    this.homeRef = null;
    this.moduleRef = null;
    this.parentOperationRef = null;
    this.leafName = null;
  }
}

export default OhsBreadcrumbsNameAndRef;
