/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React from 'react';

import { Button, ButtonGroup, Icon } from 'semantic-ui-react';
import { Box } from '@mui/material';
import _ from 'lodash';

import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';
import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import { RowOperations } from 'global-components/register/OhsRegisterModels';
import OhsLeaveButton from 'global-components/buttons/OhsLeaveButton';
import { getCurrentURLModuleConfig } from 'user/OhsUserServices';
import { OhsUser } from 'user/OhsUser';

import OhsAllocationButtons from './OhsAllocationButtons';
import OhsCopyButtons from './OhsCopyButtons';
import OhsUpdateButtons from './OhsUpdateButtons';
import { registerButtonStyles } from '../../OhsModuleHeader';
import OhsExportButton from '../../../buttons/OhsExportButton';
import OhsBulkAllocationButtons from './OhsBulkAllocationButtons';
import OhsAssignUnAssignButton from './OhsAssignUnAssignButton';

interface Props {
  row: any;
  rowOperations?: RowOperations;
}
function OhsRegisterTableColOperationButtons({ row, rowOperations }: Props) {
  const { classes } = registerButtonStyles();
  const user = useAppSelector(getOhsUser) as OhsUser;
  const moduleConfig = user?.configs && getCurrentURLModuleConfig(user?.configs);

  const withSignOff = () => {
    if (rowOperations && rowOperations.signOffButton) {
      if (rowOperations.signOffButton?.permission === true) return true;
      if (rowOperations.signOffButton?.permissionFunc) {
        if (rowOperations.signOffButton?.permissionFunc(row.original) === true) return true;
      }
    }
    return false;
  };
  const withAssign = () => {
    if (rowOperations && rowOperations.assignRowButton) {
      if (rowOperations.assignRowButton?.permissionFunc) {
        if (rowOperations.assignRowButton?.permissionFunc(row.original) === true) return true;
      }
    }
    return false;
  };
  const withDelete = () => {
    let withDeleteFlag = false;
    if (rowOperations && rowOperations.openDeleteModalButton) {
      if (rowOperations.openDeleteModalButton?.permission === true) withDeleteFlag = true;
      if (rowOperations.openDeleteModalButton?.permissionFunc) {
        if (rowOperations.openDeleteModalButton?.permissionFunc(row.original) === true)
          withDeleteFlag = true;
      }
    }
    // not sub row
    if (row.depth !== 0) {
      withDeleteFlag = false;
    }
    // not allocated record
    if (row.original.allocationOf != null) {
      // EXCEPT SCHEDULED INSPECTION
      let isScheduleInspection = false;
      if (
        row.original.type === ModuleType.Inspection &&
        row.original.execute &&
        row.original.execute.task
      ) {
        isScheduleInspection = true;
      }
      if (isScheduleInspection !== true) {
        withDeleteFlag = false;
      }
    }

    return withDeleteFlag;
  };

  const withAllocate = () => {
    if (rowOperations && rowOperations.openAllocationModalButton) {
      if (rowOperations.openAllocationModalButton?.permission === true) return true;
      if (rowOperations.openAllocationModalButton?.permissionFunc) {
        if (rowOperations.openAllocationModalButton?.permissionFunc(row.original) === true)
          return true;
      }
    }

    return false;
  };

  const withClone = () => {
    if (rowOperations && rowOperations.cloneButton) {
      if (rowOperations.cloneButton?.permission === true) return true;
      if (rowOperations.cloneButton?.permissionFunc) {
        if (rowOperations.cloneButton?.permissionFunc(row.original) === true) return true;
      }
    }
    return false;
  };

  const withEdit = () => {
    if (rowOperations && rowOperations.editButton) {
      if (rowOperations.editButton?.permission === true) return true;
      if (rowOperations.editButton?.permissionFunc) {
        if (rowOperations.editButton?.permissionFunc(row.original) === true) return true;
      }
    }
    return false;
  };

  const withLinksView = () => {
    if (rowOperations && rowOperations.linksViewButton) {
      if (rowOperations.linksViewButton?.permission === true) return true;
      if (rowOperations.linksViewButton?.permissionFunc) {
        if (rowOperations.linksViewButton?.permissionFunc(row.original) === true) return true;
      }
    }
    return false;
  };

  const withCopy = () => {
    if (rowOperations?.copyButton?.func != null) {
      if (rowOperations.copyButton?.permission !== true) return false;
      if (rowOperations.copyButton?.permissionFunc) {
        return rowOperations.copyButton?.permissionFunc(row.original);
      }
      return true;
    }
    return false;
  };

  // Took reference from COPY button implementation
  const withUpdate = () => {
    if (rowOperations?.updateButton?.func != null) {
      if (rowOperations.updateButton?.permission !== true) return false;
      if (rowOperations.updateButton?.permissionFunc) {
        return rowOperations.updateButton?.permissionFunc(row.original);
      }
      return true;
    }
    return false;
  };

  const returnStyle = () => {
    if (rowOperations?.maxWidth) return {};
    return { minWidth: '100px' };
  };

  return (
    <Box sx={returnStyle()} maxWidth={rowOperations?.maxWidth || '100%'}>
      {rowOperations && (
        <ButtonGroup size="tiny" floated="right" compact={false} className={classes.buttonGroup}>
          {rowOperations.linksButton && row.depth === 0 && (
            <Button
              onClick={() => {
                if (rowOperations.linksButton) rowOperations.linksButton.func(row.original);
              }}
              className={classes.button}
              color="blue"
            >
              <Icon className={classes.buttonIcons} name="expand" />
              <span className={classes.hideForSmall}>Links</span>
            </Button>
          )}
          {rowOperations.tasksButton &&
            rowOperations.tasksButton?.permissionFunc?.(row.original) && (
              <Button
                onClick={() => {
                  if (rowOperations.tasksButton) rowOperations.tasksButton.func(row.original);
                }}
                className={classes.button}
                color="blue"
              >
                <Icon className={classes.buttonIcons} name="tasks" />
                <span className={classes.hideForSmall}>Tasks</span>
              </Button>
            )}
          {rowOperations.viewFunc && (
            <Button
              onClick={() => {
                if (rowOperations.viewFunc) rowOperations.viewFunc(row.original);
              }}
              className={classes.button}
              color="blue"
            >
              View
            </Button>
          )}
          {rowOperations.viewButton &&
            rowOperations.viewButton.permission &&
            rowOperations.viewButton.permission === true && (
              <Button
                onClick={() => {
                  if (rowOperations.viewButton) rowOperations.viewButton.func(row.original);
                }}
                className={classes.button}
                color="blue"
              >
                {rowOperations.viewButton.label !== '' ? rowOperations.viewButton.label : 'View'}
                {rowOperations.viewButton?.icon ? (
                  <Icon
                    className={classes.buttonIcons}
                    name={
                      rowOperations?.viewButton?.icon
                        ? rowOperations.viewButton.icon
                        : 'pencil alternate'
                    }
                  />
                ) : null}
              </Button>
            )}
          {withLinksView() && (
            <Button
              onClick={() => {
                if (rowOperations.linksViewButton) rowOperations.linksViewButton.func(row.original);
              }}
              className={classes.button}
              color="blue"
            >
              {rowOperations?.linksViewButton?.label !== ''
                ? rowOperations?.linksViewButton?.label
                : 'View'}
            </Button>
          )}
          {rowOperations.addButton &&
            rowOperations.addButton.permission &&
            rowOperations.addButton.permission && (
              <Button
                onClick={() => {
                  if (rowOperations.addButton) rowOperations.addButton.func(row.original);
                }}
                className={classes.button}
                color="green"
                icon={!rowOperations.addButton.label}
              >
                <Icon className={classes.buttonIcons} name="plus" />
                {rowOperations.addButton.label !== '' ? rowOperations.addButton.label : 'Add'}
                {rowOperations.addButton?.icon ? (
                  <Icon
                    className={classes.buttonIcons}
                    name={
                      rowOperations?.addButton?.icon
                        ? rowOperations.addButton.icon
                        : 'pencil alternate'
                    }
                  />
                ) : null}
              </Button>
            )}
          {rowOperations.sendButton && rowOperations.sendButton?.permissionFunc?.(row.original) && (
            <Button
              onClick={() => {
                if (rowOperations.sendButton) rowOperations.sendButton.func(row.original);
              }}
              className={classes.button}
              color={row.original.status === 'READY_TO_SEND' ? 'green' : 'yellow'}
              icon={!rowOperations.sendButton.label}
            >
              <Icon className={classes.buttonIcons} name="mail" />
              {rowOperations.sendButton.label ??
                (row.original.status === 'READY_TO_SEND' ? 'Send' : 'Resend')}
            </Button>
          )}
          {withEdit() && (
            <Button
              onClick={() => {
                rowOperations.editButton?.func(row.original, row.index);
              }}
              className={classes.button}
              color="blue"
            >
              <Icon className={classes.buttonIcons} name="pencil" />
              <span className={classes.hideForSmall}>Edit</span>
            </Button>
          )}
          {rowOperations.assignRowButton &&
            rowOperations.assignRowButton.permissionFunc?.(row.original) && (
              <Button
                onClick={() => {
                  rowOperations.assignRowButton?.func(row.original);
                }}
                className={classes.button}
                color="teal"
              >
                <Icon className={classes.buttonIcons} name="users" />
                <span className={classes.hideForSmall}>Assign</span>
              </Button>
            )}
          {withSignOff() && (
            <Button
              onClick={() => {
                rowOperations.signOffButton?.func(row.original);
              }}
              className={classes.button}
              color="green"
            >
              <Icon
                className={classes.buttonIcons}
                name={
                  rowOperations?.signOffButton?.icon
                    ? rowOperations.signOffButton.icon
                    : 'pencil alternate'
                }
              />
              <span className={classes.hideForSmall}>
                {rowOperations?.signOffButton?.name ? rowOperations.signOffButton.name : 'Sign-off'}
              </span>
            </Button>
          )}
          {withAllocate() && row.depth === 0 && row.original.allocationOf == null && (
            <Button
              onClick={() => {
                if (rowOperations.openAllocationModalButton?.func)
                  rowOperations.openAllocationModalButton?.func(row.original, row.index);
              }}
              className={classes.button}
              size="small"
              color="green"
            >
              <Icon className={classes.buttonIcons} name="retweet" />
              <span className={classes.hideForSmall}>Allocate</span>
            </Button>
          )}
          {withClone() && row.depth === 0 && (
            <Button
              onClick={() => {
                rowOperations.cloneButton?.func(row.original);
              }}
              className={classes.button}
              color="green"
            >
              <Icon className={classes.buttonIcons} name="expand" />
              <span className={classes.hideForSmall}>Clone</span>
            </Button>
          )}
          {rowOperations.archiveButton &&
            rowOperations.archiveButton.func &&
            ((rowOperations.archiveButton.permission &&
              user?.tier.type === row.original.tier?.type) ||
              (rowOperations.archiveButton.morphPermission &&
                user?.tier.type === TierType.T3 &&
                row.original.tier?.type === TierType.T4) ||
              rowOperations.archiveButton?.permissionFunc?.(row.original)) &&
            row.depth === 0 &&
            row.original.allocationOf == null && (
              <Button
                onClick={() => {
                  if (rowOperations.archiveButton?.func)
                    rowOperations.archiveButton?.func(row.original, row.original.archived !== true);
                }}
                className={classes.button}
                color={row.original.archived !== true ? undefined : 'green'}
              >
                <Icon
                  className={classes.buttonIcons}
                  name="archive"
                  style={{ margin: '0', fontSize: '14px' }}
                />
              </Button>
            )}
          {rowOperations.assignButton &&
            rowOperations.assignButton.func &&
            rowOperations.assignButton.permission === true &&
            row.depth === 0 && (
              <OhsAssignUnAssignButton
                row={row}
                assignFunc={rowOperations.assignButton?.func}
                isAssigned={row.original._id !== null}
              />
            )}
          {rowOperations.allocationFunc && rowOperations.deAllocationFunc && (
            <OhsAllocationButtons
              row={row}
              allocationFunc={rowOperations.allocationFunc}
              deAllocationFunc={rowOperations.deAllocationFunc}
            />
          )}
          {rowOperations.bulkAllocation && (
            <OhsBulkAllocationButtons
              row={row}
              disabled={rowOperations.bulkAllocation.disabled}
              allocationFunc={rowOperations.bulkAllocation.allocationFunc}
              deAllocationFunc={rowOperations.bulkAllocation.deAllocationFunc}
            />
          )}
          {withCopy() && (
            <OhsCopyButtons
              row={row}
              copyFunc={rowOperations.copyButton?.func}
              showIsCopiedHideButton={rowOperations.copyButton?.showIsCopiedHideButton}
            />
          )}
          {withUpdate() && (
            <OhsUpdateButtons row={row} updateFunc={rowOperations.updateButton?.func} />
          )}
          {rowOperations.leaveButton?.func && row.depth === 0 && (
            <OhsLeaveButton
              onClick={() => {
                if (rowOperations && rowOperations.leaveButton && rowOperations.leaveButton?.func)
                  rowOperations.leaveButton?.func(row);
              }}
            />
          )}
          {rowOperations.exportButton?.func && row.depth === 0 && (
            <OhsExportButton
              onClick={() => {
                if (rowOperations && rowOperations.exportButton && rowOperations.exportButton?.func)
                  rowOperations.exportButton?.func(row.original);
              }}
            />
          )}
          {withDelete() && (
            <Button
              icon
              onClick={() => {
                if (rowOperations.openDeleteModalButton?.func)
                  rowOperations.openDeleteModalButton?.func(row.original, row.index);
              }}
              className={classes.button}
              color="red"
            >
              <Icon className={classes.buttonIcons} name="trash" />
            </Button>
          )}
          {rowOperations.inspectionButton && (
            <Button
              onClick={() => {
                if (rowOperations.inspectionButton)
                  rowOperations.inspectionButton.func(row.original);
              }}
              color="green"
            >
              <Icon className={classes.buttonIcons} name="pencil alternate" />
              <span className={classes.hideForSmall}>Go to {user?.configs.inspection?.TITLE}</span>
            </Button>
          )}
          {rowOperations.xsiSetupButton &&
            rowOperations.xsiSetupButton?.permissionFunc?.(row.original) && (
              <Button
                onClick={() => {
                  if (rowOperations.xsiSetupButton) rowOperations.xsiSetupButton.func(row.original);
                }}
                className={classes.button}
                color="green"
              >
                <Icon className={classes.buttonIcons} name="mail" />
                Setup
              </Button>
            )}
          {rowOperations.disableButton &&
            rowOperations.disableButton?.permissionFunc?.(row.original) && (
              <Button
                onClick={() => {
                  if (rowOperations.disableButton) rowOperations.disableButton.func(row.original);
                }}
                className={classes.button}
                color="blue"
              >
                <Icon className={classes.buttonIcons} name="lock" />
                Disable
              </Button>
            )}
          {rowOperations.enableButton &&
            rowOperations.enableButton?.permissionFunc?.(row.original) && (
              <Button
                onClick={() => {
                  if (rowOperations.enableButton) rowOperations.enableButton.func(row.original);
                }}
                className={classes.button}
                color="green"
              >
                <Icon className={classes.buttonIcons} name="lock open" />
                Enable
              </Button>
            )}
          {rowOperations.optionalButton &&
            rowOperations.optionalButton?.permissionFunc?.(row.original) && (
              <Button
                onClick={() => {
                  if (rowOperations.optionalButton) rowOperations.optionalButton.func(row.original);
                }}
                className={classes.button}
                color="orange"
              >
                {rowOperations.optionalButton.label !== ''
                  ? rowOperations.optionalButton.label
                  : 'Optional'}
              </Button>
            )}
          {rowOperations.enforceButton &&
            rowOperations.enforceButton?.permissionFunc?.(row.original) && (
              <Button
                onClick={() => {
                  if (rowOperations.enforceButton) rowOperations.enforceButton.func(row.original);
                }}
                className={classes.button}
                color="green"
              >
                {rowOperations.enforceButton.label !== ''
                  ? rowOperations.enforceButton.label
                  : 'Enforce'}
              </Button>
            )}
          {rowOperations.cloneAndLinkButton &&
            (rowOperations.cloneAndLinkButton.permission ||
              rowOperations.cloneAndLinkButton?.permissionFunc?.(row.original)) && (
              <Button
                onClick={() => {
                  if (rowOperations.cloneAndLinkButton)
                    rowOperations.cloneAndLinkButton.func(row.original);
                }}
                className={classes.button}
                color="green"
              >
                <Icon className={classes.buttonIcons} name="copy" />
                {rowOperations.cloneAndLinkButton.label !== ''
                  ? rowOperations.cloneAndLinkButton.label
                  : 'Clone & Link'}
              </Button>
            )}
          {rowOperations.linkButton &&
            (rowOperations.linkButton.permission ||
              rowOperations.linkButton?.permissionFunc?.(row.original)) && (
              <Button
                onClick={() => {
                  if (rowOperations.linkButton) rowOperations.linkButton.func(row.original);
                }}
                className={classes.button}
                color="green"
              >
                <Icon className={classes.buttonIcons} name="linkify" />
                {rowOperations.linkButton.label !== '' ? rowOperations.linkButton.label : 'Link'}
              </Button>
            )}
        </ButtonGroup>
      )}
    </Box>
  );
}

export default OhsRegisterTableColOperationButtons;
