import React from 'react';

import OhsModal from 'global-components/modal/OhsModal';

import OhsMfaEnrollMain from './OhsMfaEnrollMain';
import useOhsMfaEnrollStepsHook from '../hooks/OhsMfaStepsHook';

interface Props {
  open: boolean;
  title: string;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleOnClose: (currentStep: number) => void;
}

function OhsMfaEnrollModal(props: Props) {
  const { activeStep, setActiveStep } = useOhsMfaEnrollStepsHook();

  React.useEffect(() => {
    if (props.open) setActiveStep(0);
  }, [props.open]);

  return (
    <OhsModal
      open={props.open}
      title={props.title}
      setModalOpenFunc={props.setModalOpen}
      maxWidth="xs"
      headerTextColor="black"
      headerColor="#fafafa"
      ContentNode={<OhsMfaEnrollMain activeStep={activeStep} setActiveStep={setActiveStep} />}
      onClose={() => props.handleOnClose(activeStep)}
    />
  );
}

export default OhsMfaEnrollModal;
