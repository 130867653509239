import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Box, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import OhsLoginFooter from 'login/OhsLoginFooter';

import scLogo from '../../angular-modules/build/img/sc-logo.png';
import OhsResetPasswordForm, { LoginInputs } from './OhsResetPasswordRequestForm';
import resetUserPasswordSend, { ResetPwStageTypes } from './OhsResetPasswordServices';

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    width: '50%',
  },
}));

export default function OhsResetPasswordRequest() {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const submitLogin = async (data: LoginInputs) => {
    const identityResponse: any = await resetUserPasswordSend({
      stage: ResetPwStageTypes.Identify,
      email: data.email,
    });
    if (identityResponse.success) {
      navigate('/login');
      toast.success('Please check your email to continue to reset your password.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box className={classes.paper}>
        <img className={classes.logo} src={scLogo} alt="Safety Champion" />
        <OhsResetPasswordForm submitLogin={submitLogin} />
        <OhsLoginFooter />
      </Box>
    </Container>
  );
}
