import ModuleHomeUrl from 'dashboard/leftSideDrawer/ModuleHomeUrl';

import { OhsUser } from '../../user/OhsUser';
import OhsBreadcrumbsNameAndRef from './OhsBreadcrumbsNameAndRef';

const OhsBreadCrumbSafetyplan = (
  ohsBreadcrumbsNameAndRef: OhsBreadcrumbsNameAndRef,
  modulePath: string,
  subPath: string,
  viewPath: string,
  operationPath: string,
  user: OhsUser
) => {
  const urlParams = new URLSearchParams(window.location.search);
  const paramType = urlParams.get('type') || 'tp';

  if (subPath === 'home') {
    ohsBreadcrumbsNameAndRef.leafName = {
      name: user.configs.safetyplan?.TITLE ?? '',
    };
  } else if (operationPath.indexOf('/details') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).safetyplan,
      name: user.configs.safetyplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'View' };
  } else if (operationPath.indexOf('aggregate') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).safetyplan,
      name: user.configs.safetyplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?type=${paramType}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Aggregate' };
  } else if (operationPath.indexOf('signoff') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).safetyplan,
      name: user.configs.safetyplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?type=${paramType}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Signoff' };
  } else if (operationPath.indexOf('completed') > -1) {
    ohsBreadcrumbsNameAndRef.moduleRef = {
      href: (ModuleHomeUrl[user.tier.type] as any).safetyplan,
      name: user.configs.safetyplan?.TITLE ?? '',
    };
    ohsBreadcrumbsNameAndRef.parentOperationRef = {
      href: `/${modulePath}/${subPath}/details?type=${paramType}`,
      name: 'View',
    };
    ohsBreadcrumbsNameAndRef.leafName = { name: 'Task' };
  }
  return ohsBreadcrumbsNameAndRef;
};
export default OhsBreadCrumbSafetyplan;
