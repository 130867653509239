import React from 'react';

import _ from 'lodash';

import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsCurrentUser, OhsOrgControl, OhsOrgControlPayload } from 'user/OhsUserModels';
import { fetchOrgControl } from 'user/OhsUserServices';
import { OhsMfaMethodSettings } from 'user/OhsUser';
import { AdminSwitchOption, OhsAdmin2Config } from 'user/configurations/admin2/OhsAdmin2Config';

import { OhsMethods } from '../mfa/OhsMfaTypes';
import { getMfaMethodPermission, getMfaSettingsPermission } from '../mfa/OhsMfaUtils';

export default function useOhsOrgControl(currentUser?: OhsCurrentUser) {
  const [mfaMethodSettings, setMfaMethodSettings] = React.useState<OhsMfaMethodSettings>({
    app: false,
    sms: false,
    email: false,
  });
  const [currentMfaConfig, setCurrentMfaConfig] = React.useState<AdminSwitchOption>('optional');
  const [orgControl, setOhsOrgControl] = React.useState<OhsOrgControl>();

  const getOrgControl = async (user: OhsCurrentUser) => {
    const orgControlPayload: OhsOrgControlPayload = {
      orgTier: { ...user.tier },
    };
    const orgLimits: OhsOrgControl = await fetchOrgControl(orgControlPayload);
    if (orgLimits) {
      setOhsOrgControl(orgLimits);
      setMfaMethodSettings(orgLimits.mfa);
    }
  };

  React.useEffect(() => {
    if (currentUser) {
      const userTier = parseInt(currentUser?.tier.type.substring(11), 10);

      // Get user configuration - MFA
      const configAdmin2 = _.find(currentUser.configuration, { type: ModuleType.Admin2 });
      const mfaConfig: AdminSwitchOption = new OhsAdmin2Config(configAdmin2).MFA;
      setCurrentMfaConfig(mfaConfig);

      // Get Org Control / Limits
      if (userTier > 2) getOrgControl(currentUser);
    }
  }, [currentUser]);

  const hasMfaMethodPermission = (mfaMethod: OhsMethods, mfaEnabledList: string[]) => {
    return getMfaMethodPermission(mfaMethod, mfaMethodSettings, currentMfaConfig, mfaEnabledList);
  };

  const hasMfaSettingsPermission = (mfaEnabledList: string[]) => {
    return getMfaSettingsPermission(currentMfaConfig, mfaEnabledList);
  };

  return { hasMfaSettingsPermission, hasMfaMethodPermission, orgControl, getOrgControl } as const;
}
