import { ModuleType } from 'global-services/constants/OhsObject';

import { OhsCusValCategory } from '../OhsModuleCategory';
import { getConfigValue, setPermissions } from '../OhsConfigServices';
import { OhsModuleConfig } from '../OhsModuleConfig';
import OhsModulePermissions from '../OhsModulePermissions';

class OhsSafetyplanConfig implements OhsModuleConfig {
  TITLE: string;

  CLASSIFICATION_LIST: OhsCusValCategory[];

  PERMISSIONS: OhsModulePermissions;

  MORPH_PERMISSIONS: OhsModulePermissions;

  TYPE = ModuleType.SafetyPlan;

  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.CLASSIFICATION_LIST = getConfigValue(config, 'CLASSIFICATION_LIST');
    this.PERMISSIONS = new OhsModulePermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(this.MORPH_PERMISSIONS, getConfigValue(config, 'MORPH_PERMISSIONS'));
  }
}

export default OhsSafetyplanConfig;
