import React from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';
import OhsRequireAuth from 'global-components/OhsRequireAuth';
import OhsBackdrop from 'global-components/backdrop/OhsBackdrop';
import { ModuleRoute } from 'global-services/constants/OhsRoutes';

import OhsOnboardingRouteList from './OhsOnboardingRouteList';
import OhsOnboardingList from './list/OhsOnboardingList';

const ROUTES = [
  {
    path: OhsOnboardingRouteList[ModuleRoute.Home].path,
    element: <OhsOnboardingList />,
    permission: OhsOnboardingRouteList[ModuleRoute.Home].permission,
  },
];

function OhsOnboardingRoutes() {
  const user = useAppSelector(getOhsUser);

  return (
    <div>
      <React.Suspense fallback={<OhsBackdrop />}>
        <Routes>
          {ROUTES.map((route: any) =>
            route.permission(user) ? (
              <Route
                key={route.path}
                path={route.path}
                element={<OhsRequireAuth>{route.element}</OhsRequireAuth>}
              />
            ) : null
          )}
          <Route
            path="*"
            element={<Navigate to={OhsOnboardingRouteList[ModuleRoute.Home].path} replace />}
          />
        </Routes>
      </React.Suspense>
    </div>
  );
}

export default OhsOnboardingRoutes;
