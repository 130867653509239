import { useCallback, useEffect, useMemo, useState } from 'react';

import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import _ from 'lodash';

import { OhsApiRequestName } from 'global-services/api/OhsApiModels';
import { ModuleType } from 'global-services/constants/OhsObject';

// import OhsApi from '../../global-services/api/OhsApi';

export const useAxiosSpinner = () => {
  const [counter, setCounter] = useState(0);
  const excludeRpcLists = [
    OhsApiRequestName.List,
    OhsApiRequestName.ListAssigned,
    OhsApiRequestName.AssignAggregate,
    OhsApiRequestName.BookmarkedList,
    OhsApiRequestName.BookmarkedListByTask,
    OhsApiRequestName.CustomList,
    OhsApiRequestName.MultiUserList,
    OhsApiRequestName.GetTokenWidgets,
    OhsApiRequestName.MultiUser,
    OhsApiRequestName.AssignTo,
    OhsApiRequestName.UnAssignTo,
    OhsApiRequestName.AssignStatus,
  ];
  const excludeRpcTypeNameList: { type: ModuleType; name: OhsApiRequestName }[] = [
    { type: ModuleType.User, name: OhsApiRequestName.Fetch },
    { type: ModuleType.User, name: OhsApiRequestName.MfaList },
    { type: ModuleType.User, name: OhsApiRequestName.Whoami },
  ];
  const inc = useCallback(() => setCounter(counter + 1), [setCounter]);
  const dec = useCallback(() => setCounter(counter - 1), [setCounter]);

  const interceptors = useMemo(
    () => ({
      request: (config: InternalAxiosRequestConfig) => {
        const isInExcludeRpcLists = excludeRpcLists.includes(config.data.method.name);
        const isInExcludeRpcTypeNameList =
          _.find(excludeRpcTypeNameList, {
            type: config.data.method.type,
            name: config.data.method.name,
          }) != null;

        if (isInExcludeRpcLists !== true && isInExcludeRpcTypeNameList !== true) {
          inc();
        }
        return config;
      },
      response: (response: AxiosResponse) => {
        dec();
        return response;
      },
      error: (error: AxiosError) => {
        dec();
        return Promise.reject(error);
      },
    }),
    [inc, dec]
  ); // create the interceptors

  useEffect(() => {
    // add request interceptors
    const requestId = axios.interceptors.request.use(interceptors.request, interceptors.error);
    // add response interceptors
    const responseId = axios.interceptors.response.use(interceptors.response, interceptors.error);
    return () => {
      // remove all intercepts when done
      axios.interceptors.request.eject(requestId);
      axios.interceptors.response.eject(responseId);
    };
  }, [interceptors]);

  return [counter > 0];
};

export default axios;
