import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';

import OhsAssetRecord from './OhsAssetRecord';

export default class OhsAssetTaskRecord {
  _id: string;

  referenceId: string | null;

  attachments: OhsAttachment[];

  previousAttachments?: OhsAttachment[];

  complete: boolean;

  dateCompleted: string;

  dateDue: string;

  dateSignedoff: string;

  description: string;

  reviewNotes: string;

  signatures?: OhsAttachment[];

  signedoffBy: OhsModuleCreateBy;

  tier?: OhsTier | null;

  title: string;

  type: string;

  tzDateSignedoff: string;

  save?: boolean;

  for?: { _id: string; type: ModuleType.Asset; save?: any };

  partialChronology: OhsAssetRecord[];

  constructor(tier?: OhsTier) {
    this.referenceId = null;
    this.attachments = new Array<OhsAttachment>();
    this.complete = true;
    this.dateCompleted = '';
    this.dateDue = '';
    this.dateSignedoff = '';
    this.description = '';
    this.type = '';
    this._id = '';
    this.reviewNotes = '';
    this.signatures = new Array<OhsAttachment>();
    this.signedoffBy = new OhsModuleCreateBy();
    this.type = '';
    this.tier = tier;
    this.title = '';
    this.tzDateSignedoff = '';
    this.for = undefined;
    this.partialChronology = [];
  }
}
