import _ from 'lodash';

import { RequestType, OhsApiRequestName } from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import OhsStandardStates from 'global-services/constants/code/OhsStandardStates';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import OhsTier2 from 'global-services/constants/tier/OhsTier2';
import OhsTier3 from 'global-services/constants/tier/OhsTier3';

export const getTierList = (payload: any) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.List, payload);
};

export const createTier2Tier3 = (
  tierType: OhsTier2 | OhsTier3,
  profileTitle: string,
  name: string
) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.Create, {
    type: tierType,
    profileTitle,
    name,
  });
};

export const createOrgWithPresets = (profileTitle: string, name: string) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.CreateOrgPreset, {
    profileTitle,
    name,
  });
};

export const fetchBulkUsers = (
  orgTier: {
    type: TierType.T3;
    _id: string;
  },
  filter: any
) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.List, {
    type: 'core.user',
    orgTier,
    filter: {
      users: filter,
    },
  });
};

export const processBulkOp = (
  tier: any,
  users: any[],
  bulkOp: 'disable' | 'enable' | 'unoverride'
) => {
  let requestName;

  if (bulkOp === 'disable') {
    requestName = OhsApiRequestName.BulkDisable;
  } else if (bulkOp === 'enable') {
    requestName = OhsApiRequestName.BulkEnable;
  } else {
    requestName = OhsApiRequestName.BulkUnoverride;
  }

  const payload = {
    type: tier.type,
    _id: tier._id,

    users: _.map(users, (user) => {
      return user._id;
    }),
  };

  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, requestName, payload);
};

export const processBulkMove = (tier: any, moveTo: any, users: any[]) => {
  const payload = {
    type: tier.type,
    _id: tier._id,

    target: {
      type: moveTo.tier.type,
      _id: moveTo.tier._id,
    },

    profile: {
      type: moveTo.profile.type,
      _id: moveTo.profile._id,
    },

    users: _.map(users, (user) => {
      return user._id;
    }),
  };

  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.BulkMove, payload);
};

export const processBulkSwitch = (tier: any, profile: any, users: any[]) => {
  const payload = {
    type: tier.type,
    _id: tier._id,

    profile: {
      type: profile.type,
      _id: profile._id,
    },

    users: _.map(users, (user) => {
      return user._id;
    }),
  };

  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.BulkSwitch, payload);
};

export const fetchIndex = (type: TierType.T1 | TierType.T2, _id: string) => {
  const payload = { type, _id };

  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.Index, payload);
};

export const fetchIndexOnT345 = () => {
  const payload = {};
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.Index, payload);
};

export const createProfile = (
  type: ModuleType.TierProfile | ModuleType.UserProfile,
  applyTo: OhsTier,
  title: string,
  description: string,
  orgAdminTier: null | {
    type: TierType.T2;
    _id: string;
  },
  orgTier: null | {
    type: TierType.T3;
    _id: string;
  }
) => {
  const payload = {
    type,
    applyTo,
    title,
    description,
  };

  if (orgAdminTier) {
    _.assign(payload, { orgAdminTier });
  }

  if (orgTier) {
    _.assign(payload, { orgTier });
  }

  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.Create, payload);
};

export const createWpWg = (payload: any) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.Create, payload);
};

export const deleteEntity = (payload: any) => {
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.Admin2,
    OhsApiRequestName.Delete,
    payload,

    { disableToast: true }
  );
};

export const getMovableOrgLists = (orgAdmin: any) => {
  const payload = {
    orgAdminTier: {
      type: orgAdmin.type,
      _id: orgAdmin._id,
    },
  };

  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.Admin2,
    OhsApiRequestName.ListOrgShift,
    payload
  );
};

export const confirmOrgMove = (org: any, orgAdmin: any) => {
  const payload = {
    sourceOrganization: {
      type: org.type,
      _id: org._id,
    },
    targetOrgAdmin: {
      type: orgAdmin.type,
      _id: orgAdmin._id,
    },
  };

  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.Admin2,
    OhsApiRequestName.OrgShift,
    payload,

    { disableToast: true }
  );
};

export const disableEntity = (payload: any, enable?: boolean) => {
  if (enable) {
    _.assign(payload, { unset: true });
  }

  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.Disable, payload);
};

export const generalEdit = (payload: any) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.Edit, payload, {
    disableToast: true,
  });
};

export const switchTierProfile = (
  type: TierType.T2 | TierType.T3 | TierType.T4 | TierType.T5,
  _id: string,
  profile: null | {
    type: ModuleType.TierProfile;
    _id: string;
  },
  orgAdminTier: null | {
    type: TierType.T2;
    _id: string;
  },
  orgTier: null | {
    type: TierType.T3;
    _id: string;
  },
  workplaceTier: null | {
    type: TierType.T4;
    _id: string;
  }
) => {
  const payload = { type, _id, profile };

  if (orgAdminTier) {
    _.assign(payload, { orgAdminTier });
  }

  if (orgTier) {
    _.assign(payload, { orgTier });
  }

  if (workplaceTier) {
    _.assign(payload, { workplaceTier });
  }

  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.Admin2,
    OhsApiRequestName.ProfileSwitch,
    payload
  );
};

export const switchProfileByPayload = (payload: any) => {
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.Admin2,
    OhsApiRequestName.ProfileSwitch,
    payload
  );
};

export const getTier3 = (tierId: string) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.Fetch, {
    type: TierType.T3,
    _id: tierId,
  });
};

export const checkUserExists = (email: string) => {
  const payload = { email };

  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.UserExists, payload);
};

export const getTierKey = (tier: OhsTier) => {
  let tierKey = '';
  switch (tier.type) {
    case 'core.tier.T2':
      tierKey = 'orgAdminTier';
      break;
    case 'core.tier.T3':
      tierKey = 'orgTier';
      break;
    case 'core.tier.T4':
      tierKey = 'workplaceTier';
      break;
    case 'core.tier.T5':
      tierKey = 'workerGroupTier';
      break;
    default:
      break;
  }

  return tierKey;
};

export const createUser = (forTier: any, user: any, isMultiUser: boolean) => {
  const payload: any = {
    type: 'core.user',
    profile: {
      type: 'core.profile.user',
      _id: user.profile._id,
    },
    name: user.name,
    email: user.email,
    password: user.password,
    multiuser: isMultiUser === true,
  };

  if (forTier.type === 'core.tier.T4') {
    payload[getTierKey(forTier.parent)] = {
      type: forTier.parent.type,
      _id: forTier.parent._id,
    };

    payload[getTierKey(forTier)] = {
      type: forTier.type,
      _id: forTier._id,
    };
  } else if (forTier.type === 'core.tier.T5') {
    payload[getTierKey(forTier.parent.parent)] = {
      type: forTier.parent.parent.type,
      _id: forTier.parent.parent._id,
    };

    payload[getTierKey(forTier.parent)] = {
      type: forTier.parent.type,
      _id: forTier.parent._id,
    };

    payload[getTierKey(forTier)] = {
      type: forTier.type,
      _id: forTier._id,
    };
  } else {
    payload[getTierKey(forTier)] = {
      type: forTier.type,
      _id: forTier._id,
    };
  }

  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.Admin2,
    OhsApiRequestName.Create,
    payload,

    { disableToast: true }
  );
};

export const generatedStateOptions = (countryId: string) => {
  const stateOptions: any[] = [];
  if (countryId != null) {
    OhsStandardStates.forEach((state) => {
      if (state.country_id === countryId) {
        stateOptions.push(state);
      }
    });
  }
  return stateOptions;
};

export const fetchProfileList = (tier: any, isUserProfile: boolean, applyTo: OhsTier) => {
  let type = ModuleType.TierProfile;
  if (isUserProfile) type = ModuleType.UserProfile;
  const payload = {
    type,
    applyTo: tier.type,
  } as any;

  if (applyTo) {
    payload.applyTo = applyTo;
  }

  if (tier.type === TierType.T4) {
    payload[getTierKey(tier.parent)] = {
      type: tier.parent.type,
      _id: tier.parent._id,
    };
  } else if (tier.type === TierType.T5) {
    payload[getTierKey(tier.parent.parent)] = {
      type: tier.parent.parent.type,
      _id: tier.parent.parent._id,
    };
  } else {
    payload[getTierKey(tier)] = {
      type: tier.type,
      _id: tier._id,
    };
  }
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.List, payload);
};

export const fetchUserList = (tier: any) => {
  const payload: any = {
    type: 'core.user',
  };

  if (tier.type === 'core.tier.T4') {
    payload[getTierKey(tier.parent)] = {
      type: tier.parent.type,
      _id: tier.parent._id,
    };

    payload[getTierKey(tier)] = {
      type: tier.type,
      _id: tier._id,
    };
  } else if (tier.type === 'core.tier.T5') {
    payload[getTierKey(tier.parent.parent)] = {
      type: tier.parent.parent.type,
      _id: tier.parent.parent._id,
    };

    payload[getTierKey(tier.parent)] = {
      type: tier.parent.type,
      _id: tier.parent._id,
    };

    payload[getTierKey(tier)] = {
      type: tier.type,
      _id: tier._id,
    };
  } else {
    payload.applyTo = tier.type;

    payload[getTierKey(tier)] = {
      type: tier.type,
      _id: tier._id,
    };
  }

  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.List, payload);
};

export const transformFilebox = (configuration: any) => {
  const deleteIndexArray: any[] = [];

  configuration.forEach((item: any, key: any) => {
    if (item.type.indexOf('core.module.filebox.') === 0) {
      configuration.forEach((item1: { type: string; filebox: any }) => {
        if (
          item.type.substring('core.module.filebox.'.length) ===
          item1.type.substring('core.module.'.length)
        ) {
          item1.filebox = item;
          deleteIndexArray.push(key);
        }
      });
    }

    if (item.type === 'core.module.training') {
      configuration.forEach((item1: any) => {
        if (item1.type === 'core.module.hr') {
          item1.training = item;
          deleteIndexArray.push(key);
        }
      });
    }
  });

  deleteIndexArray.forEach((item, key) => {
    configuration.splice(item - key, 1);
  });
};

export const fetchProfileDetails = (payload: any) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.Fetch, payload).then(
    (response: any) => {
      transformFilebox(response.configuration);
      return response;
    }
  );
};

export const fetchTierDetails = (payload: any) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.Fetch, payload).then(
    (response: any) => {
      transformFilebox(response.configuration);
      return response;
    }
  );
};

export const fetchUserDetails = (payload: any) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.Fetch, payload).then(
    (response: any) => {
      transformFilebox(response.configuration);
      return response;
    }
  );
};

export const editConfiguration = (payload: any) => {
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.Admin2,
    OhsApiRequestName.EditConfiguration,
    payload
  );
};

export const cusvalDefCreate = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Cusval,
    OhsApiRequestName.CusvalDefCreate,
    payload
  );
};

export const cusvalDefFetch = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Cusval,
    OhsApiRequestName.CusvalDefFetch,
    payload
  );
};

export const cusvalDefPresetFetch = () => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Cusval,
    OhsApiRequestName.CusvalDefPresetFetch,
    {}
  );
};

export const cusvalDefEdit = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Cusval,
    OhsApiRequestName.CusvalDefEdit,
    payload
  );
};

export const cusvalDefBulkClone = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Cusval,
    OhsApiRequestName.CusvalDefBulkClone,
    payload
  );
};

export const getOrgControlDetails = (tierId: string) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.OrgControlFetch, {
    orgTier: {
      type: TierType.T3,
      _id: tierId,
    },
  });
};

export const setOrgControlDetails = (
  tierId: string,
  userLimit: any,
  hrLimit: any,
  multiuserCreate: any,
  scLogin: any,
  xsiAccess: any,
  workplaceLimit: any,
  workerGroupLimit: any,
  sms: any,
  push: any,
  mfa: any,
  sso: any,
  supersetCustom: any,
  devOptions: any
) => {
  const payload = {
    orgTier: { type: 'core.tier.T3', _id: tierId },
    multiuserCreate,
    scLogin,
    xsiAccess,
    sms: null,
    push: null,
    mfa: null,
  } as any;

  if (userLimit) {
    payload.userLimit = userLimit;
  }

  if (hrLimit) {
    payload.hrLimit = hrLimit;
  }

  if (workerGroupLimit) {
    payload.workerGroupLimit = workerGroupLimit;
  }

  if (workplaceLimit) {
    payload.workplaceLimit = workplaceLimit;
  }

  if (sms) {
    payload.sms = sms;
  }

  if (push) {
    payload.push = push;
  }

  if (mfa) {
    payload.mfa = mfa;
  }

  if (sso) {
    payload.sso = sso;
  }

  if (supersetCustom) {
    payload.supersetCustom = supersetCustom;
  }

  if (devOptions) {
    if (devOptions.emailOverrideList) {
      payload.emailOverrideList = devOptions.emailOverrideList;
    }

    if (devOptions.smsOverrideList) {
      payload.smsOverrideList = devOptions.smsOverrideList;
    }
  }
  return OhsApiRequest(
    RequestType.Admin,
    ModuleType.Admin2,
    OhsApiRequestName.OrgControlEdit,
    payload
  );
};

export const importRecords = (payload: any, type: 'users' | 'workplaces') => {
  const requestName: any =
    type === 'users' ? OhsApiRequestName.ImportUsers : OhsApiRequestName.ImportWorkplaces;

  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, requestName, payload);
};
