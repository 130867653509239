import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

import { OhsUser } from 'user/OhsUser';

interface CustomPaletteProps {
  custom: {
    MAIN_BG_COLOR: string;
    HEADER_BAR_BG_COLOR: string;
    HEADER_BAR_TEXT_COLOR: string;
    BUTTON_GREEN: string;
    BORDER_GRAY: string;
    FIELD_LABEL: string;
  };
}

declare module '@mui/material/styles' {
  interface Palette extends CustomPaletteProps {}
  interface PaletteOptions extends CustomPaletteProps {}
}

const OhsTheme = (user?: OhsUser) =>
  (() => {
    const theme = user?.configs?.theme;

    return createTheme({
      components: {
        MuiTextField: { defaultProps: { variant: 'standard' } },
        MuiSelect: { defaultProps: { variant: 'standard' } },
        MuiFormControl: { defaultProps: { variant: 'standard' } },
      },
      typography: {
        h1: {
          color: theme?.HEADER_BAR_BG_COLOR,
        },
        h2: {
          color: theme?.HEADER_BAR_BG_COLOR,
        },
        h3: {
          color: theme?.HEADER_BAR_BG_COLOR,
        },
        h4: {
          color: theme?.HEADER_BAR_BG_COLOR,
        },
        h5: {
          color: theme?.HEADER_BAR_BG_COLOR,
        },
        h6: {
          color: theme?.HEADER_BAR_BG_COLOR,
        },
        // Use the system font instead of the default Roboto font.
        fontFamily: ['Lato', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif'].join(','),
      },
      palette: {
        // background: {
        //   default: user.configs.theme.MAIN_BG_COLOR,
        // },
        primary: { main: blue[700] },
        custom: {
          MAIN_BG_COLOR: theme?.MAIN_BG_COLOR || blue[700],
          HEADER_BAR_BG_COLOR: theme?.HEADER_BAR_BG_COLOR || blue[700],
          HEADER_BAR_TEXT_COLOR: theme?.HEADER_BAR_TEXT_COLOR || 'white',
          BUTTON_GREEN: '#21ba45',
          BORDER_GRAY: 'rgba(34,36,38,.15)',
          FIELD_LABEL: '#3b5998',
        },
      },
    });
  })();

export default OhsTheme;
