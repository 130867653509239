import { TableCell } from '@mui/material';
import { createStyles, withStyles } from '@mui/styles';

const OhsRegisterTableBodyCell = withStyles(() =>
  createStyles({
    root: {
      fontSize: '13px',
      maxWidth: '300px',
      overflowWrap: 'break-word',
      padding: '6px 10px',
    },
  })
)(TableCell);

export default OhsRegisterTableBodyCell;
