import React from 'react';

import { Button, Icon } from 'semantic-ui-react';

import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';

import { registerButtonStyles } from '../register/OhsModuleHeader';
import { OhsButtonProps } from './OhsButtonModels';

export default function OhsLeaveButton(props: OhsButtonProps) {
  const { classes } = registerButtonStyles();
  const user = useAppSelector(getOhsUser);

  return (
    <Button
      className={classes.button}
      color="green"
      onClick={props.onClick}
      loading={props.loading}
    >
      <Icon name="sign-out" />

      <span className={classes.hideForSmall}>{user?.configs.visitor?.VISIT_TERMS.leave}</span>
    </Button>
  );
}
