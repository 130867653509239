import React from 'react';

import { Box } from '@mui/material';

import OhsTooltip from 'global-components/form/components/OhsTooltip';

interface Props {
  item: {
    title: string;
    notes: string;
  };
}
function OhsCellColumnWithNotes(props: Props) {
  const { title, notes } = props.item;

  return (
    <>
      <Box style={{ display: 'inline' }} mr={0.8}>
        {title}
      </Box>
      {notes && <OhsTooltip message={notes} iconColor="black" />}
    </>
  );
}

export default OhsCellColumnWithNotes;
