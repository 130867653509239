import { OhsActionsRecord } from 'action/OhsActionModels';
import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import OhsCusvalValue from 'global-components/form/cusvals/OhsCusvalValue';
import { OhsCusval } from 'global-components/form/cusvals/OhsCusvalModel';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';

export default class OhsTrainingTaskRecord {
  attachments: OhsAttachment[];

  complete: boolean;

  completionNotes: string;

  createdBy: OhsModuleCreateBy;

  dateCreated: string;

  dateDue: string;

  dateCompleted: string;

  dateSignedoff: string;

  signedoffBy: {
    name: string;
    email: string;
  };

  description: string;

  for?: { _id: string; type: ModuleType.Training };

  links: OhsActionsRecord[];

  type: ModuleType.Task;

  tzDateCreated: string;

  _id: string;

  partialChronology?: OhsTrainingTaskRecord[];

  constructor() {
    this.attachments = [];
    this.complete = false;
    this.completionNotes = '';
    this.createdBy = new OhsModuleCreateBy();
    this.dateCreated = '';
    this.dateDue = '';
    this.dateCompleted = '';
    this.dateSignedoff = '';
    this.signedoffBy = {
      name: '',
      email: '',
    };
    this.description = '';
    this.for = undefined;
    this.links = [];
    this.type = ModuleType.Task;
    this.tzDateCreated = '';
    this._id = '';
    this.partialChronology = [];
  }
}

export interface OhsTrainingTaskResponse extends OhsTrainingTaskRecord {
  categoryCusvals?: OhsCusvalValue;

  subcategoryCusvals?: OhsCusvalValue;
}

export interface OhsTrainingTaskFormatted extends OhsTrainingTaskRecord {
  categoryCusvals?: OhsCusval[];

  subcategoryCusvals?: OhsCusval[];
}
