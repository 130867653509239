import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';

class OhsSafetyPlanRecord {
  type?: ModuleType;

  name: string;

  frequencyKey: string;

  frequencyValue: number;

  description: string;

  category: string;

  categoryOther: string | null;

  subcategory: string;

  subcategoryOther: string | null;

  dateDue?: { start: string; end: string };

  attachments: OhsAttachment[];

  createdBy: {
    type: ModuleType.User;
    _id: string;
    name: string;
    email: string;
  };

  tzDateCreated: string;

  dateCreated: string;

  tier?: OhsTier;

  _id?: string;

  subRows: any[];

  allocationOf: { _id: string; type: ModuleType.SafetyPlan } | undefined;

  allocatedToSelf?: boolean;

  archived?: boolean;

  constructor(tier?: OhsTier) {
    this.type = ModuleType.SafetyPlan;
    this.tier = tier;
    this.name = '';
    this.frequencyKey = '';
    this.frequencyValue = 0;
    this.description = '';
    this.category = '';
    this.categoryOther = null;
    this.subcategory = '';
    this.subcategoryOther = null;
    this.attachments = [];
    this.createdBy = {
      type: ModuleType.User,
      _id: '',
      name: '',
      email: '',
    };
    this.tzDateCreated = '';
    this.dateCreated = '';
    this.subRows = [];
  }
}

export default OhsSafetyPlanRecord;
