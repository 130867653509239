import React from 'react';

import { TableCellProps } from '@mui/material';

import { setTierName } from 'global-services/constants/tier/OhsTierServices';
import { ModuleType } from 'global-services/constants/OhsObject';
import { locationArrayDefine, parseLocationsArrays } from 'chemical/utils/chemicalListUtils';

import OhsRegisterTableRow from './OhsRegisterTableRow';
import OhsRegisterTableBodyCell from './OhsRegisterTableBodyCell';
import OhsArrayRowDisplay from './rowDisplay/OhsArrayRowDisplay';

function OhsRegisterTableSubRow({ row, cellNum }: { row: any; cellNum: number }) {
  const getAllocatedName = setTierName(row.original.tier).name;
  const { allocationActive, type } = row.original;
  const locationArrayData = parseLocationsArrays(row.original) ?? [];
  const locationDisplayColor = locationArrayData?.length > 0 ? 'green' : 'blue';
  const isLocationDisplay = type === ModuleType.Chemical;
  return (
    <OhsRegisterTableRow {...row.getRowProps()}>
      {row.cells.map(
        (
          cell: {
            column: any;
            getCellProps: () => JSX.IntrinsicAttributes & TableCellProps;
            render: (arg0: string) => React.ReactNode;
          },
          index: number
        ) => {
          switch (index) {
            case 0:
            case cellNum - 1:
              return (
                <OhsRegisterTableBodyCell
                  {...cell.getCellProps()}
                  style={{
                    width: cell.column.customWidth ? cell.column.customWidth : 'auto',
                  }}
                >
                  {cell.render('Cell')}
                </OhsRegisterTableBodyCell>
              );
            case 2:
              return (
                <>
                  <OhsRegisterTableBodyCell
                    {...cell.getCellProps()}
                    style={{
                      width: cell.column.customWidth ? cell.column.customWidth : 'auto',
                    }}
                    colSpan={isLocationDisplay ? 1 : cellNum - 2}
                  >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {typeof row.original === 'string' ? (
                      <b style={{ color: 'green' }}>{row.original}</b>
                    ) : allocationActive ? (
                      <b style={{ color: 'green' }}>{`Allocated to ${getAllocatedName}`}</b>
                    ) : (
                      <b style={{ color: 'red' }}>{`Deallocated from ${getAllocatedName}`}</b>
                    )}
                  </OhsRegisterTableBodyCell>
                  {isLocationDisplay && (
                    <>
                      <OhsRegisterTableBodyCell
                        {...cell.getCellProps()}
                        style={{
                          width: cell.column.customWidth ? cell.column.customWidth : 'auto',
                        }}
                      >
                        <OhsArrayRowDisplay
                          arrayData={locationArrayData}
                          arrayDefine={locationArrayDefine}
                          color={locationDisplayColor}
                        />
                      </OhsRegisterTableBodyCell>
                      <OhsRegisterTableBodyCell colSpan={cellNum - 4}>
                        <div />
                      </OhsRegisterTableBodyCell>
                    </>
                  )}
                </>
              );
            default:
              return null;
          }
        }
      )}
    </OhsRegisterTableRow>
  );
}

export default OhsRegisterTableSubRow;
