import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { makeStyles } from 'tss-react/mui';

import { useAxiosSpinner } from 'global-components/backdrop/useAxiosSpinner';

import OhsModalHeader from './OhsModalHeader';

const useStyles = makeStyles()(() => ({
  footerContainer: {
    background: '#F6F8FA',
    borderTop: '1px solid #eaeaea',
    padding: '1rem',
    '& > *': {
      '&:last-child': {
        marginRight: '0',
      },
    },
  },
  contentContainer: {
    padding: '1rem',
  },
}));
interface Props {
  open: boolean;
  title?: string;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  headerColor?: string;
  headerTextColor?: string;
  setModalOpenFunc?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: () => void;
  ContentNode: any;
  actionNode?: React.ReactNode;
}

function OhsModal(props: Props) {
  // const [title, setTitle] = React.useState('');
  const { classes } = useStyles();

  const [isOpen] = useAxiosSpinner();

  // const initialSetting = () => {
  //   if (props.title) {
  //     setTitle(props.title);
  //   }
  // };

  // eslint-disable-next-line max-len
  const handleClose = (e?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return;
    }
    if (props.setModalOpenFunc) props.setModalOpenFunc(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  // useEffect(() => {
  //   if (props.open) {
  //     initialSetting();
  //   }
  // }, [props.open]);

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={props.maxWidth}
      style={isOpen ? { zIndex: 9998 } : {}}
      fullWidth
    >
      <OhsModalHeader
        title={props.title ? props.title : ' '}
        onCloseFn={handleClose}
        headerColor={props.headerColor}
        headerTextColor={props.headerTextColor}
      />
      <DialogContent className={classes.contentContainer}>{props.ContentNode || ''}</DialogContent>
      {props.actionNode && (
        <DialogActions className={classes.footerContainer}>{props.actionNode}</DialogActions>
      )}
    </Dialog>
  );
}

OhsModal.defaultProps = {
  title: undefined,
  maxWidth: 'md',
  headerColor: undefined,
  headerTextColor: undefined,
  setModalOpenFunc: undefined,
  onClose: undefined,
  actionNode: undefined,
};

export default OhsModal;
