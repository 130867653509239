import { OhsTier } from 'global-services/constants/tier/OhsTier';
import OhsGeoData from 'global-components/form/fields/geoLocation/OhsGeoDataModels';
import OhsVisitorFormRoleRecord from 'visitor/form/models/OhsVisitorFormRoleRecord';

import OhsVisitorControl from '../../models/OhsVisitorControl';

class OhsVisitorSiteRecordResponse {
  _id?: string;

  tier?: OhsTier;

  title: string;

  description: string;

  geoData: OhsGeoData | null;

  geofenceRadius: number | null;

  category: string;

  categoryOther: string | null;

  subcategory: string;

  subcategoryOther: string | null;

  control: OhsVisitorControl;

  forms: OhsVisitorFormRoleRecord[];

  tzDateCreated: string;

  dateCreated: string;

  subRows: any[];

  qrCode: { urlPath: string };

  allocations?: any;

  constructor(tier?: OhsTier) {
    this.tier = tier;
    this.title = '';
    this.description = '';
    this.geoData = null;
    this.geofenceRadius = null;
    this.category = '';
    this.categoryOther = null;
    this.subcategory = '';
    this.subcategoryOther = null;
    this.control = {
      pin: false,
      geoArrive: false,
      geoLeave: false,
    };
    this.forms = [];
    this.tzDateCreated = '';
    this.dateCreated = '';
    this.subRows = [];
    this.qrCode = { urlPath: '' };
  }
}

export default OhsVisitorSiteRecordResponse;
