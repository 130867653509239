import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { toast } from 'react-toastify';

import {
  CookiesExpiresDays,
  OhsLinkAccessAttachmentsDownloadHeader,
  OhsLinkAccessAttachmentsUploadHeader,
  OhsMultiUserOptionName,
  OhsMultiUserSessionToken,
  OhsSessionToken,
} from 'global-services/constants/OhsStorageNames';
import { getOhsMultiUserLoginOptionList } from 'login/multi/OhsMultiUserLoginOption';
import { setLocalStorage } from 'global-services/OhsDataParse';
import { ModuleUrl } from 'global-services/constants/OhsRoutes';
import OhsUserDataServices from 'user/OhsUserDataServices';

import {
  getApiRequestErrorMessage,
  OhsApiErrorCodes,
  OhsApiRequestName,
  OhsApiRequestOptions,
} from './OhsApiModels';

const showSuccess = (msg: any, options?: OhsApiRequestOptions) => {
  if (options?.disableToast === true) return;
  toast.success(msg);
};

const showError = (error: any, options?: OhsApiRequestOptions) => {
  if (options?.disableToast === true || options?.disableErrorToast === true) return;
  toast.error(error);
};
const updateSession = (response: AxiosResponse, methodName: OhsApiRequestName, payload?: any) => {
  if (response.headers && response.headers[OhsSessionToken]) {
    const newSessionToken = response.headers[OhsSessionToken];

    Cookies.set(OhsSessionToken, newSessionToken, {
      expires: CookiesExpiresDays,
      path: '/',
    });
    if (
      newSessionToken &&
      methodName === OhsApiRequestName.MultiUser &&
      _.has(payload, 'user._id')
    ) {
      const userOptions = getOhsMultiUserLoginOptionList();
      const userId = payload.user._id;
      for (let index = 0; index < userOptions.length; index += 1) {
        const element = userOptions[index];
        if (element.user._id === userId) {
          element.sessionToken = newSessionToken;

          break;
        }
      }

      setLocalStorage(OhsMultiUserOptionName, userOptions);
    }
  }

  if (response.headers && response.headers[OhsMultiUserSessionToken]) {
    Cookies.set(OhsMultiUserSessionToken, response.headers[OhsMultiUserSessionToken], {
      expires: CookiesExpiresDays,
      path: '/',
    });
  }

  // communication module

  if (
    response.headers &&
    response.headers[OhsLinkAccessAttachmentsDownloadHeader] &&
    _.has(response, 'data.result.item._id')
  ) {
    response.data.result.item.downloadToken =
      response.headers[OhsLinkAccessAttachmentsDownloadHeader];
  }
  if (
    response.headers &&
    response.headers[OhsLinkAccessAttachmentsUploadHeader] &&
    _.has(response, 'data.result.item._id')
  ) {
    response.data.result.item.uploadToken = response.headers[OhsLinkAccessAttachmentsUploadHeader];
  }
};

const handleOhsApiErrors = (
  response: any,
  methodName: OhsApiRequestName,

  options?: OhsApiRequestOptions
) => {
  let isShowErrorToast = true;
  if (
    _.has(response, 'data.error.code') &&
    response.data.error.code &&
    response.data.error.code.length
  ) {
    if (response.data.error.code.includes(OhsApiErrorCodes.SessionExpired)) {
      isShowErrorToast = false;
      OhsUserDataServices.resetUserData();
      window.location.href = `/${ModuleUrl.Login}`;
    }
    if (response.data.error.code.includes(OhsApiErrorCodes.SessionInvalid)) {
      isShowErrorToast = false;
      toast.error('Login failed, Please try again!');
      OhsUserDataServices.resetUserData();

      // window.location.href = `/${ModuleUrl.Login}`;
    }
  }
  if (isShowErrorToast === true)
    showError(getApiRequestErrorMessage(methodName, response?.data?.error), options);
};

const OhsApiServices = { updateSession, handleOhsApiErrors, showSuccess, showError };
export default OhsApiServices;
