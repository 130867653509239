import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Theme,
} from '@mui/material';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { FieldValues, Form } from 'react-hook-form';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import OhsModuleFilter from 'dashboard/topFilter/filterfields/OhsModuleFilter';
import OhsWorkplaceFilter from 'dashboard/topFilter/filterfields/OhsWorkplaceFilter';
import OhsCheckboxFilter from 'dashboard/topFilter/filterfields/OhsCheckboxFilter';
import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';
import { OhsSearchInputFields, OhsSearchModuleOption } from 'search/OhsSearchModels';
import ohsDebounce from 'global-services/utils/OhsDebounce';
import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';
import { getAvailableInspectionSearch } from 'inspection/OhsInspectionSlice';
import { TierType } from 'global-services/constants/OhsObject';

const useStyles = makeStyles()((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px',
  },
  filterWrap: {
    marginTop: '0',
    padding: '0',
  },
  filterActions: {
    marginTop: '10px',
    borderTop: '1px solid #e9e9e9',
    paddingTop: '15px',
    display: 'flex',
    justifyContent: 'end',
    flexWrap: 'wrap',
    gap: '5px',
    '& button': {
      textTransform: 'none',
      minWidth: '100px',
      fontSize: '14px',
      padding: '2px 20px',
    },
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      '& button': { width: '100%' },
    },
  },
  filterCheckboxes: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    margin: '10px 8px',
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      width: '100%',
    },
  },
  clearBtn: {
    background: theme.palette.custom.HEADER_BAR_BG_COLOR ?? '#d3d3d3',
    color: 'black',
    '&:hover': {
      background: theme.palette.custom.HEADER_BAR_BG_COLOR ?? '#fafafa',
    },
  },
}));

interface Props {
  formSubmit: (data: any) => void;
  clearSearch: () => void;
  moduleOptionLists: OhsSearchModuleOption[];
  ohsFormSettings: OhsFormSettings<FieldValues>;
  inputFields: OhsSearchInputFields;
}

export default function OhsInspectionAvailableSearchForm(props: Props) {
  const user = useAppSelector(getOhsUser);
  const { searchInfo } = useAppSelector(getAvailableInspectionSearch);
  const { classes } = useStyles();
  const { inputFields } = props;

  const [searchKey]: [string] = props.ohsFormSettings.useFormMethods.watch(['searchKey']);
  const error = _.get(props.ohsFormSettings.useFormMethods.formState.errors, 'searchKey');
  const searchFieldError: string = error?.message as string;

  React.useEffect(() => {
    props.ohsFormSettings.useFormMethods.reset({ ...searchInfo });
  }, []);

  return (
    <Box sx={{ width: '100%', margin: 'auto' }}>
      <Form
        control={props.ohsFormSettings.useFormMethods.control}
        onSubmit={() => props.ohsFormSettings.useFormMethods.handleSubmit(props.formSubmit)}
      >
        <Box className={classes.card}>
          {inputFields.searchKey && (
            <OutlinedInput
              error={!!error}
              color="warning"
              fullWidth
              placeholder="Search"
              endAdornment={
                <InputAdornment position="end">
                  {_.isNil(searchKey) || searchKey === '' ? (
                    <IconButton
                      type="button"
                      onClick={props.ohsFormSettings.useFormMethods.handleSubmit(props.formSubmit)}
                    >
                      <SearchIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        props.ohsFormSettings.useFormMethods.setValue('searchKey', '');
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
              {...props.ohsFormSettings.useFormMethods.register('searchKey', {
                required: false,
                validate: {
                  minLength: (value: any) => {
                    if (value.length < 3) return 'The length must not be less than 3.';
                    return true;
                  },
                },
              })}
            />
          )}
          {error && (
            <FormHelperText error id="accountId-error">
              {searchFieldError}
            </FormHelperText>
          )}
        </Box>
        <Box className={classes.filterCheckboxes}>
          {inputFields.substringSearch && (
            <Box>
              <OhsCheckboxFilter
                fieldid="substringSearch"
                label="Find search terms within longer words or phrases"
                ohsFormSettings={props.ohsFormSettings}
              />
            </Box>
          )}
          {inputFields.archived && user?.tierNum !== 5 && (
            <Box>
              <OhsCheckboxFilter
                fieldid="filter.archived"
                label="Archived"
                ohsFormSettings={props.ohsFormSettings}
              />
            </Box>
          )}
        </Box>
        <Box
          className={clsx({
            [classes.filterWrap]: true,
          })}
        >
          <Grid container spacing={2} p={1}>
            {inputFields.modules && (
              <Grid item md={inputFields.workplaces ? 6 : 12} xs={12}>
                <OhsModuleFilter
                  required={props.inputFields.requiredFields?.includes('modules')}
                  moduleOptionLists={props.moduleOptionLists}
                  id="filter.modules"
                  ohsFormSettings={props.ohsFormSettings}
                />
              </Grid>
            )}
            {inputFields.workplaces && (
              <Grid item md={inputFields.modules ? 6 : 12} xs={12}>
                <OhsWorkplaceFilter
                  required={props.inputFields.requiredFields?.includes('workplaces')}
                  id="filter.workplaces"
                  ohsFormSettings={props.ohsFormSettings}
                  workplaceOptions={props.inputFields.workplaceOptions || null}
                />
              </Grid>
            )}
          </Grid>
          <Box className={classes.filterCheckboxes}>
            {inputFields.skipOrgRecords && user.tier.type === TierType.T3 && (
              <Box>
                <OhsCheckboxFilter
                  fieldid="filter.skipOrgRecords"
                  label="Skip Org Records"
                  ohsFormSettings={props.ohsFormSettings}
                />
              </Box>
            )}
            {inputFields.skipAllocatedRecords && (
              <Box>
                <OhsCheckboxFilter
                  fieldid="filter.skipAllocatedRecords"
                  label="Skip Allocated Records"
                  ohsFormSettings={props.ohsFormSettings}
                />
              </Box>
            )}
            {inputFields.task.complete && (
              <Box>
                <OhsCheckboxFilter
                  fieldid="filter.task.complete"
                  label="Task Complete"
                  ohsFormSettings={props.ohsFormSettings}
                />
              </Box>
            )}
            {inputFields.task.includeMasterRecords && (
              <Box>
                <OhsCheckboxFilter
                  fieldid="filter.task.includeMasterRecords"
                  label="Task Include Master Records"
                  ohsFormSettings={props.ohsFormSettings}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box className={classes.filterActions}>
          <Button className="ui gray button" onClick={props.clearSearch} variant="contained">
            Reset
          </Button>
          <Button
            onClick={ohsDebounce(
              props.ohsFormSettings.useFormMethods.handleSubmit(props.formSubmit)
            )}
            className="ui primary button"
            type="submit"
            variant="contained"
          >
            Search
          </Button>
        </Box>
      </Form>
    </Box>
  );
}
