import _ from 'lodash';

import {
  ConfigByModule,
  ModuleConfig,
  ModuleType,
  TierType,
} from 'global-services/constants/OhsObject';
import { OhsUser } from 'user/OhsUser';

export const withAllocatePermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && moduleConfig && recordTier && !isArchived) {
    const userTier = user?.tier?.type || '';
    const validUserType = userTier === TierType.T3;
    const validRecordTier = TierType.T3;
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'PERMISSIONS.allocate', false);
    if (validUserType && validRecordTier && withPermission && userTier === recordTier) return true;
  }
  return false;
};

export const withCreatePermission = (user: OhsUser, moduleConfig: ModuleConfig) => {
  if (user && moduleConfig) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === TierType.T2 ||
      userTier === TierType.T3 ||
      userTier === TierType.T4 ||
      userTier === TierType.T5;
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission = _.get(userModuleConfig, 'PERMISSIONS.create', false);
    if (validUserType && withPermission) return true;
  }
  return false;
};
export const withCreateCorrespondencePermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  type = 'prose'
) => {
  if (user && moduleConfig) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4;
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, `PERMISSIONS.view_${type}`, false) &&
      _.get(userModuleConfig, `PERMISSIONS.create_${type}`, false);
    if (validUserType && withPermission) return true;
  }
  return false;
};
export const withSendExchangeCorrespondencePermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig
) => {
  if (user && moduleConfig) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4;
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission = _.get(userModuleConfig, 'PERMISSIONS.send_exchange', false);
    if (validUserType && withPermission) return true;
  }
  return false;
};

export const withCreateViewPermission = (user: OhsUser, moduleConfig: ModuleConfig) => {
  if (user && moduleConfig) {
    const userModuleConfig = user.configs[moduleConfig];
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4;
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'PERMISSIONS.create', false);
    return withPermission && validUserType;
  }
  return false;
};

export const withViewPermission = (user: OhsUser, moduleConfig: ModuleConfig) => {
  if (user && moduleConfig) {
    const userModuleConfig = user.configs[moduleConfig];
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4;
    const withPermission = _.get(userModuleConfig, 'PERMISSIONS.view', false);
    return withPermission && validUserType;
  }
  return false;
};

export const withCommunicationViewPermission = (user: OhsUser) => {
  if (user && user.configs.correspondence?.PERMISSIONS) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4;
    const withPermission = user.configs.correspondence?.PERMISSIONS.view_roll || false;
    return withPermission && validUserType;
  }
  return false;
};

export const withEditPermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && moduleConfig && recordTier && !isArchived) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4;
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission = _.get(userModuleConfig, 'PERMISSIONS.edit', false);

    return withPermission && validUserType && userTier === recordTier;
  }
  return false;
};

export const withLowerTierEditPermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && moduleConfig && recordTier && !isArchived) {
    const userTier = user?.tier?.type || '';

    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'MORPH_PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'MORPH_PERMISSIONS.edit', false);

    if (withPermission && userTier === TierType.T3 && recordTier === TierType.T4) return true;
  }
  return false;
};

// eslint-disable-next-line max-len
export const withMoveDownPermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType
) => {
  if (user && moduleConfig) {
    const validUserType = recordTier === TierType.T3;
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.create', false) ||
      (_.get(userModuleConfig, 'PERMISSIONS.view', false) &&
        _.get(userModuleConfig, 'PERMISSIONS.edit', false));
    const withMorphPermission = _.get(userModuleConfig, 'MORPH_PERMISSIONS.view', false);
    return (withPermission || withMorphPermission) && validUserType;
  }
  return false;
};

// eslint-disable-next-line max-len
export const withMoveUpPermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType
) => {
  if (user && moduleConfig) {
    const validUserType = recordTier === TierType.T4;
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      (_.get(userModuleConfig, 'PERMISSIONS.view', false) &&
        _.get(userModuleConfig, 'PERMISSIONS.edit', false)) ||
      _.get(userModuleConfig, 'PERMISSIONS.create', false);
    const withMorphPermission = _.get(userModuleConfig, 'MORPH_PERMISSIONS.view', false);
    return (withPermission || withMorphPermission) && validUserType;
  }
  return false;
};

export const withMoveAcrossPermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType
) => {
  if (user && moduleConfig) {
    const validUserType = recordTier === TierType.T4;
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.create', false) ||
      (_.get(userModuleConfig, 'PERMISSIONS.view', false) &&
        _.get(userModuleConfig, 'PERMISSIONS.edit', false));
    const withMorphPermission = _.get(userModuleConfig, 'MORPH_PERMISSIONS.view', false);
    return (withPermission || withMorphPermission) && validUserType;
  }
  return false;
};

export const withMovePermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined
) => {
  if (user && moduleConfig && recordTier) {
    const userTier = user?.tier?.type === TierType.T3;

    if (
      userTier &&
      (withMoveDownPermission(user, moduleConfig, recordTier) ||
        withMoveUpPermission(user, moduleConfig, recordTier) ||
        withMoveAcrossPermission(user, moduleConfig, recordTier))
    )
      return true;
  }
  return false;
};

export const withSignOffViewPermission = (user: OhsUser, moduleConfig: ModuleConfig) => {
  if (user && moduleConfig) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === TierType.T3 || userTier === TierType.T4 || userTier === TierType.T5;
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'PERMISSIONS.signoff', false);
    const withMorphPermission =
      _.get(userModuleConfig, 'MORPH_PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'MORPH_PERMISSIONS.signoff', false);
    return (withPermission || withMorphPermission) && validUserType;
  }
  return false;
};

export const withSignOffPermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined,
  taskId: string,
  isArchived: boolean
) => {
  if (user && moduleConfig && recordTier && !isArchived && taskId) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === TierType.T3 || userTier === TierType.T4 || userTier === TierType.T5;
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'PERMISSIONS.signoff', false);
    const withMorphPermission =
      _.get(userModuleConfig, 'MORPH_PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'MORPH_PERMISSIONS.signoff', false);

    if (validUserType) {
      if (
        ((userTier === recordTier || user.tierNum === 5 || userTier === TierType.T3) &&
          withPermission) ||
        (userTier === TierType.T3 && recordTier === TierType.T4 && withMorphPermission) ||
        (userTier === TierType.T5 && recordTier === TierType.T4)
      ) {
        return true;
      }
    }
  }
  return false;
};

export const withArchiveUnarchivePermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined,
  taskId: string,
  isArchived: boolean
) => {
  if (user && moduleConfig && recordTier && (isArchived || !taskId)) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === TierType.T3 || userTier === TierType.T4 || userTier === TierType.T5;
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'PERMISSIONS.archive', false);
    const withMorphPermission =
      _.get(userModuleConfig, 'MORPH_PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'MORPH_PERMISSIONS.archive', false);

    if (validUserType) {
      if (
        withPermission ||
        (userTier === TierType.T3 && recordTier === TierType.T4 && withMorphPermission)
      ) {
        return true;
      }
    }
  }
  return false;
};

const withArchivePermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined
) => {
  if (user && moduleConfig && recordTier) {
    const userTier = user?.tier?.type || '';

    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'PERMISSIONS.archive', false);

    if (withPermission && userTier === recordTier) {
      return true;
    }
  }
  return false;
};

export const withAssignPermission = (
  user: OhsUser,
  recordModuleType: ModuleType,
  isArchived: boolean
) => {
  if (user && recordModuleType && isArchived === false) {
    const userTier = user?.tier?.type || '';
    const userTierNum = user?.tierNum ?? 0;

    const validUserType = userTier === TierType.T3 || userTier === TierType.T4;

    const configName = _.get(ConfigByModule, recordModuleType);
    if (configName) {
      const config = _.get(user.configs, configName);

      if (config) {
        const withPermission =
          _.get(config, 'PERMISSIONS.view', false) &&
          _.get(config, 'PERMISSIONS.assign_task', false);

        if (validUserType && withPermission && userTierNum !== 5) {
          return true;
        }
      }
    }
  }
  return false;
};

export const withDeletePermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && moduleConfig && recordTier && !isArchived) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === recordTier &&
      (userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4);
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'PERMISSIONS.delete', false);

    if (validUserType && withPermission) return true;
  }
  return false;
};

export const withDeleteInspectionOneOffTaskPermission = (
  user: OhsUser,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && recordTier && !isArchived) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === recordTier &&
      (userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4);
    const userModuleConfig = user.configs.inspection;
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'PERMISSIONS.delete_one_off_child', false);

    if (validUserType && withPermission) return true;
  }
  return false;
};

export const withLowerTierDeletePermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && moduleConfig && recordTier && !isArchived) {
    const userTier = user?.tier?.type || '';
    const validUserType = userTier === TierType.T3 && recordTier === TierType.T4;
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'MORPH_PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'MORPH_PERMISSIONS.delete', false);

    if (validUserType && withPermission) return true;
  }
  return false;
};
export const withDeleteRollPermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && moduleConfig && recordTier && !isArchived) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === recordTier &&
      (userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4);
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.view_roll', false) &&
      _.get(userModuleConfig, 'PERMISSIONS.delete_roll', false);

    if (validUserType && withPermission) return true;
  }
  return false;
};
export const withDeleteExchangePermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && moduleConfig && recordTier && !isArchived) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === recordTier &&
      (userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4);
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission = _.get(userModuleConfig, 'PERMISSIONS.delete_exchange', false);

    if (validUserType && withPermission) return true;
  }
  return false;
};
export const withSetupExchangePermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && moduleConfig && recordTier && !isArchived) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === recordTier &&
      (userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4);
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission = _.get(userModuleConfig, 'PERMISSIONS.setup_exchange', false);

    if (validUserType && withPermission) return true;
  }
  return false;
};

export const withBulkRollExchangePermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && moduleConfig && recordTier && !isArchived) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === recordTier &&
      (userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4);
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.send_exchange', false) ||
      _.get(userModuleConfig, 'PERMISSIONS.archive_exchange', false) ||
      _.get(userModuleConfig, 'PERMISSIONS.delete_exchange', false);

    if (validUserType && withPermission) return true;
  }
  return false;
};

export const withClonePermission = (
  user: OhsUser,
  moduleConfig: ModuleConfig,
  recordTier: TierType | undefined,
  isArchived: boolean,
  withPrefix: boolean
) => {
  if (user && moduleConfig && !isArchived && withPrefix) {
    const userTier = user?.tier?.type || '';
    const validUserType =
      userTier === recordTier &&
      (userTier === TierType.T2 || userTier === TierType.T3 || userTier === TierType.T4);
    const userModuleConfig = user.configs[moduleConfig];
    const withPermission =
      _.get(userModuleConfig, 'PERMISSIONS.view', false) &&
      _.get(userModuleConfig, 'PERMISSIONS.create', false);

    if (validUserType && withPermission) return true;
  }
  return false;
};

const isAllocated = (ohsRecord: any) => {
  if (ohsRecord && ohsRecord.tier && ohsRecord.tier.type && ohsRecord.tier.type === TierType.T4) {
    if (_.has(ohsRecord, 'allocationOf')) {
      return true;
    }
  }
  return false;
};

const OhsPermission = { isAllocated, withArchivePermission };
export default OhsPermission;
