import React from 'react';

import { Route, Routes } from 'react-router-dom';

import OhsIframeModulePublicHome from 'global-components/angular-iframe/OhsIframeModulePublicHome';

import { OhsFreemiumUserSetup } from './onboarding/OhsFreemiumUserSetup';

function OhsLinkAccessRoutes() {
  return (
    <div>
      <Routes>
        <Route path="onboarding/first-signin/:token" element={<OhsFreemiumUserSetup />} />
        <Route path="*" element={<OhsIframeModulePublicHome />} />
      </Routes>
    </div>
  );
}

export default OhsLinkAccessRoutes;
