import React from 'react';

import _ from 'lodash';
import { Icon, SemanticICONS } from 'semantic-ui-react';

import {
  ChemicalDangerousGoodsClass,
  ChemicalGhsClassification,
  ChemicalSubRiskOptions,
} from 'chemical/models/OhsChemicalConstants';
import OhsCircularLabel from 'global-components/register/table/OhsCircularLabel';
import OhsChemicalRecord from 'chemical/models/OhsChemicalRecord';

export const hazardStatement: any = {
  dangrousGood: ' Is the Chemical a Dangerous good?',
  hazardous: 'Is the chemical Hazardous?',
};

export const getFilterGHSPhysicalValues = (data: string[]) => {
  return ChemicalGhsClassification[0].list.filter((row) => data?.includes(row.code));
};

export const getFilterGHSHealthValues = (data: string[]) => {
  return ChemicalGhsClassification[1].list.filter((row) => data?.includes(row.code));
};

export const getFilterGHSEnvironmentValues = (data: string[]) => {
  return ChemicalGhsClassification[2].list.filter((row) => data?.includes(row.code));
};

export const getFilterDangerousGoodsClass = (data: string) => {
  return ChemicalDangerousGoodsClass.find((obj) => obj.value === data);
};

export const getSubRiskData = (data: string[]) => {
  return ChemicalSubRiskOptions.filter((obj) => data?.includes(obj.value));
};

export function getColor(value: boolean | null | undefined) {
  if (value === true) {
    return 'red';
  }
  if (value === false) {
    return 'grey';
  }
  return 'white';
}

export function getIcon(
  type: string,
  isDangrousOrHazardous: boolean | null | undefined,
  icon: SemanticICONS | undefined,
  isGHSPresent: boolean
) {
  const color = getColor(isDangrousOrHazardous);
  switch (type) {
    case 'GHS':
      return <Icon name={icon} color={isGHSPresent ? 'red' : 'grey'} size="large" />;
    case 'dangrousGood':
      return <OhsCircularLabel value="D" color={color} />;
    case 'hazardous':
      return <OhsCircularLabel value="H" color={color} />;
    default:
      return <OhsCircularLabel value="D" color="red" />;
  }
}

export const mapRiskValues = (data: OhsChemicalRecord, key: string) => {
  let formVal = '';
  const fetchedVal = _.get(data, key, null);

  if (fetchedVal === true) {
    formVal = 'Yes';
  } else if (fetchedVal === false) {
    formVal = 'No';
  } else {
    formVal = '';
  }
  _.set(data, key, formVal);
};

export function getHazardTitleName(icon: SemanticICONS | undefined) {
  switch (icon) {
    case 'warning sign':
      return 'Physical';
    case 'ambulance':
      return 'Health';
    case 'globe':
      return 'Enviromental';
    default:
      return 'Physical';
  }
}
