import { ModuleType } from 'global-services/constants/OhsObject';

export default class OhsCusvalDefinition {
  type: ModuleType.CusvalDefinition;

  _id: string;

  constructor() {
    this.type = ModuleType.CusvalDefinition;
    this._id = '';
  }
}
