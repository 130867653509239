import { OhsUser } from 'user/OhsUser';
import { getWorkplaceOptions } from 'user/OhsUserServices';

export default class OhsCorporateRiskFilterModels {
  view1CRiskList: {
    viewPreset: 'view_1';
    residualRisks: string[];
    categories?: string[] | [];
    archived?: boolean;
  };

  view2CRiskList: {
    viewPreset: 'view_2';
    categories?: string[] | [];
    residualRisks: string[];
    workplaces: string[];
    workplaceOwned: boolean;
    allocatedToWorkplace: boolean;
    archived?: boolean;
  };

  view3CRiskList: {
    viewPreset: 'view_3';
    categories?: string[] | [];
    residualRisks: string[];
    workplaces: string[];
    allocatedToOrg: boolean;
    allocatedToWorkplace: boolean;
    notAllocated: boolean;
    archived?: boolean;
  };

  view4CRiskList: {
    viewPreset: 'view_4';
    categories?: string[] | [];
    residualRisks: string[];
    workplaces: string[];
    workplaceOwned: boolean;
    allocatedToWorkplace: boolean;
    archived?: boolean;
  };

  sort: string;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  constructor(user?: OhsUser) {
    this.view1CRiskList = {
      viewPreset: 'view_1',
      residualRisks: [],
      categories: [],
      archived: false,
    };
    this.view2CRiskList = {
      viewPreset: 'view_2',
      residualRisks: [],
      categories: [],
      allocatedToWorkplace: true,
      workplaces: [],
      workplaceOwned: true,
      archived: false,
    };
    this.view3CRiskList = {
      viewPreset: 'view_3',
      categories: [],
      residualRisks: [],
      workplaces: [],
      allocatedToOrg: true,
      allocatedToWorkplace: true,
      notAllocated: true,
      archived: false,
    };

    this.view4CRiskList = {
      viewPreset: 'view_4',
      categories: [],
      residualRisks: [],
      workplaces: [],
      workplaceOwned: true,
      allocatedToWorkplace: true,
      archived: false,
    };

    this.sort = '{ "key": "category", "order": 1 }';
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest ', value: '{ "key": "_id", "order": 1 }' },
        { name: 'Issue Date: Newest to Oldest', value: '{ "key": "dateIssued", "order": -1 }' },
        { name: 'Issue Date: Oldest to Newest', value: '{ "key": "dateIssued", "order": 1 }' },
        { name: 'Classification: A to Z', value: '{ "key": "category", "order": 1 }' },
        { name: 'Classification: Z to A', value: '{ "key": "category", "order": -1 }' },
      ],
    };

    if (user) {
      const getWorkplaceLists = getWorkplaceOptions(user.configs);
      const workplaceOptions = getWorkplaceLists.map((list: any) => list.id);
      this.view2CRiskList.workplaces = workplaceOptions;
      this.view3CRiskList.workplaces = workplaceOptions;
      this.view4CRiskList.workplaces = workplaceOptions;
    }
  }
}
