import React, { useEffect } from 'react';

import { Box, Grid } from '@mui/material';
import { Button } from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import { generatePath, useNavigate } from 'react-router-dom';

import OhsModal from 'global-components/modal/OhsModal';
import { getCurrentTimezone } from 'global-services/OhsDataParse';
import OhsDateField from 'global-components/form/fields/OhsDateField';
import OhsRadioField from 'global-components/form/fields/OhsRadioField';
import { OhsFormLayoutType, OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { getTodayDateString } from 'global-services/utils/OhsDateServices';
import { getModuleName } from 'user/OhsUserServices';
import { ModuleType } from 'global-services/constants/OhsObject';
import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';

import InspectionRouteList from '../OhsInspectionRouteList';
import { startInspection } from '../OhsInspectionServices';
import OhsInspectionStart from '../models/OhsInspectionStart';

interface Props {
  isOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  recordData: any;
  task: any;
  onRedirect?: () => void;
  closeAllModal?: () => void;
}

function OhsInspectionStartModal(props: Props) {
  const { isOpen, setModalOpen, recordData, task, closeAllModal } = props;
  const user = useAppSelector(getOhsUser);
  const recordId = recordData._id;
  const taskId = task._id;
  const navigate = useNavigate();
  const ohsFormSettings = new OhsFormSettings(
    new OhsInspectionStart(),
    OhsFormLayoutType.AngularModalForm
  );

  const isRecurring = !_.isNil(recordData.frequencyKey) && !_.isNil(recordData.frequencyValue);

  const [inspectionCommencedDate, dateCommenced]: [string, string] =
    ohsFormSettings.useFormMethods.watch(['inspectionCommencedDate', 'dateCommenced']);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleViewInspection = () => {
    const url = generatePath(InspectionRouteList.details.url, {
      inspectionId: recordId,
    });
    navigate(url);
    navigate(0);
  };

  const formSubmit = async (formData: any) => {
    const payload = {
      dateCommenced: formData.dateCommenced,
      tz: getCurrentTimezone(),
      _id: recordId,
      task: {
        _id: taskId,
      },
    };

    const res = await startInspection(payload);
    if (res) {
      if (props.onRedirect) props.onRedirect();
      if (closeAllModal) {
        closeAllModal();
      } else {
        handleClose();
      }
      const url = generatePath(InspectionRouteList.signOff.url, {
        inspectionId: recordId,
        taskId,
      });
      navigate(url);
      navigate(0);
    }
  };

  useEffect(() => {
    if (inspectionCommencedDate === 'today' && dateCommenced !== moment().format('YYYY-MM-DD')) {
      const newForm = {
        inspectionCommencedDate,
        dateCommenced: moment().format('YYYY-MM-DD'),
      };
      ohsFormSettings.useFormMethods.reset(newForm);
    }
  }, [inspectionCommencedDate]);

  useEffect(() => {
    if (isOpen) {
      const newForm = {
        inspectionCommencedDate: 'today',
        dateCommenced: moment().format('YYYY-MM-DD'),
      };
      ohsFormSettings.useFormMethods.reset(newForm);
    }
  }, [isOpen]);

  return (
    <OhsModal
      open={isOpen}
      title={`${isRecurring ? 'Recurring ' : ''} Inspection: ${recordData.title}`}
      onClose={handleClose}
      maxWidth="md"
      ContentNode={
        <Box minHeight="360px">
          <Grid container>
            <Grid item xs={4}>
              <OhsRadioField
                title="Commenced Date"
                id="inspectionCommencedDate"
                required
                options={[
                  { name: 'Today', value: 'today' },
                  { name: 'Other', value: 'other' },
                ]}
                ohsFormSettings={ohsFormSettings}
                row
              />
            </Grid>
            <Grid item xs={8}>
              <OhsDateField
                id="dateCommenced"
                required
                ohsFormSettings={ohsFormSettings}
                maxDate={{ name: 'Today', date: getTodayDateString() }}
                disabled={inspectionCommencedDate === 'today'}
              />
            </Grid>
          </Grid>

          <Box className="field" marginTop={2}>
            <Box className="ui blue message">
              <p>
                <b>Note:</b> Once you select “Start{' '}
                {getModuleName(user?.configs, ModuleType.Inspection)}, any updates to the inspection
                template will not be available until after inspection completion. If you wish to
                view the template, select “View{' '}
                {getModuleName(user?.configs, ModuleType.Inspection)}
              </p>
            </Box>
          </Box>
        </Box>
      }
      actionNode={
        <Box justifyContent="space-between" width="100%" display="flex">
          <Button onClick={handleViewInspection} color="blue">
            View {getModuleName(user?.configs, ModuleType.Inspection)}
          </Button>
          <Button onClick={ohsFormSettings.useFormMethods.handleSubmit(formSubmit)} color="green">
            Start {getModuleName(user?.configs, ModuleType.Inspection)}
          </Button>
        </Box>
      }
    />
  );
}
OhsInspectionStartModal.defaultProps = {
  onRedirect: undefined,
  closeAllModal: undefined,
};

export default OhsInspectionStartModal;
