import { OhsUser } from 'user/OhsUser';
import { ModuleRoute, ModuleUrl } from 'global-services/constants/OhsRoutes';

const commonPermission = (user: OhsUser) => {
  return (
    user?.configs?.correspondence?.PERMISSIONS.view_prose ||
    user?.configs?.correspondence?.PERMISSIONS.view_roll ||
    false
  );
};
const editTemplatePermission = (user: OhsUser) => {
  return user?.configs?.correspondence?.PERMISSIONS.edit_prose || false;
};
const addFormPermission = (user: OhsUser) => {
  return user?.configs?.correspondence?.PERMISSIONS.form_create || false;
};
const editFormPermission = (user: OhsUser) => {
  return user?.configs?.correspondence?.PERMISSIONS.form_edit || false;
};
const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.Correspondence}/${path}`,
  permission,
});

const OhsCorrespondenceRouteList = {
  [ModuleRoute.Home]: createRoute('home', commonPermission),
  [ModuleRoute.Details]: createRoute(':id/edit', commonPermission),
  [ModuleRoute.ExchangeView]: createRoute(':id/exchange/:exchangeId', commonPermission),
  [ModuleRoute.Edit]: createRoute('template/:id/edit', editTemplatePermission),
  [ModuleRoute.FormAdd]: createRoute('form/add', addFormPermission),
  [ModuleRoute.FormEdit]: createRoute('form/:id/edit', editFormPermission),
};

export default OhsCorrespondenceRouteList;
