import { OhsTier } from 'global-services/constants/tier/OhsTier';
import OhsCusvalDefinition from 'global-components/form/cusvals/OhsCusvalDefinition';

import OhsVisitorFormMessage from './OhsVisitorFormMessage';

class OhsVisitorFormRecord {
  _id?: string;

  tier?: OhsTier;

  cusvals?: OhsCusvalDefinition;

  title: string;

  description: string;

  messages: OhsVisitorFormMessage;

  constructor(tier?: OhsTier) {
    this.tier = tier;
    this.title = '';
    this.description = '';
    this.messages = new OhsVisitorFormMessage();
  }
}

export default OhsVisitorFormRecord;
