import { OhsCusval } from 'global-components/form/cusvals/OhsCusvalModel';
import OhsCusvalValue from 'global-components/form/cusvals/OhsCusvalValue';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { ModuleType } from 'global-services/constants/OhsObject';

import { OhsActionsRecord } from './OhsActionModels';

export class OhsActionTaskRecord {
  type: ModuleType.Task;

  _id: string;

  for?: { _id: string; type: ModuleType.Action };

  tier?: {
    type: string;
    _id: string;
  };

  complete: boolean;

  title: string;

  description: string;

  cusvals: OhsCusval | null;

  attachments: OhsAttachment[];

  dateDue: string;

  dateCompleted: string;

  dateSignedoff: string;

  signedoffBy: {
    name: string;
    email: string;
  };

  completionNotes: string;

  hazardCategory: string;

  hazardCategoryOther?: string;

  controlLevel: string;

  controlLevelOther?: string;

  severity: string;

  links?: OhsActionsRecord[];

  comment?: string;

  editComments?: {
    comment: string;
    date: string;
    by: {
      email: string;
      name: string;
      type: string;
      _id: string;
    };
  }[];

  constructor() {
    this._id = '';
    this.type = ModuleType.Task;
    this.for = undefined;
    this.tier = undefined;
    this.complete = false;
    this.title = '';
    this.description = '';
    this.cusvals = null;
    this.attachments = [];
    this.dateDue = '';
    this.dateCompleted = '';
    this.dateSignedoff = '';
    this.signedoffBy = {
      name: '',
      email: '',
    };
    this.completionNotes = '';
    this.hazardCategory = '';
    this.controlLevel = '';
    this.severity = '';
  }
}

export interface OhsActionTaskResponse extends OhsActionTaskRecord {
  categoryCusvals?: OhsCusvalValue;
}

export interface OhsActionTaskFormatted extends OhsActionTaskRecord {
  categoryCusvals?: OhsCusval[];
}
