import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
  OhsApiRequestOptions,
  RequestType,
} from 'global-services/api/OhsApiModels';
import { ModuleType } from 'global-services/constants/OhsObject';
import OhsHumanResourceListsRecord, {
  OhsHumanResourceFilterPayload,
} from 'human-resource/models/OhsHumanResourceRecord';
import { LinkedIncidentType } from 'incident/OhsIncidentModels';

import { OhsHrLookup } from './models/OhsHumanResourceModels';
import OhsApiRequest from '../global-services/api/OhsApiRequest';
import OhsHumanResourceTrainingListsRecord, {
  OhsHumanResourceTrainingFilterPayload,
} from './models/OhsHumanResourceTrainingRecord';
import OhsTrainingTaskRecord from './models/OhsTrainingTaskRecord';
import { OhsCommunicationTabFilterPayload } from '../contractor/models/OhsContractorModels';
import OhsCorrespondenceExchangeRecord from '../correspondence/models/OhsCorrespondenceExchangeRecord';

const getHumanResourceLists = (
  filterInfo: OhsHumanResourceFilterPayload
): Promise<ApiResponseType<ApiResListsType<OhsHumanResourceListsRecord[]>> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.HR, OhsApiRequestName.List, {
    filter: filterInfo,
  });
};

export const getHRTrainingLists = (
  filterInfo: OhsHumanResourceTrainingFilterPayload,
  _id?: string
): Promise<ApiResponseType<ApiResListsType<OhsHumanResourceTrainingListsRecord[]>> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.Training, OhsApiRequestName.List, {
    _id,
    filter: filterInfo,
  });
};

export const moveHumanResource = (payload: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.HR, OhsApiRequestName.Move, payload);
};
export const getHumanResource = async (
  _id: string,
  options: OhsApiRequestOptions = {}
): Promise<OhsHumanResourceListsRecord> => {
  const res: OhsHumanResourceListsRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.HR,
    OhsApiRequestName.Fetch,
    { _id },
    options
  );
  return res || new OhsHumanResourceListsRecord();
};
export const getHumanResourceTraining = async (
  _id: string,
  disableErrorToast?: boolean
): Promise<OhsHumanResourceTrainingListsRecord> => {
  const res: OhsHumanResourceTrainingListsRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Training,
    OhsApiRequestName.Fetch,
    { _id },
    { disableErrorToast }
  );
  return res || new OhsHumanResourceTrainingListsRecord();
};
export const addHumanResource = (payload: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.HR, OhsApiRequestName.Create, payload);
};

export const editHumanResource = (payload: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.HR, OhsApiRequestName.Edit, payload);
};

export const archiveHumanResource = (hrId: string, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.HR,
    archived ? OhsApiRequestName.Archive : OhsApiRequestName.UnArchive,
    {
      _id: hrId,
    }
  );
};

export const deleteHumanResource = (hrId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.HR, OhsApiRequestName.Delete, {
    _id: hrId,
  });
};
export const bulkDeleteHumanResource = (hrIds: string[]) => {
  return OhsApiRequest(RequestType.Module, ModuleType.HR, OhsApiRequestName.BulkDelete, {
    _ids: hrIds,
  });
};
export const bulkArchiveUnarchiveHumanResource = (hrIds: string[], archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.HR,
    archived ? OhsApiRequestName.BulkArchive : OhsApiRequestName.BulkUnarchive,
    {
      _ids: hrIds,
    }
  );
};

export const bulkMoveHumanResource = (hrIds: string[], target: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.HR, OhsApiRequestName.BulkMove, {
    _ids: hrIds,
    target,
  });
};

export const archiveHumanResourceTraining = (trainingId: string, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.Training,
    archived ? OhsApiRequestName.Archive : OhsApiRequestName.UnArchive,
    {
      _id: trainingId,
    }
  );
};
export const addTrainingHumanResource = (payload: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Training, OhsApiRequestName.Create, payload);
};

export const editTrainingHumanResource = (payload: any) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Training, OhsApiRequestName.Edit, payload);
};

export const deleteHumanResourceTraining = (trainingId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.Training, OhsApiRequestName.Delete, {
    _id: trainingId,
  });
};
export const parseHrLookupList = (items: string[][]) => {
  const hrLookupList = new Array<OhsHrLookup>();
  items.forEach((item) => {
    hrLookupList.push(new OhsHrLookup(item));
  });
  return hrLookupList;
};

export const getTrainingTaskRecord = async (
  _id: string,
  taskId: string,
  disableErrorToast?: boolean
): Promise<OhsTrainingTaskRecord> => {
  const res: OhsTrainingTaskRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Task,
    OhsApiRequestName.Fetch,
    {
      _id: taskId,
      for: {
        _id,
        type: ModuleType.Training,
      },
    },
    { disableErrorToast }
  );
  return res || new OhsTrainingTaskRecord();
};

export const getLinkedCorrespondenceExchangesLists = (
  filterInfo: OhsCommunicationTabFilterPayload
): Promise<ApiResponseType<ApiResListsType<OhsCorrespondenceExchangeRecord[]>> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.HR,
    OhsApiRequestName.LinkedCorrespondenceAll,
    {
      filter: filterInfo,
    }
  );
};
export const getLinkedCorrespondenceRollLists = (
  _ids: string[]
): Promise<ApiResponseType<ApiResListsType<OhsCorrespondenceExchangeRecord[]>> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.HR,
    OhsApiRequestName.LinkedCorrespondenceRoll,
    {
      _ids,
    }
  );
};

export const getLinkedCorrespondenceExchangesListById = (
  filterInfo: OhsCommunicationTabFilterPayload,
  _id: string
): Promise<ApiResponseType<ApiResListsType<OhsCorrespondenceExchangeRecord[]>> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.HR,
    OhsApiRequestName.ContractorLinkedCorrespondence,
    {
      _id,
      filter: filterInfo,
    }
  );
};

export const signOffTraining = (payload: any) =>
  OhsApiRequest(RequestType.Module, ModuleType.Training, OhsApiRequestName.SignOff, payload);

export const getHRLinkedIncidents = (_id: string): Promise<LinkedIncidentType[] | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.HR, OhsApiRequestName.ListIncidents, {
    _id,
  });
};

export default getHumanResourceLists;
