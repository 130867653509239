const enum OhsSearchParams {
  Tab = 'tab',
  Type = 'type',
  Allocate = 'allocate',
  Open = 'op',
}

export enum OhsSearchParamsKeyTypes {
  Allocate = 'al',
  AllocateAndAssign = 'al-as',
  Assign = 'as',
}

export const enum OhsTypeQueryParams {
  ListType = 'tmpl',
  NonListType = 'ins',
}
export default OhsSearchParams;
