import React from 'react';

import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Button } from 'semantic-ui-react';

const useStyles = makeStyles()({
  contentWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '10px',
  },
  content: {
    display: 'flex',
    margin: '10px 0 10px 0',
    flexDirection: 'row',
    textAlign: 'center',
    fontSize: '16px',
    maxWidth: '70%',
  },
  continueBtn: {
    width: '200px',
    margin: '20px 0 20px 0 !important',
  },
});

interface Props {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}
function OhsMfaEnrollMsg(props: Props) {
  const { setActiveStep } = props;
  const { classes } = useStyles();
  return (
    <Box className={classes.contentWrap}>
      <Box className={classes.content}>
        To continue, you need to select a method then verify it.
      </Box>

      <Button className={classes.continueBtn} onClick={() => setActiveStep(1)} color="orange">
        Continue
      </Button>
    </Box>
  );
}

export default OhsMfaEnrollMsg;
