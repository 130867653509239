import React from 'react';

import {
  OhsGlobalSearchPayload,
  OhsSearchInputFields,
  OhsSearchModuleOption,
} from 'search/OhsSearchModels';
import OhsModal from 'global-components/modal/OhsModal';
import {
  getAvailableInspectionSearch,
  setAvailableInspectionSearch,
} from 'inspection/OhsInspectionSlice';
import { initialGlobalSearchPayload } from 'search/store/OhsSearchSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { OhsFormLayoutType, OhsFormSettings } from 'global-components/form/services/OhsFormModels';

import OhsInspectionAvailableSearchForm from './OhsInspectionAvailableSearchForm';

interface Props {
  searchInputFields: OhsSearchInputFields;
  moduleOptionLists: OhsSearchModuleOption[];
  open: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onModalClose: () => void;
  customPreset?: OhsGlobalSearchPayload;
}
function OhsInspectionAvailableSearchModal({ ...props }: Props) {
  const { searchInfo } = useAppSelector(getAvailableInspectionSearch);
  const dispatch = useAppDispatch();

  const ohsFormSettings = new OhsFormSettings(searchInfo, OhsFormLayoutType.AngularModalForm);

  React.useEffect(() => {
    const hydrateFilter = { ...searchInfo };
    ohsFormSettings.useFormMethods.reset(hydrateFilter);
  }, [searchInfo]);

  const clearSearch = () => {
    const searchData = props.customPreset ? props.customPreset : initialGlobalSearchPayload;
    dispatch(setAvailableInspectionSearch({ ...searchData }));
    ohsFormSettings.useFormMethods.reset({ ...searchData });
    props.onModalClose();
  };

  const formSubmit = (data: any) => {
    const searchData = data as OhsGlobalSearchPayload;
    dispatch(setAvailableInspectionSearch(searchData));
    ohsFormSettings.useFormMethods.reset(searchData);
    props.onModalClose();
  };

  const state = ohsFormSettings.useFormMethods.formState.defaultValues;
  React.useEffect(() => {
    const searchKeyState = ohsFormSettings.useFormMethods.getFieldState('searchKey');
    if (!searchKeyState.isTouched) ohsFormSettings.useFormMethods.setFocus('searchKey');
  }, [state]);

  return (
    <OhsModal
      title="Search"
      open={props.open}
      setModalOpenFunc={props.setModalOpen}
      onClose={props.onModalClose}
      ContentNode={
        <OhsInspectionAvailableSearchForm
          ohsFormSettings={ohsFormSettings}
          moduleOptionLists={props.moduleOptionLists ?? []}
          clearSearch={clearSearch}
          formSubmit={formSubmit}
          inputFields={props.searchInputFields}
        />
      }
    />
  );
}

OhsInspectionAvailableSearchModal.defaultProps = {
  customPreset: undefined,
};

export default OhsInspectionAvailableSearchModal;
