const CurrencyCodes = [
  {
    code: 'AED',
    name: 'United Arab Emirates Dirham',
  },
  {
    code: 'AFN',
    name: 'Afghanistan Afghani',
  },
  {
    code: 'ALL',
    name: 'Albania Lek',
  },
  {
    code: 'AMD',
    name: 'Armenia Dram',
  },
  {
    code: 'ANG',
    name: 'Netherlands Antilles Guilder',
  },
  {
    code: 'AOA',
    name: 'Angola Kwanza',
  },
  {
    code: 'ARS',
    name: 'Argentina Peso',
  },
  {
    code: 'AUD',
    name: 'Australia Dollar',
  },
  {
    code: 'AWG',
    name: 'Aruba Guilder',
  },
  {
    code: 'AZN',
    name: 'Azerbaijan New Manat',
  },
  {
    code: 'BAM',
    name: 'Bosnia and Herzegovina Convertible Marka',
  },
  {
    code: 'BBD',
    name: 'Barbados Dollar',
  },
  {
    code: 'BDT',
    name: 'Bangladesh Taka',
  },
  {
    code: 'BGN',
    name: 'Bulgaria Lev',
  },
  {
    code: 'BHD',
    name: 'Bahrain Dinar',
  },
  {
    code: 'BIF',
    name: 'Burundi Franc',
  },
  {
    code: 'BMD',
    name: 'Bermuda Dollar',
  },
  {
    code: 'BND',
    name: 'Brunei Darussalam Dollar',
  },
  {
    code: 'BOB',
    name: 'Bolivia Boliviano',
  },
  {
    code: 'BRL',
    name: 'Brazil Real',
  },
  {
    code: 'BSD',
    name: 'Bahamas Dollar',
  },
  {
    code: 'BTN',
    name: 'Bhutan Ngultrum',
  },
  {
    code: 'BWP',
    name: 'Botswana Pula',
  },
  {
    code: 'BYR',
    name: 'Belarus Ruble',
  },
  {
    code: 'BZD',
    name: 'Belize Dollar',
  },
  {
    code: 'CAD',
    name: 'Canada Dollar',
  },
  {
    code: 'CDF',
    name: 'Congo/Kinshasa Franc',
  },
  {
    code: 'CHF',
    name: 'Switzerland Franc',
  },
  {
    code: 'CLP',
    name: 'Chile Peso',
  },
  {
    code: 'CNY',
    name: 'China Yuan Renminbi',
  },
  {
    code: 'COP',
    name: 'Colombia Peso',
  },
  {
    code: 'CRC',
    name: 'Costa Rica Colon',
  },
  {
    code: 'CUC',
    name: 'Cuba Convertible Peso',
  },
  {
    code: 'CUP',
    name: 'Cuba Peso',
  },
  {
    code: 'CVE',
    name: 'Cape Verde Escudo',
  },
  {
    code: 'CZK',
    name: 'Czech Republic Koruna',
  },
  {
    code: 'DJF',
    name: 'Djibouti Franc',
  },
  {
    code: 'DKK',
    name: 'Denmark Krone',
  },
  {
    code: 'DOP',
    name: 'Dominican Republic Peso',
  },
  {
    code: 'DZD',
    name: 'Algeria Dinar',
  },
  {
    code: 'EGP',
    name: 'Egypt Pound',
  },
  {
    code: 'ERN',
    name: 'Eritrea Nakfa',
  },
  {
    code: 'ETB',
    name: 'Ethiopia Birr',
  },
  {
    code: 'EUR',
    name: 'Euro Member Countries',
  },
  {
    code: 'FJD',
    name: 'Fiji Dollar',
  },
  {
    code: 'FKP',
    name: 'Falkland Islands (Malvinas) Pound',
  },
  {
    code: 'GBP',
    name: 'United Kingdom Pound',
  },
  {
    code: 'GEL',
    name: 'Georgia Lari',
  },
  {
    code: 'GGP',
    name: 'Guernsey Pound',
  },
  {
    code: 'GHS',
    name: 'Ghana Cedi',
  },
  {
    code: 'GIP',
    name: 'Gibraltar Pound',
  },
  {
    code: 'GMD',
    name: 'Gambia Dalasi',
  },
  {
    code: 'GNF',
    name: 'Guinea Franc',
  },
  {
    code: 'GTQ',
    name: 'Guatemala Quetzal',
  },
  {
    code: 'GYD',
    name: 'Guyana Dollar',
  },
  {
    code: 'HKD',
    name: 'Hong Kong Dollar',
  },
  {
    code: 'HNL',
    name: 'Honduras Lempira',
  },
  {
    code: 'HRK',
    name: 'Croatia Kuna',
  },
  {
    code: 'HTG',
    name: 'Haiti Gourde',
  },
  {
    code: 'HUF',
    name: 'Hungary Forint',
  },
  {
    code: 'IDR',
    name: 'Indonesia Rupiah',
  },
  {
    code: 'ILS',
    name: 'Israel Shekel',
  },
  {
    code: 'IMP',
    name: 'Isle of Man Pound',
  },
  {
    code: 'INR',
    name: 'India Rupee',
  },
  {
    code: 'IQD',
    name: 'Iraq Dinar',
  },
  {
    code: 'IRR',
    name: 'Iran Rial',
  },
  {
    code: 'ISK',
    name: 'Iceland Krona',
  },
  {
    code: 'JEP',
    name: 'Jersey Pound',
  },
  {
    code: 'JMD',
    name: 'Jamaica Dollar',
  },
  {
    code: 'JOD',
    name: 'Jordan Dinar',
  },
  {
    code: 'JPY',
    name: 'Japan Yen',
  },
  {
    code: 'KES',
    name: 'Kenya Shilling',
  },
  {
    code: 'KGS',
    name: 'Kyrgyzstan Som',
  },
  {
    code: 'KHR',
    name: 'Cambodia Riel',
  },
  {
    code: 'KMF',
    name: 'Comoros Franc',
  },
  {
    code: 'KPW',
    name: 'Korea (North) Won',
  },
  {
    code: 'KRW',
    name: 'Korea (South) Won',
  },
  {
    code: 'KWD',
    name: 'Kuwait Dinar',
  },
  {
    code: 'KYD',
    name: 'Cayman Islands Dollar',
  },
  {
    code: 'KZT',
    name: 'Kazakhstan Tenge',
  },
  {
    code: 'LAK',
    name: 'Laos Kip',
  },
  {
    code: 'LBP',
    name: 'Lebanon Pound',
  },
  {
    code: 'LKR',
    name: 'Sri Lanka Rupee',
  },
  {
    code: 'LRD',
    name: 'Liberia Dollar',
  },
  {
    code: 'LSL',
    name: 'Lesotho Loti',
  },
  {
    code: 'LYD',
    name: 'Libya Dinar',
  },
  {
    code: 'MAD',
    name: 'Morocco Dirham',
  },
  {
    code: 'MDL',
    name: 'Moldova Leu',
  },
  {
    code: 'MGA',
    name: 'Madagascar Ariary',
  },
  {
    code: 'MKD',
    name: 'Macedonia Denar',
  },
  {
    code: 'MMK',
    name: 'Myanmar (Burma) Kyat',
  },
  {
    code: 'MNT',
    name: 'Mongolia Tughrik',
  },
  {
    code: 'MOP',
    name: 'Macau Pataca',
  },
  {
    code: 'MRO',
    name: 'Mauritania Ouguiya',
  },
  {
    code: 'MUR',
    name: 'Mauritius Rupee',
  },
  {
    code: 'MVR',
    name: 'Maldives (Maldive Islands) Rufiyaa',
  },
  {
    code: 'MWK',
    name: 'Malawi Kwacha',
  },
  {
    code: 'MXN',
    name: 'Mexico Peso',
  },
  {
    code: 'MYR',
    name: 'Malaysia Ringgit',
  },
  {
    code: 'MZN',
    name: 'Mozambique Metical',
  },
  {
    code: 'NAD',
    name: 'Namibia Dollar',
  },
  {
    code: 'NGN',
    name: 'Nigeria Naira',
  },
  {
    code: 'NIO',
    name: 'Nicaragua Cordoba',
  },
  {
    code: 'NOK',
    name: 'Norway Krone',
  },
  {
    code: 'NPR',
    name: 'Nepal Rupee',
  },
  {
    code: 'NZD',
    name: 'New Zealand Dollar',
  },
  {
    code: 'OMR',
    name: 'Oman Rial',
  },
  {
    code: 'PAB',
    name: 'Panama Balboa',
  },
  {
    code: 'PEN',
    name: 'Peru Nuevo Sol',
  },
  {
    code: 'PGK',
    name: 'Papua New Guinea Kina',
  },
  {
    code: 'PHP',
    name: 'Philippines Peso',
  },
  {
    code: 'PKR',
    name: 'Pakistan Rupee',
  },
  {
    code: 'PLN',
    name: 'Poland Zloty',
  },
  {
    code: 'PYG',
    name: 'Paraguay Guarani',
  },
  {
    code: 'QAR',
    name: 'Qatar Riyal',
  },
  {
    code: 'RON',
    name: 'Romania New Leu',
  },
  {
    code: 'RSD',
    name: 'Serbia Dinar',
  },
  {
    code: 'RUB',
    name: 'Russia Ruble',
  },
  {
    code: 'RWF',
    name: 'Rwanda Franc',
  },
  {
    code: 'SAR',
    name: 'Saudi Arabia Riyal',
  },
  {
    code: 'SBD',
    name: 'Solomon Islands Dollar',
  },
  {
    code: 'SCR',
    name: 'Seychelles Rupee',
  },
  {
    code: 'SDG',
    name: 'Sudan Pound',
  },
  {
    code: 'SEK',
    name: 'Sweden Krona',
  },
  {
    code: 'SGD',
    name: 'Singapore Dollar',
  },
  {
    code: 'SHP',
    name: 'Saint Helena Pound',
  },
  {
    code: 'SLL',
    name: 'Sierra Leone Leone',
  },
  {
    code: 'SOS',
    name: 'Somalia Shilling',
  },
  {
    code: 'SPL',
    name: '	Seborga Luigino',
  },
  {
    code: 'SRD',
    name: 'Suriname Dollar',
  },
  {
    code: 'STD',
    name: 'São Tomé and Príncipe Dobra',
  },
  {
    code: 'SVC',
    name: 'El Salvador Colon',
  },
  {
    code: 'SYP',
    name: 'Syria Pound',
  },
  {
    code: 'SZL',
    name: 'Swaziland Lilangeni',
  },
  {
    code: 'THB',
    name: 'Thailand Baht',
  },
  {
    code: 'TJS',
    name: 'Tajikistan Somoni',
  },
  {
    code: 'TMT',
    name: 'Turkmenistan Manat',
  },
  {
    code: 'TND',
    name: 'Tunisia Dinar',
  },
  {
    code: 'TOP',
    name: "Tonga Pa'anga",
  },
  {
    code: 'TRY',
    name: 'Turkey Lira',
  },
  {
    code: 'TTD',
    name: 'Trinidad and Tobago Dollar',
  },
  {
    code: 'TVD',
    name: 'Tuvalu Dollar',
  },
  {
    code: 'TWD',
    name: 'Taiwan New Dollar',
  },
  {
    code: 'TZS',
    name: 'Tanzania Shilling',
  },
  {
    code: 'UAH',
    name: 'Ukraine Hryvnia',
  },
  {
    code: 'UGX',
    name: 'Uganda Shilling',
  },
  {
    code: 'USD',
    name: 'United States Dollar',
  },
  {
    code: 'UYU',
    name: 'Uruguay Peso',
  },
  {
    code: 'UZS',
    name: 'Uzbekistan Som',
  },
  {
    code: 'VEF',
    name: 'Venezuela Bolivar',
  },
  {
    code: 'VND',
    name: 'Viet Nam Dong',
  },
  {
    code: 'VUV',
    name: 'Vanuatu Vatu',
  },
  {
    code: 'WST',
    name: 'Samoa Tala',
  },
  {
    code: 'XAF',
    name: 'Communauté Financière Africaine (BEAC) CFA Franc BEAC',
  },
  {
    code: 'XAG',
    name: 'Silver Ounce',
  },
  {
    code: 'XAU',
    name: 'Gold Ounce',
  },
  {
    code: 'XBT',
    name: 'Bitcoin',
  },
  {
    code: 'XCD',
    name: 'East Caribbean Dollar',
  },
  {
    code: 'XDR',
    name: 'International Monetary Fund (IMF) Special Drawing Rights',
  },
  {
    code: 'XOF',
    name: 'Communauté Financière Africaine (BCEAO) Franc',
  },
  {
    code: 'XPD',
    name: 'Palladium Ounce',
  },
  {
    code: 'XPF',
    name: 'Comptoirs Français du Pacifique (CFP) Franc',
  },
  {
    code: 'XPT',
    name: 'Platinum Ounce',
  },
  {
    code: 'YER',
    name: 'Yemen Rial',
  },
  {
    code: 'ZAR',
    name: 'South Africa Rand',
  },
  {
    code: 'ZMW',
    name: 'Zambia Kwacha',
  },
  {
    code: 'ZWD',
    name: 'Zimbabwe Dollar',
  },
];

export default CurrencyCodes;
