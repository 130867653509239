import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';
import OhsHumanResourceTrainingListsRecord, {
  OhsHumanResourceTrainingFilterPayload,
} from 'human-resource/models/OhsHumanResourceTrainingRecord';

import globalModuleSearch from '../search/OhsSearchServices';
import { getHRTrainingLists } from './OhsHumanResourceServices';
import { OhsUserState } from '../user/userSlice';
import {
  ohsHasTrainingPrivacyAccess,
  ohsParseTrainingPrivacy,
} from './models/OhsHumanResourcePrivacyFuncs';
import { OhsUser } from '../user/OhsUser';

export interface TrainingListsRecordState {
  isLoading: boolean;
  hrTrainings: ApiResListsType<OhsHumanResourceTrainingListsRecord[]> | null;
  currentPage: number;
}

const initialState: TrainingListsRecordState = {
  isLoading: false,
  hrTrainings: null,
  currentPage: 1,
};

export const getViewPreset = (tierNum: number) => {
  switch (tierNum) {
    case 3:
    case 4:
      return 'view_1';
    default:
      return undefined;
  }
};

const hrTrainingSearch = async (
  searchDetails: any
): Promise<ApiResListsType<OhsHumanResourceTrainingListsRecord[]> | null> => {
  const globalSearchRes: any = await globalModuleSearch(searchDetails);
  return globalSearchRes.result;
};

const filterOptions = (state: RootState): Partial<OhsHumanResourceTrainingFilterPayload> => {
  const { globalfilter, user, hrTrainings } = state;
  const filter = globalfilter.filterInfo;
  const userTier = user.user?.tierNum ?? 0;
  return {
    viewPreset: getViewPreset(userTier),
    next: hrTrainings.currentPage > 10 ? hrTrainings.hrTrainings?.pagination.next ?? '' : '',
    workplaces: filter.hrTrainingModule.workplaces ?? [],
    archived: filter.hrTrainingModule.archived,
    skipOrgRecords: filter.hrTrainingModule.skipOrgRecords,
  };
};

const hrTrainingListsRPC = async (
  user: OhsUserState,
  setHRFilters: OhsHumanResourceTrainingFilterPayload,
  _id?: string
): Promise<any> => {
  if (user.user?.configs.training?.PERMISSIONS.view) {
    const response = await getHRTrainingLists(setHRFilters, _id);
    return response;
  }
  return null;
};
export const fetchHRTrainingListsAsync = createAsyncThunk<
  ApiResListsType<OhsHumanResourceTrainingListsRecord[]> | null,
  string | undefined,
  { state: RootState }
>('hrTrainings/fetchTrainingList', async (id, thunkAPI) => {
  const { user } = thunkAPI.getState();
  const state = thunkAPI.getState();
  const filter = thunkAPI.getState().globalfilter.filterInfo;

  const searchFilter = thunkAPI.getState().globalSearch.searchInfo;
  const hasGlobalSearch = state.globalSearch.searchInfo.searchKey !== '';
  const setHumanResourceFilters: OhsHumanResourceTrainingFilterPayload = {
    ...filterOptions(state),
    page: state.hrTrainings.currentPage > 10 ? 0 : state.hrTrainings.currentPage,
    sort: { ...JSON.parse(String(filter.hrTrainingModule.sort)) },
    count: false,
  };

  if (id) {
    setHumanResourceFilters.viewPreset = 'view_2';
  }

  try {
    const response = hasGlobalSearch
      ? await hrTrainingSearch({
          ...searchFilter,
        })
      : await hrTrainingListsRPC(
          user,
          {
            ...setHumanResourceFilters,
          },
          id
        );
    if (!ohsHasTrainingPrivacyAccess(user.user as OhsUser)) {
      response.items?.forEach((r: any) => {
        ohsParseTrainingPrivacy(user.user as OhsUser, r);
      });
    }
    return response as ApiResListsType<OhsHumanResourceTrainingListsRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchHumanResourceTrainingListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsHumanResourceTrainingListsRecord[]> | null,
  string | undefined,
  { state: RootState }
>('hrTrainings/fetchTrainingCountList', async (id, thunkAPI) => {
  const state = thunkAPI.getState();
  const hasGlobalSearch = state.globalSearch.searchInfo.searchKey !== '';
  const searchFilter = thunkAPI.getState().globalSearch.searchInfo;

  const hrFiltersWithCount: OhsHumanResourceTrainingFilterPayload = {
    ...filterOptions(state),
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  if (id) {
    hrFiltersWithCount.viewPreset = 'view_2';
  }
  try {
    const response = hasGlobalSearch
      ? await hrTrainingSearch({
          ...searchFilter,
        })
      : await getHRTrainingLists(
          {
            ...hrFiltersWithCount,
          },
          id
        );
    return response as ApiResListsType<OhsHumanResourceTrainingListsRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const hrTrainingSlice = createSlice({
  name: 'hrTrainings',
  initialState,
  reducers: {
    setHumanResourceTrainingCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHRTrainingListsAsync.pending, (state) => {
        state.isLoading = true;
        state.hrTrainings = {
          items: [],
          pagination: state.hrTrainings?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchHRTrainingListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.hrTrainings = action.payload;
          if (state.hrTrainings?.pagination?.totalPages) {
            // store total pages to localstorage
            setOhsLocalStorage('hrTrainingListtotalPages', state.hrTrainings.pagination.totalPages);
          }
          // get last _id and set it to pagination next
          const setItemsLastId = [...state.hrTrainings.items].pop()?._id ?? '';
          state.hrTrainings.pagination.next = setItemsLastId;
          state.hrTrainings.pagination.totalPages =
            getOhsLocalStorage('hrTrainingListtotalPages') ?? 0;
          state.isLoading = false;
        }
      })
      .addCase(fetchHRTrainingListsAsync.rejected, (state) => {
        state.hrTrainings = null;
      })
      .addCase(fetchHumanResourceTrainingListsCountAsync.fulfilled, (state, action) => {
        if (state.hrTrainings && action.payload && action.payload.pagination) {
          setOhsLocalStorage('hrTrainingListtotalPages', action?.payload?.pagination?.totalPages);
          state.hrTrainings.pagination.totalPages = action?.payload?.pagination?.totalPages ?? 0;
        }
      });
  },
});

const trainingState = (state: RootState) => state.hrTrainings;
// Memoized Selectors
export const getOhsHRTrainingModuleState = createSelector(
  [trainingState],
  (hrTrainings) => hrTrainings
);
export const { setHumanResourceTrainingCurrentPage } = hrTrainingSlice.actions;
export const hrTrainingModuleReducer = hrTrainingSlice.reducer;
