import React from 'react';

import { ListItem, ListItemProps, ListItemText } from '@mui/material';

import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';
import OhsFreemiumAlertModal from 'freemium/OhsFreemiumAlertModal';

import getMixpanelTraker from '../../global-services/mixpanel/OhsMixpanel';
import ModuleHomeUrl from './ModuleHomeUrl';

export function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
  return <ListItem button component="a" {...props} />;
}

export function OhsLeftSideDrawerFreeUserModuleList() {
  const user = useAppSelector(getOhsUser);
  const [freemiumPaidMsgOpen, setFreemiumPaidMsgOpen] = React.useState(false);
  const moduleHome = user ? (ModuleHomeUrl[user.tier.type] as any) : {};
  const openPaidPlanModal = (moduleName: string) => {
    try {
      getMixpanelTraker()?.track('Unpaid Module Select', {
        'Module Name': moduleName,
      });
    } catch (e) {
      console.error(e);
    }

    setFreemiumPaidMsgOpen(true);
  };

  return (
    <div>
      {user?.tierNum === 2 && (
        <ListItemLink button href="/onboardings/home" dense>
          <ListItemText primary="Onboardings" />
        </ListItemLink>
      )}
      <ListItemLink button href={moduleHome.action} dense>
        <ListItemText primary="Action" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Chemical');
        }}
        dense
      >
        <ListItemText primary="Chemical - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Communication');
        }}
        dense
      >
        <ListItemText primary="Communication - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Contractor');
        }}
        dense
      >
        <ListItemText primary="Contractor - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Data Analytics');
        }}
        dense
      >
        <ListItemText primary="Data Analytics - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Document');
        }}
        dense
      >
        <ListItemText primary="Document - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Equipment Register');
        }}
        dense
      >
        <ListItemText primary="Equipment Register - PAID PLAN SOON" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Human Resources');
        }}
        dense
      >
        <ListItemText primary="Human Resources - PAID PLAN" />
      </ListItemLink>
      <ListItemLink button href={moduleHome.incident} dense>
        <ListItemText primary="Incident" />
      </ListItemLink>
      <ListItemLink button href={moduleHome.inspection} dense>
        <ListItemText primary="Inspection" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Insurance');
        }}
        dense
      >
        <ListItemText primary="Insurance - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Injury Management');
        }}
        dense
      >
        <ListItemText primary="Injury Management - PAID PLAN SOON" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Noticeboard');
        }}
        dense
      >
        <ListItemText primary="Noticeboard - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Risk Management');
        }}
        dense
      >
        <ListItemText primary="Risk Management - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Training');
        }}
        dense
      >
        <ListItemText primary="Training - PAID PLAN" />
      </ListItemLink>
      <ListItemLink button href={moduleHome.safetyplan} dense>
        <ListItemText primary="Safety Plan" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Visitor');
        }}
        dense
      >
        <ListItemText primary="Visitor - PAID PLAN" />
      </ListItemLink>
      <ListItemLink
        button
        onClick={() => {
          openPaidPlanModal('Worker Records');
        }}
        dense
      >
        <ListItemText primary="Worker Records - PAID PLAN" />
      </ListItemLink>
      <OhsFreemiumAlertModal
        freemiumPaidMsgOpen={freemiumPaidMsgOpen}
        setFreemiumPaidMsgOpen={setFreemiumPaidMsgOpen}
      />
    </div>
  );
}
