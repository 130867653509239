import { ReactNode } from 'react';

import shortid from 'shortid';

import { getEnumKeyByEnumValue } from 'global-services/OhsDataParse';
import { ModuleType } from 'global-services/constants/OhsObject';

export enum OhsCusvalType {
  Currency = 'currency',
  Radio = 'radio',
  Checkbox = 'checkbox',
  Text = 'text',
  Textarea = 'textarea',
  Email = 'email',
  Telephone = 'tel',
  Number = 'number',
  Date = 'date',
  Time = 'time',
  Color = 'color',
  Url = 'url',
  Select = 'select_single',
  MutipleSelect = 'select_multiple',
  Static = 'static',
}

export class OhsCusval {
  title: string;

  description?: string;

  required: boolean;

  deleted?: boolean;

  invalid?: boolean;

  requiredDisplay?: string;

  type: OhsCusvalType;

  typeDisplay?: string;

  _id: string;

  value: any;

  options?: Array<any>;

  disabled?: boolean;

  renderAction?: ReactNode;

  constructor(type: OhsCusvalType) {
    this.title = '';
    this.description = '';
    this._id = shortid.generate();
    this.type = type;
    this.typeDisplay = getEnumKeyByEnumValue(OhsCusvalType, type);
    this.required = false;
    this.requiredDisplay = 'No';
  }
}

export type OhsCusvalDefinition = {
  _id: string;
  type: ModuleType.CusvalDefinition;
  cusvals?: OhsCusval[];
};

export interface OhsCheckboxCusval extends OhsCusval {
  type: OhsCusvalType.Checkbox;
  options: Array<{ name: string; icon?: string }>;
}

export const FrequencyOptions = [
  { name: 'Day(s)', value: 'days' },
  { name: 'Week(s)', value: 'weeks' },
  { name: 'Month(s)', value: 'months' },
  { name: 'Year(s)', value: 'years' },
];

export interface OhsCurrencyCusval extends OhsCusval {
  type: OhsCusvalType.Currency;
  options: Array<string>;
}

export type OhsCusvalSaveType = {
  _id: string;
  cusvals: OhsCusval;
  cusvalDef?: OhsCusvalDefinition;
};
