import React from 'react';

import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Box } from '@mui/material';

import { OhsMfaMethodSettings } from 'user/OhsUser';

import { OhsMethods } from './OhsMfaTypes';

/**
 * @param method - The method string.
 * @returns The icon component for the method.
 */
export const getMethodIcon = (method: 'email' | 'sms' | 'app'): JSX.Element => {
  switch (method) {
    case 'email':
      return <EmailIcon data-testid="email-icon" />;
    case 'sms':
      return <SmsIcon data-testid="sms-icon" />;
    case 'app':
      return <QrCodeIcon data-testid="qrcode-icon" />;
    default:
      return <Box />;
  }
};

export const getMfaMethodPermission = (
  mfaMethod: OhsMethods,
  mfaMethodSettings: OhsMfaMethodSettings,
  currentMfaConfig: string,
  mfaEnabledList: any
) => {
  const isMethodEnabled = mfaMethodSettings[mfaMethod];
  const isMfaRequired = currentMfaConfig === 'required';
  const isMfaOptional = currentMfaConfig === 'optional';
  // const isMfaDisabled = currentMfaConfig === 'disabled';
  const isMethodActivated = !!mfaEnabledList.includes(mfaMethod);

  const hasMethodAccess =
    isMfaRequired || // display method if MFA is required
    isMethodActivated || // display method if method is activated
    (isMfaOptional && isMethodEnabled); // display method if MFA is optional and method is enabled

  return hasMethodAccess;
};

export const getMfaSettingsPermission = (currentMfaConfig: string, mfaActiveList: string[]) => {
  const hasActiveMfaMethod = mfaActiveList.length > 0;

  const mfaHasAccess =
    currentMfaConfig === 'required' ||
    currentMfaConfig === 'optional' ||
    (currentMfaConfig === 'disabled' && hasActiveMfaMethod);
  return mfaHasAccess;
};

export default getMethodIcon;
