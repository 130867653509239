import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import handleRegisterAsyncData, {
  handleRegisterCountAsyncData,
} from 'global-components/register/OhsModuleRegisterUtils';
import { OhsSafetyPlanViewPresets } from 'dashboard/topFilter/OhsSafetyPlanFilterModels';

import { SafetyPlanListsRecordState } from '../OhsSafetyPlanModels';
import {
  fetchSafetyPlanAllocatedListsAsync,
  fetchSafetyPlanCopyListsAsync,
  fetchSafetyPlanListsAsync,
  fetchSafetyPlanListsCountAsync,
  fetchSafetyPlanScheduledListsAsync,
  fetchSafetyPlanScheduledListsCountAsync,
} from './OhsSafetyPlanActions';

const defaultSafetyPlanLists = {
  items: [],
  pagination: {
    page: 0,
    totalPages: 0,
    next: '',
  },
};
const initialState: SafetyPlanListsRecordState = {
  isLoading: false,
  safetyPlanLists: defaultSafetyPlanLists,
  safetyPlanScheduledLists: defaultSafetyPlanLists,
  allocatedList: null,
  safetyPlanCopyList: {
    isLoading: false,
    copyList: [],
  },
  currentPage: 1,
  currentViewPreset: OhsSafetyPlanViewPresets.View2SafetyPlanList,
};

export const safetyPlanSlice = createSlice({
  name: 'safetyPlan',
  initialState,
  reducers: {
    setCurrentRegisterPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setSafetyPlanIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setViewPreset: (state, action: PayloadAction<OhsSafetyPlanViewPresets>) => {
      state.currentViewPreset = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSafetyPlanListsAsync.pending, (state) => {
        state.isLoading = true;
        state.safetyPlanLists = defaultSafetyPlanLists;
      })
      .addCase(fetchSafetyPlanListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const safetyPlanList = handleRegisterAsyncData(
            action.payload,
            'safetyPlanListtotalPages'
          );
          state.isLoading = false;
          state.safetyPlanLists = safetyPlanList;
          state.currentPage = safetyPlanList.pagination.page ?? 1;
        }
      })
      .addCase(fetchSafetyPlanListsAsync.rejected, (state) => {
        state.safetyPlanLists = defaultSafetyPlanLists;
      })
      .addCase(fetchSafetyPlanAllocatedListsAsync.pending, (state) => {
        state.allocatedList = [];
      })
      .addCase(fetchSafetyPlanAllocatedListsAsync.fulfilled, (state, action) => {
        state.allocatedList = action.payload ?? [];
        state.isLoading = false;
      })
      .addCase(fetchSafetyPlanCopyListsAsync.pending, (state) => {
        state.safetyPlanCopyList.copyList = [];
        state.safetyPlanCopyList.isLoading = true;
      })
      .addCase(fetchSafetyPlanCopyListsAsync.fulfilled, (state, action) => {
        state.safetyPlanCopyList.copyList = action.payload ?? [];
        state.safetyPlanCopyList.isLoading = false;
      })
      .addCase(fetchSafetyPlanListsCountAsync.fulfilled, (state, action) => {
        const safetyPlanListCountResult: any = action.payload;
        if (state.safetyPlanLists && safetyPlanListCountResult) {
          const countSafetyPlanLists = handleRegisterCountAsyncData(
            safetyPlanListCountResult,
            state.safetyPlanLists,
            'safetyPlanListtotalPages'
          );
          state.safetyPlanLists = countSafetyPlanLists;
        }
      })
      .addCase(fetchSafetyPlanScheduledListsAsync.pending, (state) => {
        state.isLoading = true;
        state.safetyPlanLists = defaultSafetyPlanLists;
        state.safetyPlanScheduledLists = defaultSafetyPlanLists;
      })
      .addCase(fetchSafetyPlanScheduledListsAsync.fulfilled, (state, action) => {
        const scheduledListsCountResult: any = action.payload;
        if (action.payload && scheduledListsCountResult) {
          const safetyPlanScheduled = handleRegisterAsyncData(
            action.payload,
            'safetyPlanScheduledListtotalPages'
          );
          state.isLoading = false;
          state.safetyPlanScheduledLists = safetyPlanScheduled;
          state.currentPage = safetyPlanScheduled.pagination.page ?? 1;
        }
      })
      .addCase(fetchSafetyPlanScheduledListsCountAsync.fulfilled, (state, action) => {
        const scheduledListsCountResult: any = action.payload;
        if (state.safetyPlanScheduledLists && action.payload) {
          const countSafetyPlanLists = handleRegisterCountAsyncData(
            scheduledListsCountResult,
            state.safetyPlanScheduledLists,
            'safetyPlanScheduledListtotalPages'
          );
          state.safetyPlanScheduledLists = countSafetyPlanLists;
        }
        state.isLoading = false;
      });
  },
});

// Memoized Selectors
const safetyPlanState = (state: RootState) => state.safetyPlan;
export const getOhsSafetyPlanLists = createSelector([safetyPlanState], (safetyPlan) => safetyPlan);

export const { setCurrentRegisterPage, setSafetyPlanIsLoading, setViewPreset } =
  safetyPlanSlice.actions;
export const safetyPlanModuleReducer = safetyPlanSlice.reducer;
