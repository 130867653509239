import _ from 'lodash';

import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleDefaultTitle, ModuleType } from 'global-services/constants/OhsObject';
import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';

import OhsConfigurations from './configurations/OhsConfigurations';
import { OhsModuleConfig } from './configurations/OhsModuleConfig';
import {
  OhsCurrentUser,
  OhsModuleOption,
  OhsOrgControlPayload,
  OhsWorkplaceOption,
} from './OhsUserModels';
import { OhsUser } from './OhsUser';
import OhsUserSettings from './user-settings/OhsUserSettingsModels';
import { getMultiUserConfig } from './user-settings/OhsUserSettingsUtils';

export const whoAmI = (): Promise<Partial<OhsUser> | null> => {
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.Whoami);
};

export const fetchCurrentUserSettings = (): Promise<OhsCurrentUser | null> => {
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.Fetch);
};
export const fetchCurrentUser = () => {
  return fetchCurrentUserSettings().then((response: OhsCurrentUser | null) => {
    return whoAmI().then((whoAmIRes: Partial<OhsUser> | null) => {
      if (whoAmIRes && response) response.user = whoAmIRes;
      return response;
    });
  });
};

export const updateUser = (userData: OhsUserSettings): Promise<any | null> => {
  const isMultiUser = getMultiUserConfig().length > 0;
  if (isMultiUser)
    OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.MultiUserEdit, {
      ...userData,
    });

  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.Edit, {
    ...userData,
  });
};

export const fetchOrgControl = (info: OhsOrgControlPayload): Promise<any | null> => {
  return OhsApiRequest(RequestType.Admin, ModuleType.Admin2, OhsApiRequestName.OrgControlFetch, {
    ...info,
  });
};

export const getModuleName = (
  configs: OhsConfigurations | undefined,
  moduleType: ModuleType
): string => {
  if (!configs) {
    return ModuleDefaultTitle[moduleType];
  }
  switch (moduleType) {
    case ModuleType.Action: {
      return configs.action?.TITLE ?? ModuleDefaultTitle[moduleType];
    }

    case ModuleType.Incident: {
      return configs.incident?.TITLE ?? ModuleDefaultTitle[moduleType];
    }

    case ModuleType.ReviewPlan: {
      return configs.reviewplan?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.Chemical: {
      return configs?.chemical?.TITLE ?? ModuleDefaultTitle[moduleType];
    }

    case ModuleType.SafetyPlan: {
      return configs.safetyplan?.TITLE ?? ModuleDefaultTitle[moduleType];
    }

    case ModuleType.Noticeboard: {
      return configs?.noticeboard?.TITLE ?? ModuleDefaultTitle[moduleType];
    }

    case ModuleType.NoticeboardBoard: {
      return configs?.noticeboard?.TITLE ?? ModuleDefaultTitle[moduleType];
    }

    case ModuleType.CorporateRisk: {
      return configs.corporateRisk?.TITLE ?? ModuleDefaultTitle[moduleType];
    }

    case ModuleType.Asset: {
      return configs.asset?.TITLE ?? ModuleDefaultTitle[moduleType];
    }

    case ModuleType.Contractor: {
      return configs.contractor?.TITLE ?? ModuleDefaultTitle[moduleType];
    }

    case ModuleType.Correspondence: {
      return configs.correspondence?.TITLE ?? ModuleDefaultTitle[moduleType];
    }

    case ModuleType.HR: {
      return configs.hr?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.Export: {
      return configs.export?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.Training: {
      return configs?.training?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.Visitor: {
      return configs?.visitor?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.VisitorSite: {
      return ModuleDefaultTitle[moduleType];
    }
    case ModuleType.Inspection: {
      return configs?.inspection?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.ActionFileBox: {
      return configs?.action?.fileboxConfig?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.AssetFileBox: {
      return configs?.asset?.fileboxConfig?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.ChemicalFileBox: {
      return configs?.chemical?.fileboxConfig?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.ContractorFileBox: {
      return configs?.contractor?.fileboxConfig?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.CorporateRiskFileBox: {
      return configs?.corporateRisk?.fileboxConfig?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.HumanResourceFileBox: {
      return configs?.hr?.fileboxConfig?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.IncidentFileBox: {
      return configs?.incident?.fileboxConfig?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.ReviewPlanFileBox: {
      return configs?.reviewplan?.fileboxConfig?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.VersionDocumentFileBox: {
      return configs?.vdoc?.fileboxConfig?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.VDocVersion:
    case ModuleType.VersionDocument: {
      return configs?.vdoc?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    case ModuleType.Theme:
    case ModuleType.Notification:
    case ModuleType.l10n: {
      return ModuleDefaultTitle[moduleType];
    }
    case ModuleType.BusinessIntelligence: {
      return configs.bi?.TITLE ?? ModuleDefaultTitle[moduleType];
    }
    default:
      return moduleType;
  }
};

export const getCurrentURLModuleConfig = (configs: OhsConfigurations): OhsModuleConfig | null => {
  const { pathname } = window.location;
  const modulePath = pathname.split('/')[1];

  switch (modulePath) {
    case 'review-plan': {
      return configs.reviewplan;
    }
    case 'safety-plan':
      return configs.safetyplan;

    case 'people': {
      return configs.hr;
    }
    case 'documents': {
      return configs.vdoc;
    }

    case 'noticeboard': {
      return configs.noticeboard;
    }

    case 'crisk': {
      return configs.corporateRisk;
    }

    case 'assets': {
      return configs.asset;
    }

    case 'inspection': {
      return configs.inspection;
    }

    case 'action': {
      return configs.action;
    }
    case 'contractor': {
      return configs.contractor;
    }

    case 'correspondence': {
      return configs.correspondence as unknown as OhsModuleConfig;
    }

    case 'chemical': {
      return configs.chemical;
    }

    case 'ai': {
      return configs.incident;
    }

    case 'visitor': {
      return configs.visitor;
    }

    default: {
      return null;
    }
  }
};

export const getCurrentApiRoot = (): string => {
  const { pathname } = window.location;
  const modulePath = pathname.split('/')[1];
  switch (modulePath) {
    case 'safety-plan':
      return 'safetyplans';
    case 'crisk':
      return 'crisks';
    case 'review-plan':
      return 'reviewplan';
    default:
      return modulePath;
  }
};

export const getModuleOptions = (configs?: OhsConfigurations): OhsModuleOption[] => {
  const moduleOptions = new Array<OhsModuleOption>();

  if (configs && configs.action?.TITLE) {
    moduleOptions.push({ name: configs.action?.TITLE, key: ModuleType.Action });
  }

  if (configs && configs.asset?.TITLE) {
    moduleOptions.push({ name: configs.asset?.TITLE, key: ModuleType.Asset });
  }

  if (configs && configs.incident?.TITLE) {
    moduleOptions.push({ name: configs.incident?.TITLE, key: ModuleType.Incident });
  }
  if (configs && configs.chemical?.TITLE) {
    moduleOptions.push({ name: configs.chemical.TITLE, key: ModuleType.Chemical });
  }

  if (configs && configs.inspection?.TITLE) {
    moduleOptions.push({ name: configs.inspection?.TITLE, key: ModuleType.Inspection });
  }

  if (configs && configs.reviewplan?.TITLE) {
    moduleOptions.push({ name: configs.reviewplan?.TITLE, key: ModuleType.ReviewPlan });
  }

  if (configs && configs.safetyplan?.TITLE) {
    moduleOptions.push({ name: configs.safetyplan?.TITLE, key: ModuleType.SafetyPlan });
  }
  if (configs && configs.vdoc?.TITLE) {
    moduleOptions.push({ name: configs.vdoc?.TITLE, key: ModuleType.VersionDocument });
  }
  if (configs && configs.training?.TITLE) {
    moduleOptions.push({ name: configs.training?.TITLE, key: ModuleType.Training });
  }
  if (configs && configs.corporateRisk?.TITLE) {
    moduleOptions.push({ name: configs.corporateRisk?.TITLE, key: ModuleType.CorporateRisk });
  }

  const sortedAscModuleOptions = _.orderBy(moduleOptions, ['name'], ['asc']);
  return sortedAscModuleOptions;
};

export const getWorkplaceOptions = (configs?: OhsConfigurations) => {
  const workplaceOptions = new Array<OhsWorkplaceOption>();
  if (configs && configs.admin2?.LOWER_TIER_ACCESS) {
    configs.admin2?.LOWER_TIER_ACCESS.forEach((item) => {
      if (item.access === true && item.disabled !== true) {
        workplaceOptions.push({ name: item.name, id: item._id });
      }
    });
  }
  return workplaceOptions;
};

export const getWorkplaceName = (id: string, configs?: OhsConfigurations) => {
  const options = getWorkplaceOptions(configs);
  const targetWorkplace = options.find((item) => item.id === id);
  return targetWorkplace?.name;
};
