export default class OhsCorrespondenceFilterModels {
  viewPreset?: 'view_1' | 'view_2' | 'view_3';

  archived: boolean;

  sort: string;

  workplaces: string[];

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  constructor() {
    this.viewPreset = undefined;
    this.archived = false;
    this.workplaces = [];
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Date Created: Newest to Oldest', value: '{ "key": "dateCreated", "order": -1 }' },
        { name: 'Date Created: Oldest to Newest', value: '{ "key": "dateCreated", "order": 1 }' },
        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest ', value: '{ "key": "_id", "order": 1 }' },
      ],
    };
    this.sort = '{ "key": "dateCreated", "order": -1 }';
  }
}
