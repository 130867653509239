import React from 'react';

import { Box, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Header, SemanticTEXTALIGNMENTS } from 'semantic-ui-react';
import clsx from 'clsx';

import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';

export const registerButtonStylesFunc = (theme: Theme) => ({
  button: {
    marginRight: '1px!important',

    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      padding: '.78571429em .78571429em .78571429em!important',
    },
  },
  buttonGroup: {
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      margin: '10px 5px 10px 0!important',
    },
  },
  buttonIcons: {
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      opacity: '.9',
      margin: '0!important',
      verticalAlign: 'top',
    },
  },
  hideForSmall: {
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'inline',
    },
  },
  displayForSmall: {
    display: 'inline',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'none',
    },
  },
  menuItem: {
    width: '100%',
  },
});
export const registerButtonStyles = makeStyles()(registerButtonStylesFunc);

const useStyles = makeStyles<{ color?: string }>()((theme: Theme, { color }) => {
  return {
    header: {
      color: color || `${theme.palette.custom?.HEADER_BAR_BG_COLOR}!important`,
    },
    title: {
      overflowWrap: 'break-word',
    },
    hasTitleContent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    childrenWrap: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'right',
    },
  };
});
interface Props {
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  textAlign?: SemanticTEXTALIGNMENTS;
  color?: string;
}

function OhsModuleHeader(props: Props) {
  const { classes } = useStyles({ color: props.color });

  return (
    <Header
      as={props.as}
      className={clsx({
        [classes.header]: true,
        [classes.hasTitleContent]: props.title && props.children,
      })}
      textAlign={props.textAlign}
    >
      {props.title && <Box className={classes.title}>{props.title ?? ''}</Box>}
      {props.children && <Box className={classes.childrenWrap}>{props.children}</Box>}
    </Header>
  );
}

OhsModuleHeader.defaultProps = {
  children: undefined,
  title: '',
  as: 'h2',
  textAlign: undefined,
  color: undefined,
};

export default OhsModuleHeader;
