import React from 'react';

import { Box } from '@mui/material';
import { Header } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  messageBox: {
    width: '100%',
    border: '2px solid #95c9d4',
    borderRadius: '5px',
    background: '#f8ffff',
    padding: '1em 1.5em',
  },
  title: {
    color: '#0e566c !important',
    marginBottom: '.25rem !important',
  },
  description: {
    color: '#0e566c',
  },
}));

interface Props {
  title: string;
  description?: string;
}

function OhsMessageBox({ title, description }: Props) {
  const { classes } = useStyles();

  return (
    <Box className={classes.messageBox}>
      <Header as="h3" className={classes.title}>
        {title}
      </Header>
      {description && <p className={classes.description}>{description}</p>}
    </Box>
  );
}

OhsMessageBox.defaultProps = {
  description: undefined,
};

export default OhsMessageBox;
