const ModuleHomeUrl = {
  'core.tier.T1': {},
  'core.tier.T2': {
    vdoc: '/documents/home',
    import: '/admin2/import',
    inspection: '/inspection/home/template?archived=false',
    safetyplan: '/safety-plan/home?tab=tp',
  },
  'core.tier.T3': {
    correspondence: '/correspondence/home/',
    action: '/action/home',
    inspection: '/inspection/home',
    chemical: '/chemical/home',
    contractor: '/contractor/home',
    vdoc: '/documents/home',
    hr: '/people/home',
    training: '/people/home?tab=tr',
    incident: '/ai/home',
    reviewplan: '/review-plan/home?tab=li',
    safetyplan: '/safety-plan/home',
    visitor: '/visitor/home',
    noticeboard: '/noticeboard/home',
    corporateRisk: '/crisk/home',
    asset: '/assets/home',
  },
  'core.tier.T4': {
    correspondence: '/correspondence/home/',
    action: '/action/home',
    inspection: '/inspection/home',
    chemical: '/chemical/home',
    contractor: '/contractor/home',
    vdoc: '/documents/home',
    hr: '/people/home',
    training: '/people/home?tab=tr',
    incident: '/ai/home',
    reviewplan: '/review-plan/home',
    safetyplan: '/safety-plan/home',
    visitor: '/visitor/home',
    noticeboard: '/noticeboard/home',
    corporateRisk: '/crisk/home',
    asset: '/assets/home',
  },
  'core.tier.T5': { action: '/action/home' },
};
export default ModuleHomeUrl;
