import React from 'react';

import { Pagination } from '@mui/material';

interface Props {
  gotoPage: any;
  pageIndex: number;
  totalPages: number;
}

export default function OhsRegisterTablePagination({ gotoPage, pageIndex, totalPages }: Props) {
  const handlePaginationChange = (event: React.ChangeEvent<unknown>, value: number) => {
    gotoPage(value - 1);
  };

  return (
    <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
      <Pagination
        variant="outlined"
        shape="rounded"
        count={totalPages}
        page={pageIndex + 1}
        onChange={handlePaginationChange}
      />
    </div>
  );
}
