/* eslint-disable react/require-default-props */
import React from 'react';

import { Menu, MenuItem } from '@mui/material';
import { Button, Icon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';

import { registerButtonStyles } from '../../register/OhsModuleHeader';

interface Props {
  title?: string;
  color?: SemanticCOLORS;
  options: Array<{
    name: string;
    key: string;
    description?: string;
    icon?: SemanticICONS;
    isButton?: boolean;
  }>;
  handleSelect: (key: string) => void;
  icon?: SemanticICONS;
}
export default function OhsDropdownButton(props: Props) {
  const { classes } = registerButtonStyles();
  const [modalAnchor, setModalAnchor] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setModalAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setModalAnchor(null);
  };

  return (
    <>
      <Button
        icon={!props.title}
        className={classes.button}
        color={props.color}
        onClick={handleClick}
      >
        <Icon className={classes.buttonIcons} name={props.icon ?? 'ellipsis vertical'} />
        {props.title}
      </Button>
      <Menu
        anchorEl={modalAnchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        keepMounted
        open={Boolean(modalAnchor)}
        onClose={handleClose}
      >
        {props.options.map((item, index) => (
          <MenuItem
            key={index}
            component="button"
            className={classes.menuItem}
            onClick={() => {
              props.handleSelect(item.key);
              handleClose();
            }}
          >
            {item.isButton ? (
              <Button color="green">
                {item.icon && (
                  <span style={{ marginRight: '5px' }}>
                    <Icon className={classes.buttonIcons} name={item.icon} />
                  </span>
                )}
                {item.name}
              </Button>
            ) : (
              <>
                {item.icon && (
                  <span style={{ marginRight: '5px' }}>
                    <Icon className={classes.buttonIcons} name={item.icon} />
                  </span>
                )}
                {item.name}
              </>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
