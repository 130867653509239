class OhsAdmin2Permissions {
  // Note that these are *DEFAULT* permissions.
  //
  // All of these properties are the possible
  // values that can be found within module
  // 'core.module.admin2' across *ALL* tiers.
  //
  // If the config value is a '*', all these
  // properties will be set to 'true', regardless
  // of what tier the user belongs to.
  //
  // However, when the config value is an array
  // of strings, it is mapped against each property
  // here, and all those that does *NOT* intersect
  // will be set to 'false'.

  view: boolean;

  create: boolean;

  edit: boolean;

  delete_profile: boolean;

  delete_tier: boolean;

  delete_user: boolean;

  limit: boolean;

  morph: boolean;

  morph_up: boolean;

  constructor(flag: boolean) {
    this.view = flag;
    this.create = flag;
    this.edit = flag;
    this.delete_profile = flag;
    this.delete_tier = flag;
    this.delete_user = flag;
    this.limit = flag;
    this.morph = flag;
    this.morph_up = flag;
  }
}

export default OhsAdmin2Permissions;
