import React from 'react';

import { FieldValues } from 'react-hook-form';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import { getOhsUser } from 'user/userSlice';
import OhsMultipleSelectField, {
  MultipleOptionListTypes,
} from 'global-components/form/fields/OhsMultipleSelectField';
import { getWorkplaceOptions } from 'user/OhsUserServices';

interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
  id?: string;
  required?: boolean;
  workplaceOptions?: Array<MultipleOptionListTypes> | null;
}
function OhsWorkplaceFilter(props: Props) {
  const user = useAppSelector(getOhsUser);
  const getWorkplaceLists = getWorkplaceOptions(user?.configs);
  const workplaceOptions = getWorkplaceLists.map((list: any) => {
    return { name: list.name, value: list.id };
  });

  return (
    <div>
      <OhsMultipleSelectField
        title="Workplaces"
        id={props.id ? props.id : 'workplaces'}
        required={props.required ?? false}
        options={props.workplaceOptions ? props.workplaceOptions : workplaceOptions}
        ohsFormSettings={props.ohsFormSettings}
      />
    </div>
  );
}

OhsWorkplaceFilter.defaultProps = {
  id: undefined,
  required: false,
  workplaceOptions: null,
};

export default OhsWorkplaceFilter;
