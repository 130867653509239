import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { OhsCommunicationTabFilterPayload } from 'contractor/models/OhsContractorModels';
import OhsCorrespondenceExchangeRecord from 'correspondence/models/OhsCorrespondenceExchangeRecord';

import { getLinkedCorrespondenceExchangesLists } from './OhsContractorService';
import { OhsUserState } from '../user/userSlice';

export interface CommunicationListsRecordState {
  isLoading: boolean;
  contractorCorrespondences: ApiResListsType<any[]> | null;
  currentPage: number;
}

const initialState: CommunicationListsRecordState = {
  isLoading: false,
  contractorCorrespondences: null,
  currentPage: 1,
};

export const getViewPreset = (tierNum: number) => {
  switch (tierNum) {
    case 3:
    case 4:
      return 'view_1';
    default:
      return undefined;
  }
};
const filterOptions = (state: RootState): Partial<OhsCommunicationTabFilterPayload> => {
  const { globalfilter, user, contractorCorrespondences } = state;
  const filter = globalfilter.filterInfo;
  const userTier = user.user?.tierNum ?? 0;
  return {
    viewPreset: getViewPreset(userTier),
    next:
      contractorCorrespondences.currentPage > 10
        ? contractorCorrespondences.contractorCorrespondences?.pagination.next ?? ''
        : '',
    archived: filter.contractorCorrespondenceModule.archived,
  };
};

const contractorCorrespondenceListsRPC = async (
  user: OhsUserState,
  setContractorFilters: OhsCommunicationTabFilterPayload,
  _id: string
): Promise<any> => {
  const response = await getLinkedCorrespondenceExchangesLists(setContractorFilters, _id);
  return response;
};

export const fetchContractorCorrespondenceListsAsync = createAsyncThunk<
  ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null,
  string,
  { state: RootState }
>('contractor/fetchContractorCorrespondenceList', async (id, thunkAPI) => {
  const { user } = thunkAPI.getState();
  const state = thunkAPI.getState();
  const filter = thunkAPI.getState().globalfilter.filterInfo;

  const setContractorFilters: OhsCommunicationTabFilterPayload = {
    ...filterOptions(state),
    page:
      state.contractorCorrespondences.currentPage > 10
        ? 0
        : state.contractorCorrespondences.currentPage,
    sort: { ...JSON.parse(String(filter.contractorCorrespondenceModule.sort)) },
    count: false,
  };

  try {
    const response = await contractorCorrespondenceListsRPC(user, setContractorFilters, id);
    return response as ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchContractorCorrespondenceListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null,
  string,
  { state: RootState }
>('contractor/fetchContractorCorrespondenceCountList', async (id, thunkAPI) => {
  const state = thunkAPI.getState();

  const contractorFiltersWithCount: OhsCommunicationTabFilterPayload = {
    ...filterOptions(state),
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  try {
    const response = await getLinkedCorrespondenceExchangesLists(contractorFiltersWithCount, id);
    return response as ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const contractorCorrespondenceSlice = createSlice({
  name: 'contractorCorrespondences',
  initialState,
  reducers: {
    setContractorCorrespondenceCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContractorCorrespondenceListsAsync.pending, (state) => {
        state.isLoading = true;
        state.contractorCorrespondences = {
          items: [],
          pagination: state.contractorCorrespondences?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchContractorCorrespondenceListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.contractorCorrespondences = action.payload;
          // get last _id and set it to pagination next
          let setItemsLastId = '';
          const contractorCorrespondenceListsItems = [...state.contractorCorrespondences.items];
          if (contractorCorrespondenceListsItems.length > 0) {
            setItemsLastId = contractorCorrespondenceListsItems.pop()._id;
          }

          state.contractorCorrespondences.pagination.next = setItemsLastId;
          state.contractorCorrespondences.pagination.totalPages = 1;
          state.isLoading = false;
        }
      })
      .addCase(fetchContractorCorrespondenceListsAsync.rejected, (state) => {
        state.contractorCorrespondences = null;
      })
      .addCase(fetchContractorCorrespondenceListsCountAsync.fulfilled, (state, action) => {
        if (state.contractorCorrespondences && action.payload && action.payload.pagination) {
          state.contractorCorrespondences.pagination.totalPages =
            action?.payload?.pagination?.totalPages ?? 0;
        }
      });
  },
});

const communicationState = (state: RootState) => state.contractorCorrespondences;
// Memoized Selectors
export const getOhsContractorCorrespondenceModuleState = createSelector(
  [communicationState],
  (contractorCorrespondences) => contractorCorrespondences
);
export const { setContractorCorrespondenceCurrentPage } = contractorCorrespondenceSlice.actions;
export const contractorCorrespondenceModuleReducer = contractorCorrespondenceSlice.reducer;
