import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsUserState } from 'user/userSlice';
import { OhsFilterList } from 'dashboard/topFilter/OhsFilterModels';
import { GlobalSearchState } from 'search/OhsSearchModels';
import { ApiResListsType } from 'global-services/api/OhsApiModels';

import OhsInspectionRecord from './models/OhsInspectionRecord';

export interface OhsInspectionCustomFilters {
  workplaceOwned?: boolean;
  allocatedToOrg?: boolean;
  allocatedToWorkplace?: boolean;
  notAllocated?: boolean;
  archived?: boolean;
  categories?: string[];
  workplaces?: string[];
  next?: string;
}

export interface OhsInspectionFilterPayload extends OhsInspectionCustomFilters {
  viewPreset?: 'view_1' | 'view_2' | 'view_3';
  page?: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
  dateDue?: { start: string; end: string };
}

export interface OhsAvailableInspectionFilterPayload extends OhsInspectionCustomFilters {
  page: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
}

export interface OhsInspectionScheduleFilterPayload extends OhsInspectionCustomFilters {
  viewPreset?: 'view_1' | 'view_2' | 'view_3';
  page?: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
}

export interface OhsModuleListsTypes {
  rowData: any[];
  isLoading: boolean;
  totalPages: number;
}

export interface InspectionAllocListRes {
  items: AllocatedListType[];
}
export interface AllocatedListType extends OhsInspectionRecord {
  _id: string;
  type: ModuleType;
  allocated: boolean;
  latestCompletedTask: string | null;
}

export interface OhsInspectionStart {
  _id: string;
  task: {
    _id: string;
  };
  tz: string;
  dateCommenced: string;
}

export interface OhsInspectionOneOffChild {
  _id: string;
  dateDue: string;
  notes: string;
  tzDateCreated: string;
}

export enum OhsInspectionViewPresets {
  View1InspectionList = 'view1InspectionList',
  View2InspectionList = 'view2InspectionList',
  View3InspectionList = 'view3InspectionList',
  view1InspectionScheduledList = 'view1InspectionScheduledList',
  view2InspectionScheduledList = 'view2InspectionScheduledList',
}

export interface InspectionListRecordState {
  isLoading: boolean;
  inspectionLists: ApiResListsType<any[]> | null;
  inspectionScheduledLists: ApiResListsType<any[]> | null;
  allocatedList: AllocatedListType[] | null;
  availableInspectionList: ApiResListsType<any[]> | null;
  availableInspectionSearch: GlobalSearchState;
  inspectionCopyList: {
    isLoading: boolean;
    copyList: OhsInspectionRecord[];
  };
  currentPage: number;
  currentViewPreset: OhsInspectionViewPresets;
}

export interface OhsInspectionRootState {
  user: OhsUserState;
  globalfilter: {
    filterInfo: OhsFilterList;
  };
  inspection: InspectionListRecordState;
  globalSearch: GlobalSearchState;
}
