import React, { useEffect } from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { OhsFormFieldBaseProps } from '../services/OhsFormModels';
import OhsFormFieldLayout from '../components/OhsFormFieldLayout';
import OhsHiddenField from '../components/OhsHiddenField';

const useStyles = makeStyles()({
  radio: {
    paddingLeft: '14px',
  },
});

interface RadioOption {
  name: string;
  value: string | number | boolean;
}

export type RadioOptions = Array<RadioOption>;

export interface Props extends OhsFormFieldBaseProps {
  options: RadioOptions;
  row?: boolean;
}

function InputElem(props: Props) {
  const { classes } = useStyles();
  const disabled = props.disabled || false;
  const conditionalClass = props.ohsFormSettings.layout === 0 ? classes.radio : '';
  const radioValue = props.ohsFormSettings.useFormMethods.watch(props.id);

  const handleClick = (event: React.ChangeEvent<{}>) => {
    const { value } = event.target as HTMLInputElement;
    const handleBooleanLimitation = (val: boolean | string | undefined) => {
      if (val && typeof val === 'string') {
        if (val.toLowerCase() === 'true') return true;
        if (val.toLowerCase() === 'false') return false;
      }
      return val;
    };
    const newValue: string | boolean | undefined = handleBooleanLimitation(value);

    if (newValue === handleBooleanLimitation(radioValue)) {
      props.ohsFormSettings.useFormMethods.setValue(props.id, null);
    } else {
      props.ohsFormSettings.useFormMethods.setValue(props.id, newValue);
    }
    props.ohsFormSettings.useFormMethods.trigger(props.id);
  };

  useEffect(() => {
    props.ohsFormSettings.useFormMethods.register(props.id, {
      validate: {
        notEmpty: () => {
          const val = props.ohsFormSettings.useFormMethods.getValues(props.id);
          const validSelection = (props.options || []).find(
            (option: RadioOption) => `${option.value}` === `${val}`
          );

          if (props.required === true) {
            if (val == null || val === '' || validSelection === undefined) {
              return false;
            }
          }

          return true;
        },
      },
    });
  }, [
    radioValue,
    props.id,
    props.ohsFormSettings.useFormMethods,
    props.ohsFormSettings.useFormMethods.register,
    props.required,
    props.options,
  ]);

  return (
    <>
      <RadioGroup
        value={radioValue ?? ''}
        row={props.row ? props.row : false}
        className={conditionalClass}
        data-testid={props.id}
      >
        {props.options.map((option) => {
          return (
            <FormControlLabel
              key={option.name}
              value={option.value}
              disabled={disabled}
              control={<Radio color="primary" onClick={disabled ? () => {} : handleClick} />}
              label={option.name}
            />
          );
        })}
      </RadioGroup>
      <OhsHiddenField register={props.ohsFormSettings.useFormMethods.register(props.id)} />
    </>
  );
}

const defaultProps = {
  row: undefined,
};

InputElem.defaultProps = defaultProps;

export default function OhsRadioField(props: Props) {
  return <OhsFormFieldLayout {...props} inputEle={<InputElem {...props} />} />;
}

OhsRadioField.defaultProps = defaultProps;
