import { Button, Theme } from '@mui/material';
import { withStyles } from '@mui/styles';
import { green } from '@mui/material/colors';

const OhsGreenButton = withStyles((theme: Theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
    '&:hover': {
      backgroundColor: green[800],
    },
  },
}))(Button);
export default OhsGreenButton;
