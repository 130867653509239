import _ from 'lodash';

import { ModuleType } from './OhsObject';

export enum ModuleUrl {
  Admin2 = 'admin2',
  Action = 'action',
  Asset = 'assets',
  Chemical = 'chemical',
  Contractor = 'contractor',
  Correspondence = 'correspondence',
  CorporateRisk = 'crisk',
  DashboardBanner = 'dashboardbanner',
  Documents = 'documents',
  Export = 'export-centre',
  HR = 'people',
  Training = 'people/training',
  Incident = 'ai',
  Inspection = 'inspection',
  Login = 'login',
  MobileAppAdmin = 'mobileappadmin',
  MFASetting = 'mfasetting',
  Noticeboard = 'noticeboard',
  People = 'people',
  ResourceCentre = 'resourcecentre',
  ReviewPlan = 'review-plan',
  SafetyPlan = 'safety-plan',
  Search = 'search',
  Task = 'task',
  User = '',
  Visitor = 'visitor',
  Xsi = 'xsi',
  PowerBI = 'powerbi',
  SuperSet = 'superset',
  Reporting = 'reporting',
  Onboardings = 'onboardings',
  ResetPassword = 'reset',
  RecoveryPassword = 'recovery',
  LinkaccessXsi = 'linkaccess/xsi', // not a public page
  Linkaccess = 'linkaccess',
  FeedBack = 'feedback',
}

// connects core modules to routes
export const OhsCoreModuleUrl = {
  [ModuleType.Action]: ModuleUrl.Action,
  [ModuleType.Admin2]: ModuleUrl.Admin2,
  [ModuleType.Asset]: ModuleUrl.Asset,
  [ModuleType.Chemical]: ModuleUrl.Chemical,
  [ModuleType.Contractor]: ModuleUrl.Contractor,
  [ModuleType.Correspondence]: ModuleUrl.Correspondence,
  [ModuleType.CorporateRisk]: ModuleUrl.CorporateRisk,
  [ModuleType.DashboardBanner]: ModuleUrl.DashboardBanner,
  [ModuleType.Export]: ModuleUrl.Export,
  [ModuleType.HR]: ModuleUrl.HR,
  [ModuleType.Training]: ModuleUrl.Training,
  [ModuleType.Incident]: ModuleUrl.Incident,
  [ModuleType.Inspection]: ModuleUrl.Inspection,
  [ModuleType.Noticeboard]: ModuleUrl.Noticeboard,
  [ModuleType.ReviewPlan]: ModuleUrl.ReviewPlan,
  [ModuleType.SafetyPlan]: ModuleUrl.SafetyPlan,
  [ModuleType.Task]: ModuleUrl.Task,
  [ModuleType.User]: ModuleUrl.User,
  [ModuleType.Visitor]: ModuleUrl.Visitor,
  [ModuleType.Xsi]: ModuleUrl.Xsi,
  [ModuleType.PowerBI]: ModuleUrl.PowerBI,
  [ModuleType.SuperSet]: ModuleUrl.SuperSet,
  [ModuleType.MobileAppAdmin]: ModuleUrl.MobileAppAdmin,
  [ModuleType.VersionDocument]: ModuleUrl.Documents,
};

export const OhsModuleTypeByUrl = {
  [ModuleUrl.Action]: ModuleType.Action,
  [ModuleUrl.Admin2]: ModuleType.Admin2,
  [ModuleUrl.Asset]: ModuleType.Asset,
  [ModuleUrl.Chemical]: ModuleType.Chemical,
  [ModuleUrl.Contractor]: ModuleType.Contractor,
  [ModuleUrl.Correspondence]: ModuleType.Correspondence,
  [ModuleUrl.CorporateRisk]: ModuleType.CorporateRisk,
  [ModuleUrl.DashboardBanner]: ModuleType.DashboardBanner,
  [ModuleUrl.Export]: ModuleType.Export,
  [ModuleUrl.HR]: ModuleType.HR,
  [ModuleType.Training]: ModuleUrl.Training,
  [ModuleUrl.Incident]: ModuleType.Incident,
  [ModuleUrl.Inspection]: ModuleType.Inspection,
  [ModuleUrl.Noticeboard]: ModuleType.Noticeboard,
  [ModuleUrl.ReviewPlan]: ModuleType.ReviewPlan,
  [ModuleUrl.SafetyPlan]: ModuleType.SafetyPlan,
  [ModuleUrl.Task]: ModuleType.Task,
  [ModuleUrl.User]: ModuleType.User,
  [ModuleUrl.Visitor]: ModuleType.Visitor,
  [ModuleUrl.Xsi]: ModuleType.Xsi,
  [ModuleUrl.PowerBI]: ModuleType.PowerBI,
  [ModuleUrl.SuperSet]: ModuleType.SuperSet,
  [ModuleUrl.MobileAppAdmin]: ModuleType.MobileAppAdmin,
  [ModuleUrl.Documents]: ModuleType.VersionDocument,
};

export const returnModuleUrl = (moduleType: string | null) => {
  if (moduleType) {
    const module = _.get(OhsCoreModuleUrl, moduleType) || '';
    if (module) return module;
  }
  return '';
};

export enum ModuleRoute {
  Active = 'active',
  Details = 'details',
  Training = 'training',
  Evidence = 'evidence',
  Home = 'home',
  Settings = 'settings',
  SignOff = 'signOff',
  Add = 'add',
  Edit = 'edit',
  Documents = 'documents',
  Allocate = 'allocate',
  LinkRedirect = 'link/redirect',
  Custom = '',
  // noticeboard
  ADMIN = 'admin',

  // Inspection
  Scheduled = 'scheduled',
  Template = 'template',
  View = 'view',

  ImportManager = 'import',
  // Visitor
  MobileRedirect = 'mobileRedirect',
  Site = 'site',
  SiteAdd = 'siteAdd',
  SiteView = 'siteView',
  SiteEdit = 'siteEdit',
  SiteSignInOut = 'siteSignInOut',
  SiteSignOut = 'siteSignOut',
  Form = 'form',
  FormAdd = 'formAdd',
  FormView = 'formView',
  FormEdit = 'formEdit',
  Pin = 'pin',

  SignOut = 'signOut',
  // Correspondence
  Correspondence = 'correspondence',
  ExchangeView = 'exchangeView',

  Xsi = 'xsi',
  XsiConnect = 'xsiConnect',
  XsiCompleteXero = 'xsiCompleteXero',
  XsiCompleteMYOB = 'xsiCompleteMYOB',
  XsiCompleteEmpHero = 'xsiCompleteEmpHero',
  UnLoginSign = 'unLoginSign',
  TasksAggregate = 'tasksAggregate',
}
