import { ApiResListsType } from 'global-services/api/OhsApiModels';
import OhsChemicalRecord from 'chemical/models/OhsChemicalRecord';

const parseListAllocations = (
  chemicalLists: ApiResListsType<any[]> | null,
  allocationLists: OhsChemicalRecord[] | null
): ApiResListsType<any[]> | null => {
  const chemList = chemicalLists?.items ?? [];

  if (chemList.length > 0) {
    const chemicalWithAllocation = chemList.map((item: OhsChemicalRecord) => {
      const chemicalRecord: OhsChemicalRecord = {
        ...item,
        subRows: [],
      };

      allocationLists?.map((allocItem: OhsChemicalRecord) => {
        const { allocationOf } = allocItem;
        if (item._id === allocItem._id) {
          chemicalRecord.subRows.push(allocItem);
        }
        if (allocationOf && allocationOf._id === item._id) {
          chemicalRecord.subRows.push(allocItem);
        }
        return null;
      });

      return chemicalRecord;
    }) as any[];

    const updateChemicalListwithAllocation = {
      ...chemicalLists,
      items: chemicalWithAllocation,
    } as unknown as ApiResListsType<any[]>;

    return updateChemicalListwithAllocation;
  }
  return null;
};

export default parseListAllocations;
