import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import { getOhsUser } from 'user/userSlice';
import { useQuery } from 'global-components/tab/OhsTab';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';

import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';
import OhsMultipleSelectField from '../../../global-components/form/fields/OhsMultipleSelectField';
import OhsCheckboxFilter from '../filterfields/OhsCheckboxFilter';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}

function OhsDocumentsFilters(props: Props) {
  const { classes } = useStyles();
  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const user = useAppSelector(getOhsUser);
  const userTierNum = user?.tierNum ?? 0;
  const query = useQuery();
  const tabKey = query.get('tab');

  const categoryLists = user.configs.vdoc?.CATEGORY_LIST ?? [];

  const [subCategoryLists, setSubCategoryLists] = React.useState<string[]>([]);

  React.useEffect(() => {
    const slist = categoryLists.map((a: any) => a.subcategory);
    const getSubCategories = _.sortBy(_.uniqBy([...new Set(slist.flat())], (item) => item));
    setSubCategoryLists(getSubCategories);
  }, []);

  return (
    <Box className={classes.filterContent}>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id="documentsModule.documents.sort"
          required
          options={filter.documentsModule.documents.tableSortKeyLists.sortColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {userTierNum === 3 && tabKey === OhsTabKeyTypes.Workplace && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter ohsFormSettings={props.ohsFormSettings} />
        </Grid>
      )}

      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <div>
          <OhsMultipleSelectField
            title="Document Type"
            id="documentsModule.documents.categories"
            required={false}
            options={categoryLists.map((a) => ({
              name: a.category,
              value: a.category,
            }))}
            ohsFormSettings={props.ohsFormSettings}
          />
        </div>
      </Grid>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <div>
          <OhsMultipleSelectField
            title="Document Category"
            id="documentsModule.documents.subcategories"
            required={false}
            options={subCategoryLists.map((category: any) => ({
              name: category,
              value: category,
            }))}
            ohsFormSettings={props.ohsFormSettings}
          />
        </div>
      </Grid>
      {(userTierNum === 3 || userTierNum === 4) && (
        <>
          <Grid item md={12} xs={6} className={classes.fieldWrap}>
            <OhsCheckboxFilter
              fieldid="documentsModule.documents.reviewScheduled"
              label="Review Scheduled"
              ohsFormSettings={props.ohsFormSettings}
            />
          </Grid>
          <Grid item md={12} xs={6} className={classes.fieldWrap}>
            <OhsCheckboxFilter
              fieldid="documentsModule.documents.allocatedToWorkplace"
              label="Allocated To Workplace"
              ohsFormSettings={props.ohsFormSettings}
            />
          </Grid>
        </>
      )}
      {userTierNum === 3 && (
        <Grid item md={12} xs={6} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid="documentsModule.documents.notAllocated"
            label="Not Allocated"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {userTierNum === 4 && (
        <Grid item md={12} xs={6} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid="documentsModule.documents.workplaceOwned"
            label="Workplace Owned"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
    </Box>
  );
}

export default OhsDocumentsFilters;
