import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import { getOhsUser } from 'user/userSlice';

import OhsTaskDueDateFilter from '../filterfields/OhsTaskDueDateFilter';
import OhsCheckboxFilter from '../filterfields/OhsCheckboxFilter';
import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsModuleFilter from '../filterfields/OhsModuleFilter';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}
function OhsTaskFilters(props: Props) {
  const user = useAppSelector(getOhsUser);
  const { classes } = useStyles();
  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const userTier = user.tierNum;
  return (
    <Box className={classes.filterContent}>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id="taskModule.sort"
          required
          options={filter.taskModule.tableSortKeyLists?.sortColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsModuleFilter id="taskModule.modules" ohsFormSettings={props.ohsFormSettings} />
      </Grid>
      {user.tierNum === 3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter id="taskModule.workplaces" ohsFormSettings={props.ohsFormSettings} />
        </Grid>
      )}
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsTaskDueDateFilter
          fieldId="taskModule.dateDue"
          label="Task Due Date"
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {userTier <= 3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid="taskModule.skipOrgRecords"
            label="Skip Org Records"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
    </Box>
  );
}

export default OhsTaskFilters;
