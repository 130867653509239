import { OhsUser } from 'user/OhsUser';
import { ModuleUrl, ModuleRoute } from 'global-services/constants/OhsRoutes';

const commonPermission = (user: OhsUser) => user?.configs?.admin2?.PERMISSIONS.view || false;
const createRoute = (path: string, permission: (user: OhsUser) => boolean) => ({
  path,
  url: `/${ModuleUrl.Admin2}/${path}`,
  permission,
});

const OhsAdmin2RouteList = {
  [ModuleRoute.Settings]: createRoute('user-settings', commonPermission),
  [ModuleRoute.ImportManager]: createRoute('import', commonPermission),
  [ModuleRoute.Xsi]: createRoute('xsi/:orgId', commonPermission),
};

export default OhsAdmin2RouteList;
