import React from 'react';

import { isNull } from 'lodash';
import { SemanticICONS } from 'semantic-ui-react';
import { List, ListItem, ListItemText, Paper, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { getHazardTitleName, getIcon, hazardStatement } from 'chemical/utils/hazardUtils';

const useStyles = makeStyles()((theme) => ({
  customPaper: {
    padding: theme.spacing(2),
    border: '2px solid #ccc',
    width: '400px',
    maxHeight: '500px',
    overflowY: 'auto',
  },
  customTooltip: {
    background: 'transparent',
  },
}));

interface Props {
  classificationGHS?: any;
  icon?: SemanticICONS | undefined;
  type: string;
  isDangrousOrHazardous?: boolean | null | undefined;
  getGHSFunc: (arg: any[]) => any[];
}

export default function HazardIcon(props: Props) {
  const { classes } = useStyles();
  const { icon, classificationGHS, type, isDangrousOrHazardous, getGHSFunc } = props;

  const isGHSPresent =
    classificationGHS &&
    classificationGHS?.join().indexOf('_NA_') &&
    !!getGHSFunc(classificationGHS).length;

  return (
    <Tooltip
      title={
        (getGHSFunc(classificationGHS).length === 0 && type === 'GHS') ||
        isNull(isDangrousOrHazardous) ? (
          ''
        ) : (
          <Paper
            elevation={0}
            className={classes.customPaper}
            style={{
              textAlign: 'center',
            }}
          >
            {/* classificationGHS && */}
            {type === 'GHS' && (
              <>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  {getHazardTitleName(icon)}
                </Typography>

                <List
                  style={{
                    textAlign: 'center',
                  }}
                >
                  {getGHSFunc(classificationGHS).map(
                    (dataItem: any, dataIndex: React.Key | null | undefined) => {
                      return (
                        <ListItem key={dataIndex}>
                          <ListItemText>
                            <b>{dataItem.code}: </b> {dataItem.title}
                          </ListItemText>
                        </ListItem>
                      );
                    }
                  )}
                </List>
              </>
            )}
            {/* dangerous and hazardous */}
            {type !== 'GHS' && (
              <>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                  {hazardStatement[type]}
                </Typography>
                <Typography variant="subtitle1">{isDangrousOrHazardous ? 'Yes' : 'No'}</Typography>
              </>
            )}
          </Paper>
        )
      }
      arrow={false}
      classes={{ tooltip: classes.customTooltip }}
    >
      <div>{getIcon(type, isDangrousOrHazardous, icon, isGHSPresent)}</div>
    </Tooltip>
  );
}

HazardIcon.defaultProps = {
  classificationGHS: null,
  isDangrousOrHazardous: undefined,
  icon: undefined,
};
