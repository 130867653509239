import React from 'react';

import { Box } from '@mui/material';

import OhsOTPInput from 'global-components/form/fields/OhsOtpInput';

interface Props {
  setOTP: React.Dispatch<React.SetStateAction<string>>;
  currentOTP: string;
}
function OhsUserMFAInput(props: Props) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <OhsOTPInput
        value={props.currentOTP}
        setValue={(value: string) => props.setOTP(value)}
        shouldAutoFocus
        length={6}
      />
    </Box>
  );
}

export default OhsUserMFAInput;
