import { getConfigValue, setPermissions } from '../OhsConfigServices';
import OhsFileBoxPermissions from './OhsFileBoxPermissions';

class OhsFileBoxConfig {
  TITLE: string;

  PERMISSIONS: OhsFileBoxPermissions;

  MORPH_PERMISSIONS: OhsFileBoxPermissions;

  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsFileBoxPermissions(false);
    this.MORPH_PERMISSIONS = new OhsFileBoxPermissions(false);

    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(this.MORPH_PERMISSIONS, getConfigValue(config, 'MORPH_PERMISSIONS'));
  }
}

export default OhsFileBoxConfig;
