import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { ModuleUrl } from 'global-services/constants/OhsRoutes';
import { isAuthenticated } from 'global-services/OhsAuthenticate';
import { fetchCurrentUserSettings } from 'user/OhsUserServices';

import { getHomeUrl } from './OhsLoginServices';

export default function OhsRootHome() {
  const navigate = useNavigate();
  const forwardBaseOnTier = async () => {
    const currentUser = await fetchCurrentUserSettings();
    if (currentUser?._id) {
      const homeUrl = getHomeUrl(currentUser.tier);
      if (homeUrl) navigate(homeUrl);
    } else navigate(`/${ModuleUrl.Login}`);
  };

  useEffect(() => {
    if (isAuthenticated() === true) {
      forwardBaseOnTier();
    } else navigate(`/${ModuleUrl.Login}`);
  });
  return null;
}
