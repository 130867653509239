import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';
import OhsCorrespondenceListsRecord, {
  OhsCorrespondenceFilterPayload,
} from 'correspondence/models/OhsCorrespondenceRecord';

import getCorrespondenceLists from './OhsCorrespondenceServices';
import { OhsUserState } from '../user/userSlice';
import globalModuleSearch from '../search/OhsSearchServices';

export interface CorrespondenceListsRecordState {
  isLoading: boolean;
  correspondenceLists: ApiResListsType<OhsCorrespondenceListsRecord[]> | null;
  currentPage: number;
}

const initialState: CorrespondenceListsRecordState = {
  isLoading: false,
  correspondenceLists: null,
  currentPage: 1,
};

export const getViewPreset = (tierNum: number) => {
  switch (tierNum) {
    case 3:
      return 'view_1';
    case 4:
      return 'view_2';
    default:
      return undefined;
  }
};

const correspondenceSearch = async (
  searchDetails: any
): Promise<ApiResListsType<OhsCorrespondenceListsRecord[]> | null> => {
  const globalSearchRes: any = await globalModuleSearch(searchDetails);
  return globalSearchRes.result;
};

const correspondenceFilterOptions = (state: RootState): Partial<OhsCorrespondenceFilterPayload> => {
  const { globalfilter, user, correspondence } = state;
  const filter = globalfilter.filterInfo;
  const userTier = user.user?.tierNum ?? 0;

  return {
    viewPreset: getViewPreset(userTier),
    next:
      correspondence.currentPage > 10
        ? correspondence.correspondenceLists?.pagination.next ?? ''
        : '',
    archived: filter.correspondenceModule.archived,
    workplaces: filter.correspondenceModule.workplaces ?? [],
  };
};

const correspondenceListsRPC = async (
  user: OhsUserState,
  setCorrespondenceFilters: OhsCorrespondenceFilterPayload,
  fetchType: string
): Promise<any> => {
  if (user.user?.configs.correspondence?.PERMISSIONS.view_prose) {
    const response = await getCorrespondenceLists(setCorrespondenceFilters, fetchType);
    return response;
  }
  return null;
};
export const fetchCorrespondenceListsAsync = createAsyncThunk<
  ApiResListsType<OhsCorrespondenceListsRecord[]> | null,
  { fetchType: string },
  { state: RootState }
>('correspondence/fetchCorrespondenceList', async ({ fetchType }, thunkAPI) => {
  const { user } = thunkAPI.getState();
  const state = thunkAPI.getState();
  const filter = thunkAPI.getState().globalfilter.filterInfo;
  const searchFilter = thunkAPI.getState().globalSearch.searchInfo;
  const hasGlobalSearch = state.globalSearch.searchInfo.searchKey !== '';

  const setCorrespondenceFilters: OhsCorrespondenceFilterPayload = {
    ...correspondenceFilterOptions(state),
    page: state.correspondence.currentPage > 10 ? 0 : state.correspondence.currentPage,
    sort: { ...JSON.parse(String(filter.correspondenceModule.sort)) },
    count: false,
  };

  try {
    const response = hasGlobalSearch
      ? await correspondenceSearch({
          ...searchFilter,
          filter: {
            ...searchFilter.filter,
            archived: filter.correspondenceModule.archived ?? false,
          },
        })
      : await correspondenceListsRPC(
          user,
          {
            ...setCorrespondenceFilters,
          },
          fetchType
        );
    return response as ApiResListsType<OhsCorrespondenceListsRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchCorrespondenceListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsCorrespondenceListsRecord[]> | null,
  { fetchType: string },
  { state: RootState }
>('correspondence/fetchCorrespondenceCountList', async ({ fetchType }, thunkAPI) => {
  const state = thunkAPI.getState();
  const filter = thunkAPI.getState().globalfilter.filterInfo;
  const searchFilter = thunkAPI.getState().globalSearch.searchInfo;
  const hasGlobalSearch = state.globalSearch.searchInfo.searchKey !== '';

  const correspondenceFiltersWithCount: OhsCorrespondenceFilterPayload = {
    ...correspondenceFilterOptions(state),
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  try {
    const response = hasGlobalSearch
      ? await correspondenceSearch({
          ...searchFilter,
          filter: {
            ...searchFilter.filter,
            archived: filter.correspondenceModule.archived ?? false,
          },
        })
      : await getCorrespondenceLists(
          {
            ...correspondenceFiltersWithCount,
          },
          fetchType
        );
    return response as ApiResListsType<OhsCorrespondenceListsRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const correspondenceSlice = createSlice({
  name: 'correspondence',
  initialState,
  reducers: {
    setCorrespondenceCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCorrespondenceListsAsync.pending, (state) => {
        state.isLoading = true;
        state.correspondenceLists = {
          items: [],
          pagination: state.correspondenceLists?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchCorrespondenceListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.correspondenceLists = action.payload;
          if (state?.correspondenceLists?.pagination?.totalPages) {
            // store total pages to localstorage
            setOhsLocalStorage(
              'correspondenceListtotalPages',
              state.correspondenceLists.pagination.totalPages
            );
          }
          // get last _id and set it to pagination next
          const setItemsLastId = [...(state.correspondenceLists?.items ?? [])].pop()?._id ?? '';
          state.correspondenceLists.pagination.next = setItemsLastId;
          state.correspondenceLists.pagination.totalPages =
            getOhsLocalStorage('correspondenceListtotalPages') ?? 0;
          state.isLoading = false;
        }
      })
      .addCase(fetchCorrespondenceListsAsync.rejected, (state) => {
        state.correspondenceLists = null;
      })
      .addCase(fetchCorrespondenceListsCountAsync.fulfilled, (state, action) => {
        if (state.correspondenceLists && action.payload && action.payload.pagination) {
          setOhsLocalStorage(
            'correspondenceListtotalPages',
            action?.payload?.pagination?.totalPages
          );
          state.correspondenceLists.pagination.totalPages =
            action?.payload?.pagination?.totalPages ?? 0;
        }
      });
  },
});

const correspondenceState = (state: RootState) => state.correspondence;
// Memoized Selectors
export const getOhsCorrespondenceModuleState = createSelector(
  [correspondenceState],
  (correspondence) => correspondence
);
export const { setCorrespondenceCurrentPage } = correspondenceSlice.actions;
export const correspondenceModuleReducer = correspondenceSlice.reducer;
