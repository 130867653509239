import { ReactNode } from 'react';

import { FieldValues, useForm, UseFormReturn } from 'react-hook-form';

export enum OhsFormLayoutType {
  AngularPageForm,
  AngularModalForm,
  AngularModalUnderlinedForm,
}

export class OhsFormSettings<TFieldValues extends FieldValues = FieldValues> {
  useFormMethods: UseFormReturn<TFieldValues>;

  layout: OhsFormLayoutType;

  constructor(defaultValues: any, layout: OhsFormLayoutType) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    this.useFormMethods = useForm<TFieldValues>({
      defaultValues,
    });
    this.layout = layout;
  }
}
export interface OhsFormFieldBaseProps {
  title?: string;
  renderTitle?: (display: ReactNode) => ReactNode;
  description?: string | ReactNode;
  labelEle?: ReactNode;
  noLabel?: boolean;
  required: boolean;
  id: string;
  ohsFormSettings: OhsFormSettings;
  hide?: boolean;
  disabled?: boolean;
  defaultValue?: any;
  resetOnHide?: boolean;
  renderAction?: ReactNode;
  placeholder?: string;
  renderLayout?: (display: ReactNode) => ReactNode;
}
