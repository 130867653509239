import React from 'react';

import { Row } from 'react-table';
import { Table, TableBody, TableHead, TableRow, TableContainer, Paper, Box } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import TableLoading from 'global-services/utils/OhsTableLoading';

import OhsRegisterTableHeader from './OhsRegisterTableHeader';
import OhsRegisterTableRow from './OhsRegisterTableRow';
import OhsRegisterTableSortLabel from './OhsRegisterTableSortLabel';
import OhsRegisterTableBodyCell from './OhsRegisterTableBodyCell';
import { expanderColumnId, searchColumnId } from '../OhsRegisterModels';
import OhsRegisterTableRowList from './tableViewLists/OhsRegisterTableRowList';
import OhsRegisterTableRowListDraggable from './tableViewLists/OhsRegisterTableRowListDraggable';
import OhsNoResultsAlert from '../OhsNoResultsAlert';

interface Props {
  setSelectedRows?: React.Dispatch<React.SetStateAction<Row<any>[]>>;
  isLoading: boolean;
  getTableProps: any;
  headerGroups: any;
  page: any;
  prepareRow: any;
  selectedFlatRows: any;
  rowFilterNode?: React.ReactNode;
  moveRowFunc?: any;
  enableDragDrop?: boolean;
  hideHeader?: boolean;
  columnSorting?: boolean;
  onRowClick?: (item: any) => void;
}

function OhsRegisterTable(props: Props) {
  if (props.isLoading === true)
    return <TableLoading rowCount={10} columnCount={props.headerGroups[0].headers.length} />;
  return (
    <TableContainer component={Paper} className="translate">
      <Table stickyHeader {...props.getTableProps()}>
        <TableHead>
          {props.headerGroups.map((headerGroup: any, index: number) => (
            <React.Fragment key={index}>
              <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {props.enableDragDrop === true && <OhsRegisterTableHeader />}
                {headerGroup.headers.map((column: any, columnIndex: number) => {
                  return (
                    <OhsRegisterTableHeader
                      {...(column.id === 'selection'
                        ? column.getHeaderProps()
                        : props.columnSorting &&
                          column.getHeaderProps(column.getSortByToggleProps()))}
                      column={column}
                      key={columnIndex}
                    >
                      {column.render('Header')}

                      {column.id !== 'selection' &&
                      column.id !== searchColumnId &&
                      column.id !== expanderColumnId &&
                      props.columnSorting ? (
                        <OhsRegisterTableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? 'asc' : 'desc'}
                          IconComponent={ExpandLessIcon}
                        />
                      ) : null}
                    </OhsRegisterTableHeader>
                  );
                })}
              </TableRow>
            </React.Fragment>
          ))}
        </TableHead>

        {props.isLoading === false && props.enableDragDrop === true && (
          // <TableBody>
          <OhsRegisterTableRowListDraggable
            prepareRow={props.prepareRow}
            list={props.page}
            moveRowFunc={props.moveRowFunc}
          />
          // </TableBody>
        )}
        {props.isLoading === false && !props.enableDragDrop && (
          <TableBody>
            <OhsRegisterTableRowList
              prepareRow={props.prepareRow}
              dataLists={props.page}
              onRowClick={props.onRowClick}
            />
          </TableBody>
        )}
        {props.isLoading === false && props.page.length === 0 && (
          <TableBody>
            <OhsRegisterTableRow>
              <OhsRegisterTableBodyCell colSpan={123}>
                <Box pt={2} pb={2}>
                  <OhsNoResultsAlert>No item(s) found!</OhsNoResultsAlert>
                </Box>
              </OhsRegisterTableBodyCell>
            </OhsRegisterTableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

OhsRegisterTable.defaultProps = {
  setSelectedRows: undefined,
  rowFilterNode: undefined,
  moveRowFunc: undefined,
  enableDragDrop: undefined,
  hideHeader: undefined,
  columnSorting: false,
  onRowClick: undefined,
};

export default OhsRegisterTable;
