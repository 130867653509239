import React, { ReactNode } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { isAuthenticated } from 'global-services/OhsAuthenticate';

interface IProps {
  children: ReactNode;
}

function OhsRequireAuth({ children }: IProps): any {
  const location = useLocation();

  const newLocation = {
    ...location,
    pathname: `${location.pathname}${location.search}`,
  };

  return isAuthenticated() ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: newLocation }} replace />
  );
}

export default OhsRequireAuth;
