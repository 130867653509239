import React from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getGlobalFilter } from 'dashboard/topFilter/OhsTopFilterSlice';
import { getOhsUser } from 'user/userSlice';
import {
  fetchOnboardingListAsync,
  fetchOnboardingListCountAsync,
  getOhsOnboardingList,
  setCurrentRegisterPage,
} from 'freemium/list/OhsOnboardingListSlice';

export default function useOhsOnboardingList() {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const filter = useAppSelector(getGlobalFilter);
  const dispatch = useAppDispatch();
  const onboardingReducer = useAppSelector(getOhsOnboardingList);

  const { onboardingList, isLoading } = onboardingReducer;
  const user = useAppSelector(getOhsUser);

  const fetchOnboardingList = async () => {
    if (user) {
      await dispatch(fetchOnboardingListAsync());
      await dispatch(fetchOnboardingListCountAsync());
    }
  };

  React.useEffect(() => {
    dispatch(setCurrentRegisterPage(currentPage));
    fetchOnboardingList();
  }, [user, currentPage, filter]);

  return { isLoading, onboardingList, currentPage, setCurrentPage, fetchOnboardingList } as const;
}
