import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { setOhsLocalStorage } from 'global-services/OhsDataParse';

import { OhsIncidentViewPresets, IncidentListsRecordState } from '../OhsIncidentModels';
import { fetchIncidentListsAsync, fetchIncidentListsCountAsync } from './OhsIncidentActions';

const initialState: IncidentListsRecordState = {
  isLoading: false,
  incidentLists: null,
  currentPage: 1,
  currentViewPreset: OhsIncidentViewPresets.View2IncidentList,
};

export const incidentSlice = createSlice({
  name: 'incident',
  initialState,
  reducers: {
    setCurrentRegisterPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setIncidentIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setViewPreset: (state, action: PayloadAction<OhsIncidentViewPresets>) => {
      state.currentViewPreset = action.payload;
    },
    setIncidentList: (state, action: any) => {
      if (state.incidentLists) {
        state.incidentLists = {
          pagination: state.incidentLists?.pagination,
          items: action.payload || [],
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncidentListsAsync.pending, (state) => {
        state.isLoading = true;
        state.incidentLists = {
          items: [],
          pagination: state.incidentLists?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchIncidentListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.incidentLists = action.payload;

          // get last _id and set it to pagination next
          let setItemsLastId = '';
          const incidentItems = [...(state.incidentLists.items || [])];
          if (incidentItems.length > 0) setItemsLastId = incidentItems.pop()._id;

          state.incidentLists.pagination.next = setItemsLastId;
          state.incidentLists.pagination.totalPages = 1;
          state.isLoading = false;
        }
      })
      .addCase(fetchIncidentListsAsync.rejected, (state) => {
        state.incidentLists = null;
      })
      .addCase(fetchIncidentListsCountAsync.fulfilled, (state, action) => {
        if (state.incidentLists && action.payload) {
          setOhsLocalStorage('incidentListTotalPages', action.payload.pagination.totalPages);
          state.incidentLists.pagination.totalPages = action.payload.pagination.totalPages ?? 0;
        }
        state.isLoading = false;
      });
  },
});

// Memoized Selectors
const incidentState = (state: RootState) => state.incident;
export const getOhsIncidentLists = createSelector([incidentState], (incident) => incident);

export const { setCurrentRegisterPage, setIncidentIsLoading, setViewPreset, setIncidentList } =
  incidentSlice.actions;
export const incidentModuleReducer = incidentSlice.reducer;
