import React from 'react';

import { Menu, MenuItem, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Icon } from 'semantic-ui-react';

import getMixpanelTracker from 'global-services/mixpanel/OhsMixpanel';
import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';

import { getAngularIframeWindow } from '../../global-components/angular-iframe/OhsAngularIframeServices';
import { OhsMaterialIconButton } from '../../global-components/buttons/OhsMaterialButton';

const useStyles = makeStyles()((theme: Theme) => ({
  quickAccessButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
    display: 'none',
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      display: 'block',
    },
  },
}));

export default function OhsPrintButton() {
  const { classes } = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const printPageOrIframePage = () => {
    setAnchorEl(null);
    setTimeout(() => {
      const iframeWindow = getAngularIframeWindow();
      if (iframeWindow) {
        iframeWindow.print();
      } else {
        window.print();
      }
    });
  };

  const printPage = (event: any) => {
    const mixpanel = getMixpanelTracker();
    try {
      mixpanel?.track('Click Topbar Print Button');
    } catch (e) {
      console.error(e);
    }

    switch (window.location.pathname) {
      case '/reporting/dashboardReports':
      case '/reporting/orgReports':
      case '/reporting/orgAdminReports':
        handleClick(event);

        break;

      default:
        printPageOrIframePage();
        break;
    }
  };
  return (
    <>
      <OhsMaterialIconButton
        color="inherit"
        size="small"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={printPage}
        className={classes.quickAccessButton}
      >
        <Icon name="print" />
      </OhsMaterialIconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={printPageOrIframePage}>Print</MenuItem>
      </Menu>
    </>
  );
}
