import _ from 'lodash';

import { getCurrentTimezone } from 'global-services/OhsDataParse';
import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';
import OhsCorrespondenceTemplateContendType from 'correspondence/models/OhsCorrespondenceTemplateContendType';

export const getAttachment = async (id: string, token: string) => {
  const res: any = await OhsApiRequest(
    RequestType.Attachments,
    ModuleType.Attachment,
    OhsApiRequestName.linkAccessFetch,
    { _id: id },
    { linkAccessDownloadToken: token }
  );

  if (res) {
    return res;
  }
  return null;
};
async function exchangeCusvalsFetch(token: string, cusvalId: string): Promise<any> {
  const payload = {
    token,
    moduleType: 'core.module.correspondence',
    accessType: 'EXCHANGE_CUSVAL_VALUES_BULK_FETCH',
    tzDateCreated: getCurrentTimezone(),
    valueIds: [cusvalId],
  };
  return OhsApiRequest(RequestType.Admin, ModuleType.Linkaccess, OhsApiRequestName.Access, payload);
}
async function exchangeFetch(token: string) {
  const payload = {
    token,
    moduleType: 'core.module.correspondence',
    accessType: 'EXCHANGE_FETCH',
    tz: getCurrentTimezone(),
  };
  const exchange: any = await OhsApiRequest(
    RequestType.Admin,
    ModuleType.Linkaccess,
    OhsApiRequestName.Access,
    payload
  );

  if (_.has(exchange, 'result.item.exchanges')) {
    for (let index = 0; index < exchange.result.item.exchanges.length; index += 1) {
      const ex: any = exchange.result.item.exchanges[index];
      if (_.has(ex, 'prose.content')) {
        for (let contentIndex = 0; contentIndex < ex.prose.content.length; contentIndex += 1) {
          const content: any = ex.prose.content[contentIndex];

          if (
            content &&
            content.type === OhsCorrespondenceTemplateContendType.OhsAttachment &&
            _.has(content, 'data.attachments')
          ) {
            for (let attIndex = 0; attIndex < content.data.attachments.length; attIndex += 1) {
              const att = content.data.attachments[attIndex];
              // eslint-disable-next-line no-await-in-loop
              const attDetails = await getAttachment(att._id, exchange.result.item.downloadToken);
              content.data.attachments[attIndex] = attDetails;

              content.data = { ...content.data, ...attDetails, ...attDetails?.details };
            }
          }
        }
      }
      if (ex.attachments && ex.attachments.length) {
        for (let attIndex = 0; attIndex < ex.attachments.length; attIndex += 1) {
          const att = ex.attachments[attIndex];
          // eslint-disable-next-line no-await-in-loop
          const attDetails = await getAttachment(att._id, exchange.result.item.downloadToken);
          ex.attachments[attIndex] = attDetails;
          ex.attachments[attIndex].downloadLink = attDetails.url;
          ex.attachments[attIndex] = { ...ex.attachments[attIndex], ...attDetails.details };
        }
      }
      if (ex.signatures && ex.signatures.length) {
        for (let attIndex = 0; attIndex < ex.signatures.length; attIndex += 1) {
          const signature = ex.signatures[attIndex];

          if (signature.attachments && signature.attachments[0]) {
            // eslint-disable-next-line no-await-in-loop
            const attDetails = await getAttachment(
              signature.attachments[0]._id,
              exchange.result.item.downloadToken
            );
            ex.signatures[attIndex] = { ...signature, ...attDetails, ...attDetails.details };
          }
        }
      }
      if (ex.cusvals) {
        // eslint-disable-next-line no-await-in-loop
        const cusvals = (await exchangeCusvalsFetch(token, ex.cusvals._id)) as any;
        if (_.has(cusvals, 'result.items[0]') === true && cusvals.result.items[0]) {
          const csv = cusvals.result.items[0];
          ex.cusvals = csv;
        }
      }
    }
  }
  return exchange;
}

async function exchangeCusvalsCreate(
  token: string,
  definitionId: string,
  cusvals: any
): Promise<any> {
  const payload = {
    token,
    moduleType: 'core.module.correspondence',
    accessType: 'EXCHANGE_CUSVAL_VALUES_CREATE',
    tzDateCreated: getCurrentTimezone(),
    definitionId,
    cusvals,
  };
  return OhsApiRequest(RequestType.Admin, ModuleType.Linkaccess, OhsApiRequestName.Access, payload);
}

async function exchangeEdit(payload: any) {
  const exchangePayload = {
    token: payload.token,
    moduleType: 'core.module.correspondence',
    accessType: 'EXCHANGE_EDIT',
    reply: {
      comment: null,
      signatures: [],
      attachments: [],
    },
    cusvals: null,
    tz: getCurrentTimezone(),
  } as any;

  if (payload.reply) {
    if (payload.reply.comment) {
      exchangePayload.reply.comment = payload.reply.comment;
    }
    if (payload.reply.attachments && payload.reply.attachments.length) {
      exchangePayload.reply.attachments = [];
      payload.reply.attachments.forEach((element: any) => {
        const att = JSON.parse(element.details);
        att.tzDateCreated = getCurrentTimezone();
        exchangePayload.reply.attachments.push(att);
      });
    }
    if (payload.reply.signatures && payload.reply.signatures.length) {
      exchangePayload.reply.signatures = [];
      payload.reply.signatures.forEach((element: any) => {
        element.details.tzDateCreated = getCurrentTimezone();
        exchangePayload.reply.signatures.push({
          name: element.name,
          attachments: [element.details],
        });
      });
    }
  }
  if (payload.cusvals && payload.cusvalsId) {
    const cusvals = await exchangeCusvalsCreate(payload.token, payload.cusvalsId, payload.cusvals);

    if (_.has(cusvals, 'result.item._id') === true) {
      exchangePayload.cusvals = { _id: cusvals.result.item._id, type: 'core.module.cusval.values' };
    }
  }

  const exchange: any = await OhsApiRequest(
    RequestType.Admin,
    ModuleType.Linkaccess,
    OhsApiRequestName.Access,
    exchangePayload
  );
  return exchange;
}

const OhsCorrespondenceLinkAccessServices = { exchangeFetch, exchangeEdit };
export default OhsCorrespondenceLinkAccessServices;
