import React from 'react';

import AngularPageFormLayoutFields from './AngularPageFormLayoutFields';
import OhsFormFieldMessageFrame from './OhsFormFieldMessageFrame';
import { OhsFormFieldBaseProps, OhsFormLayoutType } from '../services/OhsFormModels';
import OhsHideConditionalField from './OhsHideConditionalField';

interface Props extends OhsFormFieldBaseProps {
  inputEle: React.ReactNode;
}

interface AngularLayoutProps extends OhsFormFieldBaseProps {
  inputEle: React.ReactNode;
  renderAction?: React.ReactNode;
  renderTitle?: (display: React.ReactNode) => React.ReactNode;
  renderLayout?: (display: React.ReactNode) => React.ReactNode;
}

function DefaultLayout(props: Props) {
  return props.hide === true ? (
    <span />
  ) : (
    <OhsFormFieldMessageFrame
      title={props.title}
      description={props.description}
      labelEle={props.labelEle}
      noLabel={props.noLabel}
      id={props.id}
      required={props.required}
      ohsFormSettings={props.ohsFormSettings}
      inputEle={props.inputEle}
    />
  );
}

function UnderlinedLayout(props: Props) {
  return props.hide === true ? (
    <span />
  ) : (
    <OhsFormFieldMessageFrame
      title={props.title}
      description={props.description}
      labelEle={props.labelEle}
      noLabel={props.noLabel}
      id={props.id}
      required={props.required}
      ohsFormSettings={props.ohsFormSettings}
      inputEle={props.inputEle}
    />
  );
}
function AngularPageFormLayout(props: AngularLayoutProps) {
  return props.hide === true ? (
    <span />
  ) : (
    <AngularPageFormLayoutFields
      title={props.title}
      renderTitle={props.renderTitle}
      description={props.description}
      labelEle={props.labelEle}
      required={props.required}
      renderAction={props.renderAction}
      renderLayout={props.renderLayout}
      inputEle={
        <OhsFormFieldMessageFrame
          title={props.title}
          description={props.description}
          labelEle={props.labelEle}
          noLabel={props.noLabel}
          id={props.id}
          required={props.required}
          ohsFormSettings={props.ohsFormSettings}
          inputEle={props.inputEle}
        />
      }
    />
  );
}
AngularPageFormLayout.defaultProps = {
  renderTitle: undefined,
  renderAction: undefined,
  renderLayout: undefined,
};

export default function OhsFormFieldLayout(props: Props) {
  const renderField = () => {
    switch (props.ohsFormSettings.layout) {
      case OhsFormLayoutType.AngularPageForm:
        return <AngularPageFormLayout {...props} />;
      case OhsFormLayoutType.AngularModalForm:
        return <DefaultLayout {...props} />;
      case OhsFormLayoutType.AngularModalUnderlinedForm:
        return <UnderlinedLayout {...props} />;
      default:
        return <DefaultLayout {...props} />;
    }
  };
  if (props.resetOnHide) {
    return (
      <OhsHideConditionalField
        hide={props.hide || false}
        defaultValue={props.defaultValue}
        id={props.id}
        ohsFormSettings={props.ohsFormSettings}
      >
        {renderField()}
      </OhsHideConditionalField>
    );
  }
  return renderField();
}
