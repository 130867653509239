import React, { useCallback } from 'react';

import _ from 'lodash';

import Button from 'global-components/buttons/OhsMaterialButton';
import getMixpanelTraker from 'global-services/mixpanel/OhsMixpanel';

import { BannerContentType } from '../../OhsDashboardSlice';
import {
  BannerItemContainer,
  BannerMessageWrapper,
  BannerCloseButton,
  BannerButtonGroup,
} from '../register/utils/OhsDashboardBannerStyledComponents';
import { ActionButtonType } from '../OhsDashboardBannerConstants';

function ActionButton({ message, label, fgColor, bgColor, click, solo }: ActionButtonType) {
  const buttonOnClick = useCallback(() => {
    const mixpanel = getMixpanelTraker();
    try {
      mixpanel?.track('Click Banner', {
        BannerMessage: message,
        ButtonActionType: click?.actionType,
      });
    } catch (e) {
      console.error(e);
    }
    if (click && click.actionType === 'link') {
      const newTabValue = click.external ? '_blank' : '_self';
      window.open(click.target, newTabValue);
    }
  }, [click]);

  const boxShadowStyle = solo
    ? // eslint-disable-next-line max-len
      '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
    : 'unset';

  return (
    <Button
      onClick={buttonOnClick}
      style={{
        boxShadow: boxShadowStyle,
        marginLeft: solo ? '10px' : 'unset',
        color: fgColor,
        backgroundColor: bgColor,
        minHeight: '32px',
      }}
    >
      {label}
    </Button>
  );
}

function OhsDashboardBannerItem(props: BannerContentType) {
  const bannerColorStyle = _.join(props.bannerColors, ', ');

  return (
    <BannerItemContainer
      style={{
        backgroundImage: `linear-gradient(to right, ${bannerColorStyle})`,
        color: props.messageColor,
      }}
    >
      <BannerMessageWrapper>{props.message}</BannerMessageWrapper>
      {/*
        For Future development,
        We put these to new component and handle which is which to render
        between `action` or `menu` button.

        if Button prop has greater than 1 length,
        we wrap the buttons to `ButtonGroup` component.
      */}
      {props.buttons && props.buttons.length > 1 ? (
        <BannerButtonGroup>
          {_.map(props.buttons, ({ label, fgColor, bgColor, type, click }, btnIndex) =>
            type === 'action' ? (
              <ActionButton
                message={props.message}
                key={btnIndex}
                label={label}
                fgColor={fgColor}
                bgColor={bgColor}
                click={click}
              />
            ) : (
              ''
            )
          )}
        </BannerButtonGroup>
      ) : (
        _.map(props.buttons, ({ label, fgColor, bgColor, type, click }, btnIndex) =>
          type === 'action' ? (
            <ActionButton
              message={props.message}
              key={btnIndex}
              label={label}
              fgColor={fgColor}
              bgColor={bgColor}
              click={click}
              solo
            />
          ) : (
            ''
          )
        )
      )}

      {!props.isPreview && (
        <BannerCloseButton
          onClick={props.removeBannerOnClick ? props.removeBannerOnClick : () => {}}
        />
      )}
    </BannerItemContainer>
  );
}

export default OhsDashboardBannerItem;
