import { ApiResListsType } from 'global-services/api/OhsApiModels';
import OhsNoticeBoardRecord from 'notice-board/model/OhsNoticeBoardRecord';

const parseListAllocations = (
  noticeBoardLists: ApiResListsType<any[]> | null,
  allocationLists: OhsNoticeBoardRecord[] | null
): ApiResListsType<any[]> | null => {
  const nbList = noticeBoardLists?.items ?? [];

  if (nbList.length > 0) {
    const noticeBoardWithAllocation = nbList.map((item: OhsNoticeBoardRecord) => {
      const noticeBoardRecord: OhsNoticeBoardRecord = {
        ...item,
        subRows: [],
      };

      allocationLists?.map((allocItem: OhsNoticeBoardRecord) => {
        // check if the Notice Board is allocated to self
        const { allocationOf } = allocItem;
        if (item._id === allocItem._id) {
          noticeBoardRecord.subRows.push(allocItem);
        }
        // check if the Notice Board is allocated to a workplace
        if (allocationOf && allocationOf._id === item._id) {
          noticeBoardRecord.subRows.push(allocItem);
        }
        return null;
      });

      return noticeBoardRecord;
    }) as any[];

    const updateNoticeBoardListwithAllocation = {
      ...noticeBoardLists,
      items: noticeBoardWithAllocation,
    } as unknown as ApiResListsType<any[]>;

    return updateNoticeBoardListwithAllocation;
  }
  return null;
};

export default parseListAllocations;
