export default class OhsOnboardingFilterModels {
  viewPreset: 'view_1' | 'view_2' | 'view_3';

  archived: boolean;

  sort: string;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  constructor() {
    this.viewPreset = 'view_1';
    this.archived = false;

    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'ID: High to Low ', value: '{ "key": "_id", "order": 1 }' },
        { name: 'ID: Low to High', value: '{ "key": "_id", "order": -1 }' },
      ],
    };
    this.sort = '{ "key": "_id", "order": 1 }';
  }
}
