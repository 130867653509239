import React from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import validator from 'validator';

const useStyles = makeStyles()((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: '50%',
  },
}));

export type LoginInputs = {
  email: string;
  password: string;
};

type Props = { submitLogin: (data: LoginInputs) => void };

function OhsLoginForm(props: Props) {
  const { submitLogin } = props;
  const { classes } = useStyles();

  const queryParams = new URLSearchParams(useLocation().search);

  const username = queryParams.get('username');
  const password = queryParams.get('password');
  const idToken = queryParams.get('id_token');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>({
    defaultValues: {
      email: username && password ? username : '',
      password: username && password ? password : '',
    },
  });

  let errorMessage = '';

  if (errors.email?.type) {
    if (errors.email?.type === 'isEmail') {
      errorMessage = 'Please provide a valid email address.';
    } else errorMessage = 'Email cannot be empty.';
  }

  return (
    <form className={classes.form}>
      <TextField
        disabled={idToken != null}
        error={errors.email != null}
        variant="outlined"
        margin="normal"
        fullWidth
        label="Email Address"
        autoComplete="email"
        autoFocus
        {...register('email', {
          required: true,
          validate: { isEmail: (value) => validator.isEmail(value) },
        })}
        helperText={errorMessage}
      />
      <TextField
        disabled={idToken != null}
        error={errors.password != null}
        variant="outlined"
        margin="normal"
        fullWidth
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
        {...register('password', {
          required: true,
        })}
        helperText={errors.password?.type === 'required' ? 'Password cannot be empty.' : ''}
      />
      <Button
        disabled={idToken != null}
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        id="loginButtonId"
        data-testid="loginButtonTestId"
        className={classes.submit}
        onClick={handleSubmit((data) => {
          submitLogin(data);
        })}
      >
        Login
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href="/reset" variant="body2">
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link href="/office365.html" variant="body2">
            Login via SSO
          </Link>
        </Grid>
      </Grid>
    </form>
  );
}

export default OhsLoginForm;
