export default class OhsNoticeBoardFilterModels {
  view1NoticeBoardList: {
    viewPreset: 'view_1';
    archived?: boolean;
    workplaces?: string[];
  };

  view2NoticeBoardList: {
    viewPreset: 'view_2';
    archived?: boolean;
  };

  sort: string;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  constructor() {
    this.view1NoticeBoardList = {
      viewPreset: 'view_1',
      archived: false,
      workplaces: [],
    };
    this.view2NoticeBoardList = {
      viewPreset: 'view_2',
      archived: false,
    };
    this.sort = '{ "key": "_id", "order": 1 }';
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Title / Name: A to Z', value: '{ "key": "title", "order": 1 }' },
        { name: 'Title / Name: Z to A', value: '{ "key": "title", "order": -1 }' },
        { name: 'Date Created: Latest to Oldest', value: '{ "key": "dateCreated", "order": -1 }' },
        { name: 'Date Created: Oldest to Latest', value: '{ "key": "dateCreated", "order": 1 }' },
        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest ', value: '{ "key": "_id", "order": 1 }' },
      ],
    };
  }
}
