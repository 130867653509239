import React from 'react';

import { Row } from 'react-table';
import { Button, Icon } from 'semantic-ui-react';

import { registerButtonStyles } from '../../OhsModuleHeader';

export interface CopyButtonsProps {
  row: Row<any>;
  updateFunc?: (item: any) => void;
}
function OhsUpdateButtons({ row, updateFunc }: CopyButtonsProps) {
  const { classes } = registerButtonStyles();
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [updateDisabled, setUpdateDisabled] = React.useState(false);

  return (
    updateFunc &&
    row.original &&
    !row.original.updated &&
    !row.original.archived &&
    row.original.allocated !== true && (
      <Button
        onClick={async () => {
          setUpdateDisabled(true);
          setUpdateLoading(true);
          await updateFunc(row.original);
          setUpdateDisabled(false);
          setUpdateLoading(false);
        }}
        className={classes.button}
        size="small"
        color="orange"
        disabled={updateDisabled}
        loading={updateLoading}
      >
        <Icon className={classes.buttonIcons} name="edit" />
        <span className={classes.hideForSmall}>Update</span>
      </Button>
    )
  );
}

OhsUpdateButtons.defaultProps = { updateFunc: undefined };

export default OhsUpdateButtons;
