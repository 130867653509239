import React from 'react';

import { Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import QrCodeIcon from '@mui/icons-material/QrCode';
import _ from 'lodash';

import OhsComponentLoading from 'global-services/utils/OhsLoading';
import { OhsApiErrorCodes, checkContainsErrorCode } from 'global-services/api/OhsApiModels';
import { fetchCurrentUserSettings } from 'user/OhsUserServices';
import { OhsCurrentUser } from 'user/OhsUserModels';
import useOhsOrgControl from 'user/user-settings/hooks/OhsOrgControl';

import { fetchUserMfaList, userMfaEnrollRequest } from '../OhsMfaServices';
import { MfaAuthenticatorType, OhsMethodList, OhsMethods, OhsMfaMethod } from '../../OhsMfaTypes';
import OhsMfaMethodListsDisplay from './fragments/OhsMfaMethodListsDisplay';

interface Props {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setMfaEnrollMethodInfo: React.Dispatch<React.SetStateAction<OhsMfaMethod | undefined>>;
}

export const getMfaMethodList = async (
  user: OhsCurrentUser,
  hasMfaMethodPermission: (mfaMethod: OhsMethods, mfaEnabledList: string[]) => boolean
) => {
  const userMfaActiveList = await fetchUserMfaList();

  const activeMfaList = userMfaActiveList?.authenticators?.map((item: any) => item.method);
  const mfaMethodList: OhsMethodList[] = [
    {
      title: 'Email',
      method: 'email',
      name: user.email,
      isEnabled: activeMfaList?.includes('email'),
      icon: <EmailIcon />,
      permission: hasMfaMethodPermission('email', activeMfaList) ?? true,
    },
    {
      title: 'SMS',
      method: 'sms',
      name: `+${user.phoneCountryCode}${user.phone}`,
      isEnabled: activeMfaList?.includes('sms'),
      icon: <SmsIcon />,
      permission: hasMfaMethodPermission('sms', activeMfaList) ?? true,
    },
    {
      title: 'Authentication App',
      method: 'app',
      name: '',
      isEnabled: activeMfaList?.includes('app'),
      icon: <QrCodeIcon />,
      permission: hasMfaMethodPermission('app', activeMfaList) ?? true,
    },
  ];

  return mfaMethodList;
};

function OhsMfaEnrollMethodLists(props: Props) {
  const [userProfile, setOhsUserProfileRecord] = React.useState<OhsCurrentUser>();
  const { hasMfaMethodPermission, orgControl } = useOhsOrgControl(userProfile);
  const [activeMfaLists, setActiveMfaMethods] = React.useState<OhsMethodList[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { setMfaEnrollMethodInfo, setActiveStep } = props;

  const fetchUserInfo = async () => {
    setIsLoading(true);
    const getUserData = await fetchCurrentUserSettings();

    if (getUserData) {
      setOhsUserProfileRecord(getUserData);
    }
  };

  const handleEnrollMfaMethod = async (authInfo: MfaAuthenticatorType) => {
    setIsLoading(true);
    const response = await userMfaEnrollRequest(authInfo.method, authInfo.name);

    // check if MFA is required and move to enroll MFA
    if (_.has(response, 'success') && response?.success === false) {
      const setMfatoEnrollInfo = new OhsMfaMethod(authInfo);
      setMfaEnrollMethodInfo(setMfatoEnrollInfo);
      const hasErrorCode = checkContainsErrorCode(response?.error, OhsApiErrorCodes.MfaRequire);
      if (hasErrorCode) {
        if (activeMfaLists.length > 0) {
          setActiveStep(5); // Verify MFA if add additional MFA method
        } else {
          setActiveStep(3); // Enroll MFA
        }

        setIsLoading(false);
      }
    }

    if (_.has(response, 'success') && response?.success) {
      setMfaEnrollMethodInfo(response.result);
      setIsLoading(false);
      const goToStep = response?.result?.item?.recoveryCodes ? 2 : 3;
      setActiveStep(goToStep);
    }
  };

  React.useEffect(() => {
    fetchUserInfo();
  }, []);

  React.useEffect(() => {
    if (userProfile && orgControl) {
      getMfaMethodList(userProfile, hasMfaMethodPermission).then((mfaMethodList) => {
        setActiveMfaMethods(mfaMethodList);
        setIsLoading(false);
      });
    }
  }, [userProfile, orgControl]);

  if (isLoading) return <OhsComponentLoading />;
  const hasNoMobileNum = !userProfile?.phoneCountryCode && !userProfile?.phone;
  return (
    <Box mb={1} minHeight={100}>
      <OhsMfaMethodListsDisplay
        activeMfaLists={activeMfaLists}
        handleEnrollMfaMethod={handleEnrollMfaMethod}
        hasNoMobileNum={hasNoMobileNum}
      />
    </Box>
  );
}

export default OhsMfaEnrollMethodLists;
