import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import OhsSafetyPlanRecord from 'safety-plan/OhsSafetyPlanRecord';
import OhsReviewPlanRecord from 'review-plan/models/OhsReviewPlanRecord';

import { OhsContacts } from './OhsContractorModels';

export interface ContractorLocationType {
  main: string;
  mainOther: string | null;
  sub: string;
  subOther: string | null;
}

export type ContractorLinkageType = OhsSafetyPlanRecord | OhsReviewPlanRecord;
export default class OhsContractorRecord {
  _id: string;

  type: ModuleType.Contractor;

  tier?: OhsTier;

  allocationActive: boolean;

  links: ContractorLinkageType[];

  businessName: string;

  category: string;

  subcategory: string;

  contacts: OhsContacts[];

  attachments: OhsAttachment[];

  logo: OhsAttachment[];

  businessLogo?: OhsAttachment;

  createdBy: OhsModuleCreateBy;

  tzDateCreated: string;

  dateCreated: string;

  businessNotes: string | null;

  status: string | null;

  statusOther: string | null;

  categoryOther: string | null;

  subcategoryOther: string | null;

  referenceIdType: string | null;

  referenceId: string | null;

  subRows: any[];

  allocationOf: { _id: string; type: ModuleType.Contractor } | undefined;

  allocatedToSelf?: boolean;

  allocated: boolean;

  address: {
    street: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    postcode: string | null;
  };

  locations: ContractorLocationType[];

  constructor(tier?: OhsTier) {
    this._id = '';
    this.type = ModuleType.Contractor;
    this.tier = tier;
    this.allocationActive = true;
    this.links = [];
    this.businessName = '';
    this.businessNotes = '';
    this.status = '';
    this.statusOther = '';

    this.category = '';
    this.categoryOther = null;
    this.subcategory = '';
    this.subcategoryOther = null;
    this.contacts = [];
    this.attachments = [];
    this.logo = [];
    this.referenceId = '';
    this.referenceIdType = '';
    this.locations = [];
    this.subRows = [];
    this.allocated = false;
    this.address = {
      street: '',
      city: '',
      state: '',
      country: '',
      postcode: '',
    };
    this.createdBy = {
      type: ModuleType.User,
      _id: '',
      name: '',
      email: '',
    };
    this.tzDateCreated = '';
    this.dateCreated = '';
  }
}
