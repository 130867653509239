import OhsAssetRecord from 'assets/models/OhsAssetRecord';
import { ApiResListsType } from 'global-services/api/OhsApiModels';

const parseListAllocations = (
  assetLists: ApiResListsType<any[]> | null,
  allocationLists: OhsAssetRecord[] | null
): ApiResListsType<any[]> | null => {
  const crList = assetLists?.items ?? [];

  if (crList.length > 0) {
    const assetWithAllocation = crList.map((item: OhsAssetRecord) => {
      const assetRecord: OhsAssetRecord = {
        ...item,
        subRows: [],
      };

      allocationLists?.map((allocItem: OhsAssetRecord) => {
        const { allocationOf } = allocItem;
        if (item._id === allocItem._id) {
          assetRecord.subRows.push(allocItem);
        }
        if (allocationOf && allocationOf._id === item._id) {
          assetRecord.subRows.push(allocItem);
        }
        return null;
      });

      return assetRecord;
    }) as any[];

    const updateAssetListwithAllocation = {
      ...assetLists,
      items: assetWithAllocation,
    } as unknown as ApiResListsType<any[]>;

    return updateAssetListwithAllocation;
  }
  return null;
};

export default parseListAllocations;
