export default class OhsHrCorrespondenceFilterModels {
  viewPreset?: 'view_1';

  archived: boolean;

  sort: string;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  constructor() {
    this.viewPreset = undefined;
    this.archived = false;
    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'ID: High to Low ', value: '{ "key": "_id", "order": 1 }' },
        { name: 'ID: Low to High', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Date Created: Newest to Oldest', value: '{ "key": "dateCreated", "order": -1 }' },
        { name: 'Date Created: Oldest to Newest', value: '{ "key": "dateCreated", "order": 1 }' },
      ],
    };
    this.sort = '{ "key": "_id", "order": 1 }';
  }
}
