import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';

const handleRegisterAsyncData = (payload: any, totalPageName: string) => {
  if (payload) {
    const { items, pagination } = payload || { items: [], pagination: {} };

    const totalPageCountCookie = getOhsLocalStorage(totalPageName);
    const totalPageCount = totalPageCountCookie ? Number(totalPageCountCookie) : 1;

    if (items && items.length > 0) {
      const recordLists = {
        items: [...items],
        pagination: {
          ...pagination,
          page: pagination.page,
          next: pagination.next?._id,
          totalPages: totalPageCount,
        },
      } as any;
      return recordLists;
    }
  }
  return { items: [], pagination: {} };
};

export const handleRegisterCountAsyncData = (
  recordLists: any,
  currentStateModuleLists: any,
  totalPageName: string
) => {
  // store total pages to cookies
  setOhsLocalStorage(totalPageName, String(recordLists.pagination.totalPages));
  const totalPageCount = recordLists.pagination.totalPages ?? 1;

  const recordListsData = {
    ...currentStateModuleLists,
    pagination: {
      ...currentStateModuleLists?.pagination,
      totalPages: totalPageCount,
    },
  };
  return recordListsData;
};

export default handleRegisterAsyncData;
