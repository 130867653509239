import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResListsType, OhsApiRequestName } from 'global-services/api/OhsApiModels';
import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';
import OhsReviewPlanRecord, {
  OhsReviewPlansFilterPayload,
} from 'review-plan/models/OhsReviewPlanRecord';
import { OhsGlobalSearchFilter, OhsGlobalSearchPayload } from 'search/OhsSearchModels';
import { initialGlobalSearchPayload } from 'search/store/OhsSearchSlice';
import { ModuleType, TierType } from 'global-services/constants/OhsObject';
import globalModuleSearch from 'search/OhsSearchServices';
import { getApiReqNameByTab } from 'search/OhsSearchUtils';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';

import getReviewPlansList, { getReviewPlansAllocatedList } from './OhsReviewPlanServices';
import parseListAllocations from './utils/parseListAllocations';

export interface ReviewPlanListsRecordState {
  isLoading: boolean;
  reviewPlanList: ApiResListsType<any[]> | null;
  currentPage: number;
  currentTab: OhsTabKeyTypes;
}

export const defaultTaskSearchFilter: OhsGlobalSearchFilter = {
  ...initialGlobalSearchPayload.filter,
  modules: [ModuleType.ReviewPlan],
};
export const initialReviewPlanSearch: OhsGlobalSearchPayload = {
  searchKey: '',
  substringSearch: true,
  highlight: false,
  filter: defaultTaskSearchFilter,
};

const initialState: ReviewPlanListsRecordState = {
  isLoading: false,
  reviewPlanList: null,
  currentPage: 1,
  currentTab: OhsTabKeyTypes.List,
};

const reviewPlanViewPreset = {
  view1: 'view_1',
  view2: 'view_2',
  view3: 'view_3',
  view4: 'view_4',
};

const reviewPlanSearch = async (
  task: OhsGlobalSearchPayload,
  count: boolean,
  page: number,
  apiRequestName?: OhsApiRequestName
): Promise<ApiResListsType<OhsReviewPlanRecord[]> | null> => {
  const searchInfo = {
    ...task,
    filter: { ...task.filter, count, page, skipAllocatedRecords: true },
  };
  const globalSearchRes: any = await globalModuleSearch(searchInfo, apiRequestName);
  return globalSearchRes.result;
};

export const getViewPreset = (tierNum: number) => {
  switch (tierNum) {
    case 1:
      return 'view_1';
    case 2:
      return 'view_2';
    case 3:
      return 'view_3';
    case 4:
      return 'view_4';
    default:
      return undefined;
  }
};

const reviewPlansFilterOptions = (
  state: RootState,
  viewPreset: string
): Partial<OhsReviewPlansFilterPayload> => {
  const { globalfilter } = state;
  const filter = globalfilter.filterInfo;

  const {
    user: { user },
  } = state;

  const tierNum = user?.tierNum;

  if (tierNum === 3) {
    if (viewPreset === reviewPlanViewPreset.view1) {
      return {
        sort: { ...JSON.parse(String(filter.reviewPlanModule.sort)) },
        categories: filter.reviewPlanModule.categories ?? [],
      };
    }
    if (viewPreset === reviewPlanViewPreset.view2) {
      return {
        sort: { ...JSON.parse(String(filter.reviewPlanModule.sort)) },
        categories: filter.reviewPlanModule.categories ?? [],
        workplaces: filter.workplaces ?? [],
        allocatedToWorkplace: filter.reviewPlanModule.allocatedToWorkplace,
        workplaceOwned: filter.reviewPlanModule.workplaceOwned,
      };
    }
    if (viewPreset === reviewPlanViewPreset.view3) {
      return {
        sort: { ...JSON.parse(String(filter.reviewPlanModule.sort)) },
        categories: filter.reviewPlanModule.categories ?? [],
        archived: filter.reviewPlanModule.archived,
        allocatedToWorkplace: filter.reviewPlanModule.allocatedToWorkplace,
        allocatedToOrg: filter.reviewPlanModule.allocatedToOrg,
        notAllocated: filter.reviewPlanModule.notAllocated,
      };
    }
  }
  if (tierNum === 4) {
    if (viewPreset === reviewPlanViewPreset.view4) {
      return {
        sort: { ...JSON.parse(String(filter.reviewPlanModule.sort)) },
        categories: filter.reviewPlanModule.categories ?? [],
        archived: filter.reviewPlanModule.archived,
        allocatedToWorkplace: filter.reviewPlanModule.allocatedToWorkplace,
        workplaceOwned: filter.reviewPlanModule.workplaceOwned,
      };
    }
  }
  return {};
};

const getReviewPlansWithAllocations = (
  state: RootState,
  filterInfo: OhsReviewPlansFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const { reviewplans, globalSearch } = state;
  const hasGlobalSearch = globalSearch.searchInfo.searchKey !== '';
  const apiRequestName = getApiReqNameByTab(state.reviewplans.currentTab);
  const getReviewPlansListReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const currentPage = reviewplans.currentPage > 10 ? 0 : reviewplans.currentPage;
    const reviewPlansList = hasGlobalSearch
      ? await reviewPlanSearch(globalSearch.searchInfo, false, currentPage, apiRequestName)
      : await getReviewPlansList(filterInfo);

    if (reviewPlansList && reviewPlansList?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(reviewPlansList);
      });
    }

    if (reviewPlansList && !hasGlobalSearch) {
      const getIDs: string[] = [];
      reviewPlansList?.items.forEach((item: OhsReviewPlanRecord) => {
        if (item._id && item.tier?.type === TierType.T3) {
          getIDs.push(item._id);
        }
      });
      if (getIDs && getIDs.length === 0) {
        return new Promise((resolve) => {
          resolve(reviewPlansList);
        });
      }
      const allocatedList = await getReviewPlansAllocatedList(getIDs);

      const reviewPlansListWithAllocations = parseListAllocations(
        reviewPlansList,
        allocatedList
      ) as ApiResListsType<OhsReviewPlanRecord[]> | null;

      return new Promise((resolve) => {
        resolve(reviewPlansListWithAllocations);
      });
    }

    return reviewPlansList;
  };

  return getReviewPlansListReq();
};

const reviewPlanListRPC = async (
  state: RootState,
  filters: OhsReviewPlansFilterPayload
): Promise<any> => {
  const { user, reviewplans } = state;
  const { searchInfo } = state.globalSearch;
  const apiRequestName = getApiReqNameByTab(state.reviewplans.currentTab);
  const hasGlobalSearch = searchInfo.searchKey !== '';
  const userTier = user.user?.tierNum ?? 0;
  const { currentPage } = reviewplans;

  if (userTier && userTier === 2 && user.user?.configs.reviewplan?.PERMISSIONS.view) {
    const response = hasGlobalSearch
      ? await reviewPlanSearch(searchInfo, false, currentPage, apiRequestName)
      : await getReviewPlansList(filters);
    return response;
  }
  if (userTier && userTier === 3 && user.user?.configs.reviewplan?.PERMISSIONS.view) {
    const response = await getReviewPlansWithAllocations(state, filters);
    return response;
  }
  if (userTier && userTier === 4 && user.user?.configs.reviewplan?.PERMISSIONS.view) {
    const response = hasGlobalSearch
      ? await reviewPlanSearch(searchInfo, false, currentPage, apiRequestName)
      : await getReviewPlansList(filters);
    return response;
  }

  return null;
};

export const fetchReviewPlanListsAsync = createAsyncThunk<
  ApiResListsType<OhsReviewPlanRecord[]> | null,
  { preset: 'view_1' | 'view_2' | 'view_3' | 'view_4' },
  { state: RootState }
>('reviewPlan/fetchReviewPlanList', async ({ preset }, thunkAPI) => {
  const state = thunkAPI.getState();
  let setReviewPlansFilters: OhsReviewPlansFilterPayload = {};
  if (preset === 'view_3') {
    setReviewPlansFilters = {
      ...reviewPlansFilterOptions(state, preset),
      viewPreset: preset,
    };
  } else if (preset === 'view_1') {
    setReviewPlansFilters = {
      ...reviewPlansFilterOptions(state, preset),
      viewPreset: preset,
    };
  } else {
    setReviewPlansFilters = {
      ...reviewPlansFilterOptions(state, preset),
      viewPreset: preset,
      count: false,
      page: state.reviewplans.currentPage > 10 ? 0 : state.reviewplans.currentPage,
    };
  }

  try {
    const response = await reviewPlanListRPC(state, setReviewPlansFilters);
    return response as ApiResListsType<OhsReviewPlanRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchReviewPlanListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsReviewPlanRecord[]> | null,
  { preset: 'view_1' | 'view_2' | 'view_3' | 'view_4' },
  { state: RootState }
>('reviewPlan/fetchReviewPlanCountList', async ({ preset }, thunkAPI) => {
  const state = thunkAPI.getState();
  const setReviewPlansFilters: OhsReviewPlansFilterPayload = {
    ...reviewPlansFilterOptions(state, preset),
    viewPreset: preset,
    count: true,
    page: 1,
  };

  try {
    const response = await reviewPlanListRPC(state, setReviewPlansFilters);
    return response as ApiResListsType<OhsReviewPlanRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const reviewPlanSlice = createSlice({
  name: 'reviewPlan',
  initialState,
  reducers: {
    setReviewPlanCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setReviewPlanCurrentTab: (state, action: PayloadAction<OhsTabKeyTypes>) => {
      state.currentTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewPlanListsAsync.pending, (state) => {
        state.isLoading = true;
        state.reviewPlanList = {
          items: [],
          pagination: state.reviewPlanList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchReviewPlanListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.reviewPlanList = action.payload;
          if (state?.reviewPlanList?.pagination?.totalPages) {
            setOhsLocalStorage(
              'reviewPlanListTotalPages',
              state.reviewPlanList.pagination.totalPages
            );
          }
          const setItemsLastId = [...state.reviewPlanList.items].pop()?._id ?? '';
          state.reviewPlanList.pagination.next = setItemsLastId;
          state.reviewPlanList.pagination.totalPages =
            getOhsLocalStorage('reviewPlanListTotalPages') ?? 0;
          state.isLoading = false;
        }
      })
      .addCase(fetchReviewPlanListsAsync.rejected, (state) => {
        state.reviewPlanList = null;
      })
      .addCase(fetchReviewPlanListsCountAsync.fulfilled, (state, action) => {
        if (state.reviewPlanList && action.payload) {
          setOhsLocalStorage('reviewPlanListTotalPages', action.payload?.pagination.totalPages);
          state.reviewPlanList.pagination.totalPages = action.payload.pagination.totalPages ?? 0;
        }
      });
  },
});

const reviewPlansState = (state: RootState) => state.reviewplans;
export const getOhsReviewPlansModuleState = createSelector(
  [reviewPlansState],
  (reviewPlans) => reviewPlans
);
export const { setReviewPlanCurrentPage, setReviewPlanCurrentTab } = reviewPlanSlice.actions;
export const reviewPlanModuleReducer = reviewPlanSlice.reducer;
