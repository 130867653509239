import { OhsActionsRecord } from 'action/OhsActionModels';
import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import OhsCusvalValue from 'global-components/form/cusvals/OhsCusvalValue';
import { OhsCusval } from 'global-components/form/cusvals/OhsCusvalModel';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';

export default class OhsInspectionTaskRecord {
  allocationActive: boolean;

  complete: boolean;

  completionNotes: string;

  createdBy: OhsModuleCreateBy;

  dateCreated: string;

  dateDue: string;

  dateCompleted: string;

  dateSignedoff: string;

  signedoffBy: {
    name: string;
    email: string;
  };

  description: string;

  executionMeta: {
    notes: string;
  };

  for?: { _id: string; type: ModuleType.Inspection };

  links: OhsActionsRecord[];

  type: ModuleType.Task;

  tzDateCreated: string;

  _id: string;

  inExecution: boolean;

  tier?: OhsTier;

  templateExecution?: {
    cumulativeScorePercentage: number | string;
    sections: {
      _id: string;
      cumulativeScorePercentage: number | string;
      questions: {
        _id: string;
        answer: { links?: OhsActionsRecord[]; attachments?: OhsAttachment[] };
      }[];
    }[];
  };

  reference: string;

  constructor() {
    this.allocationActive = false;
    this.complete = false;
    this.completionNotes = '';
    this.createdBy = new OhsModuleCreateBy();
    this.dateCreated = '';
    this.dateDue = '';
    this.dateCompleted = '';
    this.dateSignedoff = '';
    this.signedoffBy = {
      name: '',
      email: '',
    };
    this.description = '';
    this.executionMeta = {
      notes: '',
    };
    this.for = undefined;
    this.links = [];
    this.type = ModuleType.Task;
    this.tzDateCreated = '';
    this._id = '';
    this.inExecution = false;
    this.reference = '';
  }
}

export interface OhsInspectionTaskResponse extends OhsInspectionTaskRecord {
  categoryCusvals?: OhsCusvalValue;

  subcategoryCusvals?: OhsCusvalValue;
}

export interface OhsInspectionTaskFormatted extends OhsInspectionTaskRecord {
  categoryCusvals?: OhsCusval[];

  subcategoryCusvals?: OhsCusval[];
}
