import { ModuleType } from 'global-services/constants/OhsObject';

import { OhsModuleCategory } from '../OhsModuleCategory';
import { getConfigValue, setPermissions, setCategory } from '../OhsConfigServices';
import OhsModulePermissions from '../OhsModulePermissions';

class OhsInspectionConfig {
  TITLE: string;

  CATEGORY_LIST: OhsModuleCategory[];

  PERMISSIONS: OhsModulePermissions;

  MORPH_PERMISSIONS: OhsModulePermissions;

  TYPE = ModuleType.Inspection;

  DEFAULT_DATE_DUE_FROM: string;

  constructor(config: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.CATEGORY_LIST = setCategory(config, 'CATEGORY_LIST');
    this.PERMISSIONS = new OhsModulePermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    this.DEFAULT_DATE_DUE_FROM = getConfigValue(config, 'DEFAULT_DATE_DUE_FROM');
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(this.MORPH_PERMISSIONS, getConfigValue(config, 'MORPH_PERMISSIONS'));
  }
}

export default OhsInspectionConfig;
