import React from 'react';

import { Link, Typography } from '@mui/material';

export default function OhsCopyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'© '}
      <Link color="inherit" href="https://www.safetychampion.com.au/">
        Safety Champion Software
      </Link>{' '}
      {new Date().getFullYear()}. All rights reserved.
    </Typography>
  );
}
